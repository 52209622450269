import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import JobpostSidebar from "./component/JobpostSidebar";
import JobPostContainer from "./component/JobPostContainer";
import "assets/css/createJob.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Actions from '../../store/actions/index';
import history from "utils/history";
import { JOBPOST_DATA, JOBPOST_STEPS_DATA } from "utils/constant";
import CircularProgress from "@mui/material/CircularProgress";

const steps = [
    {
        label: "Basic Info",
        description: ``,
        data: null,
    },
    {
        label: "Availability",
        description: "",
        data: { title: null, availability: 1 },
    },
    {
        label: "Expertise",
        description: ``,
        data: null,
    },
    {
        label: "Experience",
        description: ``,
        data: null,
    },
    {
        label: "Budget",
        description: ``,
        data: null,
    },
];

function JobPost(props) {

    const { match } = props;
    const dispatch = useDispatch();
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);
    const [activeStep, setActiveStep] = useState(0);
    const [loader, setLoader] = useState(false);
    const [jobPostSubmitting, setJobPostSubmitting] = useState(false);
    const jobId = match?.params?.jobId || null;

    const handleNext = () => {
        if (activeStep < (steps.length - 1)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        window.scrollTo(0, 0);
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        window.scrollTo(0, 0);
    };

    const handleStoreData = async (data) => {
        let postData = {};
        let processedData = {
            ...data,
            ...(data?.skills) && { skills: data?.skills?.map(skill => skill.id) },
            ...(data?.technologies) && { technologies: data?.technologies?.map(technology => technology.id) },
        };
        for (let i = 1; i <= (activeStep + 1); i++) {
            let replacedData = Object.entries(JOBPOST_STEPS_DATA[i]).reduce((op, [key,]) => {
                op[key] = processedData[key];
                return op;
            }, {});
            postData = { ...postData, ...replacedData };
        }
        if (saveAsDraft) {
            // postData.step = (activeStep === 4) ? activeStep + 1 : activeStep + 2;
            postData.step = activeStep + 1;
            await dispatch(Actions.saveJobPostAsDraft(postData, jobId)).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    toast.success("Job post saved successfully as draft");
                    history.push('/manage-job-posts');
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                    dispatch({ type: "SAVE_DRAFT_CLICK", payload: false });
                }
            }).catch(() => {
                toast.error("Something went wrong");
                dispatch({ type: "SAVE_DRAFT_CLICK", payload: false });
            })
        } else if (activeStep === 4) {
            setJobPostSubmitting(true);
            postData.status = 1;
            await dispatch(Actions.saveJobPost(postData, jobId)).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    toast.success("Job post created successfully");
                    history.push('/manage-job-posts');
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                    setJobPostSubmitting(true);
                }
            }).catch(() => {
                toast.error("Something went wrong");
                setJobPostSubmitting(true);
            })
        } else {
            handleNext();
        }
    }

    const getJobData = (id) => {
        setLoader(true);
        dispatch(Actions.getSingleJobPost(id)).then((res) => {
            if (res.status === 200) {
                let data = { ...res.data };
                data.experience = data?.experience?.id || data?.experience;
                data.payment_platform = data?.payment_platform?.toString() || '1';
                dispatch({ type: "JOBPOST_DATA", payload: data });
                if (data?.step) setActiveStep(data?.step - 1);
                setTimeout(() => {
                    setLoader(false);
                }, 500);
            } else {
                dispatch({ type: "JOBPOST_DATA", payload: JOBPOST_DATA });
                setLoader(false);
                toast.error("Job could not be found");
                history.push('/manage-job-posts');
            }
        }).catch(() => {
            toast.error("Something went wrong...");
            setLoader(false);
            history.push('/manage-job-posts');
        });
    }

    useEffect(() => {
        if (jobId) {
            getJobData(jobId);
        }
    }, [jobId])

    useEffect(() => {
        saveAsDraft && dispatch({ type: "SAVE_DRAFT_CLICK", payload: false });
        dispatch({ type: "JOBPOST_DATA", payload: JOBPOST_DATA });
        setActiveStep(0);
    }, []);

    return (
        <Container>
            {loader ?
                <div className='job-post-form-loader'>
                    <CircularProgress className='custom-circle-loder' />
                </div>
                :

                <Grid container className="createJob-container" data-testid="jobpost-container">
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <JobpostSidebar activeStep={activeStep} steps={steps} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <JobPostContainer activeStep={activeStep} steps={steps} back={handleBack}
                            next={handleNext} storeData={handleStoreData} processing={jobPostSubmitting} />
                    </Grid>
                </Grid>
            }
        </Container>
    );
}

export default JobPost;