import React from "react";
import FutureIcon1 from 'assets/img/icons/future-icon1.png';
import FutureIcon2 from 'assets/img/icons/future-icon2.png';
import FutureIcon3 from 'assets/img/icons/future-icon3.png';
import FutureIcon4 from 'assets/img/icons/future-icon4.png';
import FutureIcon5 from 'assets/img/icons/future-icon5.png';
import FutureIcon6 from 'assets/img/icons/future-icon6.png';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/aboutus.css";
import Typography from '@mui/material/Typography';

const MiddleSection = () => {

    const resolutionData = [{ id: 1, img: FutureIcon1, className: 'future-card', title: 'Global Presence', des: 'Let enterprises connect with top software development agencies’ talent!!' },
    { id: 2, img: FutureIcon2, className: 'future-card future-card1', title: 'Trusted Enterprises & Resources', des: 'Connect with certified software development teams for hire or get hired.' },
    { id: 3, img: FutureIcon3, className: 'future-card future-card2', title: 'Assured Payment', des: 'Get paid through us or directly from clients, flexibility is our top priority.' },
    { id: 4, img: FutureIcon4, className: 'future-card future-card3', title: 'More Versatile', des: 'Small yet arguably more qualified vetting process that stands ideal for the software development marketplace.' },
    { id: 5, img: FutureIcon5, className: 'future-card future-card3', title: 'Ease of Use', des: 'Signup, connect and chat, hire. Difference is the platform that enables all at one place.' },
    { id: 6, img: FutureIcon6, className: 'future-card future-card4', title: '24/7 Support', des: 'Get answered via FAQs and extensive knowledge bases, or even chat.' }]

    return (
        <div className="future-content">
            <Container>
                <Grid container>
                    <Grid item lg={6} md={10}>
                        <div className="heading">
                            <Typography variant="h4">
                                Revolutionizing Your Business’s Future with SourceFirms…Thinking Why?
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    {resolutionData.map((data) => (
                        <Grid item lg={4} md={4} sm={4} xs={12} key={data.id}>
                            <div className={data.className}>
                                <div className="future-icon">
                                    <img src={data.img} alt="" />
                                </div>
                                <div className="future-text">
                                    <Typography variant="h4">{data.title}</Typography>
                                    <Typography variant="body1">{data.des}</Typography>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    )
}

export default MiddleSection