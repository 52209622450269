import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import 'assets/css/featureCard.css'
import 'assets/css/developer-profile.css';
import editIcon from 'assets/img/icons/edit-icon.svg';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import DeleteProjectDialog from 'components/Dialog/DeleteProjectDialog';
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import * as DeveloperActions from '../../../store/actions/manageDevelopers/manageDevelopers.actions';
import Typography from '@mui/material/Typography';
import history from 'utils/history';
import linkIcon from "assets/img/icons/link-icon.svg";
import { SpliceList } from 'components/SpliceList/SpliceList';
import ProjectDetailsDialog from "../../../components/Dialog/ProjectDetailsDialog";
import { getDeveloperTabDetails } from 'utils/constant';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import noProjectImage from "assets/img/icons/no-projects-icon.svg";

function ProjectTab(props) {
    const { currentTab, getTabDetails, developerDetails } = props;
    const userProjectList = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperProjectList);
    const userProjectCount = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperProjectCount);
    const [projectId, setprojectId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [openDetailsDialogue, setOpenDetailsDialogue] = useState(false);
    const [singleProjectData, setSingleProjectData] = useState(null);
    const pageSize = 5;

    const handleOpenDialog = (id) => {
        setprojectId(id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handlePages = (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(DeveloperActions.getAgencyDeveloperProjectById(developerDetails?.id, pageNumber, pageSize));
    }
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        dispatch(DeveloperActions.deleteDevelopersProject(developerDetails?.id, projectId)).then(response => {
            if (response.status === 204) {
                toggalLoader(false);
                setOpenDialog(false);
                toast.success('Project deleted successfully');
                getTabDetails(getDeveloperTabDetails(currentTab, developerDetails?.id));
            } else {
                toggalLoader(false);
                setOpenDialog(false);
                toast.error('Please try again!');
            }
        }).catch(() => {
            toast.error("Something went wrong...");
        })
    }

    const handleOpenDetailsDialogue = (project = null) => {
        setOpenDetailsDialogue(!openDetailsDialogue);
        setTimeout(() => {
            setSingleProjectData(project);
        }, (project ? 0 : 500));
    }
    return (
        <>
            {developerDetails?.is_projects_in_review ||
                <IconButton
                    className="edit-icon editBtn"
                    onClick={() => history.push(`/manage-developers/${developerDetails?.id}/edit-projects`)}
                >
                    <img src={editIcon} alt="img" />
                </IconButton>
            }
            {
                (!userProjectList || userProjectList.length === 0)
                    ?
                    <Grid className="certificate-card-empty">
                        <div>
                            <img src={noProjectImage} alt="img" />
                            <Typography>No Projects!</Typography>
                        </div>
                    </Grid>
                    :
                    <>
                        {developerDetails?.is_projects_in_review &&
                            <Typography className='approval-tag profileInnerApproval-tag'>
                                Project is under approval
                            </Typography>
                        }
                        <Typography className="count-profileTitle" component="p">
                            <span>{userProjectCount}</span> Projects
                        </Typography>
                        <Grid container className="projectsList">
                            {userProjectList && userProjectList.length > 0 && userProjectList.map((project, index) => (
                                <Grid item className="projectListItem" key={index}>
                                    <Card className="project-card">
                                        <CardContent>
                                            <Grid className="project-top">
                                                <div className="icon-container">
                                                    <p>{project?.title?.substring(0, 2)?.toUpperCase()}</p>
                                                </div>
                                                <div className="project-title">
                                                    <p className="name cursor-pointer" onClick={() => handleOpenDetailsDialogue(project)}>{project?.title}</p>
                                                </div>
                                                <div className="" key={index} style={{ marginLeft: 'auto' }}>
                                                    {!developerDetails?.is_projects_in_review &&
                                                        <IconButton
                                                            aria-label="delete"
                                                            size="medium"
                                                            className="deleteIconBtn cursor-pointer"
                                                            onClick={() => handleOpenDialog(project.id)}
                                                        >
                                                            <img
                                                                src={deleteIcon}
                                                                alt="img"
                                                            />
                                                        </IconButton>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid className="about_project">
                                                <Typography>
                                                    {project?.description}
                                                </Typography>
                                            </Grid>
                                            <p className="CategoryTitle portfolioTabSkill">Used Technologies</p>
                                            <Grid className='skillset'>
                                                <List className='skillList'>
                                                    <SpliceList data={project?.technologies} mainClass="skillList-item" subClass="more-skill"
                                                        limit={3}
                                                    />
                                                </List>
                                            </Grid>
                                            <p className="CategoryTitle portfolioTabSkill">Used Skills</p>
                                            <Grid className='skillset'>
                                                <List className='skillList'>
                                                    <SpliceList data={project?.skills} mainClass="skillList-item" subClass="more-skill"
                                                        limit={3}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid className="duration-details">
                                                <Typography>
                                                    {(project?.duration > 0) &&
                                                        <>
                                                            <span>{project?.duration} Months</span>
                                                            {project?.is_on_going && <span> - Ongoing</span>}
                                                        </>
                                                    }
                                                </Typography>
                                                {project?.link &&
                                                    <a href={project?.link} target="_blank">{/* eslint-disable-line */}
                                                        <img src={linkIcon} alt="img" />
                                                    </a>
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        {userProjectList && userProjectList.length > 0 &&
                            <PaginationRounded
                                totalPages={Math.ceil(userProjectCount / pageSize)}
                                handlePages={handlePages}
                                page={currentPage}
                            />
                        }
                        <DeleteProjectDialog
                            open={openDialog}
                            close={handleCloseDialog}
                            confirm={handleConfirmDelete}
                            message="Are you Sure you want to Delete this Project?"
                            description="This action cannot be undone. Deleting it will erase all data associated with this project from system."
                        />
                        <ProjectDetailsDialog
                            open={openDetailsDialogue}
                            close={handleOpenDetailsDialogue}
                            data={singleProjectData}
                        />
                    </>
            }
        </>
    );
}

export default ProjectTab;