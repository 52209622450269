import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import { Field, reduxForm } from 'redux-form';
import { required } from 'utils/Validation';
import SingleAutocomplete from 'components/CustomeAutoComplete/SingleAutocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import { SALARY_TYPE } from 'utils/constant';
import { useDispatch } from 'react-redux';
import validate from "./OfferValidate";
import defaultImg from "assets/img/default-avatar.png";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { USER_TYPES } from 'utils/constant';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



function OfferDialog(props) {

    const { open, close, data, jobposts, candidates, jobPostChange, handleSubmit, change, valid, reset, submitting, jobpostLoading, candidateLoading } = props;
    const dispatch = useDispatch();

    const handleClose = (__, reason) => {
        if (reason === "backdropClick") return false;
        setTimeout(() => {
            dispatch(reset('OfferForm'));
            dispatch({ type: "PROCESSED_CANDIDATES_LIST", payload: [] });
        }, 500);
        close();
    }
    const handleChangeJobPost = (value) => {
        if (value) {
            change('candidate', '');
            const salary = SALARY_TYPE.find(sal => sal.id === value.salary_type);
            change('sal_type', salary?.title);
            salary && change('amount', salary.budget_from);
        } else {
            change('candidate', '');
            change('sal_type', '');
            change('amount', '');
        }
        jobPostChange(value?.id)
    }

    const beforeSubmit = (values) => {
        values.salary_type = values?.job_post?.salary_type;
        handleSubmit();
    }

    return (
        <>
            <div>
                {/* modal start */}
                <BootstrapDialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    className='approval_modal offer-accept-model'
                >
                    <BootstrapDialogTitle id="customized-dialog-title" style={{ pointerEvents: submitting ? "none" : "inherit" }} className='model-top-heading' onClose={handleClose}>
                        <Typography variant='h3'>Make an Offer</Typography>
                    </BootstrapDialogTitle>
                    <form onSubmit={handleSubmit(beforeSubmit.bind(this))} data-testid="make-offer-form" id='make-offer-form'>
                        <DialogContent className='interview-text'>
                            <div className='offer-content'>
                                <div className='inner-content'>
                                    <div className='offer-profile-img'>
                                        <img src={data?.profile_image || defaultImg} alt='' />
                                    </div>
                                    <div className='offer-profile-text'>
                                        <Typography variant='h5'>{data?.company_name}</Typography>
                                        <Typography variant='body1'>{data?.city?.name}, {data?.city?.country_name}</Typography>
                                    </div>
                                </div>
                            </div>


                            <div className='center-content'>
                                <div className='interview-data'>
                                    <div className='select-option-data'>
                                        <Field
                                            name="job_post"
                                            component={SingleAutocomplete}
                                            id="job_post"
                                            labelText="Job post*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={jobposts || []}
                                            displayKey="title"
                                            validate={[required]}
                                            onChange={value => handleChangeJobPost(value)}
                                            onInputChange={(value) => value}
                                            inputProps={{
                                                placeholder: "Select job post",
                                            }}
                                            zIndex={1300}
                                            loading={jobpostLoading}
                                        />
                                    </div>
                                    {data?.user_type !== USER_TYPES.FREELANCER &&
                                        <div className='select-option-data'>
                                            <Field
                                                name="candidate"
                                                component={SingleAutocomplete}
                                                id="candidate"
                                                labelText="Developer*"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                options={candidates || []}
                                                displayKey="uid"
                                                validate={[required]}
                                                onChange={(value) => value}
                                                onInputChange={(value) => value}
                                                inputProps={{
                                                    placeholder: "Select developer id",
                                                }}
                                                zIndex={1300}
                                                loading={candidateLoading}
                                            />
                                        </div>
                                    }
                                    <div className='select-option-data'>
                                        <Field
                                            name="sal_type"
                                            component={CustomInput}
                                            id="sal_type"
                                            labelText="Salary Type*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            // validate={[required]}
                                            inputProps={{
                                                placeholder: "Developer salary type",
                                                disabled: true,
                                            }}
                                        />
                                    </div>
                                    <div className='select-option-data'>
                                        <FormControl variant="standard" className="formControl">
                                            <Field
                                                name="amount"
                                                component={CustomInput}
                                                id="amount"
                                                labelText="Enter Amount*"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validate={[required]}
                                                inputProps={{
                                                    placeholder: "Enter amount",
                                                    type: "number"
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions className='bottom last-content-btn'>
                            <Link
                                href={void (0)}
                                style={{ pointerEvents: submitting ? "none" : "inherit" }}
                                underline="none"
                                onClick={handleClose}
                                className='cancelBtn'>
                                Cancel
                            </Link>
                            <LoadingButton
                                type="submit"
                                className={`modalBtn primaryButton ${submitting && 'loading'}`}
                                loading={submitting}
                                variant="contained"
                                loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                                disabled={!valid}
                            >
                                Send
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </BootstrapDialog>
            </div>

        </>
    );
}

const OfferReduxForm = reduxForm({
    form: "OfferForm",
    validate,
    enableReinitialize: true,
})(OfferDialog);

export default OfferReduxForm;
