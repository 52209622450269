import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import "assets/css/aboutus.css";
import "assets/css/subscription.css";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Google from "assets/img/google-img.png";
import Facebook from "assets/img/facebook-img.png";
import Argrace from "assets/img/argrace-img.png";
import Samsung from "assets/img/samsung-img.png";
import Ebay from "assets/img/ebay-img.png";
import Netflix from "assets/img/netflix.png";
import futureImage from "assets/img/future-lt-img.png";
import FutureIcon1 from "assets/img/icons/future-icon1.png";
import FutureIcon2 from "assets/img/icons/future-icon2.png";
import FutureIcon3 from "assets/img/icons/future-icon3.png";
import FutureIcon4 from "assets/img/icons/future-icon4.png";
import FutureIcon5 from "assets/img/icons/future-icon5.png";
import FutureIcon6 from "assets/img/icons/future-icon6.png";
import { Accordion } from "@mui/material";
import AccordianIcon from "assets/img/accordion-icon.svg";
import Footer from "components/Footer/Footer";
import { getSubscriptonPlan } from "store/actions";
import { useLocation } from "react-router";
import {
  PATH_USER_TYPE_MAP,
  hasActiveSubscription,
  USER_TYPES,
} from "utils/constant";
import history from "utils/history";
import { isLogIn } from "utils/Auth";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import { cancelSubscription } from "store/actions";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";

function Subscription() {
  const dispatch = useDispatch();
  const url = useLocation();
  const userType = PATH_USER_TYPE_MAP[url.pathname];
  const breadCrumbs = [{ name: "home", path: "/home" }];
  const [faqExpanded, setFaqExpanded] = React.useState("");
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const [actionDialogue, setActionDialogue] = React.useState({
    open: false,
    title: "Are you sure ?",
    message:
      "You want to cancel your subscription? This will be canceled at the end of the current cycle.",
  });
  const [plans, setPlans] = React.useState([]);
  const currentSubscriptionPlan = plans?.find(
    (data) => data?.is_current_plan?.id
  );
  
  const HasCurrentSubscription = plans?.map((data) =>
    data?.is_current_plan?.id ? true : false
  );
  const HasSubscriptionPlan = HasCurrentSubscription?.includes(true);

  const getPlanListApi = () => {
    dispatch(getSubscriptonPlan(userType, isLogIn()))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data?.results ?? response.data;
          setPlans(data);
        } else {
          setPlans([]);
        }
      })
      .catch(() => {
        setPlans([]);
      });
  };
  React.useEffect(() => {
    getPlanListApi();
  }, [userType]);

  const setting1 = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    swipe: false,
    autoplaySpeed: 0,
    speed: 6000,
    arrows: false,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const handleChange = (panel, section) => (event, isExpanded) => {
    if (section === "FAQ") {
      setFaqExpanded(isExpanded ? panel : false);
    }
  };

  const resolutionData = [
    {
      id: 1,
      img: FutureIcon1,
      className: "future-card",
      title: "Global Presence",
      des: "Let enterprises connect with top software development agencies’ talent!!",
    },
    {
      id: 2,
      img: FutureIcon2,
      className: "future-card future-card1",
      title: "Trusted Enterprises & Resources",
      des: "Connect with certified software development teams for hire or get hired.",
    },
    {
      id: 3,
      img: FutureIcon3,
      className: "future-card future-card2",
      title: "Assured Payment",
      des: "Get paid through us or directly from clients, flexibility is our top priority.",
    },
    {
      id: 4,
      img: FutureIcon4,
      className: "future-card future-card3",
      title: "More Versatile",
      des: "Small yet arguably more qualified vetting process that stands ideal for the software development marketplace.",
    },
    {
      id: 5,
      img: FutureIcon5,
      className: "future-card future-card3",
      title: "Ease of Use",
      des: "Signup, connect and chat, hire. Difference is the platform that enables all at one place.",
    },
    {
      id: 6,
      img: FutureIcon6,
      className: "future-card future-card4",
      title: "24/7 Support",
      des: "Get answered via FAQs and extensive knowledge bases, or even chat.",
    },
  ];
  const ButtonText = (hasPlan,plan) => {
    if (HasSubscriptionPlan || hasActiveSubscription(userDetails)) {
      if (hasPlan) {
        return "Cancel";
      } else if(parseFloat(plan?.amount) < parseFloat( currentSubscriptionPlan?.amount)){
        return "Buy Now";
      }
      else return "Upgrade";
    } else {
      return "Get Started";
    }
  };

  const openCancelSubscriptionDialog = () => {
    setActionDialogue({ ...actionDialogue, open: true });
  };

  const handlePaymentStart = (planId, hasPlan) => {
    if (isLogIn()) {
      if (!userDetails?.is_email_verified || userDetails?.is_new_registered) {
        history.push("/my-profile/add");
      } else {
        if (HasSubscriptionPlan) {
          if (hasPlan) {
            {
              openCancelSubscriptionDialog();
            }
          } else
            return history.push({
              pathname: `/make-payment/`,
              state: { planId, type: "upgrade" },
            });
        } else {
          history.push({ pathname: `/make-payment/`, state: { planId } });
        }
      }
    } else {
      history.push("/login/");
    }
  };

  const closeActionDialogue = () => {
    setActionDialogue({ ...actionDialogue, open: false });
  };

  const cancelSelectedSubscription = () => {
    dispatch(
      cancelSubscription(currentSubscriptionPlan?.is_current_plan?.id)
    ).then((response) => {
      if (response.status == 200) {
        toast.info("Your transaction cancellation request is sent.");
        getPlanListApi();
      }
    });
    closeActionDialogue();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
       {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  const subscriptionButton = (currentPlan) => {
    if (currentPlan?.is_current_plan?.is_cancelled === true) {
      return (
        <p className="cancel-subscription-text">
          You have already cancel this plan
        </p>
      );
    } else if (currentPlan?.is_current_plan?.subscription_status === "in progress") {
      return (
        <p className="plan-progress-subscription-text">
          Your plan purchase is currently being processed.
        </p>
      );
    } else {
      return (
        <Button
          className="btn btn-primary"
          onClick={() =>
            handlePaymentStart(
              currentPlan?.id,
              currentPlan?.is_current_plan?.id
            )
          }
        >
          {ButtonText(currentPlan?.is_current_plan?.id,currentPlan)}
        </Button>
      );
    }
  };
  return (
    <div>
      <div
        className={`subscription-main-content dashboard-main-content ${
          userDetails?.user_type === USER_TYPES.CLIENT
            ? "dashboard-main-client-content"
            : ""
        }`}
      >
        <div className="main-panel">
          <AdminNavbar />

          <section className="privacy-policy-section subscription-plan-section">
            <div className="privacy-policy-inner subscription-plan-inner">
              <Container>
                <Grid container>
                  <Grid item lg={6}>
                    <Stack spacing={2} className="breadcrumb-div">
                      <CustomBreadcrumbs
                        current="Plan"
                        previous={breadCrumbs}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid className="banner-header">
                  <Typography variant="h2">Subscription Plan</Typography>
                </Grid>
              </Container>
            </div>
          </section>

          <section className="subscription-card-section">
            <div className="subscription-card-inner">
              <Container className="subscription-card-container">
                <div>
                  <Slider {...settings} className="projectImage_slider">
                    {plans?.map((plan) => (
                      <div className="sub-slider-inner" key={plan?.id}>
                        <div className={`subscription-ft-card ${plan?.is_current_plan?.id?"active-current-plan":""}`}>
                          <div className="subscription-ft-header">
                            <Typography variant="h3">{plan?.name}</Typography>
                            <Typography variant="body1">
                              <ReadMoreReadLess
                                charLimit={30}
                                readMoreText={"Read More"}
                                readLessText={"Read Less"}
                                readMoreClassName="read-more-open-39A1FF"
                                readLessClassName="read-more-close-39A1FF"
                                data={plan?.description || ""}
                                textType={false}
                              />
                            </Typography>
                            <Typography variant="h4">
                              {plan?.currency.sign}
                              {parseFloat(plan?.amount)
                                ?.toFixed(2)
                                ?.replace(/\.?0+$/, "")}
                              <span>/ {plan?.recurring.name}</span>
                            </Typography>
                            {subscriptionButton(plan)}
                          </div>
                          <div className="subscription-ft-body">
                            <div
                              className="new-ul"
                              dangerouslySetInnerHTML={{
                                __html: plan?.plan_details,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Container>
            </div>
          </section>

          <section className="subscription-slider-section">
            <div className="subscription-slider-inner">
              <Container>
                <Grid container spacing={3} alignItems="center">
                  <Grid item lg={2} md={3} sm={3} xs={4}>
                    <Grid className="subscription-slider-heading">
                      <Typography variant="h3">
                        Trusted By Global Companies{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item lg={10} md={9} sm={9} xs={8}>
                    <Slider
                      {...setting1}
                      className="subscription-slider-wrapper"
                    >
                      <div className="subscription-slider-logo">
                        <img src={Google} alt="Developimg1" />
                      </div>
                      <div className="subscription-slider-logo">
                        <img src={Facebook} alt="Developimg1" />
                      </div>
                      <div className="subscription-slider-logo">
                        <img src={Argrace} alt="Developimg1" />
                      </div>
                      <div className="subscription-slider-logo">
                        <img src={Samsung} alt="Developimg1" />
                      </div>
                      <div className="subscription-slider-logo">
                        <img src={Ebay} alt="Developimg1" />
                      </div>
                      <div className="subscription-slider-logo">
                        <img src={Netflix} alt="Developimg1" />
                      </div>
                    </Slider>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </section>

          <section className="future-include-section">
            <div className="future-include-inner">
              <Container>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid className="future-lt-box">
                      <Grid className="future-patter1"></Grid>
                      <Grid className="future-patter2"></Grid>
                      <Grid className="future-lt-img">
                        <img src={futureImage} alt="img" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid className="future-rt-box">
                      <Grid className="heading">
                        <Typography variant="h2">
                          <span> Features </span> included
                        </Typography>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Link className="future-include-card">
                            <Typography variant="h3">
                              A Pool of Experienced Agencies
                            </Typography>
                            <Typography variant="body1">
                              Agencies with experienced resources to work on
                              200+ sophisticated upgraded technology to deliver
                              advanced solutions.
                            </Typography>
                          </Link>
                          <Link className="future-include-card">
                            <Typography variant="h3">Security</Typography>
                            <Typography variant="body1">
                              Verify software development agencies to ensure
                              100% data security.
                            </Typography>
                          </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Link className="future-include-card">
                            <Typography variant="h3">
                              Time-Zone Aligned
                            </Typography>
                            <Typography variant="body1">
                              Hire software development agencies to manage
                              projects as per your time zone.
                            </Typography>
                          </Link>
                          <Link className="future-include-card">
                            <Typography variant="h3">
                              Supportive Policies
                            </Typography>
                            <Typography variant="body1">
                              Ensure reliable hiring with our supportive
                              policies. Our offered agencies have skilled
                              resources that fulfill client’s needs.
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </section>

          <section className="aboutus-section subscription-global-section">
            <div className="aboutus-inner">
              <div className="about-bottom-content">
                {/* <MiddleSection /> */}
                <div className="future-content">
                  <Container>
                    <Grid container justifyContent="center">
                      <Grid item lg={12} md={12}>
                        <div className="heading">
                          <Typography variant="h4">
                            <span> We&apos;re here </span> for you
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="bg-shape-blue">
                      <Grid container>
                        {resolutionData.map((data) => (
                          <Grid item lg={4} md={4} sm={6} xs={12} key={data.id}>
                            <div className={data.className}>
                              <div className="future-icon">
                                <img src={data.img} alt="" />
                              </div>
                              <div className="future-text">
                                <Typography variant="h4">
                                  {data.title}
                                </Typography>
                                <Typography variant="body1">
                                  {data.des}
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </section>

          <section className="faq-question client-faq-question subscription-faq-section">
            <div className="faq-section">
              <div className="faq-inner">
                <Container>
                  <Grid container>
                    <Grid item sm={12}>
                      <div className="heading">
                        <h2>
                          Frequently Asked <span> Questions </span>
                        </h2>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <div className="faq-accordian">
                        <Accordion
                          className="acordian-content"
                          expanded={faqExpanded === "panel30a-header"}
                          onChange={handleChange("panel30a-header", "FAQ")}
                        >
                          <AccordionSummary
                            expandIcon={<img src={AccordianIcon} />}
                            aria-controls="panel10a-content"
                            id="panel30a-header"
                            className="accordion-panel"
                          >
                            <Typography variant="h2">
                              Is SourceFirms free to join as a software
                              development agency?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="faq-accordion-body">
                            <Typography variant="body1">
                              Yes! It’s completely free to join, find work, and
                              find new projects as an agency on our platform. We
                              do not charge any fee while registration or
                              connecting with clients for projects.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          className="acordian-content"
                          expanded={faqExpanded === "panel31a-header"}
                          onChange={handleChange("panel31a-header", "FAQ")}
                        >
                          <AccordionSummary
                            expandIcon={<img src={AccordianIcon} />}
                            aria-controls="panel11a-content"
                            id="panel31a-header"
                            className="accordion-panel"
                          >
                            <Typography variant="h2">
                              As a software development agency, Can I grow my
                              business with SourceFirms?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="faq-accordion-body">
                            <Typography variant="body1">
                              Absolutely! Many successful businesses had bright
                              careers with strong backgrounds and employment
                              records on SourceFirms. Our platform supports
                              agencies who are new to the freelancing field to
                              build strong backgrounds. We support every agency
                              for building a successful career in the software
                              development field.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <div className="faq-accordian">
                        <Accordion
                          className="acordian-content"
                          expanded={faqExpanded === "panel32a-header"}
                          onChange={handleChange("panel32a-header", "FAQ")}
                        >
                          <AccordionSummary
                            expandIcon={<img src={AccordianIcon} />}
                            aria-controls="panel7a-content"
                            id="panel32a-header"
                            className="accordion-panel"
                          >
                            <Typography variant="h2">
                              How does SourceFirms benefit me as a software
                              development agency?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="faq-accordion-body">
                            <Typography variant="body1">
                              Many software development agencies prefer to work
                              through the B2B marketplace because of the freedom
                              and flexibility. Moreover, by working through the
                              B2B marketplace, agencies can get a chance to work
                              with great clients/brands from around the globe.
                              SourceFirms is the world&apos;s first and fast
                              resource augmentation marketplace which provides
                              enough opportunity to talents along with the
                              support they need for success.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          className="acordian-content"
                          expanded={faqExpanded === "panel33a-header"}
                          onChange={handleChange("panel33a-header", "FAQ")}
                        >
                          <AccordionSummary
                            expandIcon={<img src={AccordianIcon} />}
                            aria-controls="panel8a-content"
                            id="panel33a-header"
                            className="accordion-panel"
                          >
                            <Typography variant="h2">
                              What types of projects can I find on SourceFirms?
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="faq-accordion-body">
                            <Typography variant="body1">
                              Brands and companies across the globe are looking
                              for professionals with the desired skill sets to
                              complete their jobs. In the SourceFirms platform,
                              you can easily find jobs that match your skill set
                              in your desired field. You can find all kinds of
                              work from fixed prices to hourly rate contracts on
                              the SourceFirms platform.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </section>
        </div>
        <ConfirmationDialogue
          open={actionDialogue.open}
          close={closeActionDialogue}
          title={actionDialogue.title}
          message={actionDialogue.message}
          buttonText="Yes"
          buttonCallback={() => cancelSelectedSubscription()}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Subscription;
