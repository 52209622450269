import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "assets/css/manageJob.css";
import Button from "@mui/material/Button";
import ManageJobPostSidebar from "views/Jobpost/component/ManageJobPostSidebar";
import ManageJobPostList from "views/Jobpost/component/ManageJobPostList";
import * as Actions from "../../store/actions/index";
import { useDispatch, useSelector /* useSelector */ } from "react-redux";
import history from "utils/history";
import JobPostListSkeleton from "./component/Skeletons/JobPostListSkeleton";
import JobPostSkeleton from "./component/Skeletons/JobPostSkeleton";
import ScrollToggleButton from "components/CustomHooks/ScrollToggleButton";
import { toast } from "react-toastify";
import { hasActiveSubscription } from "utils/constant";

function ManageJobPost() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(null);
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const currentStatus = useSelector(
    (state) => state?.jobPostReducer?.currentStatus
  );
  const pageLoading = useSelector((state) => state.commonReducer?.pageLoading);
  const contentLoading = useSelector(
    (state) => state.commonReducer?.contentLoading
  );
  const pageSize = 10;

  const getJobPostList = (status, page, pageSize) => {
    if (page !== currentPage || status !== currentStatus) {
      dispatch({ type: "CONTENT_LOADING", payload: true });
      dispatch(Actions.getJobPost(status, page, pageSize));
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    getJobPostList(0, 1, pageSize);
    return () => {
      dispatch({ type: "PAGE_LOADING", payload: true });
    };
  }, []);

  const handleCreateJobPost = () => {
    if (userDetails.can_add_new && hasActiveSubscription(userDetails)) {
      history.push("/create-job");
    } else if (userDetails.can_add_new == false) {
      toast.error("Your job creation limit has been reached. You'll need to wait until your subscription cycle ends to create more.");
    } else {
      toast.error("Since your subscription is inactive, you cannot create jobs or hire developers.");
    }
  };

  return (
    <>
      {pageLoading ? (
        <JobPostSkeleton />
      ) : (
        <div className="manage_job">
          <Container>
            <div className="manage-job-heading">
              <h4>Manage Job Posts</h4>

              <Button
                className="btn primaryButton"
                onClick={() => handleCreateJobPost()}
              >
                + Create Job Post
              </Button>
            </div>

            <div className="manage-job-list">
              <Grid container>
                <ManageJobPostSidebar
                  getJobPost={getJobPostList}
                  pageSize={pageSize}
                />
                {contentLoading ? (
                  <JobPostListSkeleton />
                ) : (
                  <ManageJobPostList
                    getJobPost={getJobPostList}
                    page={currentPage}
                  />
                )}
              </Grid>
            </div>
          </Container>
          <div className="default-fab-btn">
            <ScrollToggleButton />
          </div>
        </div>
      )}
    </>
  );
}

export default ManageJobPost;