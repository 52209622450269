import React from 'react';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import 'assets/css/featureCard.css'
import 'assets/css/developer-profile.css';

const ProjectTabSkeleton = () => {
    return (
        <>
            <Typography className='profileInnerApproval-tag'>
                <Skeleton width={50} height={40} />
            </Typography>
            <Typography className="count-profileTitle" component="p" align="left">
                <Skeleton width={150} height={40} />
            </Typography>
            <Grid container className="projectsList">
                <div className="projectListItem">
                    <Card className="project-card">
                        <CardContent>
                            <div className="project-top">
                                <div className="icon-container">
                                    <Skeleton variant="circular" width={150} height={50} />
                                </div>
                                <div className="project-title">
                                    <Skeleton width={150} height={40} />
                                </div>
                            </div>
                            <div className="about_project">
                                <Skeleton width={200} height={60} />
                            </div>
                            <Grid className='skillset'>
                                <List className='skillList'>
                                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                                </List>
                            </Grid>
                            <Grid className="duration-details">
                                <Skeleton width={200} height={25} />
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </>
    );
}

export default ProjectTabSkeleton;