import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "assets/css/manageJob.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import history from "utils/history";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Link from '@mui/material/Link';
import * as Actions from "store/actions/index";
import { toast } from 'react-toastify';
import ControlledPaginationRounded from "components/Pagination/ControlledPaginationRounded";
import noDataImg from 'assets/img/icons/no-developer-found.svg';
import editIcon from 'assets/img/icons/edit-icon.svg';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { findStatusTitle, findSalaryType, findPostStatus, getAvailability, errorToast } from "utils/Common";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import ConfirmationDialogue from 'components/Dialog/ConfirmationDialogue';
import closeIcon from 'assets/img/icons/close-icon-job-post.svg';
import { hasActiveSubscription } from "utils/constant";

function ManageJobPostList(props) {

    const { getJobPost, page } = props;
    const dispatch = useDispatch();
    const jobPostList = useSelector(state => state?.jobPostReducer?.jobPostList);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const status = useSelector(state => state?.jobPostReducer?.currentStatus);
    const [anchorEl, setAnchorEl] = useState(null);
    const [jobPostId, setJobPostId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState(null);
    const [description, setDescription] = useState(null);
    const [closeJobDialogue, setCloseJobDialogue] = useState({ open: false, title: 'Confirm Close?', message: "Close This Job Post" })
    const [processing, setProcessing] = useState(false);
    const pageSize = 10;

    const handleClick = (event, id) => {
        setJobPostId(id);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleOpenDialog = (v) => {
        setMessage('Confirm Delete?');
        setDescription('Delete This Job Post');
        setJobPostId(v);
        setAnchorEl(null);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenActivePostDialog = (id) => {
        setCloseJobDialogue({ ...closeJobDialogue, open: !closeJobDialogue.open })
        setJobPostId(id);
        setAnchorEl(null);
    }
    const handleCloseActivePostDialog = () => {
        setCloseJobDialogue({ ...closeJobDialogue, open: false })
    }

    const closeJobPost = () => {
        setProcessing(true);
        let data = { "status": 5 };
        dispatch(Actions.closeJobPost(jobPostId, data)).then((res) => {
            if (res.status === 200) {
                setProcessing(false);
                toast.success('Job post closed successfully');
                dispatch(Actions.getJobPost(status, 1, pageSize));
                handleCloseActivePostDialog();
            } else {
                setProcessing(false);
                errorToast(res);
                handleCloseActivePostDialog();
            }
        })
    }

    const deleteJobPost = () => {
        dispatch(Actions.deleteJobPost(jobPostId)).then((res) => {
            if (res.status === 204) {
                toast.success('Job post deleted successfully');
                // getJobPost(status, 1, pageSize);
                dispatch(Actions.getJobPost(status, 1, pageSize));
                handleCloseDialog();
            } else {
                errorToast(res);
                handleCloseDialog();
            }
        })
    }
    const handlePages = async(page) => {
        await getJobPost(status, page, pageSize);
        window.scrollTo(0, 0);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <>
            <Grid item lg={9} md={9} sm={12} xs={12} className="job-list-col-new">
                {(jobPostList?.results?.length > 0) ? jobPostList?.results?.map((jobPost, index) => (
                    <div className="job-list-content" key={index}>
                        <div className="job-list-card">
                            <div className="job-card-header">
                                <div className={jobPost?.status === 1 ? "heading-text cursor-pointer" : "heading-text"}
                                    onClick={() => { jobPost?.status === 1 && history.push(`/job-post-detail/${jobPost.id}`) }}
                                >
                                    <Typography variant="h5">{jobPost?.title}</Typography>
                                    <Typography variant="body1">Job post ID : {jobPost?.uid}</Typography>
                                </div>
                                <div className="active-inactive-list">
                                    <div className={findPostStatus(jobPost.status)}>
                                        <Typography variant="body2">{findStatusTitle(jobPost.status)}</Typography>
                                    </div>
                                    {(jobPost.status === 2 || jobPost.status === 1) &&
                                        <div className="heading-dot-icon">
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-owns="long-menu"
                                                aria-controls='long-menu'
                                                aria-haspopup="true"
                                                onClick={(e) => handleClick(e, jobPost.uid)}
                                                className='actionBtn'
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{ 'aria-labelledby': 'long-button' }}
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && jobPostId === jobPost.uid}
                                                onClose={handleMenuClose}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                PaperProps={{ style: { width: '10ch' } }}>
                                                {jobPost.status === 2 &&
                                                    <>
                                                        <MenuItem disabled={userDetails.can_add_new==false||!hasActiveSubscription(userDetails)} onClick={() => history.push(`/edit-job/${jobPost?.id}`)}
                                                            className='more_actionList'>
                                                            <Link>
                                                                <img src={editIcon} className='iconButton' alt='img' />
                                                                Edit
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleOpenDialog(jobPost.id)} className='more_actionList delete'>
                                                            <Link>
                                                                <img src={deleteIcon} className='iconButton' alt='img' />
                                                                Delete
                                                            </Link>
                                                        </MenuItem>
                                                    </>
                                                }
                                                {jobPost.status === 1 &&
                                                    <MenuItem onClick={() => handleOpenActivePostDialog(jobPost.id)} className='more_actionList delete'>
                                                        <Link>
                                                        <img src={closeIcon} className='iconButton' alt='img' />
                                                            Close
                                                        </Link>
                                                    </MenuItem>
                                                }
                                            </Menu>
                                        </div>}
                                </div>
                            </div>
                            <div className="job-card-body">
                                <div className="card-body-text">
                                    <Typography variant="body1">
                                        <ReadMoreReadLess
                                            charLimit={300}
                                            readMoreText={"Read More"}
                                            readLessText={"Read Less"}
                                            readMoreClassName="read-more-open-39A1FF"
                                            readLessClassName="read-more-close-39A1FF"
                                            data={jobPost?.description || ""}
                                            textType={false}
                                        />
                                    </Typography>
                                </div>
                                <div className="position-content">
                                    <List>
                                        <ListItem>
                                            <span>Experience</span>
                                            <Typography variant="h3">{jobPost?.experience?.title || '-'}</Typography>
                                        </ListItem>
                                        <ListItem>
                                            <span>Availability</span>
                                            <Typography variant="h3">{getAvailability(jobPost?.availability) || '-'}</Typography>
                                        </ListItem>
                                        <ListItem>
                                            <span>Position</span>
                                            <Typography variant="h3">{jobPost?.positions || '-'}</Typography>
                                        </ListItem>
                                    </List>
                                    {jobPost?.budget_from && jobPost?.budget_to && jobPost?.salary_type &&
                                        <div className="amount-year">
                                            <Typography variant="h2">
                                                ${`${jobPost?.budget_from} - ${jobPost?.budget_to}`}
                                                <span>/{findSalaryType(jobPost?.salary_type)}</span>
                                            </Typography>
                                        </div>
                                    }
                                </div>
                            </div>     
                            {jobPost.status === 1 ?
                                <div className="job-card-footer">
                                    <div className="card-post">
                                        <Typography variant="body1">Posted <span> {moment(jobPost?.job_posted, "YYYYMMDD, h:mm:ss a").fromNow(true)} </span></Typography>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                )) :
                    <Grid className='manage-developer-paper'>
                        <div className='no_data-content'>
                            <div>
                                <img src={noDataImg} alt="no data" />
                                <Typography variant="h6">
                                    No Job Posts Found!!
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                }
                {jobPostList && jobPostList?.results?.length > 0 &&
                    <ControlledPaginationRounded
                        currentPage={page}
                        totalPages={Math.ceil(jobPostList?.count / pageSize) || 0}
                        handlePages={handlePages}
                    />
                }
            </Grid>
            <DeleteProjectDialog
                open={openDialog}
                close={handleCloseDialog}
                confirm={deleteJobPost}
                message={message}
                description={description}
            />
            <ConfirmationDialogue
                open={closeJobDialogue.open}
                close={handleCloseActivePostDialog}
                title={closeJobDialogue.title}
                message={closeJobDialogue.message}
                buttonText="Yes, Close"
                processing={processing}
                buttonCallback={closeJobPost}
            />
        </>
    )
}
export default ManageJobPostList;