import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


const DeveloperListSkeleton = () => {
    return (
        <Container>
            <Grid className='pagetitle-container'>
                <h3><Skeleton width={300} height={40} /></h3>
                <div><Skeleton width={140} height={70} /></div>
            </Grid>
            <Paper sx={{ width: '100%' }} className='manage-developer-paper'>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className='manage_developer-table'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={12} className='tableTitle'>
                                    <Skeleton width={125} height={30} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='tableCellHead singleTableCell'
                                >
                                    <Skeleton width="100%" height={25} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className='tableDataCell singleTableCell'>
                                    <Skeleton width="100%" height={40} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div>
                <Stack spacing={2} className="pagination-container">
                    <div className='pagination-inner'>
                        <Skeleton width={90} height={60} />
                    </div>
                </Stack>
            </div>
        </Container>
    );
}

export default DeveloperListSkeleton;