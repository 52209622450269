import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router';

const headCells = [
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Job Post Title',
        ordering: true,
    },
    {
        id: 'client_uid',
        numeric: true,
        disablePadding: false,
        label: 'Client Id',
        ordering: true,
    },
    {
        id: 'client_country',
        numeric: false,
        disablePadding: false,
        label: 'Country',
        ordering: true,
    },
    {
        id: 'interview_date',
        numeric: true,
        disablePadding: false,
        label: 'Scheduled on',
        ordering: true,
    },
    {
        id: 'request_handled_by',
        numeric: false,
        disablePadding: false,
        label: 'Rejected By',
        ordering: true,
    },
];

export default function RejectedTableDeveloper(props) {
    const { data, filterData, pageData, loading, developerId } = props;
    const pageOrderBy = pageData?.orderBy?.replace("-", "");
    const [dense,] = useState(false);
    const history = useHistory();

    const handleRequestSort = (_, property) => {
        const isAsc = pageOrderBy === property ? (pageData.order === 'desc' ? true : false) : true;
        const order = isAsc ? 'asc' : 'desc'
        const orderBy = isAsc ? property : `-${property}`;
        filterData(pageData.page, pageData.pageSize, pageData.search, order, orderBy);
    };

    const handleChangePage = (_, newPage) => {
        filterData(newPage + 1, pageData.pageSize, pageData.search);
    };

    const handleChangeRowsPerPage = (event) => {
        filterData(1, parseInt(event.target.value, 10), pageData.search);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRedirect = (id) => {
        history.push(`/proposals/${developerId}/detail/${id}`)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 0 }} id="dev-reject-tab">
                <TableContainer>
                    <Table className='table-center-content' aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow >
                                {headCells.map((headCell) => (
                                    <TableCell key={headCell.id} align='left' padding='normal'
                                        sortDirection={pageOrderBy === headCell.id ? pageData.order : false}>
                                        {headCell.ordering &&
                                            <TableSortLabel
                                                active={pageOrderBy === headCell.id}
                                                direction={pageOrderBy === headCell.id ? pageData.order : 'asc'}
                                                onClick={createSortHandler(headCell.id)} >
                                                {headCell.label}
                                                {pageOrderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {pageData.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading ?
                            <div className='custom-search-loader'>
                                <CircularProgress className='custom-circle-loder' />
                            </div>
                            :
                            <TableBody>
                                {(data?.results && data?.results?.length > 0) ?
                                    data?.results?.map((row, index) => {
                                        return (
                                            <TableRow tabIndex={-1} key={index}
                                                onClick={() => handleRedirect(row.id)}
                                                className="cursor-pointer"
                                            >
                                                <TableCell scope="row" padding="none">
                                                    <Tooltip title={row?.title} TransitionComponent={Zoom}>
                                                        <div className='jobpost-first-title'>{row?.title}</div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="left">{row?.client_uid}</TableCell>
                                                <TableCell align="left">{row?.client_country}</TableCell>
                                                <TableCell align="left">{row?.interview_date ? moment(new Date(row?.interview_date)).local().format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="left">{row?.request_handled_by || '-'}</TableCell>
                                            </TableRow>
                                        );
                                    }) :
                                    <TableRow>
                                        <TableCell className='single-td' align="center" colSpan={6}>Sorry, no matching records found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.count || 0}
                    rowsPerPage={pageData?.pageSize}
                    page={pageData?.page ? pageData?.page - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}