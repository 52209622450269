import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import FormControl from '@mui/material/FormControl';
// import Slider from '@mui/material/Slider';

const ProfessionalTabSkeleton = () => {
    return (
        <>
            <Grid container justifyContent="flex-end">
                <Skeleton className="editBtn bg-dark" variant='rounded' width={30} height={30} />
            </Grid>
            <p className="CategoryTitle portfolioTabSkill"><Skeleton width={300} /></p>
            <Grid className='skillset'>
                <List className='skillList'>
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                </List>
            </Grid>
            <p className="CategoryTitle portfolioTabSkill"><Skeleton width={300} /></p>
            <Grid className='skillset'>
                <List className='skillList'>
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                </List>
            </Grid>
            <p className="CategoryTitle portfolioTabSkill"><Skeleton width={300} /></p>
            <Grid className='skillset'>
                <List className='skillList'>
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                    <Skeleton className="skillList-item bg-dark" width={100} height={40} />
                </List>
            </Grid>
            <Grid className="aboutContent">
                <p className="developer-profile-subtitle"><Skeleton width={100} /></p>
                <p className="content"><Skeleton width={300} /></p>
                <p className="developer-profile-subtitle"><Skeleton width={100} /></p>
                <p className="content"><Skeleton width={300} /></p>
                <p className="developer-profile-subtitle"><Skeleton width={100} /></p>
                <div className="language-section">
                    <div className="language-div">
                        <Typography id="langEng-slider"><Skeleton width={100} /></Typography>
                        <Typography className="lang-status"><Skeleton width={80} /></Typography>
                        <Skeleton sx={{ margin: "13px 0 13px 0" }} width={340} />
                    </div>
                </div>
                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label"><Skeleton width={100} /></label>
                                <Typography variant='p' component='p' className='idProof-name'>
                                    <Skeleton className='bg-dark' variant="rounded" width={40} hight={40} />
                                    <Skeleton className='bg-dark' width={100} height={30} />
                                </Typography>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ProfessionalTabSkeleton;