import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

function ContainerSkeleton() {
    return (
        <>
            <Grid className="chat-msg-wrapper">
                <Grid className="chat-msg-container">
                    <Grid className="right-chat-skeleton">
                        {/* <div className='chat-box'> */}
                        <Skeleton width="90%" height={50}>
                            <Typography>.</Typography>
                        </Skeleton>
                        {/* </div> */}
                        {/* <div className='chat-user'>
                    <Skeleton variant="circular" width={40} height={40}>
                        <Avatar />
                    </Skeleton>
                </div> */}
                    </Grid>
                    <Grid className='left-chat-skeleton'>
                        {/* <div className='chat-user'>
                   <Skeleton variant="circular" width={40} height={40}>
                        <Avatar />
                    </Skeleton>
                </div> */}
                        {/* <div className='chat-box'> */}
                        <Skeleton width="90%" height={50}>
                            <Typography>.</Typography>
                        </Skeleton>
                        {/* </div> */}
                    </Grid>
                </Grid>
            </Grid >
            <Grid className="footer-container">
                <Grid className='chat-footer'>
                    <Skeleton className="chat-typing-container" width="100%" height={70} />
                    <Grid className='chat-action'>
                        <Skeleton width={70} height={55} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ContainerSkeleton;
