import React from "react";
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import "assets/css/manage_agency-developer.css";

function SidebarSkeleton() {
    return (
        <List>
            <ListItem>
                <ListItemAvatar className='chat-profile'>
                    <Skeleton variant="circular" width={50} height={50}>
                        <Avatar />
                    </Skeleton>
                </ListItemAvatar>
                <ListItemText>
                    <Skeleton width="100%" height={25} />
                    <Skeleton width="100%" height={15} />
                </ListItemText>
            </ListItem>
        </List>
    );
}

export default SidebarSkeleton;
