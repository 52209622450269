import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import CancelBtnRed from "assets/img/icons/close-icon-red.svg";
import CancelBtn from "assets/img/icons/close-icon-green.svg";
import CheckBtn from "assets/img/icons/check.svg";
import CheckBtnWhite from "assets/img/icons/check-icon.svg";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { JOBPOST_CANDIDATE_STATUS } from "utils/constant";
import history from "utils/history";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import { useSelector } from "react-redux";
import { hasActiveSubscription } from "utils/constant";

const hCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Job Post Title",
    ordering: true,
  },
  {
    id: "client_uid",
    numeric: true,
    disablePadding: false,
    label: "Client Id",
    ordering: true,
  },
  {
    id: "client_country",
    numeric: true,
    disablePadding: false,
    label: "Country",
    ordering: true,
  },
  {
    id: "status_modified_on",
    numeric: true,
    disablePadding: false,
    label: "Requested Date",
    ordering: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: " ",
    ordering: false,
  },
];

export default function FreelancerRequestTable(props) {
  const { data, action, filter, tab, page, loading } = props;
  const pageOrderBy = page?.orderBy?.replace("-", "");
  const [dense] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const candidateStatus = JOBPOST_CANDIDATE_STATUS;
  let headCells = [...hCells];
  const [actionDialogue, setActionDialogue] = useState({
    open: false,
    title: "Are you sure ?",
    message: "",
  });
  const userDetails = useSelector((state) => state?.authReducer?.userDetails);
  const [actionData, setActionData] = useState({
    status: null,
    requestId: null,
    candidateUid: null,
  });
  const [processing, setProcessing] = useState(false);

  if (tab === 1) {
    headCells = hCells.filter((cell) => cell.id !== "actions");
    headCells[headCells.length - 1].id = "request_handled_by";
    headCells[headCells.length - 1].label = "Rejected By";
  }

  const openActionDialogue = (
    message = "",
    status = null,
    requestId = null,
    candidateUid
  ) => {
    setActionData({ status, requestId, candidateUid });
    const dialogueData = {
      ...actionDialogue,
      open: !actionDialogue.open,
      message,
    };
    setActionDialogue(dialogueData);
  };
  const closeActionDialogue = () => {
    setActionDialogue({ ...actionDialogue, open: false });
  };

  const handleAction = (id, status, message) => {
    setProcessing(true);
    action(id, status, message)
      .then(() => {
        setProcessing(false);
        closeActionDialogue();
      })
      .catch(() => {
        setProcessing(false);
        closeActionDialogue();
      });
  };

  const handleRequestSort = (_, property) => {
    const isAsc =
      pageOrderBy === property ? (page.order === "desc" ? true : false) : true;
    const order = isAsc ? "asc" : "desc";
    const orderBy = isAsc ? property : `-${property}`;
    filter(page.page, page.pageSize, page.search, order, orderBy);
  };
  const handleChangePage = (_, newPage) => {
    filter(newPage + 1, page.pageSize);
  };
  const handleChangeRowsPerPage = (event) => {
    const dataPerPage = parseInt(event.target.value, 10);
    filter(1, dataPerPage);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleHover = (rowId = null) => {
    setHoveredId(rowId);
  };
  const handleRedirect = (id) => {
    history.push(`/my-requests/${id}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 0 }}>
        <TableContainer>
          <Table
            className="table-center-content"
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead>
              <TableRow className="cursor-pointer">
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={
                      pageOrderBy === headCell.id ? page.order : false
                    }
                  >
                    {headCell.ordering ? (
                      <TableSortLabel
                        active={pageOrderBy === headCell.id}
                        direction={
                          pageOrderBy === headCell.id ? page.order : "asc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {pageOrderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {page.order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <div className="custom-search-loader">
                <CircularProgress className="custom-circle-loder" />
              </div>
            ) : (
              <TableBody>
                {data?.results && data?.results?.length > 0 ? (
                  data?.results?.map((row) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row?.title}
                        onClick={() => handleRedirect(row.id)}
                        onMouseEnter={() => handleHover(row.id)}
                        onMouseLeave={() => handleHover()}
                        className="cursor-pinter"
                      >
                        <TableCell scope="row" padding="none">
                          <Tooltip
                            title={row?.title}
                            TransitionComponent={Zoom}
                          >
                            <div className="jobpost-first-title">
                              {row?.title}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">{row?.client_uid}</TableCell>
                        <TableCell align="left">
                          {row?.client_country}
                        </TableCell>
                        {tab !== 1 ? (
                          <>
                            <TableCell align="left">
                              {row?.status_modified_on
                                ? moment(
                                    new Date(row?.status_modified_on)
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <div className="chat-cancle-btn">
                                <Tooltip
                                  title="Accept Request"
                                  TransitionComponent={Zoom}
                                >
                                  <LoadingButton
                                    type="submit"
                                    className={
                                      row.id === hoveredId
                                        ? `cancle-btn cancle-btn-blue cursor-pointer ${
                                            processing && "loading"
                                          }`
                                        : `cancle-btn cursor-pointer ${
                                            processing && "loading"
                                          }`
                                    }
                                    loading={
                                      processing ||
                                      !hasActiveSubscription(userDetails)
                                    }
                                    loadingIndicator={
                                      <CircularProgress
                                        sx={{
                                          color:
                                            row.id === hoveredId
                                              ? "#ffffff"
                                              : "#548ca8",
                                        }}
                                        size={16}
                                      />
                                    }
                                    onClick={() =>
                                      handleAction(
                                        row?.id,
                                        candidateStatus.REQUEST_ACCEPTED,
                                        "Request accepted successfully"
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        row.id === hoveredId
                                          ? CheckBtnWhite
                                          : CheckBtn
                                      }
                                    />
                                  </LoadingButton>
                                </Tooltip>
                                <Tooltip
                                  title="Reject Request"
                                  TransitionComponent={Zoom}
                                >
                                  <div
                                    className={
                                      row.id === hoveredId
                                        ? "cancle-btn cancle-btn-red cursor-pointer"
                                        : "cancle-btn cursor-pointer"
                                    }
                                    style={
                                      !hasActiveSubscription(userDetails)
                                        ? { pointerEvents: "none" }
                                        : null
                                    }
                                    onClick={() =>
                                      openActionDialogue(
                                        "you want to reject the request",
                                        candidateStatus.REQUEST_REJECTED,
                                        row.id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        row.id === hoveredId
                                          ? CancelBtnRed
                                          : CancelBtn
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell align="left">
                            {row?.request_handled_by || "-"}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="single-td" align="center" colSpan={6}>
                      Sorry, no matching records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={page.pageSize}
          page={page?.page ? page.page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ConfirmationDialogue
          open={actionDialogue.open}
          close={closeActionDialogue}
          title={actionDialogue.title}
          message={actionDialogue.message}
          buttonText="Yes"
          processing={processing}
          buttonCallback={() => {
            handleAction(
              actionData.requestId,
              actionData.status,
              `Request rejected successfully`
            );
          }}
        />
      </Paper>
    </Box>
  );
}
