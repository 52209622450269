import Header from "components/Navbars/AdminNavbar";
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CardContent, Link, Stack } from "@mui/material";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import Footer from "components/Footer/Footer";
import { Card } from "react-bootstrap";

function MakePaymentNew() {
  const breadCrumbs = [{ name: "home", path: "/home" }];
  return (
    <>
      <div>
        <Header />
        <div className="makepayment-page updated-section">
          <section className="privacy-policy-section makepayment-banner">
            <div className="privacy-policy-inner">
              <Container>
                <Grid container>
                  <Grid item lg={6}>
                    <Stack spacing={2} className="breadcrumb-div">
                      <CustomBreadcrumbs
                        current="Plan"
                        previous={breadCrumbs}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid className="banner-header">
                  <Typography variant="h2">Make Payment</Typography>
                </Grid>
              </Container>
            </div>
          </section>
          <section className="makepayment-selection-section mp-updated-section">
              <div className="makepayment-selection-inner">
                <Container className="mp-container">
                  <Grid container  justifyContent={"space-between"}>
                  <Grid item lg={6} md={6} xs={12}>
                      <div className="select-plan-col">
                        <div className="heading">
                            <Typography variant="h3">Selected Plan</Typography>
                            <Typography variant="h2">Advanced</Typography>
                            <p>Lorem faucibus gravida tincid lorem felis, tincidunt amet.</p>
                        </div>
                        <div className="new-ul plan-list">
                            <ul>
                              <li>Real-time contact syncing</li>
                              <li>Automatic data enrichment</li>
                              <li>Up to 3 seats</li>
                              <li>Up to 3 seats</li>
                            </ul>
                        </div>

                        <div className="plan-price">
                            <Typography variant="h3">$450</Typography>
                            <Typography variant="h4">/ 6 Months</Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <div className="summary-col">
                      
                        <div className="card-body">
                          <Card
                            sx={{ maxWidth: 368 }}
                            className="card-body-inner"
                          >
                            <CardContent className="card-cont">
                            <div className="card-cont-top-wrp">
                              <div className="card-cont-top">
                                <Typography variant="h3">
                                Summary
                                </Typography>
                                {/* <div
                                  className="new-ul"
                                  dangerouslySetInnerHTML={{
                                    __html: planDetail.plan_details,
                                  }}
                                ></div> */}
                              </div>
                              <div className="price-wrp">
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  className="price-item"
                                >
                                  <Grid item lg={9}>
                                    <Typography variant="h6">Price</Typography>
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography variant="span">
                                      {/* {planDetail.currency.sign} */}$450
                                      {/* {planDetail.amount} */}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  className="price-item"
                                >
                                  <Grid item lg={9}>
                                    <Typography variant="h6">Tax</Typography>
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography variant="span">
                                      {/* {planDetail.currency.sign} */}$50
                                      {/* {planDetail.tax_amount} */}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="total-amt">
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  className="price-item"
                                >
                                  <Grid item lg={9}>
                                    <Typography variant="h6">
                                      Total Amount
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography variant="span">
                                      {/* {planDetail.currency.sign} */}$500
                                      {/* {planDetail.total_amount} */}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              </div>
                              <div className="pay-btn-wrp">
                            <Link className="cancel-btn">
                              Cancel
                            </Link>
                         
                              <Link className="pay-btn">
                                Pay Now
                              </Link>
                            
                          </div>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    </Grid>
                 
                  </Grid>
                </Container>
              </div>
            
          </section>
    
         
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MakePaymentNew;
