import React from 'react';
import Typography from '@mui/material/Typography';
import { findSalaryType, wrapperDeveloperRedirection, wrapperJobPostRedirection } from 'utils/Common';
import { useSelector } from 'react-redux';
import Link from '@mui/material/Link';

const HiredWrapper = (props) => {
    const { details, messagePosition } = props;
    const selectedUser = useSelector(state => state.chatReducer?.currentUser);
    const userDetails = useSelector((state) => state.authReducer.userDetails);

    return (
        <div className={(messagePosition == 'left') ? 'interview-wrapper interview-left-wrapper' : "interview-wrapper"}>
            <div className='offer-main-box interview-main-box '>
                <div className='interview-text-heading'>
                    <Typography variant='h6'>Offer Accepted by {messagePosition == 'right' ? 'You' : selectedUser?.opponent?.company_name}.</Typography>
                    <Typography variant='h4'>Hired <span> for ${details?.amount}/{findSalaryType(details?.salary_type)} </span></Typography>
                    <div className='interview-client-id'>
                        <Typography variant='body1'>Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(userDetails?.user_type, details?.job_post?.id, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.job_post?.uid}</Link></span></Typography>
                        <Typography variant='body1'>Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(userDetails?.user_type, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.candidate?.uid}</Link></span></Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HiredWrapper;