import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Field, getFormValues, reduxForm } from "redux-form";
import { required } from "utils/Validation";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../store/actions/index';
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

export const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        className="availability-options"
        valueselected={input.value}
        onChange={(__, value) => input.onChange(value)}
    />
);

function Experience(props) {

    const { handleSubmit, back, formStates, valid, initialValues, change } = props;
    const dispatch = useDispatch();
    const experienceList = useSelector(state => state.commonReducer?.experienceList);
    const data = useSelector(state => state.jobPostReducer?.jobPostData);
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);

    const getExperience = () => {
        dispatch(Actions.getExperience());
    }

    const handleSaveDraft = () => {
        if (valid) {
            dispatch({ type: "SAVE_DRAFT_CLICK", payload: true });
            setTimeout(() => {
                handleSubmit();
            }, 1000);
        }
    }
    const handleBack = () => {
        if (valid) {
            let budgetObj = {
                ...data,
                ...formStates
            }
            dispatch({ type: "JOBPOST_DATA", payload: budgetObj });
        }
        back();
    }

    useEffect(() => {
        getExperience();
    }, []);

    useEffect(() => {
        if (experienceList?.[0]?.id) {
            initialValues.experience = (data.experience) ? data?.experience : experienceList?.[0].id.toString();
            change('experience', initialValues.experience);
        }
    }, [experienceList]);

    return (
        <form onSubmit={handleSubmit} noValidate data-testid="experience-form" id="experience-form">
            <Grid className="createJob-wrapper-inner">
                <h3>Experience</h3>
                <Grid container className="jobExperience">
                    <List className="createJobList">
                        {experienceList?.map(exp => (
                                <Field
                                key={exp.id}
                                    name="experience"
                                    id="experience"
                                    className="availability-options"
                                    aria-labelledby="demo-form-control-label-placement"
                                    component={renderRadioGroup}
                                    validate={[required]}>
                                        
                                <label className="create-radio-btn">
                            <ListItem className={((!formStates?.experience && experienceList?.[0]?.id == exp.id) || formStates?.experience == exp.id) ? "createJob-item active" : "createJob-item"} >
                                <Typography>{exp.title}</Typography>    
                                    <div className="select-avaibility cursor-pointer">
                                        <FormControlLabel value={exp.id}
                                            control={<Radio
                                                icon={<CircleOutlinedIcon />}
                                                checkedIcon={<CheckCircleIcon />}
                                                className="select-checkbox createJob-checkbox"
                                            />}
                                        />
                                    </div>
                            </ListItem>
                                </label>
                                </Field>
                        ))}
                    </List>
                </Grid>
            </Grid>
            <div className="bottom-buttons">
                {(!initialValues?.id || (initialValues?.id && initialValues?.step)) &&
                    <LoadingButton
                        className={`saveDraftBtn cursor-pointer ${saveAsDraft && 'loading'}`}
                        loading={saveAsDraft} variant="string"
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        disabled={!valid || saveAsDraft}
                        onClick={handleSaveDraft}
                    >
                        Save as Draft
                    </LoadingButton>
                }
                <div className='action-div'>
                    <Link underline="none" className='cancelBtn' onClick={handleBack} component='button' disabled={saveAsDraft}>Back</Link>
                    <Button variant="contained" type="submit" className="primaryButton nextBtn" disabled={saveAsDraft}>
                        Next
                    </Button>
                </div>
            </div>
        </form >
    );
}
const ReduxExperienceForm = reduxForm({
    form: "ExperienceForm",
    enableReinitialize: true,
})(Experience);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues("ExperienceForm")(state)
    }
}

export default connect(mapStateToProps)(ReduxExperienceForm);
