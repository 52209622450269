import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions/index";
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import history from "utils/history";
import BankDetailsForm from "components/BankDetails/BankDetailsForm";
import "assets/css/profile.css";
import { checkForImageValidations } from "utils/Common";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";

function ManageBankDetails() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [initValues, setInitValues] = useState(null);
    const [idProof, setIdProof] = useState(null);
    const [fileName, setFileName] = useState('Choose File Name');
    const [IFSCData, setIFSCData] = useState({ valid: false, data: null });
    const [openDialog, setOpenDialog] = useState(false);
    const userDetails = useSelector((state) => state.authReducer.userDetails);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
    ];

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(res => {
            if (res.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: res.data });
                res.data?.is_profile_in_review && setOpenDialog(res.data?.is_profile_in_review);
            }
            else {
                dispatch({ type: 'GET_USER_DETAILS', payload: {} });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_DETAILS', payload: {} });
        })
    }

    useEffect(() => {
        getUserDetails();
        bankDetails();
    }, []);

    const bankDetails = () => {
        dispatch(Actions.getBankDetails())
            .then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    const bankData = { ...response.data[0] };
                    bankData.ifsc_code && onIFSCChange(bankData.ifsc_code);
                    const fileNameArray = response.data[0]?.id_proof.split('/')
                    fileNameArray.length > 0 && setFileName(fileNameArray[fileNameArray.length - 1])
                    setInitValues(bankData);
                }
            }).catch(() => {
                setInitValues(null);
            });
    };
    const handleChangeIdentity = async (event) => {
        try {
            if (event instanceof File) {
                const fileCheck = await checkForImageValidations(event, 'file', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", event);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        setIdProof(response.data);
                    }).catch(() => {
                        setIdProof(null);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    };

    const submitBankDetails = (values) => {
        values.id_proof = idProof;
        let bankDetailsData = {
            name: values.name,
            account_no: values.account_no,
            ifsc_code: values.ifsc_code,
            id_proof: idProof,
            id: values?.id,
            user:userDetails.id,
        }
        toggalLoader(true);
        if (!bankDetailsData?.id) {
            delete bankDetailsData.id
        }
        if (bankDetailsData?.id) {
            if (!idProof || typeof idProof === 'string') delete bankDetailsData.id_proof;
            dispatch(Actions.editBankDetails(bankDetailsData.id, bankDetailsData))
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Bank details updated successfully");
                        history.push("/my-profile");
                    } else {
                        Object.keys(response.data).forEach((error) => {
                            toast.error(response.data[error][0]);
                        });
                    }
                    toggalLoader(false);
                })
                .catch(() => {
                    toggalLoader(false);
                });
        } else {
            dispatch(Actions.addBankDetails(bankDetailsData))
                .then((response) => {
                    if (response.status === 201) {
                        toast.success("Bank details added successfully");
                        history.push("/my-profile");
                    } else {
                        Object.keys(response.data).forEach((error) => {
                            toast.error(response.data[error][0]);
                        });
                    }
                    toggalLoader(false);
                })
                .catch(() => {
                    toggalLoader(false);
                });
        }
    };

    const onIFSCChange = (value) => {
        const isValid = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/i.test(value);
        if (isValid) {
            dispatch(Actions.getAny(`https://ifsc.razorpay.com/${value}`)).then((response) => {
                setIFSCData({ valid: true, data: response.data });
            }).catch(() => {
                setIFSCData({ valid: false, data: null });
            })
        } else if (IFSCData.valid) {
            setIFSCData({ valid: false, data: null });
        }
    };


    return (
        <>
            <Grid className="profile-top-section add_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="add-bank-details" previous={breadCrumbs} />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <BankDetailsForm
                onSubmit={(values) => {
                    submitBankDetails(values);
                }}
                initialValues={initValues}
                handleChangeIdentity={handleChangeIdentity}
                idProof={idProof}
                fileName={fileName}
                IFSCData={IFSCData}
                onIFSCChange={onIFSCChange}
                openDialog={openDialog}
            />
        </>
    );
}

export default ManageBankDetails;
