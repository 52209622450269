import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
import { Field, getFormValues, reduxForm } from "redux-form";
import { required } from "utils/Validation";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomInput from "components/CustomInput/CustomInput";
import { avoidKeysForNumber } from "utils/Common";
import { SALARY_TYPE, PAYMENT_PLATFORM } from '../../../utils/constant';
import { Link } from "@mui/material";
import validate from "../validate";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

function valueText(value) {
    return `${value}°C`;
}

export const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        className="availability-options"
        valueselected={input.value}
        onChange={(__, value) => input.onChange(value)}
    />
);

function Budget(props) {

    const { handleSubmit, formStates, change, valid, back, initialValues, processing } = props;
    const dispatch = useDispatch();
    const [budget, setBudget] = useState([1, 1]);
    const [salaryIndex, setSalaryIndex] = useState(0);
    const data = useSelector(state => state.jobPostReducer?.jobPostData);
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);

    const handleChange = (__, newValue) => {
        change('budget_from', newValue?.[0]);
        change('budget_to', newValue?.[1]);
        setBudget(newValue);
    };

    const handleSaveDraft = () => {
        if (valid) {
            dispatch({ type: "SAVE_DRAFT_CLICK", payload: true });
            setTimeout(() => {
                handleSubmit();
            }, 1000);
        }
    }
    const handleChangeSalaryType = (cost, index) => {
        setSalaryIndex(index);
        setBudget([cost.budget_from, cost.budget_to]);
        change('budget_from', cost.budget_from);
        change('budget_to', cost.budget_to);
    }
    const handleBack = () => {
        if (valid) {
            let budgetObj = {
                ...data,
                ...formStates
            }
            dispatch({ type: "JOBPOST_DATA", payload: budgetObj });
        }
        back();
    }

    const handleBudgetInputs = (min, max) => {
        if (min && max) {
            let minN = parseInt(min);
            let maxN = parseInt(max);
            setBudget([minN, maxN]);
        }
    }
    useEffect(() => {
        if (!initialValues?.payment_platform) {
            initialValues.payment_platform = '1'
        }
        if (!initialValues?.salary_type) {
            initialValues.salary_type = '1';
        }
        if (!initialValues?.salary_type || (!initialValues?.budget_from && !initialValues?.budget_to)) {
            handleChange(null, [SALARY_TYPE[0].budget_from, SALARY_TYPE[0].budget_to]);
        }
        else if (initialValues?.salary_type) {
            let sal = SALARY_TYPE.map(salary => salary.id).indexOf(parseInt(initialValues?.salary_type));
            setSalaryIndex(sal);
            setBudget([initialValues?.budget_from, initialValues?.budget_to]);
        }

    }, [initialValues]);

    return (
        <form onSubmit={handleSubmit} noValidate data-testid="budget-form" id="budget-form">
            <Grid className="createJob-wrapper-inner">
                <h3>Budget</h3>
                <Grid container className="formGrid">
                    <Grid container className="jobBudget">
                        <Typography className="title">
                            Salary Type*
                        </Typography>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                            className="jobBudget-radiogroup">
                            <List className="createJobList">
                                {SALARY_TYPE.map((radio, index) => (
                                    <ListItem className={formStates?.salary_type == radio.id ? "createJob-item active" : "createJob-item"} key={index}>
                                        <label className="create-radio-btn">
                                            <Typography>{radio?.title}</Typography>
                                            <Field
                                                name="salary_type"
                                                id="salary_type"
                                                className="availability-options"
                                                aria-labelledby="demo-form-control-label-placement"
                                                component={renderRadioGroup}
                                                validate={[required]}
                                                onChange={() => handleChangeSalaryType(radio, index)}>
                                                <div className="select-avaibility active cursor-pointer job-post-availablity">
                                                    <FormControlLabel value={radio.id}
                                                        control={<Radio
                                                            icon={<CircleOutlinedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            className="createJob-radiobox"
                                                        />}
                                                    />
                                                </div>
                                            </Field>
                                        </label>
                                    </ListItem>
                                ))}
                            </List>
                        </RadioGroup>
                    </Grid>
                    <Grid container className="jobBudget">
                        <Typography className="title">
                            Budget*
                        </Typography>
                        <Box className="budgetSlider">
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={budget}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valueText}
                                min={SALARY_TYPE[salaryIndex].budget_from}
                                max={SALARY_TYPE[salaryIndex].budget_to}
                                className="swipe-sliding"
                            />
                        </Box>
                        <Grid className="budgetSliderInputs">
                            <Grid container className="formGrid budget-formGrid">
                                <Field
                                    name="budget_from"
                                    id="budget_from"
                                    component={CustomInput}
                                    min={1}
                                    inputProps={{
                                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                        type: "number",
                                        // disabled: true
                                    }}
                                    onKeyDown={avoidKeysForNumber}
                                    validate={[required]}
                                    onChange={(e) => { handleBudgetInputs(e.target.value, (formStates?.budget_to || null)) }}
                                />
                                <Typography className="to-text">to</Typography>
                                <Field
                                    name="budget_to"
                                    id="budget_to"
                                    component={CustomInput}
                                    min={1}
                                    inputProps={{
                                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                        type: "number",
                                        // disabled: true
                                    }}
                                    onKeyDown={avoidKeysForNumber}
                                    validate={[required]}
                                    onChange={(e) => handleBudgetInputs((formStates?.budget_from || null), e.target.value)}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="jobBudget">
                        <Typography className="title">
                            How do you wish to make Payment*
                        </Typography>
                        <List className="createJobList">
                            {PAYMENT_PLATFORM.map((radio, index) => (
                                <ListItem className={formStates?.payment_platform == radio.id ? "createJob-item active" : "createJob-item"} key={index}>
                                    <label className="create-radio-btn">
                                        <Typography>{radio?.title}</Typography>
                                        <Field
                                            name="payment_platform"
                                            id="payment_platform"
                                            className="availability-options"
                                            aria-labelledby="demo-form-control-label-placement"
                                            component={renderRadioGroup}
                                            validate={[required]}>
                                            <div className="select-avaibility active cursor-pointer job-post-availablity">
                                                <FormControlLabel value={radio.id}
                                                    control={<Radio
                                                        icon={<CircleOutlinedIcon />}
                                                        checkedIcon={<CheckCircleIcon />}
                                                        className="createJob-radiobox"
                                                    />}
                                                />
                                            </div>
                                        </Field>
                                    </label>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            <div className="bottom-buttons">
                {(!initialValues?.id || (initialValues?.id && initialValues?.step)) &&
                    <LoadingButton
                        className={`saveDraftBtn cursor-pointer ${saveAsDraft && 'loading'}`}
                        loading={saveAsDraft} variant="string"
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        disabled={!valid || saveAsDraft || processing}
                        onClick={handleSaveDraft}
                    >
                        Save as Draft
                    </LoadingButton>
                }
                <div className='action-div'>
                    <Link underline="none" className='cancelBtn' onClick={handleBack} component='button' disabled={saveAsDraft || processing}>Back</Link>
                    <LoadingButton
                        type="submit"
                        className={`primaryButton nextBtn ${processing && 'loading'}`}
                        loading={processing}
                        variant="contained"
                        loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                        disabled={!valid || saveAsDraft}
                    >
                        Submit
                    </LoadingButton>
                </div>
            </div>
        </form >
    );
}
const ReduxBudgetForm = reduxForm({
    form: "BudgetForm",
    validate,
    enableReinitialize: true,
})(Budget);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues("BudgetForm")(state)
    }
}

export default connect(mapStateToProps)(ReduxBudgetForm);
