import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import editIcon from "assets/img/icons/edit-icon.svg";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import certificateIcon from "assets/img/certificate.svg";
import history from "utils/history";
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import * as DeveloperActions from '../../../store/actions/manageDevelopers/manageDevelopers.actions';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CertificateDetailsDialog from "components/Dialog/CertificateDetailsDialog";
import { getDeveloperTabDetails, IMAGES } from "utils/constant";
import { getFileExtention, getFileIcon } from "utils/Common";
import PaginationRounded from "components/Pagination/PaginationRounded";
import noProjectImage from "assets/img/icons/no-projects-icon.svg";

function CertificationDetailsTab(props) {

    const { currentTab, getTabDetails, developerDetails } = props;
    const developerCertificates = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperCertificationList);
    const userCertificateCount = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperCertificateCount);
    const dispatch = useDispatch();
    const [certificationId, setCertificationId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { toggalLoader } = useContext(LoaderContext);
    const [certificateData, setCertificateData] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const handleOpenDialog = (id) => {
        setCertificationId(id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handlePages = (pageNumber) => {
        setCurrentPage(pageNumber);
        dispatch(DeveloperActions.getAgencyDeveloperCertificationById(developerDetails?.id, pageNumber, pageSize));
    }

    const handleConfirmDelete = () => {
        setOpenDialog(false);
        dispatch(DeveloperActions.deleteDevelopersCertificate(developerDetails?.id, certificationId)).then(response => {
            if (response.status === 204) {
                toggalLoader(false);
                setOpenDialog(false);
                toast.success('Certificate deleted successfully');
                getTabDetails(getDeveloperTabDetails(currentTab, developerDetails?.id));
            } else {
                toggalLoader(false);
                setOpenDialog(false);
                toast.error('Please try again!');
            }
        }).catch(() => {
            toast.error("Something went wrong...");
        })
    }
    const columns = [
        { id: 'image_id', label: '', minWidth: 100 },
        {
            id: 'certi_title',
            label: 'Certificate Title',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'certi_date',
            label: 'Certification Date',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'action',
            label: '',
            align: 'right',
            minWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
    ];

    const handleClickOpen = (certificate = null) => {
        setCertificateData(certificate)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {developerDetails?.is_certification_in_review ||
                <IconButton
                    className="edit-icon editBtn"
                    onClick={() => history.push(`/manage-developers/${developerDetails?.id}/edit-certification`)}
                >
                    <img src={editIcon} alt="img" />
                </IconButton>
            }
            {!developerCertificates || developerCertificates.length == 0
                ?
                <Grid className="certificate-card-empty">
                    <div>
                        <img src={noProjectImage} alt="img" />
                        <Typography>No Certificates!</Typography>
                    </div>
                </Grid>
                :
                <>
                    {developerDetails?.is_certification_in_review &&
                        <Typography className='approval-tag profileInnerApproval-tag'>
                            Certification is under approval
                        </Typography>
                    }
                    <Typography className="count-profileTitle" component="p"><span>{userCertificateCount}</span> Certificates</Typography>
                    <TableContainer>
                        <Table className='project_detail-table certificate_table'>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ top: 50, minWidth: column.minWidth }}
                                            className='tableCellHead'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {developerCertificates && developerCertificates.length > 0 && developerCertificates.map((certificate, index) => {
                                    const extension = getFileExtention(certificate?.certificate_file);
                                    const icon = getFileIcon(extension)
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell className='tableDataCell'>
                                                <div className="certificateImg">
                                                    <img src={certificate?.certificate_file ? (IMAGES.includes(extension) ? certificate?.certificate_file : icon) : certificateIcon} alt="img" />
                                                </div>
                                            </TableCell>
                                            <TableCell className='tableDataCell cursor-pointer' onClick={() => handleClickOpen(certificate)}>
                                                {certificate?.title}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {moment(new Date(certificate?.certification_date)).format('DD MMMM YYYY')}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {!developerDetails?.is_certification_in_review &&
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="medium"
                                                        className="deleteIconBtn cursor-pointer"
                                                        onClick={() => handleOpenDialog(certificate?.id)}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="img"
                                                        />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {developerCertificates && developerCertificates.length > 0 &&
                        <PaginationRounded
                            totalPages={Math.ceil(userCertificateCount / pageSize)}
                            handlePages={handlePages}
                            page={currentPage}
                        />
                    }
                    <DeleteProjectDialog
                        open={openDialog}
                        close={handleCloseDialog}
                        confirm={handleConfirmDelete}
                        message="Are you Sure you want to Delete this Certificate?"
                        description="This action cannot be undone. Deleting it will erase all data associated with this certificate from system."
                    />
                    <CertificateDetailsDialog
                        open={open}
                        close={handleClose}
                        data={certificateData}
                    />
                </>
            }
        </>
    );
}

export default CertificationDetailsTab;
