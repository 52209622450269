import React from "react"
import sf from 'assets/img/SF1.png';
import MissionImage from 'assets/img/MissionImg1.png';
import visionImage from 'assets/img/VisionImg.png';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/aboutus.css";
import Typography from '@mui/material/Typography';

const TopSection = () => {
    return (
        <>
            <Container>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Typography variant='h4'>SourceFirms is founded! </Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Typography variant='body1'>TRooTech Group is seeing bigger dreams, and bigger ambitions with their next venture that targets bigger problems and upsurges bigger opportunities.</Typography>
                        <Typography variant='body1'>Yes, after the success of TRooTech, an enterprise software development company offering re-engineering of business to enterprise solutions from scratch. These folks come with a new venture, called SOURCEFIRMS that promises to be your bridge to the top software development agencies around the world.</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Typography variant='body1'>SOURCEFIRMS helps businesses acquire technology talents from businesses. We know what it&apos;s like to find better resources or to build successful enterprises.</Typography>
                        <Typography variant='body1'>After being successful development partners for many enterprises, we wanted to become collaborators and coaches of entrepreneurs looking for development partners. So, come work with us, and we’ll work for you to provide solutions that focus on the future.</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <div className='sf-image'>
                            <img src={sf} />
                        </div>
                    </Grid>
                </Grid>
            </Container>

            <div className="about-middle-content">
                <Container>
                    <div className="about-mission">
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6}>
                                <div className="about-mission-content">
                                    <div className="about-mission-img">
                                        <img src={MissionImage} alt="" />
                                        <div className="about-mission-text">
                                            <Typography variant="h3">Our Mission</Typography>
                                            <Typography variant="body1">To be trusted collaborators for enterprises and talents with an unparalleled combination of business acumen and technology expertise that focuses on their winnings.</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <div className="about-mission-content">
                                    <div className="about-mission-img">
                                        <img src={visionImage} alt="" />
                                        <div className="about-mission-text">
                                            <Typography variant="h3">Our Vision</Typography>
                                            <Typography variant="body1">To reimagine resource augmentation marketplace for freelancers and enterprises and bridge a gap that has existed in the global market among them for software development needs.</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default TopSection