import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import defaultImg from "assets/img/default-avatar.png";
import FormControl from "@mui/material/FormControl";
import { Field, reduxForm } from 'redux-form';
import { required } from 'utils/Validation';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch } from 'react-redux';
import validate from "./OfferValidate";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { wrapperJobPostRedirection, wrapperDeveloperRedirection } from 'utils/Common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



function CounterOfferDialog(props) {
    const { open, close, opponent, initialValues, handleSubmit, valid, reset, submitting, userDetails } = props;
    const dispatch = useDispatch();

    const handleClose = (__, reason) => {
        if (reason === "backdropClick") return false;
        dispatch(reset('OfferForm'));
        close();
    }

    return (
        <div>
            <BootstrapDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                className='approval_modal offer-accept-model'
            >
                <BootstrapDialogTitle id="customized-dialog-title" style={{ pointerEvents: submitting ? "none" : "inherit" }} className='model-top-heading' onClose={handleClose}>
                    <Typography variant='h3'>Counter Offer</Typography>
                </BootstrapDialogTitle>
                <form onSubmit={handleSubmit} data-testid="counter-offer-form" id='counter-offer-form'>
                    <DialogContent className='interview-text'>
                        <div className='offer-content'>
                            <div className='inner-content'>
                                <div className='offer-profile-img'>
                                    <img src={opponent?.profile_image || defaultImg} alt='' />
                                </div>
                                <div className='offer-profile-text'>
                                    <Typography variant='h5'>{opponent?.company_name}</Typography>
                                    <Typography variant='body1'>{opponent?.city?.name}, {opponent?.city?.country_name}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className='job-post-id'>
                            <Typography variant='body1'>
                                Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(userDetails?.user_type, initialValues?.job_post?.id, initialValues?.candidate?.id, initialValues?.job_post_proposal)} target='_blank'>{initialValues?.job_post?.uid}</Link></span> 
                            </Typography>
                            <Typography variant='body1'>
                                Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(userDetails?.user_type, initialValues?.candidate?.id, initialValues?.job_post_proposal)} target='_blank'>{initialValues?.candidate?.uid}</Link></span>
                            </Typography>
                        </div>


                        <div className='center-content'>
                            <div className='interview-data'>
                                <div className='select-option-data'>
                                    <Field
                                        name="sal_type"
                                        component={CustomInput}
                                        id="sal_type"
                                        labelText="Salary Type*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            placeholder: "Developer salary type",
                                            disabled: true,
                                        }}
                                    />
                                </div>
                                <div className='select-option-data'>
                                    <FormControl variant="standard" className="formControl">
                                        <Field
                                            name="amount"
                                            component={CustomInput}
                                            id="amount"
                                            labelText="Enter Amount*"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            validate={[required]}
                                            inputProps={{
                                                placeholder: "Enter amount",
                                                type: "number"
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className='bottom last-content-btn'>
                        <Link
                            href={void (0)}
                            style={{ pointerEvents: submitting ? "none" : "inherit" }}
                            underline="none"
                            onClick={handleClose}
                            className='cancelBtn'
                        >
                            Cancel
                        </Link>
                        <LoadingButton
                            type="submit"
                            className={`modalBtn primaryButton ${submitting && 'loading'}`}
                            loading={submitting}
                            variant="contained"
                            loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                            disabled={!valid}
                        >
                            Send
                        </LoadingButton>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </div>
    );
}

const CounterOfferReduxForm = reduxForm({
    form: "CounterOfferForm",
    validate,
    enableReinitialize: true,
})(CounterOfferDialog);

export default CounterOfferReduxForm;
