import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableSkeleton from './TableSkeleton';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography variant='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const RequestSkeleton = (props) => {
    const { classes, user } = props;
    return (
        <Grid className="request-top-section">
            <Container>
                <Grid className="request-top-inner">
                    <div className="request-heading">
                        <Typography variant='h3'><Skeleton className='transform-scale-1' width={187} /></Typography>
                    </div>
                    <div className='request-table'>
                        <div className={classes.root}>
                            {user !== 'agency' &&
                                <AppBar position="static">
                                    <Tabs value={0} aria-label="simple tabs example">
                                        <Tab label={<Skeleton width={70} />} />
                                        <Tab label={<Skeleton width={70} />} />
                                    </Tabs>
                                </AppBar>
                            }
                            <div className='main-table-filter'>
                                <div className='searchbar-content'>
                                    <div className='searchBar'>
                                        <Skeleton className='transform-scale-1' width={320} height={48} />
                                    </div>
                                </div>
                            </div>
                            {user !== 'agency' ?
                                <TabPanel value={0} index={0}>
                                    <div className='main-table-filter'>
                                        <div className='job-post-table'>
                                            <TableSkeleton />
                                        </div>
                                    </div>
                                </TabPanel>
                                :
                                <TableSkeleton />
                            }
                        </div>
                    </div>
                </Grid>
            </Container>
        </Grid>
    );
}

export default RequestSkeleton;