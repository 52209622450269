import moment from "moment";
import { isToday, isPastDate } from "utils/Date";

export const InterviewValidate = values => {
    const errors = {};

    if (values?.time && (isToday(values?.date) || isPastDate(values?.date))) {
        const incomingTime = moment(values?.time).local();
        const currentTime = moment().local();
        errors.time = incomingTime.isBefore(currentTime) ? 'Please enter valid time' : undefined;
    }

    return errors;
}

export default InterviewValidate