import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import history from "utils/history";
import DeleteDeveloperDialog from "components/Dialog/DeleteDeveloperDialog";
import "assets/css/manage_agency-developer.css";
import visibilityIcon from "assets/img/icons/visibility-icon.svg";
import noDataImg from "assets/img/icons/no-developer-found.svg";
import Typography from "@mui/material/Typography";
import PaginationRounded from "components/Pagination/PaginationRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { hasActiveSubscription } from "utils/constant";

const columns = [
  { id: "id", label: "Developer ID", minWidth: 170 },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "experience",
    label: "Experience",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "projects",
    label: "Projects",
    minWidth: 20,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "",
    minWidth: 20,
    format: (value) => value.toLocaleString("en-US"),
  },
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#548ca8",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#CECECE" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function List(props) {
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const {
    confirmDeleteDeveloper,
    developerStatusChange,
    pageSize,
    handlePages,
    loading,
    currentPage,
  } = props;
  const getManageDeveloperList = useSelector(
    (state) => state.manageDevelopersReducer?.getDevelopersList?.results
  );
  const getManageDeveloperDetails = useSelector(
    (state) => state.manageDevelopersReducer?.getDevelopersList
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [fileElIndex, setFileElIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isHover, setIsHover] = useState(null);

  const handleAction = (event, agencyDeveloperId) => {
    setAnchorEl(event.currentTarget);
    setFileElIndex(agencyDeveloperId);
  };
  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };
  const viewProfile = (agencyDeveloperId, onBoardingSteps) => {
    setAnchorEl(null);
    if (onBoardingSteps) {
      history.push("/manage-developers/add", {
        developerId: agencyDeveloperId,
      });
    } else {
      history.push(`/developer-profile/${agencyDeveloperId}`);
    }
  };
  const deleteDeveloper = (_id) => {
    setAnchorEl(null);
    setOpen(true);
    setUserId(_id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addDevelopers = useCallback(() => {
    if (userDetails.can_add_new && hasActiveSubscription(userDetails)) {
      history.push("/manage-developers/add");
    } else if (userDetails.can_add_new == false) {
      toast.warn("Your developer creation limit has been reached. You'll need to wait until your subscription cycle ends to create more.");
    } else {
      toast.warn("Since your subscription is inactive, you cannot create developers.");
    }
   
  }, []);

  const statusChange = (event, _id) => {
    developerStatusChange(_id, { is_active: event.target.checked });
  };

  const mouseOver = useCallback(
    (key) => {
      setIsHover(key);
    },
    [isHover]
  );
  const mouseOut = useCallback(() => {
    setIsHover(null);
  }, []);

  const getIdCellWithStatus = (row) => {
    const updatedDot = row.is_blocked
      ? "updated-dot-blocked"
      : row.is_pending_request
      ? "updated-dot"
      : "";
    if (row.is_pending_request && isHover === row.id) {
      return (
        <Typography className="approval-tag">
          Profile is under Approval
        </Typography>
      );
    } else if (row.is_blocked && isHover === row.id) {
      return (
        <Typography className="blocked-tag">Profile is Blocked</Typography>
      );
    } else {
      return (
        <div>
          <span className={updatedDot}></span>
          {row.uid}
        </div>
      );
    }
  };

  return (
    <>
      <Container>
        <Grid className="pagetitle-container">
          <h3>Manage Developers</h3>
          {getManageDeveloperList &&
            getManageDeveloperList.length > 0 &&(
              <Button
                variant="contained"
                className="primaryButton nextBtn"
                onClick={addDevelopers}
              >
                + Add New
              </Button>
            )}
        </Grid>
        {getManageDeveloperList && getManageDeveloperList.length > 0 ? (
          <Paper sx={{ width: "100%" }} className="manage-developer-paper">
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="manage_developer-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={12} className="tableTitle">
                      Developers
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 50, minWidth: column.minWidth }}
                        className="tableCellHead"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading ? (
                  <div className="custom-search-loader">
                    <CircularProgress className="custom-circle-loder" />
                  </div>
                ) : (
                  <TableBody>
                    {getManageDeveloperList &&
                      getManageDeveloperList.map((row) => {
                        return (
                          <TableRow
                            key={row.id}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className={
                              row.last_user_request_status == "3"
                                ? "isRejected"
                                : ""
                            }
                          >
                            <TableCell
                              onMouseOver={() => mouseOver(row.id)}
                              onMouseOut={mouseOut}
                              className="tableDataCell cell-id"
                            >
                              {getIdCellWithStatus(row)}
                            </TableCell>
                            <TableCell className="tableDataCell">
                              {row.name}
                            </TableCell>
                            <TableCell className="tableDataCell">
                              {row.experience} Years
                            </TableCell>
                            <TableCell className="tableDataCell">
                              {row.projects || 0}
                            </TableCell>
                            <TableCell className="tableDataCell">
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={row.status}
                                    onChange={(event) =>
                                      statusChange(event, row.id)
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell className="tableDataCell">
                              <>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={
                                    openMenu ? "long-menu" : undefined
                                  }
                                  aria-expanded={openMenu ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) =>
                                    handleAction(event, row.id)
                                  }
                                  className="actionBtn"
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={
                                    Boolean(anchorEl) && fileElIndex === row.id
                                  }
                                  onClose={handleActionMenuClose}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  PaperProps={{
                                    style: {
                                      width: "10ch",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      viewProfile(
                                        row.id,
                                        row?.on_boarding_steps
                                      )
                                    }
                                    className="more_actionList"
                                  >
                                    <Link>
                                      <img
                                        src={visibilityIcon}
                                        style={{ width: "12px" }}
                                        alt="img"
                                      />
                                      View
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => deleteDeveloper(row.id)}
                                    className="more_actionList delete"
                                  >
                                    <Link>
                                      <img
                                        src={deleteIcon}
                                        style={{ width: "12px" }}
                                        alt="img"
                                      />
                                      Delete
                                    </Link>
                                  </MenuItem>
                                </Menu>
                              </>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <DeleteDeveloperDialog
              open={open}
              handleClose={handleClose}
              confirmDelete={() => {
                setOpen(false);
                confirmDeleteDeveloper(userId);
              }}
            />
          </Paper>
        ) : (
          <Grid className="manage-developer-paper">
            <div className="no_data-content">
              <div>
                <img src={noDataImg} alt="no data" />
                <Typography variant="h6">No Developer Found!!</Typography>
                <Typography component="p">Want to Add Developer?</Typography>
                <Button
                  variant="contained"
                  className="primaryButton nextBtn"
                  onClick={addDevelopers}
                >
                  + Add New
                </Button>
              </div>
            </div>
          </Grid>
        )}
        <div>
          {getManageDeveloperList && getManageDeveloperList?.length > 0 && (
            <PaginationRounded
              totalPages={
                Math.ceil(getManageDeveloperDetails?.count / pageSize) || 0
              }
              handlePages={handlePages}
              page={currentPage}
            />
          )}
        </div>
      </Container>
    </>
  );
}
export default List;
