import React from "react";
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import 'assets/css/myrequest.css';


const TableSkeleton = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 0 }}>
                <TableContainer>
                    <Table
                        className='table-center-content'
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <TableHead>
                            <TableRow >
                                <TableCell
                                    align='left'
                                    padding='normal'
                                    className="single-td"
                                >
                                    <Skeleton width='100%' height={20} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" className="single-td">
                                    <Skeleton width='100%' height={30} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default TableSkeleton;