import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import { Field, reduxForm, getFormValues } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import DatePickerField from 'components/CustomDateRangePicker/DatePickerField';
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import { required, minimumDate } from 'utils/Validation';
import moment from 'moment';
import { connect, } from "react-redux";


function WorkExperienceForm(props) {

    const { handleSubmit, formValue, change } = props;
    const [minDate, setMinDate] = useState(null);

    const handleChangeDate = (date) => {
        let tempDate = moment(date).add(1, 'days');
        setMinDate(new Date(tempDate));
        if (date >= formValue?.end_date) {
            change('end_date', '');

        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="edit-container-content">
                <Grid container className="formGrid">
                    <Grid className="formGrid-inner">
                        <Field
                            name="company_name"
                            id="company_name"
                            labelText="Company Name*"
                            className='inputField'
                            component={CustomInput}
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[required]}
                            inputProps={{
                                type: "text",
                                placeholder: "Enter Company Name",
                            }}
                        />
                    </Grid>
                    <Grid className='formGrid-inner'>
                        <Field
                            name="title"
                            id="title"
                            component={CustomInput}
                            labelText="Designation*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[required]}
                            inputProps={{
                                placeholder: "Enter Designation",
                                type: "text",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="formGrid">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container className="formGrid datepicker-formGrid">
                            <Grid className="formGrid-inner">
                                <Field
                                    name="start_date"
                                    id="start_date"
                                    labelText="Duration*"
                                    placeholder="Start Date"
                                    component={DatePickerField}
                                    inputFormat="YYYY-MM-DD"
                                    validate={[required]}
                                    onChange={(date) => handleChangeDate(date)}
                                />
                            </Grid>
                            <span className="to-text">to</span>
                            <Grid className="formGrid-inner">
                                <Field
                                    name="end_date"
                                    id="end_date"
                                    component={DatePickerField}
                                    labelText=""
                                    minDate={minDate}
                                    placeholder="End Date"
                                    inputFormat="YYYY-MM-DD"
                                    validate={[required, minimumDate]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className='formGrid'>
                    <Field
                        name="description"
                        component={CustomTextArea}
                        id="description"
                        labelText="Description"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        multiline={true}
                        rows="2"
                        inputProps={{
                            placeholder: "Enter a description",
                        }}
                    />
                </Grid>
            </div>
        </form >
    )
}

const ReduxFormMapped = reduxForm({
    form: 'WorkExperienceForm', // a unique identifier for this form
    enableReinitialize: true
})(WorkExperienceForm);
const mapStateToProps = (state) => {
    return { formValue: getFormValues('WorkExperienceForm')(state) }
}
export default connect(mapStateToProps)(ReduxFormMapped);