import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import "assets/css/profile.css";
import "assets/css/skeleton.css";
import ProfessionalTabSkeleton from './ProfessionalTabSkeleton';

const ProfilePageSkeleton = () => {
    return (
        <>
            <Grid className="profile-top-section freelancer_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={12} md={12} sm={12}>
                            <Skeleton className='bg-light' width={350} />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container my_profile-container">
                <Grid className="userprofile-detail-section">
                    <Grid className="userDetails">
                        <Grid className="userImage">
                            <Skeleton className='bg-light' variant='circular' width={130} height={130} />
                        </Grid>

                        <Grid className="userInfo">
                            <div>
                                <h4><Skeleton className='bg-light' width={150} /></h4>
                                <p className="clientId">
                                    <Skeleton className='bg-light' width={250} />
                                </p>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid className="companyDetails experienceDetails">
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={100} />
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className="freelancer_profile-details">
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Grid className="profile-inner my_profile-inner personalDetail-section">
                            <Grid className="title">
                                <Typography variant="h5"><Skeleton width={180} height={40} /></Typography>
                                <Skeleton className="editBtn bg-dark" variant='rounded' width={30} height={30} />
                            </Grid>
                            <Grid container className="formGrid">
                                <FormControl variant="standard" className="formControl">
                                    <Skeleton className="m-r-8 bg-dark" variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label><Skeleton width={100} /></label>
                                        <p className="value"><Skeleton width={130} /></p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid container className="formGrid">
                                <FormControl variant="standard" className="formControl">
                                    <Skeleton className="m-r-8" variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label><Skeleton width={50} /></label>
                                        <p className="value"><Skeleton width={130} /></p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid className="profile-inner my_profile-inner otherDetail-section">
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={0}
                                    onChange={(value) => value}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Skeleton className='m-12-16' width={200} />
                                    <Skeleton className='m-12-16' width={100} />
                                    <Skeleton className='m-12-16' width={100} />
                                </Tabs>
                            </Box>
                            <div>
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="div"><ProfessionalTabSkeleton /></Typography>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </>
    );
}

export default ProfilePageSkeleton;