import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import 'assets/css/featureCard.css'
import 'assets/css/developer-profile.css';
import editIcon from 'assets/img/icons/edit-icon.svg';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import DeleteProjectDialog from 'components/Dialog/DeleteProjectDialog';
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from "../../../store/actions/index";
import { getUrl } from 'utils/constant';
import Typography from '@mui/material/Typography';
import history from 'utils/history';
import linkIcon from "assets/img/icons/link-icon.svg";
import ProjectDetailsDialog from 'components/Dialog/ProjectDetailsDialog';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import noProjectImage from "assets/img/icons/no-projects-icon.svg";

function ProjectTab(props) {
    const { currentTab, getTabDetails } = props;
    const userProjectList = useSelector(state => state.freelancerReducer?.projectList);
    const projectCount = useSelector(state => state.freelancerReducer?.projectCount);
    const [projectId, setprojectId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const userDetails = useSelector(state => state.authReducer.userDetails);
    const [openDetailsDialogue, setOpenDetailsDialogue] = useState(false);
    const [singleProjectData, setSingleProjectData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const handleOpenDialog = (id) => {
        setprojectId(id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        dispatch(Actions.deleteProject(projectId)).then(response => {
            if (response.status === 204) {
                toggalLoader(false);
                setOpenDialog(false);
                toast.success('Project deleted successfully');
                getTabDetails(getUrl(currentTab));
            } else {
                toggalLoader(false);
                setOpenDialog(false);
                toast.error('Please try again!');
            }
        }).catch(() => {
            toast.error('Please try again!');
        })
    }
    const handleOpenDetailsDialogue = (project = null) => {
        setOpenDetailsDialogue(!openDetailsDialogue)
        setSingleProjectData(project);
    }

    const handlePages = (page) => {
        setCurrentPage(page);
        dispatch(Actions.getProjects(page, pageSize));
    }

    return (
        <>
            {userDetails.is_projects_in_review ||
                <IconButton
                    className="edit-icon editBtn"
                    onClick={() => history.push("/freelancer-profile/edit-projects")}
                >
                    <img src={editIcon} alt="img" />
                </IconButton>
            }
            {(!userProjectList || userProjectList.length === 0)
                ?
                <Grid className="certificate-card-empty">
                    <div>
                        <img src={noProjectImage} alt="img" />
                        <Typography>No Projects!</Typography>
                    </div>
                </Grid>
                :
                <>
                    {userDetails.is_projects_in_review &&
                        <Typography className='approval-tag profileInnerApproval-tag'>
                            Project is under approval
                        </Typography>
                    }
                    <Typography className="count-profileTitle" component="p"><span>{projectCount}</span> Projects</Typography>
                    <Grid container className="projectsList">
                        {userProjectList && userProjectList.length > 0 && userProjectList.map((project, index) => (
                            <Grid item className="projectListItem" key={index}>
                                <Card className="project-card">
                                    <CardContent>
                                        <Grid className="project-top">
                                            <div className="icon-container">
                                                <p>{project?.title?.substring(0, 2)?.toUpperCase()}</p>
                                            </div>
                                            <div className="project-title cursor-pointer" onClick={() => handleOpenDetailsDialogue(project)}>
                                                <p className="name">{project?.title}</p>
                                            </div>
                                            <div className="" key={index} style={{ marginLeft: 'auto' }}>
                                                {!userDetails.is_projects_in_review &&
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="medium"
                                                        className="deleteIconBtn cursor-pointer"
                                                        onClick={() => handleOpenDialog(project.id)}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="img"
                                                        />
                                                    </IconButton>
                                                }
                                            </div>
                                        </Grid>
                                        <Grid className="about_project">
                                            <Typography>
                                                {project?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid className='skillset'>
                                            <List className='skillList'>
                                                {project?.technologies?.map(tech => (
                                                    <ListItem className='skillList-item' key={tech.id}>
                                                        <ListItemText primary={tech.title} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                        <Grid className="duration-details">
                                            <Typography>
                                                {(project?.duration > 0) &&
                                                    <>
                                                        <span>{project?.duration} Months</span>
                                                        {project?.is_on_going && <span> - Ongoing</span>}
                                                    </>
                                                }
                                            </Typography>
                                            {project?.link &&
                                                <a href={project?.link} target="_blank"> {/* eslint-disable-line */}
                                                    <img src={linkIcon} alt="img" />
                                                </a>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {userProjectList && userProjectList.length > 0 &&
                        <PaginationRounded
                            totalPages={Math.ceil(projectCount / pageSize) || 0}
                            handlePages={handlePages}
                            page={currentPage}
                        />
                    }
                    <DeleteProjectDialog
                        open={openDialog}
                        close={handleCloseDialog}
                        confirm={handleConfirmDelete}
                        message="Are you Sure you want to Delete this Project?"
                        description="This action cannot be undone. Deleting it will erase all data associated with this project from system."
                    />
                    <ProjectDetailsDialog
                        open={openDetailsDialogue}
                        close={handleOpenDetailsDialogue}
                        data={singleProjectData}
                    />
                </>
            }
        </>
    );
}

export default ProjectTab;