import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import { createFilterOptions } from '@mui/material/Autocomplete';
import OfferModalImg from 'assets/img/offer-modal-img.png'
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function OfferActionsDialog(props) {
    const { open, close, texts, status, performActions } = props;
    const [processing, setProcessing] = React.useState(false);

    const handleAction = () => {
        setProcessing(true);
        performActions(status).then(() => setProcessing(false)).catch(() => setProcessing(false));
    }

    return (
        <BootstrapDialog
            open={open}
            onClose={close}
            aria-labelledby="customized-dialog-title"
            className='approval_modal offer-accept-model'
        >
            <BootstrapDialogTitle id="customized-dialog-title" className='model-top-heading offer-modal-heading' onClose={close}>
                <div className='profile-coin'>
                    <img src={OfferModalImg} alt="" />
                </div>
                <Typography variant='h3'>{texts?.title}</Typography>
            </BootstrapDialogTitle>
            <DialogContent className='interview-text'>
                <div className='interview-data-text'>
                    <div className='interview-heading interview-open'>
                        <Typography variant='body1'>{texts?.message}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='bottom last-content-btn offer-btn-cancel'>
                <LoadingButton
                    className={`modalBtn primaryButton ${processing && 'loading'}`}
                    loading={processing}
                    variant="contained"
                    loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                    onClick={() => handleAction()}
                >
                    {texts?.buttonText}
                </LoadingButton>
                <Link
                    href={void (0)}
                    underline="none"
                    onClick={close}
                    className='cancelBtn'
                    style={{ pointerEvents: processing ? "none" : "inherit" }}
                >
                    Cancel
                </Link>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default OfferActionsDialog;
