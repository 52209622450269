import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CompanyIcon from "assets/img/icons/compeny-icon-blue.svg";
import ResourceIcon from "assets/img/icons/resorce-icon-white.svg";
import { Field, reduxForm } from "redux-form";
import Button from '@mui/material/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import { email, trimmed, charLimitWithTrim, required } from 'utils/Validation';
import { CONTACT_US_USER_TYPES } from 'utils/constant';
import { loadcaptcha, validateCaptcha, LoadCanvasTemplate } from 'components/CustomCaptcha/CustomCaptcha';
import { charOnly } from 'utils/Validation';

const checkCaptcha = (value) => {
    if (validateCaptcha(value, false) === true) {
        return undefined
    } else {
        return "Captcha does not match"
    }
}

const ContactForm = (props) => {

    const { contactTo, handleContactTo, handleSubmit, change } = props;

    const handleClearCaptchaCode = () => {
        change('captcha_code', '')
    }

    useEffect(() => {
        loadcaptcha(5);
    }, []);
    return (
        <div className='contact-left-content'>
            <form onSubmit={handleSubmit} noValidate className='form-content'>
                <div className='contact-heading'>
                    <Typography variant='h1'>Contact us <span>(Let&apos;s talk about hiring top-rated dedicated software development teams.)</span></Typography>
                    <Typography variant='body1'>Thank you for your interest in SourceFirms.</Typography>
                    <Typography variant='body1'>Give us a call or better yet drop us an email. Either way, we’d Love to talk.</Typography>
                </div>

                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className='tabing-label'>
                            <span>Select Your User Type*</span>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6} className='grid-tabing-label'>
                        <div className={`contact-selection cursor-pointer ${contactTo == CONTACT_US_USER_TYPES.COMPANY ? 'active' : ''}`} onClick={() => handleContactTo(CONTACT_US_USER_TYPES.COMPANY)}>
                            <img src={ResourceIcon} alt='' />
                            <Typography variant='h6'>Company</Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6} className='grid-tabing-label'>
                        <div className={`contact-selection cursor-pointer ${contactTo == CONTACT_US_USER_TYPES.RESOURCE ? 'active' : ''}`} onClick={() => handleContactTo(CONTACT_US_USER_TYPES.RESOURCE)}>
                            <img src={CompanyIcon} alt='' />
                            <Typography variant='h6'>Resources</Typography>
                        </div>
                    </Grid>
                </Grid>


                <Grid container className="formGrid">
                    <Grid item className="formGrid-inner">
                        <Field
                            name="first_name"
                            component={CustomInput}
                            id="first_name"
                            labelText="First Name*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[required,charOnly]}
                            inputProps={{
                                placeholder: "Enter first name",
                                type: "text",
                            }}
                            normalize={charLimitWithTrim(30)}
                        />
                    </Grid>
                    <Grid item className="formGrid-inner">
                        <Field
                            name="last_name"
                            component={CustomInput}
                            id="last_name"
                            labelText="Last Name*"
                            formControlProps={{
                                fullWidth: true,

                            }}
                            validate={[required,charOnly]}
                            inputProps={{
                                placeholder: "Enter last name",
                                type: "text",
                            }}
                            normalize={charLimitWithTrim(30)}
                        />
                    </Grid>
                </Grid>
                <Grid item className="formGrid-inner">
                    <Field
                        name="email"
                        component={CustomInput}
                        id="email"
                        labelText="Email Address*"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        validate={[required, email]}
                        inputProps={{
                            placeholder: "Enter email address",
                            type: "text",

                        }}
                        normalize={trimmed()}
                    />
                </Grid>
                <Grid item className="formGrid-inner">
                    <Field
                        name="message"
                        id="message"
                        // className='inputField'
                        labelText="Your Message*"
                        component={CustomTextArea}
                        formControlProps={{
                            fullWidth: true
                        }}
                        rows={5}
                        validate={[required]}
                        inputProps={{
                            placeholder: "Enter text",
                        }}
                        placeholder="Enter your message"
                        normalize={charLimitWithTrim(1000)}
                    />
                </Grid>
                <Grid container className="formGrid">
                    <Grid item className="formGrid-inner captchContainer">
                        <div className="captcha-inner">
                            <LoadCanvasTemplate clearCode={handleClearCaptchaCode} />
                        </div>
                    </Grid>
                    <Grid item className="formGrid-inner">
                        <Field
                            name="captcha_code"
                            component={CustomInput}
                            id="captcha_code"
                            labelText="Enter the text as shown in Image*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[required, checkCaptcha]}
                            inputProps={{
                                placeholder: "Enter captcha code",
                                type: "text"
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item className="formGrid-inner contact-submit">
                    <Button
                        variant="contained"
                        className="primaryButton nextBtn"
                        type="submit"
                    >
                        Submit
                    </Button>
                </Grid>
            </form>
        </div >
    );
}

const ReduxContactUsFormMapped = reduxForm({
    form: "ContactUsForm", // a unique identifier for this form
    enableReinitialize: false,
})(ContactForm);

export default ReduxContactUsFormMapped