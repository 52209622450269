import React from 'react';
import CandidateProfileSkeleton from 'views/UserDashboards/ClientDashboard/Skeletons/CandidateProfileSkeleton';
import JobpostDetailsCardSkeleton from './JobpostDetailsCardSkeleton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const JobpostCandidateProfileSkeleton = (props) => {
    const { path, jobPostId, proposalId } = props;
    return (
        <>
            <Container>
                <Grid container className="profile-top-inner">
                    <Grid item lg={12} md={12} sm={12}>
                        <div className="topcandidate-heading">
                            <Stack spacing={2} className='breadcrumb-div'>
                                <Breadcrumbs className='breadcrumb-nav' separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                </Breadcrumbs>
                            </Stack >
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container className="top-job-post">
                {path !== `/job-post/${jobPostId}/candidate/${proposalId}` &&
                    <div className="m-t-24">
                        <JobpostDetailsCardSkeleton />
                    </div>
                }
                <CandidateProfileSkeleton />
            </Container>
        </>
    );
}

export default JobpostCandidateProfileSkeleton;