import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import editIcon from "assets/img/icons/edit-icon.svg";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import history from "utils/history";
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import * as Actions from "../../../store/actions/index";
import { getUrl, IMAGES } from 'utils/constant';
import CertificateDetailsDialog from "components/Dialog/CertificateDetailsDialog";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import certificateIcon from "assets/img/certificate.svg";
import PaginationRounded from "components/Pagination/PaginationRounded";
import { getFileExtention, getFileIcon } from "utils/Common";
import noProjectImage from "assets/img/icons/no-projects-icon.svg";
import TableBody from "@mui/material/TableBody";


function Certificates(props) {
    const { currentTab, getTabDetails } = props;
    const userCertificates = useSelector(state => state.freelancerReducer?.certificateList);
    const certificateCount = useSelector(state => state.freelancerReducer?.certificateCount);
    const userDetails = useSelector(state => state.authReducer.userDetails);
    const dispatch = useDispatch();
    const [certificationId, setCertificationId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { toggalLoader } = useContext(LoaderContext);
    const [certificateData, setCertificateData] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const handleOpenDialog = (id) => {
        setCertificationId(id);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        setOpenDialog(false);
        dispatch(Actions.deleteCertification(certificationId)).then(response => {
            if (response.status === 204) {
                toggalLoader(false);
                setOpenDialog(false);
                toast.success('Certificate deleted successfully');
                getTabDetails(getUrl(currentTab));
            } else {
                toggalLoader(false);
                setOpenDialog(false);
                toast.error('Please try again!');
            }
        }).catch(() => {
            toast.error('Please try again!');
        })
    }

    const handleClickOpen = (certificate = null) => {
        setCertificateData(certificate)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handlePages = (page) => {
        setCurrentPage(page);
        dispatch(Actions.getCertificationList(page, pageSize));
    }

    const columns = [
        { id: 'image_id', label: '', minWidth: 100 },
        {
            id: 'certi_title',
            label: 'Certificate Title',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'certi_date',
            label: 'Certification Date',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'action',
            label: '',
            align: 'right',
            minWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
    ];

    return (
        <>
            {userDetails?.is_certification_in_review ||
                <IconButton
                    className="edit-icon editBtn"
                    onClick={() => history.push("/freelancer-profile/edit-certification")}
                >
                    <img src={editIcon} alt="img" />
                </IconButton>
            }
            {!userCertificates || userCertificates.length == 0
                ?
                <Grid className="certificate-card-empty">
                    <div>
                        <img src={noProjectImage} alt="img" />
                        <Typography>No Certificates!</Typography>
                    </div>
                </Grid>
                :
                <>
                    {userDetails.is_certification_in_review &&
                        <Typography className='approval-tag profileInnerApproval-tag'>
                            Certification is under approval
                        </Typography>
                    }
                    <Typography className="count-profileTitle" component="p"><span>{certificateCount}</span> Certificates</Typography>
                    <TableContainer>
                        <Table className='project_detail-table certificate_table'>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align={column.align}
                                            style={{ top: 50, minWidth: column.minWidth }}
                                            className='tableCellHead'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userCertificates && userCertificates.length > 0 && userCertificates.map((certificate, index) => {
                                    let extension = getFileExtention(certificate?.certificate_file);
                                    let icon = getFileIcon(extension)
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell className='tableDataCell'>
                                                <div className="certificateImg">
                                                    <img src={certificate?.certificate_file ? (IMAGES.includes(extension) ? certificate?.certificate_file : icon) : certificateIcon} alt="img" />
                                                </div>
                                            </TableCell>
                                            <TableCell className='tableDataCell cursor-pointer' onClick={() => handleClickOpen(certificate)}>
                                                {certificate?.title}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {moment(new Date(certificate?.certification_date)).format('DD MMMM YYYY')}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {userDetails?.is_certification_in_review ||
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="medium"
                                                        className="deleteIconBtn cursor-pointer"
                                                        onClick={() => handleOpenDialog(certificate?.id)}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="img"
                                                        />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {userCertificates && userCertificates.length > 0 &&
                        <PaginationRounded
                            totalPages={Math.ceil(certificateCount / pageSize) || 0}
                            handlePages={handlePages}
                            page={currentPage}
                        />
                    }
                    <DeleteProjectDialog
                        open={openDialog}
                        close={handleCloseDialog}
                        confirm={handleConfirmDelete}
                        message="Are you Sure you want to Delete this Certificate?"
                        description=" This action cannot be undone. Deleting it will erase all data associated with this certificate from system."
                    />
                    <CertificateDetailsDialog
                        open={open}
                        close={handleClose}
                        data={certificateData}
                    />
                </>
            }
        </>
    );
}

export default Certificates;
