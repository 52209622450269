import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { USER_TYPES } from "../../../utils/constant";
import ClientRequests from './Client/ClientRequests';
import FreelancerRequests from './Freelancer/FreelancerRequests';
import AgencyRequests from './Agency/AgencyRequests';



const Requests = () => {
    const user = useSelector(state => state?.authReducer?.userDetails);

    return (
        <Grid className="request-top-section">
            {user?.user_type === USER_TYPES.CLIENT && <ClientRequests />}
            {user?.user_type === USER_TYPES.AGENCY && <AgencyRequests />}
            {user?.user_type === USER_TYPES.FREELANCER && <FreelancerRequests />}

        </Grid>
    );
}

export default Requests;