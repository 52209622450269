import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/aboutus.css";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import Footer from "components/Footer/Footer";
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MiddleSection from "./component/MiddleSection";
import BottomSection from "./component/BottomSection";
import TopSection from "./component/TopSection";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { useScrollspy } from "utils/Common";
import { USER_TYPES } from "utils/constant";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const AboutUs = () => {

  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const breadCrumbs = [{ name: 'home', path: '/home' }];

  const Menu = [{ id: 'about_top_section', title: 'SourceFirms is founded!' },
  { id: 'about_bottom_section', title: 'Revolutionizing Your Business’s Future' },
  { id: 'about_source_section', title: 'Leading Heads of SourceFirms' }];

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const activeIndex = useScrollspy(Menu, 54, "about_top_section");

  return (
    <>
      <Helmet>
        <title>SourceFirms: Marketplace of top software development agencies</title>
      </Helmet>
      <div>
        <div className={`dashboard-main-content ${(userDetails?.user_type === USER_TYPES.CLIENT) ? "dashboard-main-client-content" : ""}`}>
          <div className="main-panel">
            <AdminNavbar />
            <div className='privacy-policy'>
              <section className="privacy-policy-section">
                <div className='privacy-policy-inner aboutus-inner custom-bread-crumb'>
                  <Container>
                    <Grid container>
                      <Grid item lg={6}>
                        <Stack spacing={2} className='breadcrumb-div'>
                          <CustomBreadcrumbs current="About Us" previous={breadCrumbs} />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Grid className='banner-header'>
                      <Typography variant='h1'>
                        About SourceFirms <span>Best marketplace to hire dedicated software developers from top software development agencies</span>
                      </Typography>
                    </Grid>
                  </Container>
                </div>
              </section>
              <section className='aboutus-section'>
                <div className='aboutus-inner'>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <div className="tabing-box-content">
                      <div className="about-wrapper">
                        <Container>
                          <List className="tab-listing-content">
                            {Menu.map((menu) => (
                              <ListItem key={menu.id} className={(menu.id === activeIndex) ? 'active' : ''}>
                                <Link onClick={() => handleScroll(menu.id)}>{menu.title}</Link>
                              </ListItem>
                            ))}
                          </List>
                        </Container>
                      </div>
                    </div>

                    <div className="about-content">
                      <div id="about_top_section" className="about-top-main-content">
                        <TopSection />
                      </div>
                      <div className="about-bottom-content" id="about_bottom_section">
                        <MiddleSection />
                      </div>
                      <div className="source-content" id="about_source_section">
                        <BottomSection />
                      </div>
                    </div>
                  </Box>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs