import React from 'react';
import { MESSAGE_TYPE } from 'utils/constant';
import InterviewWrapper from './InterviewWrapper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChatAttachments from './ChatAttachments';
import ReadMoreLessWithSC from 'components/ReadMoreReadLess/ReadMoreLessWithSC';
import moment from 'moment';
import OfferWrapper from './OfferWrapper';
import HiredWrapper from './HiredWrapper';
import RejectedOfferWrapper from './RejectedOfferWrapper';

const MessageWrapper = (props) => {
    const { chat, messagePosition, handleDownloadFile } = props;
    return (
        <>
            <Grid className={`${messagePosition}-chat`}>
                <div className='chat-box'>
                    {messagePosition == 'right' && <Typography className='chat-time'>{moment(chat?.created).local().format('LT')}</Typography>}
                    {chat?.message_type === MESSAGE_TYPE.INTERVIEW &&
                        <InterviewWrapper details={chat?.message_detail} messagePosition={messagePosition} />}
                    {(chat?.message_type === MESSAGE_TYPE.OFFER && chat?.message_detail?.status === 1) &&
                        <OfferWrapper details={chat?.message_detail} messagePosition={messagePosition} />}
                    {(chat?.message_type === MESSAGE_TYPE.OFFER && chat?.message_detail?.status === 2) &&
                        <HiredWrapper details={chat?.message_detail} messagePosition={messagePosition} />}
                    {(chat?.message_type === MESSAGE_TYPE.OFFER && chat?.message_detail?.status === 3) &&
                        <RejectedOfferWrapper details={chat?.message_detail} messagePosition={messagePosition} />}
                    {chat?.message_type === MESSAGE_TYPE.CHAT &&
                        <>
                            {chat?.attachments?.length > 0 ||
                                <div className={`${messagePosition}-chat-block`}>
                                    <Typography>
                                        <ReadMoreLessWithSC
                                            className="Test"
                                            charLimit={300}
                                            readMoreText={"Read More"}
                                            readLessText={"Read Less"}
                                            readMoreClassName="read-more-open-39A1FF"
                                            readLessClassName="read-more-close-39A1FF"
                                            children={chat.message || ""} /* eslint-disable-line */
                                            textType={true}
                                        />
                                    </Typography>
                                </div>
                            }
                            <ChatAttachments attachments={chat?.attachments} downlaodFile={handleDownloadFile} cls={messagePosition} message={chat?.message} />
                        </>
                    }
                    {messagePosition == 'left' && <Typography className='chat-time'>{moment(chat?.created).local().format('LT')}</Typography>}
                </div>
            </Grid>
        </>
    );
}

export default MessageWrapper;