import React, { useState, useEffect, useContext } from "react";
import ProfessionalDetailsForm from "./component/ProfessionalDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions/index";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import history from "utils/history";
import { checkForImageValidations } from "utils/Common";

function ProfessionalDetail() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const userDetails = useSelector(state => state.authReducer.userDetails);
    const userPortfolio = useSelector(state => state.freelancerReducer?.portFolioList[0]);
    const [files, setFiles] = useState(userPortfolio?.document_file);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: '/my-profile' },
    ];

    useEffect(() => {
        dispatch(Actions.getProfessionalDetails());
    }, []);

    const updatePortfolio = (portfolio) => {
        toggalLoader(true);
        const updateUserProfileData = {
            skills: portfolio.skills.map(val => (val.id)),
            sub_skills: portfolio.sub_skills.map(val => (val.id)),
            technologies: portfolio.technologies.map(val => (val.id)),
            communication_language: portfolio.communication_language.map(val => {
                return {
                    id: val.id,
                    communication_language: val.communication_language.key,
                    proficiency: val.proficiency.id,
                }
            }),
            availability: portfolio.availability,
            document_file: files,
            profile_type: portfolio.profile_type.id,
            experience: portfolio.experience,
            salary_type: portfolio?.salary_type,
            amount: portfolio?.amount,
            education_details: portfolio.education_details,
            specialties: portfolio.specialties,
            description: portfolio.description,
        };
        if (!files || typeof files === 'string')
            delete updateUserProfileData.document_file;

        delete updateUserProfileData.profile_image;
        dispatch(Actions.updateProfessionalDetails(portfolio.id, updateUserProfileData)).then((response) => {
            if (response.status === 200) {
                toggalLoader(false);
                toast.success('Details updated successfully');
                history.push("/my-profile");
            }
            else {
                toggalLoader(false);
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                })
            }
        }).catch((error) => {
            toggalLoader(false);
            toast.error(error);
        });
    }
    const uploadImage = async (e) => {
        try {
            if (e instanceof File) {
                const fileCheck = await checkForImageValidations(e, 'file', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", e);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        setFiles(response.data);
                    }).catch(() => {
                        setFiles(userPortfolio?.document_file);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    };

    return (
        <div>
            <Grid className='profile-top-section freelancer_profile-top-section'>
                <Container>
                    <Grid container className='profile-top-inner'>
                        <Grid item lg={6}>
                            <CustomBreadcrumbs current="edit-professional-details" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6}>
                            <p className='clientId'>ID : <span>{userDetails.uid}</span></p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className='profile-container add_profile-container'>
                <h4>Edit Professional Details</h4>
                <Grid className='profile-inner add_profile-inner'>
                    <ProfessionalDetailsForm
                        initialValues={userPortfolio}
                        onSubmit={(values) => updatePortfolio(values)}
                        uploadImage={uploadImage}
                        customText={{
                            buttonText: "Save",
                            titleText: ''
                        }} />
                </Grid>
            </Container>
        </div >
    );
}

export default ProfessionalDetail;
