import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions/index";
import * as DeveloperActions from '../../store/actions/manageDevelopers/manageDevelopers.actions';
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DeveloperDetailsForm from "./component/DeveloperDetailsForm";
import AddProjectForm from "./component/AddProjectForm";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import "assets/css/profile.css";
import { checkForImageValidations, errorToast } from "utils/Common";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { getDeveloperTabDetailsForAdd } from "../../utils/constant";
import AddCertificationForm from "./component/AddCertificationForm";
import { useHistory } from "react-router";
import ImageCropDialog from "components/Dialog/ImageCropDialog";

const steps = ["Professional Details", "Projects Details", "Certification"];

function AddDeveloper() {
    const dispatch = useDispatch();
    const developerDetails = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperDetails);
    const developerProjects = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperProjectList);
    const developerCertificate = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperCertificationList);

    const [filesData, setFilesData] = useState([]);
    const [certificationFiles, setCertificationFiles] = useState([]);
    const [files, setFiles] = useState({ profile: null, identity: null });
    const [activeStep, setActiveStep] = useState(0);
    const [currentTab, setCurrentTab] = useState(getDeveloperTabDetailsForAdd(0));
    const history = useHistory();
    const devId = history?.location?.state?.developerId || null;
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });
    const [completed, setCompleted] = useState(null); /* eslint-disable-line */
    const [imageLoaders, setImageLoaders] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [submiting, setSubmiting] = useState(false);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-developers', path: '/manage-developers' },
    ];
    const totalSteps = () => {
        return steps.length;
    };
    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file instanceof File ? file : null });
    }
    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = (developerId = null) => {
        const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((_step, i) => !(i in completed)) : activeStep + 1;
        setActiveStep(newActiveStep);
        setFilesData([]);
        const newTab = getDeveloperTabDetailsForAdd(newActiveStep, developerId)
        setCurrentTab(newTab);
        getTabDetails(newTab);
    };

    const getTabDetails = (tab) => {
        dispatch(DeveloperActions.getDeveloperDetails(tab.getUrl)).then(response => {
            if (response.status === 200) {
                dispatch({ type: tab.type, payload: response.data?.results ?? response.data });
            } else {
                dispatch({ type: tab.type, payload: null });
            }
        }).catch(() => {
            dispatch({ type: tab.type, payload: null });
        });
    }

    const handleImageUpload = async (e) => {
        try {
            if (e instanceof File) {
                const fileCheck = await checkForImageValidations(e, 'file', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", e);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        if (response.status === 201) {
                            const filesObject = { ...files }
                            filesObject.identity = response.data;
                            setFiles(filesObject);
                        } else {
                            errorToast(response);
                        }
                    }).catch(error => {
                        toast.error(error?.message);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    };

    const hanldeProfileUplaod = async (event) => {
        try {
            if (event.target.files[0]) {
                const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message);
                    event.target.value = null;
                } else {
                    handleImgCropOpen(event.target.files[0]);
                    event.target.value = null;
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
            event.target.value = null;
        }
    }

    const uploadCroppedImage = (file) => {
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(Actions.uploadImage(formData)).then(response => {
            handleImgCropOpen();
            if (response.status === 201) {
                const filesObject = { ...files }
                filesObject.profile = response.data;
                setFiles(filesObject);
            } else {
                errorToast(response);

            }
        }).catch(error => {
            toast.error(error?.message);
        });
    }

    const handleMultipleImageUpload = (e, filedIndex) => {
        try {
            let allFiles = [...e.target.files];
            let uploadLength = 10;
            let filesLength = filesData?.[filedIndex]?.length ?? 0;
            let length = uploadLength - filesLength;

            if (allFiles.length == 0) return false;
            if (filesData?.[filedIndex]?.length >= 10) {
                toast.error("Max file upload has been reached for this project");
                return false;
            } else if (length > 0 && allFiles?.length > length) {
                allFiles = allFiles.slice(0, length);
                let message = length === 10 ?
                    "You can only upload 10 files at a time." :
                    `${filesLength} files have already been uploaded for this project now you can upload only ${length} files`;
                toast.warn(message);
            }
            setIsUploading(true);
            const tempArr = [...filesData];
            const loaders = [...imageLoaders];
            loaders[filedIndex] = true;
            setImageLoaders(loaders);
            let i = 1;
            let data = tempArr[filedIndex] || [];
            allFiles.forEach(async (file) => {
                const fileCheck = await checkForImageValidations(file, 'image');
                if (fileCheck instanceof Error) {
                    if (allFiles.length === i) {
                        loaders[filedIndex] = false;
                        setTimeout(() => {
                            setIsUploading(false);
                            setImageLoaders([...loaders]);
                        }, 500);
                    }
                    toast.error(fileCheck.message);
                    i++;
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", file);
                    dispatch(Actions.fileUpload(formData))
                        .then((response) => {
                            if (response.status === 201) {
                                data.push(response.data);
                                if (allFiles.length === i) {
                                    tempArr[filedIndex] = data;
                                }
                            } else {
                                Object.keys(response.data).forEach((error) => {
                                    toast.error(response.data[error][0]);
                                });
                            }
                            if (allFiles.length === i) {
                                loaders[filedIndex] = false;
                                setImageLoaders(loaders);
                                setFilesData(tempArr);
                                setIsUploading(false);
                            }
                            i++;
                        })
                        .catch(() => {
                            i++;
                            toast.error('Something went wrong');
                        });
                }
            });
        } catch (error) {
            toast.error('Something went wrong...' + error);
        }
    };

    const handleCertificationImageUpload = async (file, formIndex) => {
        try {
            const imagesData = [...certificationFiles];
            if (file instanceof File) {
                const fileCheck = await checkForImageValidations(file, 'certificate_file_type');
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", file);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        imagesData[formIndex] = response.data;
                        setCertificationFiles(imagesData);
                    }).catch(error => {
                        toast.error(error?.message);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }

    const removeImage = (fileIndex, index) => {
        let temp = [...filesData];
        temp[index].splice(fileIndex, 1);
        setFilesData(temp);
    };

    const removeAllProjectImages = (formIndex) => {
        let tempFiles = [...filesData];
        if (tempFiles?.[formIndex]) {
            tempFiles.splice(formIndex, 1);
            setFilesData(tempFiles)
        }
    }

    /* end for project add for developers */

    const submitForm = (data, message) => {
        try {
            if (activeStep === 0 && developerDetails?.id) {
                dispatch(Actions.agencyDeveloperUpdate(developerDetails?.id, data)).then(response => {
                    if (response.status === 200) {
                        dispatch({
                            type: "GET_AGENCY_DEVELOPER_DETAILS",
                            payload: response.data,
                        });
                        setSubmiting(false)
                        handleNext(response.data?.id);
                        history.push({ state: { developerId: response.data?.id } });
                    } else {
                        setSubmiting(false);
                        Object.keys(response.data).forEach((error) => {
                            toast.error(response.data[error]);
                        });
                    }
                }).catch((err) => {
                    setSubmiting(false)
                    Object.keys(err.data).forEach((error) => {
                        toast.error(err.data[error][0]);
                    });
                });
            } else {
                dispatch(Actions.submitDeveloperStepper(currentTab.postUrl, data)).then(response => {
                    if (response.status === 201) {
                        if (activeStep === 0) {
                            dispatch({
                                type: "GET_AGENCY_DEVELOPER_DETAILS",
                                payload: response.data,
                            });
                            setSubmiting(false)
                            handleNext(response.data?.id);
                            history.push({ state: { developerId: response.data?.id } });
                        } else {
                            let developerId = developerDetails?.id ?? devId;
                            setTimeout(() => {
                                setSubmiting(false);
                            }, 500);
                            if (isLastStep()) {
                                toast.success(message);
                                setTimeout(() => {
                                    history.push(`/manage-developers`);
                                }, 500);
                            } else {
                                handleNext(developerId);
                                history.push({ state: { developerId: developerId } });
                            }
                        }
                    } else {
                        setSubmiting(false)
                        Object.keys(response.data).forEach((error) => {
                            toast.error(response.data[error][0]);
                        })
                    }
                }).catch((error) => {
                    setSubmiting(false)
                    toast.error(error);
                });
            }
        } catch (error) {
            setSubmiting(false);
            toast.error('Something went wrong');
        }
    }

    const getAgencyDeveloperById = (developerId) => {
        dispatch(Actions.getAgencyDeveloperById(developerId));
    };

    const handleSkip = () => {
        setSubmiting(true);
        let param = { on_boarding_steps: activeStep + 2 };
        if (isLastStep()) {
            param.on_boarding_steps = null;
        }
        dispatch(Actions.agencyDeveloperUpdate(developerDetails?.id, param)).then(response => {
            if (response.status === 200) {
                if (isLastStep()) {
                    toast.success("Developer details added successfully");
                    dispatch({ type: 'GET_AGENCY_DEVELOPER_DETAILS', payload: { ...developerDetails, on_boarding_steps: null, is_profile_in_review: true } });
                    history.push('/manage-developers');
                } else {
                    getAgencyDeveloperById(developerDetails?.id);
                    setSubmiting(false);
                    handleNext(developerDetails?.id);
                    history.push({ state: { developerId: developerDetails?.id } });
                }
            } else {
                setSubmiting(false);
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                });
            }
        }).catch(() => {
            setSubmiting(false);
            toast.error('Something went wrong');
        });
    }

    const handleProjectRemove = (index) => {
        let tempFiles = [...filesData];
        if (tempFiles?.[index]) {
            tempFiles.splice(index, 1); /* eslint-disable-line */
            setFilesData(tempFiles);
        }
    }

    useEffect(() => {
        if (devId) {
            getAgencyDeveloperById(devId);
        }
    }, [devId])

    useEffect(() => {
        if (developerDetails?.on_boarding_steps) {
            let oStep = developerDetails.on_boarding_steps - 1;
            setActiveStep(oStep)
            let currentTabDetails = getDeveloperTabDetailsForAdd(oStep, developerDetails?.id);
            if (oStep > 0) {
                getTabDetails(currentTabDetails);
            }
            let fileList = { ...files };
            fileList.profile = developerDetails?.portfolio?.profile_image;
            fileList.identity = developerDetails?.portfolio?.document_file;
            setFiles(fileList);
            setCurrentTab(currentTabDetails);
        }
    }, [developerDetails]);


    useEffect(() => {
        if (developerProjects?.length > 0) {
            let temp = [...filesData];
            developerProjects.forEach((project, index) => {
                temp[index] = project?.images;
                if (developerProjects?.length === index + 1) {
                    setFilesData(temp);
                }
            })
        }
    }, [developerProjects]);

    useEffect(() => {
        if (developerCertificate?.length > 0) {
            let temp = [...certificationFiles];
            developerCertificate.forEach((certificate, index) => {
                temp[index] = certificate.certificate_file;
                if (developerCertificate.length === index + 1) {
                    setCertificationFiles(temp);
                }
            })
        }
    }, [developerCertificate]);

    return (
        <React.Fragment>
            <Grid className="profile-top-section add_profile-top-section custom-bread-crumb">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="add-developer" previous={breadCrumbs} />
                        </Grid>
                        {developerDetails?.first_name &&
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <p className='clientId'>Developer Name : <span>{`${developerDetails?.first_name} ${developerDetails?.last_name}`}</span></p>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Add Profile Details</h4>
                <Grid className="stepper-container">
                    <Box>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            className="stepperBox"
                        >
                            {steps.map((label) => {
                                return (
                                    <Step key={label} className="stepperStep">
                                        <StepLabel className="stepLabel">{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Grid>
                <Grid className="profile-inner add_profile-inner">
                    <Box className="add_profile-form">
                        {activeStep === steps.length ? (
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed
                            </Typography>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 && (
                                    <DeveloperDetailsForm
                                        initialValues={developerDetails || null}
                                        onSubmit={(developer) => {
                                            setSubmiting(true)
                                            let values = JSON.parse(JSON.stringify(developer));
                                            const data = {
                                                first_name: values.first_name,
                                                last_name: values.last_name,
                                                email: values.email,
                                                contact_number: values.contact_number,
                                                profile_image: files.profile,
                                                portfolio: {
                                                    skills: values?.portfolio.skills?.map(
                                                        (skill) => skill.id
                                                    ),
                                                    sub_skills: values?.portfolio.sub_skills?.map(
                                                        (subSkill) => subSkill.id
                                                    ),
                                                    technologies: values?.portfolio?.technologies?.map(
                                                        (tech) => tech.id
                                                    ),
                                                    profile_type: values.portfolio.profile_type.id,
                                                    communication_language:
                                                        values?.portfolio?.communication_language?.map((val) => {
                                                            return {
                                                                id: val.id,
                                                                communication_language: val.communication_language.key,
                                                                proficiency: val.proficiency.id,
                                                            }
                                                        }),
                                                    document_file: files.identity,
                                                    experience: values.portfolio.experience,
                                                    availability: values.portfolio.availability,
                                                    salary_type: values.portfolio.salary_type,
                                                    amount: values.portfolio.amount,
                                                    education_details: values.portfolio.education_details,
                                                    specialties: (values.portfolio?.specialties?.replace(/<(.|\n)*?>/g, '').trim().length === 0) ? '' : values.portfolio.specialties,
                                                    description: values.portfolio.description,
                                                }
                                            }
                                            if (!files.profile || typeof files.profile === 'string') {
                                                delete data.profile_image;
                                                delete data.portfolio.profile_image;
                                            }
                                            if (!files.identity || typeof files.identity === 'string')
                                                delete data.portfolio.document_file;

                                            submitForm(data, 'Developer details added successfully');
                                        }}
                                        submiting={submiting}
                                        isAddType={true}
                                        handleImageUpload={handleImageUpload}
                                        uplaodProfile={hanldeProfileUplaod}
                                        files={files}
                                    />
                                )}
                                {activeStep === 1 && (
                                    <AddProjectForm
                                        initialValues={{ projects: developerProjects || [] }}
                                        onSubmit={(values) => {
                                            setSubmiting(true);
                                            let projectsData = [];
                                            let projects = JSON.parse(JSON.stringify(values.projects));
                                            if (projects.length > 0) {
                                                projects.map((project, index) => {
                                                    const data = {
                                                        title: project.title,
                                                        duration: parseInt(project.duration),
                                                        is_on_going: (project.is_on_going) ? project.is_on_going : false,
                                                        technologies: project.technologies?.map((tech) => tech.id),
                                                        skills: project.skills?.map((skill) => skill.id),
                                                        description: project.description,
                                                        images: filesData[index],
                                                        link: project.link
                                                    }
                                                    projectsData.push(data)
                                                    return project;
                                                });
                                                submitForm(projectsData, 'Project Details added successfully');
                                            }
                                        }}
                                        open={false}
                                        isAddType={true}
                                        uploadImage={handleMultipleImageUpload}
                                        removeImage={removeImage}
                                        removeAllProjectImages={removeAllProjectImages}
                                        filesData={filesData}
                                        developerDetails={developerDetails}
                                        handleSkip={handleSkip}
                                        handleRemove={handleProjectRemove}
                                        imageLoaders={imageLoaders}
                                        isUploading={isUploading}
                                        submiting={submiting}
                                    />
                                )}
                                {activeStep === 2 &&
                                    <AddCertificationForm
                                        initialValues={{ certification: developerCertificate || [] }}
                                        activeStep={activeStep}
                                        imageUpload={handleCertificationImageUpload}
                                        developerDetails={developerDetails}
                                        handleSkip={handleSkip}
                                        submiting={submiting}
                                        onSubmit={(details) => {
                                            setSubmiting(true)
                                            let certificatesData = [];
                                            details?.certification.forEach((ele, index) => {
                                                const certificates = {
                                                    title: ele.title,
                                                    certification_date: ele.certification_date,
                                                    certification_link: ele.certification_link
                                                }
                                                if (certificationFiles && certificationFiles[index]) {
                                                    certificates.certificate_file = certificationFiles[index]
                                                }
                                                certificatesData.push(certificates)
                                                if (details?.certification.length === index + 1) {
                                                    // submitForm(certificatesData, "Certification saved successfully")
                                                    submitForm(certificatesData, "Developer details added successfully")
                                                }
                                            });
                                        }}
                                    />
                                }
                            </React.Fragment>
                        )}
                    </Box>
                </Grid>
            </Container>
            <ImageCropDialog open={cropHandlers.openDialog} close={handleImgCropOpen} file={cropHandlers.file} uploadCroppedImage={uploadCroppedImage} />
        </React.Fragment>
    );
}

export default AddDeveloper;
