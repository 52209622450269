import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { TablePagination, Tooltip, Zoom } from '@mui/material';
import history from 'utils/history';
import CircularProgress from '@mui/material/CircularProgress';

const headCells = [
    {
        id: 'uid',
        numeric: false,
        disablePadding: true,
        label: 'Developer Id',
        ordering: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Developer Name',
        ordering: true,
    },
    {
        id: 'profile_type',
        numeric: true,
        disablePadding: false,
        label: 'Profile Type',
        ordering: true,
    },
    {
        id: 'experience',
        numeric: true,
        disablePadding: false,
        label: 'Experince',
        ordering: true,
    },
    {
        id: 'proposals_count',
        numeric: true,
        disablePadding: false,
        label: 'Pending Requests',
        ordering: true,
    },
];

export default function AgencyRequestTable(props) {
    const { data, filter, page, loading } = props;
    const pageOrderBy = page?.orderBy?.replace("-", "");
    const [dense,] = useState(false);

    const handleRequestSort = (_, property) => {
        const isAsc = pageOrderBy === property ? (page.order === 'desc' ? true : false) : true;
        const order = isAsc ? 'asc' : 'desc'
        const orderBy = isAsc ? property : `-${property}`;
        filter(page.page, page.pageSize, page.search, order, orderBy);
    };

    const handleChangePage = (_, newPage) => {
        filter(newPage + 1, page.pageSize);
    };

    const handleChangeRowsPerPage = (event) => {
        const dataPerPage = parseInt(event.target.value, 10)
        filter(1, dataPerPage);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRedirect = (id) => {
        history.push(`/requests/${id}`);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 0 }}>
                <TableContainer>
                    <Table
                        className='table-center-content'
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <TableHead>
                            <TableRow className="cursor-pointer">
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'left' : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={pageOrderBy === headCell.id ? page.order : false}
                                    >
                                        {headCell.ordering ?
                                            <TableSortLabel
                                                active={pageOrderBy === headCell.id}
                                                direction={pageOrderBy === headCell.id ? page.order : 'asc'}
                                                onClick={createSortHandler(headCell.id)} >
                                                {headCell.label}
                                                {pageOrderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {page.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel> :
                                            headCell.label
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading ?
                            <div className='custom-search-loader'>
                                <CircularProgress className='custom-circle-loder' />
                            </div>
                            :
                            <TableBody>
                                {(data?.results && data?.results?.length > 0) ? data?.results?.map((row, index) => {
                                    return (
                                        <TableRow
                                            tabIndex={-1}
                                            key={index}
                                            onClick={() => handleRedirect(row.id)}
                                            className="cursor-pointer"
                                        >
                                            <Tooltip key={index} title={`Click to view the details of this request`} TransitionComponent={Zoom}>
                                                <TableCell
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row?.uid}
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="left">{row?.name}</TableCell>
                                            <TableCell align="left">{row?.profile_type}</TableCell>
                                            <TableCell align="left">{row?.experience}</TableCell>
                                            <TableCell align="left">{row?.request_count}</TableCell>
                                        </TableRow>
                                    );
                                }) :
                                    <TableRow>
                                        <TableCell className='single-td' align="center" colSpan={6}>Sorry, no matching records found</TableCell>
                                    </TableRow>

                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.count || 0}
                    rowsPerPage={page?.pageSize}
                    page={page?.page ? page.page - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box >
    );
}