import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess'
import editIcon from 'assets/img/icons/edit-icon.svg';
import history from "utils/history";
import { FormControl, Slider, Typography } from "@mui/material";
import fileIcon from 'assets/img/icons/file-icon.svg';
import { PROFICIENCY_DIVIDER, PROFICIENCY_TAG } from "utils/constant";
import { SpliceList } from "components/SpliceList/SpliceList";

function ProfessionalDetailsTab(props) {
    const { developerDetails } = props;

    return (
        <>
            <Grid container justifyContent="flex-end">
                {developerDetails?.is_portfolio_in_review &&
                    <Typography className='approval-tag profileInnerApproval-tag'>
                        Professional Detail is under approval
                    </Typography>
                }
                {developerDetails?.is_portfolio_in_review ||
                    <IconButton className="edit-icon editBtn cursor-pointer" disabled={developerDetails?.is_portfolio_in_review} onClick={() => { history.push(`/manage-developers/edit/${developerDetails?.id}`) }}>
                        <img src={editIcon} alt='img' />
                    </IconButton>
                }
            </Grid>
            <p className="CategoryTitle portfolioTabSkill">Technologies</p>
            <Grid>
                <List className="categoryList">
                    <SpliceList data={developerDetails?.portfolio?.technologies} mainClass="categoryList-item" subClass="more-subcategory"
                        limit={4} />
                </List>
            </Grid>
            <p className="CategoryTitle portfolioTabSkill">Skills</p>
            <Grid>
                <List className="categoryList">
                    <SpliceList data={developerDetails?.portfolio?.skills} mainClass="categoryList-item" subClass="more-subcategory"
                        limit={5} />
                </List>
            </Grid>
            {developerDetails?.portfolio?.sub_skills?.length > 0 &&
                <>
                    <p className="CategoryTitle">Sub-skills</p>
                    <Grid>
                        <List className="categoryList">
                            <SpliceList data={developerDetails?.portfolio?.sub_skills} mainClass="categoryList-item" subClass="more-subcategory"
                                limit={5} />
                        </List>
                    </Grid>
                </>
            }
            <Grid className="aboutContent">
                {developerDetails?.portfolio?.description &&
                    <>
                        <p className="developer-profile-subtitle">About</p>
                        <p className="content">
                            <ReadMoreReadLess
                                charLimit={100}
                                readMoreText={"Read More"}
                                readLessText={"Read Less"}
                                readMoreClassName="read-more-open-39A1FF"
                                readLessClassName="read-more-close-39A1FF"
                                data={developerDetails?.portfolio?.description || ""}
                                textType={true}
                            />
                        </p>
                    </>
                }

                <p className="developer-profile-subtitle">Specialities</p>
                <p className="content">
                    {developerDetails?.portfolio?.specialties ?
                        <ReadMoreReadLess
                            charLimit={100}
                            readMoreText={"Read More"}
                            readLessText={"Read Less"}
                            readMoreClassName="read-more-open-39A1FF"
                            readLessClassName="read-more-close-39A1FF"
                            data={developerDetails?.portfolio?.specialties || "-"}
                            textType={true}
                        />
                        : '-'
                    }
                </p>

                {/* language slider */}
                <p className="developer-profile-subtitle">Language</p>
                <div className="language-section">
                    {developerDetails?.portfolio?.communication_language?.map((lang) => (
                        <div className="language-div" key={lang?.communication_language?.key}>
                            <Typography className="language-content" id="langEng-slider">{lang?.communication_language?.value}</Typography>
                            <Typography className="lang-status">{PROFICIENCY_TAG[lang?.proficiency?.id]}</Typography>
                            <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[lang?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                        </div>
                    ))}
                </div>

                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label">ID Proof</label>
                                {developerDetails?.portfolio?.document_file ?
                                    <Typography variant='p' component='p' className='idProof-name'>
                                        <span><img src={fileIcon} height="16px" alt="img" /></span>
                                        {developerDetails?.portfolio?.file_name}
                                        <a href={developerDetails?.portfolio?.document_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                        >View</a>
                                    </Typography>
                                    :
                                    ""
                                }
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ProfessionalDetailsTab;
