import React from "react";
import history from "utils/history";
import AddFreelancerProfile from "./AddFreelancerProfile";

function ManageFreeLancerProfile() {

    const path = window.location.pathname;
    
    return (
        <div>
            {(path === "/my-profile/add") ? <AddFreelancerProfile /> : history.push("/my-profile")}
        </div>
    );
}

export default ManageFreeLancerProfile;