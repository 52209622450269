import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "assets/css/developer-profile.css";
import "assets/css/client-profile.css";
import "assets/css/profile.css";
import "assets/css/projectCard.css";
import suitcaseIcon from "assets/img/icons/suitcase-dark.svg";
import paperIcon from "assets/img/icons/paper-dark.svg";
import clockIcon from "assets/img/icons/clock-dark.svg";
import graduateIcon from "assets/img/icons/graduate-cap-dark.svg";
import * as Actions from "../../../store/actions/index";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getAvailability } from "utils/Common";
import { PROFICIENCY_TAG, PROFICIENCY_DIVIDER } from "utils/constant";
import CandidateCertificationSection from "./Components/CandidateCertificationSection";
import CandidateProjectsSection from "./Components/CandidateProjectsSection";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import CandidateProfileSkeleton from "./Skeletons/CandidateProfileSkeleton";

function CandidateProfile(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const candidateId = match.params?.candidateId;
    const candidate = useSelector(state => state?.commonReducer?.candidate);
    const candidateProjects = useSelector(state => state?.commonReducer?.candidatesProjectsList);
    const candidateCertifications = useSelector(state => state?.commonReducer?.candidatesCertificationsList);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const contentLoading = useSelector(state => state?.commonReducer?.contentLoading);
    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const candidatePortfolio = candidate?.portfolio;
    const projectPageSize = 5;
    const certificationPageSize = 3;

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'dashboard', path: '/user-dashboard' },
    ];

    const getCandidate = () => {
        dispatch(Actions.getCandidateById(candidateId));
    }

    const getCandidateProjects = (page = 1, pageSize = projectPageSize) => {
        dispatch(Actions.getCandidateProjects(candidateId, page, pageSize));
    }

    const getCandidateCertifications = (page = 1, pageSize = certificationPageSize) => {
        dispatch(Actions.getCandidateCertifications(candidateId, page, pageSize));
    }

    const handlePages = (pageNumber, which) => {
        dispatch({ type: "CONTENT_LOADING", payload: true });
        switch (which) {
            case 'projects':
                setProjectCurrentPage(pageNumber);
                getCandidateProjects(pageNumber, projectPageSize);
                break;

            case 'certificates':
                getCandidateCertifications(pageNumber, certificationPageSize);
                break;
        }
    }

    useEffect(() => {
        if (candidateId) {
            getCandidate();
            getCandidateProjects();
            getCandidateCertifications(1, 'all');
        }
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    }, [])
    return (
        <>
            {pageLoading ?
                <CandidateProfileSkeleton /> :
                <>
                    <Container>
                        <Grid container className="profile-top-inner">
                            <Grid item lg={12} md={12} sm={12}>
                                <CustomBreadcrumbs current="profile" previous={breadCrumbs} />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container className="dashboard-container candidate-profile">
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <Grid className="left-profile-container">
                                    <div className="profile-details">
                                        <Typography variant="h5" className="profile-id">
                                            {candidate?.uid || "-"}
                                        </Typography>
                                        <Typography className="profile-position">
                                            {candidatePortfolio?.profile_type?.title || "-"}
                                        </Typography>
                                    </div>
                                    <div className="profile-subDetails">
                                        <img src={suitcaseIcon} alt="img" />
                                        <Typography>
                                            <span>{candidatePortfolio?.experience ? `${candidatePortfolio?.experience} Years` : "0"}</span>
                                            <br></br>
                                            Experience
                                        </Typography>
                                    </div>
                                    <div className="profile-subDetails">
                                        <img src={paperIcon} alt="img" />
                                        <Typography>
                                            <span>{candidatePortfolio?.projects_count || 0}</span>
                                            <br></br>
                                            Projects Done
                                        </Typography>
                                    </div>
                                    <div className="profile-subDetails">
                                        <img src={clockIcon} alt="img" />
                                        <Typography>
                                            <span>{getAvailability(candidatePortfolio?.availability)}</span>
                                            <br></br>
                                            Availability
                                        </Typography>
                                    </div>
                                    <div className="profile-subDetails">
                                        <img src={graduateIcon} alt="img" />
                                        <Typography>
                                            <span>{candidatePortfolio?.education_details || "-"}</span>
                                            <br></br>
                                            Education
                                        </Typography>
                                    </div>
                                    <Divider />
                                    <Grid className="lang-section candidate-lang-section">
                                        <Typography className="candidate-profile-title">Languages</Typography>
                                        <div className="language-section">
                                            {candidatePortfolio?.communication_language?.map((lang) => (
                                                <div className="language-div" key={lang?.communication_language?.key}>
                                                    <Typography className="language-content" id="langEng-slider">{lang?.communication_language?.value}</Typography>
                                                    <Typography className="lang-status">{PROFICIENCY_TAG[lang?.proficiency?.id]}</Typography>
                                                    <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[lang?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                <Grid className="right-profile-container">
                                    {candidatePortfolio?.description &&
                                        <Grid className="profile-aboutContent">
                                            <Typography className="candidate-profile-title">
                                                About
                                            </Typography>
                                            <Typography className="candidate-profile-description">
                                                <ReadMoreReadLess
                                                    charLimit={500}
                                                    readMoreText={"Read More"}
                                                    readLessText={"Read Less"}
                                                    readMoreClassName="read-more-open-39A1FF"
                                                    readLessClassName="read-more-close-39A1FF"
                                                    data={candidatePortfolio?.description || ""} /* eslint-disable-line */
                                                    textType={true}
                                                />
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid className="specialties-section">
                                        <Typography className="candidate-profile-title">
                                            Specialities
                                        </Typography>
                                        <p className="content">
                                            {candidatePortfolio?.specialties ?
                                                <ReadMoreReadLess
                                                    charLimit={200}
                                                    readMoreText={"Read More"}
                                                    readLessText={"Read Less"}
                                                    readMoreClassName="read-more-open-39A1FF"
                                                    readLessClassName="read-more-close-39A1FF"
                                                    data={candidatePortfolio?.specialties || ""}
                                                    textType={true}
                                                />
                                                : '-'}
                                        </p>
                                    </Grid>
                                    <Grid className="technologies-section">
                                        <Typography className="candidate-profile-title">
                                            Technologies
                                        </Typography>
                                        <Grid>
                                            <List className='categoryList'>
                                                {
                                                    (candidatePortfolio?.technologies && candidatePortfolio?.technologies?.length > 0)
                                                        ?
                                                        candidatePortfolio.technologies.map(technology => (
                                                            <ListItem className='categoryList-item' key={technology?.id}>
                                                                <ListItemText
                                                                    primary={technology?.title}
                                                                />
                                                            </ListItem>
                                                        ))
                                                        :
                                                        <Typography className="empty-text">-</Typography>
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Grid className="skills-section">
                                        <Typography className="candidate-profile-title">
                                            Skills
                                        </Typography>
                                        <Grid>
                                            <List className='categoryList'>
                                                {
                                                    (candidatePortfolio?.skills && candidatePortfolio?.skills?.length > 0)
                                                        ?
                                                        candidatePortfolio.skills.map(skill => (
                                                            <ListItem className='categoryList-item' key={skill?.id}>
                                                                <ListItemText
                                                                    primary={skill?.title}
                                                                />
                                                            </ListItem>
                                                        ))
                                                        :
                                                        <Typography className="empty-text">-</Typography>
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                    {(candidatePortfolio?.sub_skills && candidatePortfolio?.sub_skills?.length > 0) &&
                                        <Grid className="sub-skills-section">
                                            <Typography className="candidate-profile-title">
                                                Sub-Skills
                                            </Typography>
                                            <Grid>
                                                <List className='categoryList'>

                                                    {candidatePortfolio.sub_skills.map(subSkill => (
                                                        <ListItem className='categoryList-item' key={subSkill?.id}>
                                                            <ListItemText
                                                                primary={subSkill?.title}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    }
                                    {candidateCertifications && candidateCertifications.length > 0 &&
                                        <Grid className="certificates-section">
                                            <CandidateCertificationSection
                                                candidate={candidate}
                                                certificates={candidateCertifications}
                                                handlePages={handlePages}
                                                totalPages={Math.ceil(candidateCertifications?.count / certificationPageSize) || 0}
                                                pageSize={certificationPageSize}
                                                totalRecords={candidateCertifications?.count ?? 0}
                                            />
                                        </Grid>
                                    }
                                    {(candidateProjects?.results?.length > 0) &&
                                        <CandidateProjectsSection
                                            candidate={candidate}
                                            projects={candidateProjects?.results}
                                            handlePages={handlePages}
                                            totalPages={Math.ceil(candidateProjects?.count / projectPageSize) || 0}
                                            pageSize={projectPageSize}
                                            loading={contentLoading}
                                            currentPage={projectCurrentPage}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            }
        </>
    );
}

export default CandidateProfile;
