import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import "assets/css/manageJob.css";
import { JOBPOST_STATUS } from "utils/constant";
import { useDispatch, useSelector } from "react-redux";

function ManageJobPostSidebar(props) {
    const { getJobPost, pageSize } = props;
    const dispatch = useDispatch();
    const current = useSelector(state => state?.jobPostReducer?.currentStatus);
    const jobPostList = useSelector(state => state?.jobPostReducer?.jobPostList);
    const handleChangeStatus = (jobPostStatus) => {
        if (current != jobPostStatus?.id) {
            dispatch({ type: 'SET_CURRENT_PAGE', payload: jobPostStatus?.id });
            getJobPost(jobPostStatus?.id, 1, pageSize);
            window.scrollTo(0, 0);
        }
    }
    useEffect(() => { return () => { dispatch({ type: 'SET_CURRENT_PAGE', payload: 0 }) } }, []);

    return (
        <Grid item lg={3} md={3} sm={12} xs={12} className="job-list-col-new">
            <div className="left-job-list">
                <List>
                    {JOBPOST_STATUS?.map((jobPostStatus, index) => (
                        <ListItem onClick={() => handleChangeStatus(jobPostStatus)} key={index}
                            className={current === jobPostStatus?.id ? 'active cursor-pointer' : 'cursor-pointer'}  >
                            <div className="list-img">
                                <img src={current === jobPostStatus?.id ? jobPostStatus?.imgA : jobPostStatus?.img} />
                            </div>
                            <div className="list-content">
                                <Typography variant="h5">{jobPostStatus.title}</Typography>
                            </div>
                            {(jobPostList?.counts?.[jobPostStatus.title.toLowerCase()] > 0) &&
                                <div className="count-list">
                                    <span></span>
                                    <Typography variant="body1">{jobPostList?.counts?.[jobPostStatus.title.toLowerCase()]}</Typography>
                                </div>
                            }
                        </ListItem>
                    ))
                    }
                </List>
            </div>
        </Grid>
    )
}
export default ManageJobPostSidebar;