import React from "react";
import LinkedIn from 'assets/img/icons/about-linkedin-icon.svg';
import Slider from 'react-slick';
import Link from "@mui/material/Link";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/aboutus.css";
import Typography from '@mui/material/Typography';
import ChiefPeopleOfficer from 'assets/img/ChiefPeopleOfficer.png';
import ChiefProductOfficer from 'assets/img/ChiefProductOfficer.png';
import ProductDirector from 'assets/img/ProductDirector.png';
import ProductOwner from 'assets/img/ProductOwner.png';

const BottomSection = () => {

    const setting3 = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        swipe: true,
        // speed: 5000,
        arrows: true,
        pauseOnHover: true,
        // asNavFor: '.slider-nav',
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,

                },
            },
        ],
    };

    const slideData = [{ id: 1, img: ChiefPeopleOfficer, name: 'Chirag Akbari', designation: 'Chief People Officer', link: 'https://www.linkedin.com/in/chirag-akbari-6456a155/' },
    { id: 2, img: ProductOwner, name: 'Alpa Patel', designation: 'Product Owner', link: 'https://www.linkedin.com/in/alpa-patel-2560392a/' },
    { id: 3, img: ProductDirector, name: 'Priyank Dobariya', designation: 'Product Director', link: 'https://www.linkedin.com/in/priyank-dobariya-20a79053/' }]

    return (
        <Container>
            <Grid container>
                <Grid item lg={6} md={6}>
                    <div className="heading">
                        <Typography variant="h4">Leading Heads of SourceFirms</Typography>
                        <Typography variant="body1">SourceFirms was bootstrapped by experts who work hard to deliver the best software development outsourcing agencies and an enduring experience to YOU!!</Typography>
                    </div>
                </Grid>
            </Grid>
            <div className="about-slider-wrapper">
                <Grid container className="about-slider-align" spacing={2}>
                    <Grid item lg={7} md={6} sm={6} xs={12}>
                        <Slider  {...setting3} className="main-slider">
                            {slideData?.map((i) => (
                                <div className="source-slider" key={i.id}>
                                    <div className="slider-img">
                                        <img src={i.img} alt="" />
                                    </div>
                                    <div className="slider-text">
                                        <Typography variant="h5">{i.name}</Typography>
                                        <Typography variant="body1">{i.designation}</Typography>
                                    </div>
                                    <div className="bottom-linked-img">
                                        <Link href={i.link} target="_blank" rel='noopener noreferrer'><img src={LinkedIn} alt="" /></Link>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                    </Grid>
                    <Grid item lg={5} md={6} sm={6} xs={12}>

                        <div className="right-slider">
                            <div className="main-profile-content">
                                <div className="right-slider-img">
                                    <img src={ChiefProductOfficer} alt="" />
                                </div>
                                <div className="slider-text">
                                    <Typography variant="h5">Niraj Jagwani</Typography>
                                    <Typography variant="body1">Chief Product Officer</Typography>
                                </div>
                                <div className="bottom-linked-img">
                                    <Link href="https://www.linkedin.com/in/niraj-jagwani/" target="_blank" rel='noopener noreferrer'><img src={LinkedIn} alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default BottomSection