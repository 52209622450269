import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { USER_TYPES } from "../../../utils/constant";
import ClientProposals from "../Proposals/Client/ClientProposal";
import FreelancerProposals from "../Proposals/Freelancer/FreelancerProposal";
import AgencyProposals from "../Proposals/Agency/AgencyProposal";

const Proposals = () => {
    const user = useSelector(state => state?.authReducer?.userDetails);

    return (
        <Grid className="request-top-section">
            {user?.user_type === USER_TYPES.CLIENT && <ClientProposals />}
            {user?.user_type === USER_TYPES.FREELANCER && <FreelancerProposals />}
            {user?.user_type === USER_TYPES.AGENCY && <AgencyProposals />}
        </Grid>
    );
}
export default Proposals;
