import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/contact.css";
import Bottom from './component/Bottom.js';
import Right from './component/Right.js';
import ContactForm from './component/Form.js';
import { toast } from 'react-toastify';
import * as Actions from "../../store/actions/index";
import { useDispatch } from 'react-redux';
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { CONTACT_US_USER_TYPES } from 'utils/constant.js';
import { reset } from 'redux-form';
import NotificationDialogue from 'components/Dialog/NotificationDialogue.js';
import history from 'utils/history.js';
import { USER_TYPES } from 'utils/constant.js';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function Contact() {

    const [contactTo, setContactTo] = useState(CONTACT_US_USER_TYPES.COMPANY);
    const dispatch = useDispatch();
    const [notificationDialog, setNotificationDialog] = useState(false);
    const userDetails = useSelector((state) => state.authReducer.userDetails);

    const handleContactTo = (type) => {
        setContactTo(type);
    }
    const backToHome = () => {
        setNotificationDialog(false);
        history.push('/');
    }
    const handleNotificationDialog = (_event, reason) => {
        if (reason == 'escapeKeyDown' || reason == 'backdropClick') return false;
        setNotificationDialog(!notificationDialog);
    }

    const performSubmit = (values) => {
        try {
            const data = {
                user_type: contactTo,
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
                email: values.email.trim(),
                message: values.message.trim(),
            };

            dispatch(Actions.submitContactUs(data)).then(response => {
                if (response.status === 201) {
                    setNotificationDialog(!notificationDialog);
                    dispatch(reset('ContactUsForm'));
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    })
                }
            }).catch(() => {
                toast.error("Something went wrong in API");
            })
        } catch (error) {
            toast.error("Something went wrong...");
        }
    }

    return (
        <>
            <Helmet>
                <title>SourceFirms: Hire top-rated dedicated software development teams</title>
            </Helmet>
            <div className="wrapper">
                <div className="main-panel">
                    <AdminNavbar />
                    <div className={`dashboard-main-content ${(userDetails?.user_type === USER_TYPES.CLIENT) ? "dashboard-main-client-content" : ""}`}>
                        <section className="contact-main-section">
                            <div className='contact-inner'>
                                <div className='contact-form'>
                                    <Container>
                                        <Grid container>
                                            <Grid item xl={5} lg={5} md={5} sm={12}>
                                                <ContactForm
                                                    contactTo={contactTo}
                                                    handleContactTo={handleContactTo}
                                                    onSubmit={(values) => performSubmit(values)}
                                                />
                                            </Grid>

                                            <Grid item xl={7} lg={7} md={7} sm={12}>
                                                <Right />
                                            </Grid>

                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                            <Bottom />
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
            <NotificationDialogue
                open={notificationDialog}
                close={handleNotificationDialog}
                title="Thanks for contacting us"
                message=""
                buttonText="Go back to Home"
                buttonCallback={backToHome}
            />
        </>
    )
}

export default Contact