import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from 'utils/history';
import * as Actions from '../../../store/actions/index';
import { isLogIn } from 'utils/Auth';
import { errorToast } from 'utils/Common';


const UpdateInterview = (props) => {
    const { match } = props;
    const interviewId = match.params.interview_id;
    const uid = match.params.uid;
    const token = match.params.token;
    const dispatch = useDispatch();

    const updateAnInterview = () => {
        const data = { uid, token }
        dispatch(Actions.updateInterviewWithEmail(data, interviewId))
            .then(response => {
                if (response.status === 200) {
                    isLogIn() ? history.push('/') : history.push('/login');
                    toast.success("Interview has been updated.");
                } else {
                    isLogIn() ? history.push('/') : history.push('/login');
                    errorToast(response);
                }
            }).catch(error => {
                isLogIn() ? history.push('/') : history.push('/login');
                toast.error(error.message);
            })
    }

    useEffect(() => {
        updateAnInterview();
    }, []);

    return (
        <div className='loader-div'>
            <img src="/SF_Loader.gif" alt='img' />
        </div>
    );
}

export default UpdateInterview;