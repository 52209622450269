import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import defaultImg from "assets/img/default-avatar.png";
import { createFilterOptions } from '@mui/material/Autocomplete';
import CalenderImg from 'assets/img/interview-calender.png';
import CopyIcon from 'assets/img/icons/filecopy-icon.svg';
import checkIcon from 'assets/img/icons/manage-list-blue4.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { USER_TYPES } from 'utils/constant';
import LinkIcon from 'assets/img/modal-link-icon.png';
import { wrapperJobPostRedirection, wrapperDeveloperRedirection } from 'utils/Common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



function InterviewDetailsDialog(props) {
    const { open, close, details, opponent } = props;
    const user = useSelector(state => state.authReducer?.userDetails);
    const [copied, setCopied] = React.useState(CopyIcon);

    const handleClose = (__, reason, reschedule) => {
        if (reason === "backdropClick") return false;
        close(details, reschedule || false);
    }

    const handleCopy = (event) => {
        event.preventDefault();
        setCopied(checkIcon);
        navigator.clipboard.writeText(details?.meet_link);
        setTimeout(() => {
            setCopied(CopyIcon);
        }, 1000);
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className='approval_modal offer-accept-model'
        >
            <BootstrapDialogTitle id="customized-dialog-title" className='model-top-heading' onClose={handleClose}>
                <Typography variant='h3'>Schedule Interview</Typography>
            </BootstrapDialogTitle>
            <DialogContent className='interview-text'>
                <div className='offer-content open-job_post'>
                    <div className='inner-content'>
                        <div className='offer-profile-img'>
                            <img src={opponent?.profile_image || defaultImg} alt='' />
                        </div>
                        <div className='offer-profile-text'>
                            <Typography variant='h5'>{opponent?.company_name}</Typography>
                            <Typography variant='body1'>{opponent?.city?.name}, {opponent?.city?.country_name}</Typography>
                        </div>
                    </div>

                </div>
                <div className='job-post-id'>
                    <Typography variant='body1'>
                        Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(user?.user_type, details?.job_post?.id, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.job_post?.uid}</Link></span>
                    </Typography>
                    <Typography variant='body1'>
                        Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(user?.user_type, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.candidate?.uid}</Link></span>
                    </Typography>
                </div>

                <div className='interview-data-text'>
                    <div className='interview-heading'>
                        <Typography variant='h4'><img src={CalenderImg} alt="" />{moment(details?.date + ' ' + details?.time).local().format('DD/MM/YYYY, hh:mm A')}</Typography>
                        <Typography variant='body1'>{details?.description}</Typography>
                    </div>
                    <div className='interview-body-box'>
                        <Button className='link-btn'><img src={LinkIcon} alt="LinkIcon" /></Button>
                        <Link href={details?.meet_link} target="_blank">{details?.meet_link}
                            <img src={copied} alt="" onClick={(e) => { handleCopy(e) }} className="copyIcon cursor-pointer" />
                        </Link>
                    </div>
                </div>
            </DialogContent>

            <DialogActions className='bottom last-content-btn interview-last-content'>
                <Link href={void (0)} underline="none" autoFocus onClick={handleClose} className='cancelBtn'>
                    Cancel
                </Link>
                {user.user_type === USER_TYPES.CLIENT &&
                    <Button onClick={(event, reason) => handleClose(event, reason, true)} variant="contained" className='modalBtn primaryButton'>
                        Reschedule
                    </Button>
                }
            </DialogActions>
        </BootstrapDialog>
    );
}

export default InterviewDetailsDialog;
