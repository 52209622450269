import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import basicInfoIcon from "assets/img/icons/basic_info-icon.svg";
import avaibilityIcon from "assets/img/icons/avaibility-icon.svg";
import expertiseIcon from "assets/img/icons/expertise-icon.svg";
import experienceIcon from "assets/img/icons/experience-icon.svg";
import budgetIcon from "assets/img/icons/budget-icon.svg";
import { styled } from "@mui/material/styles";

const SidebarStyle = styled("div")(({ ownerState }) => ({
    backgroundColor: "#ffffff26",
    zIndex: 1,
    color: "#fff",
    width: 44,
    height: 44,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: '6px solid #476072',
    marginLeft: '3px',
    ...(ownerState.active && {
        background: "#548CA8",
        border: '6px solid #566d7d',
        marginLeft: '0',
    }),
    ...(ownerState.completed && {
        background: "#548CA8",
        
    }),
}));

function Sidebar(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <img src={basicInfoIcon} alt="img" />,
        2: <img src={avaibilityIcon} alt="img" />,
        3: <img src={expertiseIcon} alt="img" />,
        4: <img src={experienceIcon} alt="img" />,
        5: <img src={budgetIcon} alt="img" />,
    };

    return (
        <SidebarStyle
            ownerState={{ completed, active }}
            className={className}>
            {icons[String(props.icon)]}
        </SidebarStyle>
    );
}
Sidebar.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

function JobpostSidebar(props) {

    const { activeStep, steps } = props;

    return (
        <Grid className="createJob-stepper">
            <Grid className="title">
                <Typography variant="h5">Create Job Post</Typography>
                <Typography className="step-count">
                    Step {activeStep + 1}<span>/5</span>
                </Typography>
            </Grid>
            <Divider className="createJob-divider" />

            <Stepper id="jobpost-stepper" activeStep={activeStep} orientation="vertical" data-testid="stepper">
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel className="stepperLabel" data-testid="stepper-label" StepIconComponent={Sidebar}>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography className="stepDescription">
                                {step.description}
                            </Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    )
}

export default JobpostSidebar;
