import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';

const CertificateTabSkeleton = () => {
    return (
        <>
            {/* <Typography className="edit-icon editBtn"> */}
            <Skeleton className="edit-icon editBtn" width={40} height={40} />
            {/* </Typography> */}
            <Typography className="count-profileTitle" component="p" align="left">
                <Skeleton width={150} height={40} />
            </Typography>
            <Table className='project_detail-table certificate_table'>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ top: 50 }}
                            className='tableCellHead singleTableCell'
                            colSpan={4}
                        >
                            <Skeleton width="100%" height={25} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell className='tableDataCell'>
                            <Skeleton width={50} height={70} />
                        </TableCell>
                        <TableCell className='tableDataCell'>
                            <Skeleton width={200} height={40} />
                        </TableCell>
                        <TableCell className='tableDataCell'>
                            <Skeleton width={250} height={40} />
                        </TableCell>
                        <TableCell className='tableDataCell'>
                            <Skeleton width={30} height={30} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default CertificateTabSkeleton;