import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import searchIcon from "assets/img/icons/search.svg";
import "assets/css/chat.css";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions/index";
import SearchSkeleton from "./skeletons/SearchSkeleton";

const Search = styled("div")(() => ({
    position: "relative",
    borderRadius: "8px",
    backgroundColor: alpha("#6a8caa", 0.08),
    "&:hover": {
        backgroundColor: alpha("#FFF", 0.25),
    },
    marginLeft: 0,
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: "32px",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

function SearchBar(props) {
    const { search } = props;
    const dispatch = useDispatch();
    const isInitial = useSelector(state => state?.chatReducer?.initial);
    const [time, setTime] = useState("");

    const handleSearch = (value) => {
        clearTimeout(time);
        setTime(setTimeout(async () => {
            await dispatch(Actions.getContactList(value));
            if (value) {
                dispatch({ type: 'SEARCH', payload: value });
            } else {
                dispatch({ type: 'SEARCH', payload: null });
            }
        }, 1000));
    }
    return (
        <>
            {isInitial ?
                <SearchSkeleton />
                :
                <Search className="searchBar">
                    <SearchIconWrapper>
                        <img src={searchIcon} alt="img" />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => handleSearch(e.target.value)}
                        defaultValue={search}
                    />
                </Search>
            }
        </>
    );
}
export default SearchBar;