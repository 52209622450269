import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions/index'
import AgencyDetailView from './component/AgencyDetailView';
import { toast } from "react-toastify";
import history from 'utils/history';
import { checkForImageValidations } from 'utils/Common';
import ImageCropDialog from 'components/Dialog/ImageCropDialog';
import AgencyProfileSkeleton from './Skeletons/AgencyProfileSkeleton';


function AgencyProfile() {

    const dispatch = useDispatch();
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });
    const pageLoading = useSelector(state => state.commonReducer?.pageLoading);
    const [cropProcessing, setCropProcessing] = useState(false);

    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file });
    }

    const getUserProfile = () => {
        dispatch(Actions.getUserProfile()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_PROFILE', payload: response.data });
                if (response.data.is_new_registered && (!response.data.is_profile_in_review)) {
                    history.push("/my-profile/add");
                }
            } else {
                dispatch({ type: 'GET_USER_PROFILE', payload: null });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_PROFILE', payload: null });
        })
    }

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(res => {
            if (res.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: res.data });
                dispatch({ type: "PAGE_LOADING", payload: false });
            }
            else {
                dispatch({ type: 'GET_USER_DETAILS', payload: {} });
                dispatch({ type: "PAGE_LOADING", payload: false });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_DETAILS', payload: {} });
            dispatch({ type: "PAGE_LOADING", payload: false });
        })
    }
    const uploadProfileImage = async (event) => {
        try {
            if (event.target.files.length > 0) {
                const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message);
                    event.target.value = null;
                } else {
                    handleImgCropOpen(event.target.files[0]);
                    event.target.value = null;
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
            event.target.value = null;
        }
    }

    const uploadCroppedImage = (file) => {
        setCropProcessing(true);
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(Actions.uploadProfileImage(formData)).then(response => {
            if (response.status == 201) {
                getUserProfile();
                getUserDetails();
                handleImgCropOpen();
                setCropProcessing(false);
            }
        }).catch(() => {
            toast.error('Something went wrong');
        });
    }

    useEffect(() => {
        getUserProfile()
        getUserDetails()
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    }, []);

    return (
        <>
            {pageLoading ?
                <AgencyProfileSkeleton /> :
                <>
                    <AgencyDetailView uploadProfileImage={uploadProfileImage} />
                    <ImageCropDialog
                        open={cropHandlers.openDialog}
                        close={handleImgCropOpen}
                        file={cropHandlers.file}
                        uploadCroppedImage={uploadCroppedImage}
                        processing={cropProcessing} />
                </>
            }
        </>
    )
}
export default AgencyProfile;