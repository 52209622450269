import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import 'assets/css/jobpostdetail.css';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../store/actions/index";
import { toast } from 'react-toastify';
import { findSalaryType, getAvailability, dispatchPageLoading, errorToast } from 'utils/Common';
import ReadMoreLessWithSC from 'components/ReadMoreReadLess/ReadMoreLessWithSC';
import { SpliceList } from 'components/SpliceList/SpliceList';
import moment from 'moment';
import { USER_TYPES, JOBPOST_CANDIDATE_STATUS } from 'utils/constant';
import RequestDetailButtons from './RequestDetailButtons';
import { useHistory } from 'react-router';
import RequestDetailsSkeleton from './Skeletons/RequestDetailsSkeleton';
import ConfirmationDialogue from 'components/Dialog/ConfirmationDialogue';

const RequestDetail = (props) => {
    const { match } = props;
    const developerId = match.params?.requestedDeveloperId || null;
    const proposalId = match.params?.requestDetailId || null;
    const dispatch = useDispatch();
    const jobPost = useSelector(state => state?.jobPostReducer?.jobPostDetail);
    const proposalData = useSelector(state => state?.jobPostReducer?.proposalData);
    const candidate = useSelector(state => state?.jobPostReducer?.candidateProfile);
    const user = useSelector(state => state?.authReducer?.userDetails);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const history = useHistory();
    let userId = candidate?.uid.toUpperCase();
    const [actionDialogue, setActionDialogue] = useState({ open: false, title: 'Are you sure ?', message: "" })
    const [actionData, setActionData] = useState({ status: null, responseMessage: '' });
    const [processing, setProcessing] = useState(false);
    const [dialogeLoader, setDialogeLoader] = useState(false);

    const breadCrumbs = () => {
        if (user?.user_type === USER_TYPES.FREELANCER) {
            return [
                { name: 'Home', path: '/home' },
                { name: 'My-Requests', path: '/my-requests' },
            ];
        } else if (user?.user_type === USER_TYPES.AGENCY) {
            return [
                { name: 'Home', path: '/home' },
                { name: 'Requests', path: '/requests' },
                { name: userId, path: `/requests/${developerId}` },
            ];
        }
        return null;
    };

    const openActionDialogue = (message = "", status = null) => {
        setActionData({ status });
        const dialogueData = { ...actionDialogue, open: !actionDialogue.open, message };
        setActionDialogue(dialogueData);
    }
    const closeActionDialogue = () => {
        setActionDialogue({ ...actionDialogue, open: false });
    }

    const getProposal = () => {
        dispatch(Actions.getJobPostProposalById(proposalId)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "CANDIDATE_PROFILE", payload: response?.data?.candidate });
                dispatch({ type: "JOBPOST_DETAIL", payload: response?.data?.job_post });
                dispatch({ type: "PROPOSAL_DATA", payload: response?.data });
                dispatchPageLoading(dispatch);
            } else {
                dispatch({ type: "CANDIDATE_PROFILE", payload: null });
                dispatch({ type: "JOBPOST_DETAIL", payload: null });
                dispatch({ type: "PROPOSAL_DATA", payload: null });
                errorToast(response);
                history.goBack();
                dispatchPageLoading(dispatch);
            }
            processing && setProcessing(false);
            dialogeLoader && setDialogeLoader(false);
        }).catch(() => {
            dispatch({ type: "CANDIDATE_PROFILE", payload: null });
            dispatch({ type: "JOBPOST_DETAIL", payload: null });
            dispatch({ type: "PROPOSAL_DATA", payload: null });
            toast.error("Something went wrong");
            history.goBack();
            dispatchPageLoading(dispatch);
            processing && setProcessing(false);
            dialogeLoader && setDialogeLoader(false);
        })
    }
    const performAction = (status, value) => {
        if (value === 'reject') {
            setDialogeLoader(true);
        } else {
            setProcessing(true);
        }
        const data = { status }
        let messageStatus = (status == JOBPOST_CANDIDATE_STATUS.REQUEST_REJECTED ? 'rejected' : 'accepted')
        dispatch(Actions.jobpostCandidateActions(data, proposalId)).then(response => {
            if (response.status === 200) {
                toast.success(`Request ${messageStatus} successfully`);
                getProposal();
                history.goBack();
            } else {
                setDialogeLoader(false);
                setProcessing(false);
                errorToast(response);
            }
        });
    }

    useEffect(() => {
        getProposal();
    }, [])

    return (
        <>
            {pageLoading
                ?
                <RequestDetailsSkeleton />
                :
                <Grid className="top-job-post request-detail">
                    <Container>
                        <Grid container className='job-post-inner'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <CustomBreadcrumbs current="Request-Details" previous={breadCrumbs()} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <p className='clientId'>Developer ID : <span>{candidate?.uid}</span></p>
                            </Grid>
                        </Grid>
                        <Grid >
                            <div className="job-list-content">
                                <div className="job-list-card">
                                    <div className="job-card-header">
                                        <div className="heading-text">
                                            <Typography variant="h5">{jobPost?.title}</Typography>
                                            <Typography variant="body1">Job post ID : {jobPost?.uid || 'N/A'}</Typography>
                                        </div>
                                        <div className="amount-year">
                                            <Typography variant="h2">{`$${jobPost?.budget_from || 0}-${jobPost?.budget_to || 0}`}<span>/{findSalaryType(jobPost?.salary_type)}</span></Typography>
                                        </div>
                                    </div>
                                    <div className="job-card-body">
                                        <div className="position-content">
                                            <List>
                                                <ListItem>
                                                    <Typography variant="h3">{jobPost?.experience?.title || 'N/A'}</Typography>
                                                    <span>Experience</span>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="h3">{getAvailability(jobPost?.availability)}</Typography>
                                                    <span>Availability</span>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="h3">{jobPost?.positions}</Typography>
                                                    <span>Position</span>
                                                </ListItem>
                                            </List>
                                            <div className='reject-btn'>
                                                <RequestDetailButtons
                                                    status={proposalData?.status}
                                                    callback={performAction}
                                                    dialogCallback={openActionDialogue}
                                                    processing={processing} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid container>
                            <Grid item lg={12} sm={12}>
                                <div className='job-post-description'>
                                    <Typography variant="h4">Job Description</Typography>
                                    <Typography variant="body1">
                                        <ReadMoreLessWithSC
                                            className="Test"
                                            charLimit={500}
                                            readMoreText={"Read More"}
                                            readLessText={"Read Less"}
                                            readMoreClassName="read-more-open-39A1FF"
                                            readLessClassName="read-more-close-39A1FF"
                                            children={jobPost?.description || ""} /* eslint-disable-line */
                                            textType={true}
                                        />
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className='job-technology' spacing={3}>
                            <Grid item lg={6} md={6} sm={12}>
                                <div className='job-post-skill'>
                                    <Typography variant="h4">Technologies</Typography>
                                    <Grid>
                                        <List className="categoryList">
                                            <SpliceList data={jobPost?.technologies} mainClass="subcategoryList-item" subClass="more-subcategory" limit={4} />
                                        </List>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <div className='job-post-skill'>
                                    <Typography variant="h4">Skills</Typography>
                                    <Grid>
                                        <List className="categoryList">
                                            <SpliceList data={jobPost?.skills} mainClass="subcategoryList-item" subClass="more-subcategory" limit={4} />
                                        </List>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className='location-content'>
                            <div className='location-text'>
                                <Typography variant='h5'>{proposalData?.client_city + ', ' + proposalData?.client_country}</Typography>
                            </div>
                            <div className='location-time'>
                                <Typography variant='h5'>Requested Date: {moment(new Date(proposalData?.created)).format("DD/MM/YYYY")}</Typography>
                            </div>
                        </Grid>
                    </Container>
                    <ConfirmationDialogue
                        open={actionDialogue.open}
                        close={closeActionDialogue}
                        title={actionDialogue.title}
                        message={actionDialogue.message}
                        buttonText="Yes"
                        processing={dialogeLoader}
                        buttonCallback={() => { performAction(actionData.status, 'reject'); }}
                    />
                </Grid>
            }
        </>
    );
}
export default RequestDetail;
