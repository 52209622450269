import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import "assets/css/profile.css";
import * as Actions from "../../../store/actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import ProjectField from "components/FieldArrayComponent/ProjectFields";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import "assets/css/add-developer.css";
import SkipDialog from "components/Dialog/SkipDialog";

function AddProjectForm(props) {

    const { handleSubmit, change, formValue, invalid, uploadImage, removeImage, filesData, handleSkip, handleRemove, imageLoaders, isUploading, submiting } = props;
    const dispatch = useDispatch();
    const technologyList = useSelector((state) => state.commonReducer?.technologies);
    const skills = useSelector((state) => state.commonReducer?.skillsWithoutDepth);
    const [openSkipDialog, setOpenSkipDialog] = useState(false);

    const handleSkipDialog = () => {
        setOpenSkipDialog(!openSkipDialog);
    }

    useEffect(() => {
        dispatch(Actions.getTechnologies(""));
        dispatch(Actions.getSkillsWithoutDepth());
    }, []);

    return (
        <>
            <h5>Projects</h5>
            <form onSubmit={handleSubmit} noValidate>
                <FieldArray name="projects"
                    component={ProjectField}
                    change={change}
                    technologies={technologyList}
                    skills={skills}
                    uploadImage={uploadImage}
                    allImages={filesData}
                    handleRemove={handleRemove}
                    removeImage={removeImage}
                    imageLoaders={imageLoaders}
                    isUploading={isUploading}
                />
                <Grid className="action-div">
                    <Button
                        type="button"
                        variant="contained"
                        className="primaryButton skipBtn"
                        onClick={handleSkipDialog}
                        disabled={submiting}>
                        Skip
                    </Button>
                    <Button type="submit" variant="contained"
                        className="primaryButton nextBtn"
                        disabled={invalid || submiting || !formValue?.projects?.length}>
                        Next
                    </Button>
                </Grid>
            </form>
            <SkipDialog
                open={openSkipDialog}
                close={handleSkipDialog}
                message="Are you sure ?"
                description="If you don't have the projects then you can skip this, You can add projects later from the profile"
                confirm={() => { handleSkipDialog(); handleSkip() }}
                buttonText="Yes, Skip"
            />
        </>
    );
}
const ReduxWorkExperienceFormMapped = reduxForm({
    form: "ProjectFormForm",
    enableReinitialize: true,
})(AddProjectForm);
const mapStateToProps = (state) => {
    return {
        formValue: getFormValues("ProjectFormForm")(state)
    };
};
export default connect(mapStateToProps)(ReduxWorkExperienceFormMapped);