import React from 'react';
import Button from '@mui/material/Button';
import { JOBPOST_CANDIDATE_STATUS } from 'utils/constant';
import chatIcon from 'assets/img/icons/chat-icon.svg';
import closeIcon from 'assets/img/icons/close-icon-red-bigger.svg';
import { useHistory } from 'react-router';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const RequestDetailButtons = (props) => {
    const { status, callback, dialogCallback, chatRoom, processing } = props;
    const history = useHistory();
    return (<>
        {(status === JOBPOST_CANDIDATE_STATUS.REQUESTED) &&
            <>
                <Button
                    className='btn-prymary btn'
                    variant="contained"
                    color="primary"
                    onClick={() => dialogCallback("you want to reject", JOBPOST_CANDIDATE_STATUS.REQUEST_REJECTED)}
                    disabled={processing}
                >
                    Reject
                </Button>

                <LoadingButton
                    className={`btn-secondary btn ${processing && 'loading'}`}
                    loading={processing}
                    variant="contained"
                    color="secondary"
                    disabled={processing}
                    loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                    onClick={() => callback(JOBPOST_CANDIDATE_STATUS.REQUEST_ACCEPTED)}
                >
                    Accept
                </LoadingButton>
            </>
        }
        {(status === JOBPOST_CANDIDATE_STATUS.REQUEST_ACCEPTED) &&
            <>
                <Button
                    className='btn-prymary btn'
                    variant="contained"
                    color="primary"
                    onClick={() => dialogCallback("you want to reject", JOBPOST_CANDIDATE_STATUS.OFFER_REJECTED)}
                    startIcon={<img src={closeIcon} alt="img" />}
                    disabled={processing}
                >
                    Reject
                </Button>
                <Button
                    className='btn-secondary btn'
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push('/chat', { agency: { room: chatRoom } })}
                    startIcon={<img src={chatIcon} alt="img" />}
                    disabled={processing}
                >
                    Chat
                </Button>
            </>
        }
    </>
    );
}

export default RequestDetailButtons;