import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Field, reduxForm } from "redux-form";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import { required, charLimitWithTrim, requiredWithRegex } from "utils/Validation";
import CustomInput from "components/CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";


function BasicInfo(props) {

    const { handleSubmit, valid, initialValues } = props;
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);
    const dispatch = useDispatch();

    const handleSaveDraft = () => {
        if (valid) {
            dispatch({ type: "SAVE_DRAFT_CLICK", payload: true });
            setTimeout(() => {
                handleSubmit();
            }, 1000);
        }
    }

    return (
        <form onSubmit={handleSubmit} noValidate data-testid="basic-info-form" id='basic-info-form'>
            <Grid className="createJob-wrapper-inner">
                <h3>Basic info</h3>
                <Grid container className="formGrid">
                    <Field
                        name="title"
                        component={CustomInput}
                        id="title"
                        labelText="Job Title*"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        validate={[required]}
                        inputProps={{
                            placeholder: "Enter job title",
                            type: "text",
                        }}
                        dataTestId="bf-title"
                        normalize={charLimitWithTrim(100)}

                    />
                    <Field
                        name="description"
                        id="description"
                        className="multiSelect-Textfield"
                        labelText="Job Description*"
                        component={CustomTextArea}
                        rows={8}
                        validate={[requiredWithRegex]}
                        formControlProps={{
                            fullWidth: true
                        }}
                        placeholder="Enter description"
                        dataTestId="bf-description"
                        normalize={charLimitWithTrim(1000)}
                    />
                </Grid>
            </Grid>
            <div className="bottom-buttons">
                {(!initialValues?.id || (initialValues?.id && initialValues?.step)) &&

                    <LoadingButton
                        className={`saveDraftBtn cursor-pointer ${saveAsDraft && 'loading'}`}
                        loading={saveAsDraft} variant="string"
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        disabled={!valid || saveAsDraft}
                        onClick={handleSaveDraft}
                    >
                        Save as Draft
                    </LoadingButton>
                }
                <Button variant="contained" id="bs-next-btn" type="submit" className="primaryButton nextBtn" data-testid="basic-next" disabled={saveAsDraft}>
                    Next
                </Button>
            </div>
        </form>
    );
}
const ReduxBasicInfoForm = reduxForm({
    form: "BasicInfoForm",
    enableReinitialize: true,
})(BasicInfo);

export default ReduxBasicInfoForm;
