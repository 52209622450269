import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Skeleton from '@mui/material/Skeleton';

const CandidateProjectSkeleton = () => {
    return (
        <Grid container className="projectsList candidateprofile-projects" >
            <Grid item className="projectListItem" >
                <Card className="project-card">
                    <div className="project-card-inner">
                        <CardContent>
                            <Grid className="project-top">
                                <Skeleton className='icon-container bg-dark' variant='circular' width={48} height={48} />

                                <div className="project-title">
                                    <p className="name">
                                        <Skeleton transform-scale-1 width={100} height={45} />
                                    </p>
                                </div>
                                <Grid className="duration-details">
                                    <Typography>
                                        <Skeleton transform-scale-1 width={267} height={30} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className="about_project">
                                <Typography>
                                    <Skeleton className='transform-scale-1' width='100%' height={30} />
                                </Typography>
                            </Grid>
                            <Grid className='skillset'>
                                <List className='skillList'>
                                    <Skeleton className='skillList-item bg-dark' width={100} height={45} />
                                    <Skeleton className='skillList-item bg-dark' width={100} height={45} />
                                    <Skeleton className='skillList-item bg-dark' width={100} height={45} />
                                </List>
                            </Grid>
                        </CardContent>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CandidateProjectSkeleton;