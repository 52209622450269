import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import "assets/css/chat.css";
import defaultImg from "assets/img/default-avatar.png";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../store/actions/index";
import moment from 'moment';
import SidebarSkeleton from './skeletons/SidebarSkeleton';
import { readMessage } from 'services/WebSocketService';

function ChatSideBar() {
    const dispatch = useDispatch();
    const contactList = useSelector(state => state.chatReducer?.contactList);
    const currentUser = useSelector(state => state?.chatReducer?.currentUser);
    const isInitial = useSelector(state => state?.chatReducer?.initial);
    const userDetails = useSelector(state => state.authReducer?.userDetails);
    const connection = useSelector(state => state.socketReducer?.connection);
    const isMobileView = useSelector(state => state.chatReducer?.isMobile);

    const handleReadMessage = (contact) => {
        if (contact?.unread_count > 0) {
            let read = readMessage(connection, userDetails?.id, contact?.room);
            if (read) {
                dispatch(Actions.getContactList(''));
            }
        }
    }
    const getChatData = (contact) => {
        dispatch({ type: 'CONTENT_LOADING', payload: true });
        dispatch({ type: 'CHAT_SCROLL_POSITION', payload: null });
        dispatch({ type: 'PAGE_DATA', payload: { page: 1, pageSize: 30 } });
        dispatch({ type: 'IS_MOBILE_VIEW', payload: { ...isMobileView, leftBar: "none", rightBar: "block" } });
        dispatch(Actions.getCurrentChatHistory(contact, 1, 30));
        handleReadMessage(contact);
        // let temp_list = [...contactList];
        // temp_list[index].unread_count = 0;
        // dispatch({ type: 'GET_CONTACT_LIST', payload: temp_list });
        // dispatch(Actions.readallMessgae(contact?.room));

    }

    useEffect(() => {
        if (contactList?.length > 0 && currentUser) {
            const selectedUser = contactList.find(contact => contact.room === currentUser.room);
            dispatch({ type: 'GET_CURRENT_USER', payload: selectedUser });
        }
    }, [contactList])
    return (
        <Grid className='chat-list'>
            {isInitial ?
                <SidebarSkeleton />
                :
                <List>
                    {contactList && contactList.length > 0 ?
                        contactList.map((contact, index) => (
                            <ListItem key={index} onClick={() => getChatData(contact, index)} className={(contact?.room === currentUser?.room && !isMobileView.active) ? "active cursor-pointer" : "cursor-pointer"}>
                                <ListItemAvatar className='chat-profile'>
                                    <Avatar className='profile-img-container'>
                                        <img src={contact?.opponent?.profile_image || defaultImg}
                                            onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} alt="profile-img" />
                                    </Avatar>
                                    {contact?.opponent?.is_online &&
                                        <span className='statusDot'></span>
                                    }
                                </ListItemAvatar>
                                <ListItemText>
                                    <div className='primary-section'>
                                        <Typography className='chat-id'>
                                            {contact?.opponent?.company_name}
                                        </Typography>
                                        {contact?.last_message?.created &&
                                            <div className='time'>
                                                <span>
                                                    {moment(contact?.last_message?.created).local().format('LT')}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    <div className='secondary-section'>
                                        <Typography className='chat-msg'>
                                            {contact?.last_message?.message}
                                        </Typography>
                                        {contact?.unread_count > 0 &&
                                            <div className='msg-count'>
                                                <span>
                                                    {contact?.unread_count}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </ListItemText>
                            </ListItem>
                        ))
                        :
                        <Typography className='chat_no_contact'>No contact found</Typography>
                    }
                </List>
            }
        </Grid>
    )
}

export default ChatSideBar