import React, { Fragment, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/faq.css";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CustomBreadcrumbs from 'components/CustomBreadcrumbs/CustomBreadcrumbs';
import FaqLEftImg from 'assets/img/faq-left-img.png'
import Footer from 'components/Footer/Footer';
import AdminNavbar from "components/Navbars/AdminNavbar";
import AccordionComponent from './component/AccordionComponent';
import { USER_TYPES } from 'utils/constant';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function Faq() {

    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const breadCrumbs = [{ name: 'home', path: '/home' }];
    const [expanded, setExpanded] = useState(false);

    const faqList = [
        {
            header: "What is SourceFirms?",
            body: "SourceFirms is the world’s first resource augmentation marketplace that acts as a bridge between employers and talents. Talents are able to apply for the jobs they like. On the other hand, employers can find numerous potential agile software development teams who can complete projects at a comprehensive price."
        },
        {
            header: "How Does SourceFirms Differ From Other Platforms?",
            body: "At SourceFirms, we connect employers and remote software development resources from all around the world. Unlike other platforms, we test every account registered with us and make sure we don’t have any spammers on our platform. Also, we offer a secured and safe workroom for agile software development teams & employers.",
        },
        {
            header: "What are all special benefits offered by SourceFirms while joining?",
            body: "Many online marketplaces face numerous challenges in producing reliable freelance workers for employers and vice versa. However, at SourceFirms we follow strong protocols and screening tests to maintain the reliability of our platform users. Also, we offer a dedicated workroom for all users where they can manage their work.",
        },
        {
            header: "How many resources can I expect on the SourceFirms platform?",
            body: "At the SourceFirms platform, we can assure you will find remote software development resources for multiple tech stacks from multiple top-rated software development agencies on this single platform. But getting work or getting hired by employers is on your caliber and it should match what employers are looking for. However, our expert will assist you in all forms to get the right resources or the ideal agile software development teams.",
        },
        {
            header: "Who owns the legal rights for the work done on the SourceFirms platform?",
            body: "Once the work is accepted and paid by the respective employer, the legal rights for the work done by the Agile software development teams go to the employer. Also, SourceFirms doesn't own any legal rights to the software outsourcing agencies' work. To know more about the work legality, please get in touch with us.",
        },
        {
            header: "What happens if SourceFirms is down during the final conversation of my project?",
            body: "SourceFirms platform is empowered with a strong technology stack; we go to every possible length to maintain our site reliability. Also, we keep the site’s availability and reliability as our top priority. However, if any unexpected thing happens during an extreme situation SourceFirms will try hard to get the site live as soon as possible.",
        },
        {
            header: "Is there any legal agreement needed for working on the SourceFirms platform?",
            body: "Yes! SourceFirms enters into a talent agreement with all the software development agencies & clients registered on its platform.",
        },
        {
            header: "How much do resources get paid?",
            body: "Payment terms will be mentioned in the employer’s job post and the same will be paid upon task completion.",
        },
        {
            header: "Whether there will be any cut-off of resource’s earnings by SourceFirms as commission?",
            body: "No! As of now, we don’t charge anything from software outsourcing agencies. Even registration is completely FREE! However, SourceFirms will only charge for job posting to the clients.",
        },
        {
            header: "What happens if my project gets rejected?",
            body: "You need to deliver quality workmanship to maintain a good reputation on the SourceFirms platform. Project/task rejection is up to the employer’s decision; resources must meet the requirements of the project. However, if the project gets rejected, the client may ask you for re-work or cancel the project; if it gets canceled you own the legal right for the same.",
        },
        {
            header: "What shall I suppose to do if the client does not pay after receiving my work?",
            body: "SourceFirms platform goes to every possible length to maintain legacy and reliability on having authenticated users. So, you don’t have to worry about the payment.",
        }
    ]

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Helmet>
                <title>SourceFirms: Agile software development teams are your finger tips</title>
            </Helmet>
            <div>
                <div className={`dashboard-main-content ${(userDetails?.user_type === USER_TYPES.CLIENT) ? "dashboard-main-client-content" : ""}`}>
                    <div className="main-panel">
                        <AdminNavbar />
                        <div className='faq-main-section'>
                            <section className="faq-banner">
                                <div className='faq-banner-inner custom-bread-crumb'>
                                    <Container>
                                        <Grid container>
                                            <Grid item lg={6}>
                                                <Stack spacing={2} className='breadcrumb-div'>
                                                    <CustomBreadcrumbs current="faq" previous={breadCrumbs} />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid className='banner-header'>
                                            <Typography variant='h1'>
                                                Frequently Asked Questions
                                                <span>Learn how SourceFirms helps you with the answers to some of the most frequently asked questions for hiring agile software development teams.</span>
                                            </Typography>
                                        </Grid>
                                    </Container>
                                </div>
                            </section>

                        </div>
                        <div className="faq-question">
                            <section className="faq-section">
                                <div className="faq-inner">
                                    <Container>
                                        <Grid container spacing={10}>
                                            <Grid item lg={4} md={3}>
                                                <div className="faq-left-img">
                                                    <img src={FaqLEftImg} alt="" />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={9}>
                                                <div className="faq-accordian">
                                                    {faqList.length > 0 &&
                                                        faqList.map((faq, index) =>
                                                            <Fragment key={index.toString()}>
                                                                <AccordionComponent
                                                                    expanded={expanded}
                                                                    handleChange={handleChange}
                                                                    header={faq.header}
                                                                    body={faq.body}
                                                                />
                                                            </Fragment>
                                                        )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </section>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}


export default Faq