import React from 'react';
import { Alert, AlertTitle, Typography } from "@mui/material";
import fileBlockIcon from 'assets/img/icons/file-block.svg';
import moment from 'moment';

const FileBlockIcon = () => {
    return (
        <img src={fileBlockIcon} width={24} height={24} />
    )
}

export default function JobpostWrapper(props) {
    const { details, chat } = props;

    return (
        <>
            {details?.status === 5 &&
                <Alert className='jobpost-alert' variant="outlined" severity="warning" icon={false}>
                    <AlertTitle className='jobpost-alert-title'>
                        <FileBlockIcon />
                        <p> {details?.uid}
                            <span>Job Post Closed</span> </p>
                    </AlertTitle>
                    <Typography className='jobpost-alert-time'>{moment(chat?.created).local().format('LT')}</Typography>
                </Alert>
            }
        </>
    );
}