import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Field, getFormValues, reduxForm } from 'redux-form';
import { required, checkUrl } from 'utils/Validation';
import SingleAutocomplete from 'components/CustomeAutoComplete/SingleAutocomplete';
import DatePickerField from 'components/CustomDateRangePicker/DatePickerField';
import TimePickerField from 'components/CustomTimePicker/TimePickerField';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import CustomInput from 'components/CustomInput/CustomInput';
import { connect, useDispatch } from 'react-redux';
import { interviewMaxDate, handleMinTime } from 'utils/Common';
import defaultImg from "assets/img/default-avatar.png";
import validate from './InterviewValidate';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { USER_TYPES } from 'utils/constant';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function InterviewScheduleDialog(props) {
    const { open, close, data, jobposts, candidates, jobPostChange, handleSubmit, valid, reset, change, formStates, submitting, jobpostLoading, candidateLoading } = props;
    const dispatch = useDispatch();

    const handleClose = (__, reason) => {
        if (reason === "backdropClick") return false;
        setTimeout(() => {
            dispatch(reset('InterviewScheduleForm'));
            dispatch({ type: "PROCESSED_CANDIDATES_LIST", payload: [] });
        }, 500);
        close();
    }


    return (
        <BootstrapDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            className='approval_modal offer-accept-model'
        >
            <BootstrapDialogTitle id="customized-dialog-title" className='model-top-heading' style={{ pointerEvents: submitting ? "none" : "inherit" }} onClose={handleClose}>
                <Typography variant='h3'>Schedule Interview</Typography>
            </BootstrapDialogTitle>
            <form onSubmit={handleSubmit} data-testid="interview-schedule-form" id='interview-schedule-form'>
                <DialogContent className='interview-text'>
                    <div className='offer-content'>
                        <div className='inner-content'>
                            <div className='offer-profile-img'>
                                <img src={data?.profile_image || defaultImg} alt='' />
                            </div>
                            <div className='offer-profile-text'>
                                <Typography variant='h5'>{data?.company_name}</Typography>
                                <Typography variant='body1'>{data?.city?.name}, {data?.city?.country_name}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className='center-content'>
                        <div className='interview-data'>
                            <div className='select-option-data'>
                                <Field
                                    name="job_post"
                                    component={SingleAutocomplete}
                                    id="job_post"
                                    labelText="Job post*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    options={jobposts || []}
                                    displayKey="title"
                                    validate={[required]}
                                    onChange={value => { change('candidate', ''); jobPostChange(value?.id) }}
                                    onInputChange={(value) => value}
                                    inputProps={{
                                        placeholder: "Select job post",
                                    }}
                                    loading={jobpostLoading}
                                    zIndex={1300}
                                />
                            </div>
                            {data?.user_type !== USER_TYPES.FREELANCER &&
                                <div className='select-option-data'>
                                    <Field
                                        name="candidate"
                                        component={SingleAutocomplete}
                                        id="candidate"
                                        labelText="Developer*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={candidates || []}
                                        displayKey="uid"
                                        validate={[required]}
                                        onChange={(value) => value}
                                        onInputChange={(value) => value}
                                        inputProps={{
                                            placeholder: "Select developer id",
                                        }}
                                        loading={candidateLoading}
                                        zIndex={1300}
                                    />
                                </div>
                            }

                            <div className='date-time-content schedule-interview-main-box'>
                                <FormControl variant="standard" className="formControl form-date">
                                    <div className='select-option-data datetime-custom-control'>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Interview Date*
                                        </InputLabel>
                                        <div className='date-wrapper'>
                                            <Field
                                                name="date"
                                                id="date"
                                                labelText=""
                                                placeholder="Select Date"
                                                component={DatePickerField}
                                                inputFormat="YYYY-MM-DD"
                                                formatSelected='dd/MM/yyyy'
                                                validate={[required]}
                                                disableFuture={false}
                                                disablePast={true}
                                                maxDate={interviewMaxDate()}
                                                className="form-date chat-header-date"
                                                zIndex={1400}
                                            />
                                        </div>
                                    </div>
                                </FormControl>
                                <FormControl variant="standard" className="formControl form-time">
                                    <div className='select-option-data'>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Interview Time*
                                        </InputLabel>

                                        <div className='date-wrapper time-wrapper'>
                                            <Field
                                                name="time"
                                                id="time"
                                                labelText=""
                                                placeholder="Select Date"
                                                component={TimePickerField}
                                                inputFormat="YYYY-MM-DD"
                                                validate={[required]}
                                                minTime={handleMinTime(formStates?.date)}
                                                className="form-date chat-header-date"
                                                zIndex={1400}
                                            />
                                        </div>
                                    </div>
                                </FormControl>
                            </div>
                            <div className='select-option-data'>
                                <FormControl variant="standard" className="">
                                    <Field
                                        name="description"
                                        component={CustomTextArea}
                                        id="description"
                                        labelText="Description"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        multiline={true}
                                        rows="2"
                                        inputProps={{
                                            placeholder: "Enter Text",
                                        }}
                                        placeholder="Enter description"
                                        className='multiSelect-Textfield text-area-content'
                                    />
                                </FormControl>
                            </div>
                            <div className='select-option-data'>
                                <FormControl variant="standard" className="">
                                    <Field
                                        name="link"
                                        component={CustomInput}
                                        id="link"
                                        labelText="Meeting link*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, checkUrl]}
                                        inputProps={{
                                            placeholder: "Enter meeting link",
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className='bottom last-content-btn'>
                    <Link
                        href={void (0)}
                        style={{ pointerEvents: submitting ? "none" : "inherit" }}
                        underline="none"
                        onClick={handleClose}
                        className='cancelBtn'
                    >
                        Cancel
                    </Link>
                    <LoadingButton
                        type="submit"
                        className={`modalBtn primaryButton ${submitting && 'loading'}`}
                        loading={submitting}
                        variant="contained"
                        loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                        disabled={!valid}
                    >
                        Schedule
                    </LoadingButton>
                </DialogActions>
            </form>
        </BootstrapDialog>
    );
}

const InterviewScheduleReduxForm = reduxForm({
    form: "InterviewScheduleForm",
    validate,
    enableReinitialize: true,
    initialValues: { date: moment().local().format('YYYY-MM-DD') },

})(InterviewScheduleDialog);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues("InterviewScheduleForm")(state),
    };
};

export default connect(mapStateToProps)(InterviewScheduleReduxForm);
