import React from "react";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Stack } from "@mui/material";

function JobPostListSkeleton() {
    return (
        <Grid item lg={9} md={9} sm={12} xs={12}>
            <Grid className="job-list-content">
                <Grid className="job-list-card">
                    <Grid className="job-card-header">
                        <div className="heading-text">
                            <Typography variant="h5">
                                <Skeleton width={150} height={40}>
                                </Skeleton>
                            </Typography>
                            <Typography variant="body1">

                                <Skeleton width={200} height={20}>
                                </Skeleton>
                            </Typography>
                        </div>
                        <div className="active-inactive-list">
                            <div className="active">
                                <Typography variant="body2">
                                    <Skeleton width={100} height={40} />
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <div className="job-card-body">
                        <div className="card-body-text">
                            <Typography variant="body1">
                                <Skeleton width={300} height={50} />
                            </Typography>
                        </div>
                        <div className="position-content">
                            <List>
                                <ListItem>
                                    <span><Skeleton width={80} height={20} /></span>
                                    <Typography variant="h3"><Skeleton width={100} height={40} /></Typography>
                                </ListItem>
                                <ListItem>
                                    <span><Skeleton width={80} height={20} /></span>
                                    <Typography variant="h3"><Skeleton width={100} height={40} /></Typography>
                                </ListItem>
                                <ListItem>
                                    <span><Skeleton width={80} height={20} /></span>
                                    <Typography variant="h3"><Skeleton width={100} height={40} /></Typography>
                                </ListItem>
                            </List>
                            <div className="amount-year">
                                <Typography variant="h2">
                                    <Skeleton width={120} height={40} />
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Stack spacing={2} className="pagination-container positionCenter" >
                <Skeleton width={190} />
            </Stack>
        </Grid >
    );
}

export default JobPostListSkeleton;
