import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import docIcon from "assets/img/icons/doc.svg";
import pdfIcon from "assets/img/icons/pdf.svg";
import { Container, IconButton, Typography } from '@mui/material';
import angleLeftIcon from "assets/img/icons/angle-left-white.svg";
import angleRightIcon from "assets/img/icons/angle-right-white.svg";
import downloadDocIcon from "assets/img/icons/download-white.svg";
import { saveAs } from "file-saver";
import ReadMoreLessWithSC from 'components/ReadMoreReadLess/ReadMoreLessWithSC';

const Preview = (props) => {
    return (
        <Slideshow items={props.attachments} message={props.message} />)
};

const fileIcons = {
    ".doc": docIcon,
    ".docx": docIcon,
    ".xls": docIcon,
    ".xlsx": docIcon,
    ".pdf": pdfIcon,
};
const Slideshow = (props) => {
    const { message } = props;
    const [{ items, activeIndex }, setState] = useState({
        items: props.items,
        activeIndex: 0,
    });
    const handleDownloadFile = (file) => {
        if (file?.url) {
            saveAs(file.url, (file?.original_filename || "attachments"));
        }
    }

    const moveTo = (newIndex) => () => {
        if (newIndex === -1) {
            setState((s) => ({ ...s, activeIndex: items.length - 1 }));
            return;
        }
        if (newIndex === items.length) {
            setState((s) => ({ ...s, activeIndex: 0 }));
            return;
        }

        setState((s) => ({ ...s, activeIndex: newIndex }));
    };
    const getFileExtention = (file) => {
        return (/[.]/.exec(file)) ? '.' + /[^.]+$/.exec(file)[0] : undefined;
    }
    const getFileIcon = (extension) => {
        return fileIcons[extension] ? fileIcons[extension] : null;
    };

    return (
        <Container tabIndex={0} >
            <SlideWrapper>
                <ImageBox>
                    {
                        !getFileIcon(getFileExtention(items[activeIndex].original_filename))
                            ?
                            <div className='imagePreviewContainer'>
                                <img alt={items[activeIndex].caption} src={items[activeIndex].url} />
                                <div className='captionContainer'>
                                    <ImageCaption>
                                        <div className='caption-header'>
                                            <Typography>{items[activeIndex].original_filename}</Typography>
                                            <IconButton className='downloadIconBtn' onClick={() => handleDownloadFile(items[activeIndex])}>
                                                <img src={downloadDocIcon} alt="img" />
                                            </IconButton>
                                        </div>
                                        <Typography className='captionContent'>
                                            <ReadMoreLessWithSC
                                                className="Test"
                                                charLimit={200}
                                                readMoreText={"Read More"}
                                                readLessText={"Read Less"}
                                                readMoreClassName="read-more-open-39A1FF"
                                                readLessClassName="read-more-close-39A1FF"
                                                children={message || ""} /* eslint-disable-line */
                                                textType={true}
                                            />
                                        </Typography>
                                    </ImageCaption>
                                </div>
                            </div>
                            :
                            <div className='docPreviewContainer'>
                                <div className='doc-inner'>
                                    <img className="doc-icons" src={getFileIcon(getFileExtention(items[activeIndex].original_filename))} alt="img" />
                                    <Typography className='doc-name'>{items[activeIndex].original_filename}</Typography>
                                    <Typography className='doc-size'>{items[activeIndex]?.size}</Typography>
                                </div>
                                <div className='captionContainer'>
                                    <ImageCaption>
                                        <div className='caption-header'>
                                            <Typography></Typography>
                                            <IconButton className='downloadIconBtn' onClick={() => handleDownloadFile(items[activeIndex])}>
                                                <img src={downloadDocIcon} alt="img" />
                                            </IconButton>
                                        </div>
                                        <Typography className='captionContent'>
                                            <ReadMoreLessWithSC
                                                className="Test"
                                                charLimit={200}
                                                readMoreText={"Read More"}
                                                readLessText={"Read Less"}
                                                readMoreClassName="read-more-open-39A1FF"
                                                readLessClassName="read-more-close-39A1FF"
                                                children={message || ""} /* eslint-disable-line */
                                                textType={true}
                                            />
                                        </Typography>
                                    </ImageCaption>
                                </div>

                            </div>
                    }
                    {(items && items.length > 1) &&
                        <>
                            <NavButton position="left" onClick={moveTo(activeIndex - 1)}>
                                <img src={angleLeftIcon} alt="img" />
                            </NavButton>
                            <NavButton position="right" onClick={moveTo(activeIndex + 1)}>
                                <img src={angleRightIcon} alt="img" />
                            </NavButton>
                        </>
                    }
                </ImageBox>
                <ThumbnailList className='thumbnail-section'>
                    {items.map((item, index) => (
                        <>
                            {!getFileIcon(getFileExtention(item?.original_filename))
                                ?
                                <Thumbnail
                                    onClick={moveTo(index)}
                                    active={activeIndex === index}
                                    src={item?.url}
                                    className="thumbnail-bg"
                                    key={index}
                                />
                                :
                                <Thumbnail className="previewDocSliderItem" onClick={moveTo(index)} active={activeIndex === index} key={index}>
                                    <img src={getFileIcon(getFileExtention(item?.original_filename))} className="file-icon" alt="img" />
                                    <div className="fileDetail">
                                        <p className="fileName">{item.original_filename}</p>
                                        <p className="fileSize">{item?.size}</p>
                                    </div>
                                </Thumbnail>
                            }

                        </>
                    ))}
                </ThumbnailList>
            </SlideWrapper>
        </Container>
    );
};

const ThumbnailList = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 16%;
  justify-content: center;
  overflow-x: auto;

`;
const Thumbnail = styled.div`
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  flex-grow: 1;
  opacity: 1;
  margin: 0 4px;
  border-radius: 4px;
  max-width: 140px;
  min-width: 140px;
  border: 1px solid transparent;
  background-color: #fff;
  :hover {
    border: 1px solid #548CA8;
  }
`;

const NavButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 42%;
  padding: 5px;
  border-radius: 100%;
  border: none;
  background: rgba(255, 255, 255, 0.7);
  width: 32px;
  height: 32px;
  font-size:10px;

  img{
      width: 9px;
      height: auto;
  }
  @media (max-width: 800px) {
    background:rgb(203 203 203 / 70%);
  }
  ${({ position }) =>
        position === 'left' &&
        css`
      left: -22%;
      @media (max-width: 720px) {
        left: -4%;
      }
    `}

  ${({ position }) =>
        position === 'right' &&
        css`
        right: -22%;
        @media (max-width: 720px) {
            right: -4%;
        }
    `}
`;

const ImageCaption = styled.div`
  text-align: left;
  padding: 0 16px 8px;
  font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
`;

const ImageBox = styled.div`
  background-color: #343434;
  width: 766px;
  height: 620px;
  margin: auto auto 25px;
  border-radius: 12px;
  position: relative;
  img {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  @media (max-width: 1366px){
    width: 766px;
    height: 544px;
  }
  @media (max-width: 1280px){
    width: 754px;
    height: 566px;
  }
  @media (max-width: 1024px){
    width: 674px;
    height: 520px;
  }
  @media (max-width: 992px) {
    width: 645px;
    height: 499px;
  }
  @media (max-width: 800px) {
    width: 500px;
    height: 354px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const SlideWrapper = styled.div`
  position: relative;
  height: 90vh;
`;

export default Preview;
