import React from 'react';
import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import JobPostListSkeleton from './JobPostListSkeleton';
import JobPostSidebarSkeleton from './JobPostSidebarSkeleton';

const JobPostSkeleton = () => {
    return (
        <div>
            <div className="manage_job">
                <Container>
                    <div className="manage-job-heading">
                        <h4><Skeleton width={226} /></h4>
                        <Skeleton className='bg-dark btn transform-scale-1' width={156} height={42} />
                    </div>

                    <div className="manage-job-list">
                        <Grid container>
                            <JobPostSidebarSkeleton />
                            <JobPostListSkeleton />
                        </Grid>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default JobPostSkeleton;