import React, { useState, useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import "assets/css/profile.css";
import "assets/css/allModals.css";
import plusIcon from "assets/img/icons/plus-icon.svg";
import UpdateProjectDialog from "components/Dialog/UpdateProjectDialog";
import DeveloperProjectForm from "./component/DeveloperProjectForm";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions/index';
import * as DeveloperActions from '../../store/actions/manageDevelopers/manageDevelopers.actions';
import { submit, isValid, isInvalid, getFormValues, formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { checkForImageValidations } from "utils/Common";
import UnderApprovedDialog from "components/Dialog/UnderApprovedDialog";

function EditDeveloperProject(props) {
    const { match, title } = props;
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const allProjects = useSelector(state => state.manageDevelopersReducer?.agencyDeveloperProjectList);
    const remoteData = useSelector(state => state.manageDevelopersReducer?.remoteData);
    const technologies = useSelector(state => state.commonReducer?.technologies);
    const skills = useSelector(state => state.commonReducer?.skillsWithoutDepth);
    const agencyDeveloper = useSelector((state) => state.manageDevelopersReducer?.agencyDeveloperDetails);
    const history = useHistory();
    const { toggalLoader } = useContext(LoaderContext);
    const devId = match.params?.agencyDeveloperId ?? null;
    const [imageLoaders, setImageLoaders] = useState([]);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-developers', path: '/manage-developers' },
        { name: 'developer-profile', path: `/developer-profile/${devId}` },
    ];
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleConfirmRemove = async () => {
        setOpenDeleteDialog(false);
        const index = remoteData.currentForm;
        const projectData = allProjects.filter((elem, ind) => ind !== index);
        const formData = await { ...remoteData, update_data: remoteData.update_data.filter((elem, ind) => ind !== index) };
        formData.currentForm = 0;
        dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: formData });
        dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
    };
    const getProjectList = async () => {
        await dispatch(DeveloperActions.getAgencyDeveloperProjectById(devId, 1, 'all'))
        await dispatch(Actions.getTechnologies(''));
        await dispatch(Actions.getSkillsWithoutDepth());
    };
    const handleFormChange = async (index = null) => {
        await dispatch(submit('DeveloperProjectForm'));
        if (props.valid) {
            if (index === null) {
                const keys = Object.keys(allProjects)
                index = (keys.length > 0) ? Math.max(...new Set(keys)) + 1 : 0;
                const projectData = [...allProjects];
                projectData[remoteData.currentForm] = props.formData;
                projectData[index] = { project_title: <i style={{ opacity: "0.5" }}>(Project Title)</i> };
                await dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
            }
            const formData = { ...remoteData };
            formData.currentForm = index;
            if (allProjects[index]) {
                formData.update_data[index] = allProjects[index];
            } else {
                formData.update_data[index] = {};
            }
            dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: formData });
        }
    };
    const handleSubmit = (values, index) => {
        if (props.valid) {
            const formData = { ...remoteData };
            formData.update_data[index] = values;
            const projectData = [...allProjects];
            projectData[index] = values;
            dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: formData });
            dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
        }
    };
    const handleMultipleImageUpload = (event, formIndex) => {
        try {
            const tempArr = allProjects[formIndex]?.images ? [...allProjects[formIndex]?.images] : [];
            let allFiles = [...event.target.files];
            let uploadLength = 10;
            let filesLength = tempArr.length ?? 0;
            let length = uploadLength - filesLength;

            if (allFiles.length == 0) return false;
            if (tempArr.length >= 10) {
                toast.error("Max file upload has been reached for this project");
                return false;
            } else if (length > 0 && allFiles?.length > length) {
                allFiles = allFiles.slice(0, length);
                let message = length === 10 ?
                    "You can only upload 10 files at a time." :
                    `${filesLength} files have already been uploaded for this project now you can upload only ${length} files`;
                toast.warn(message);
            }
            const imagesData = { ...remoteData };
            const projectData = [...allProjects];
            const loaders = [...imageLoaders];
            if (allFiles?.length !== 0) {
                loaders[formIndex] = true;
                setImageLoaders(loaders);
                let i = 1;
                allFiles.forEach(async (file) => {
                    const fileCheck = await checkForImageValidations(file, 'image');
                    if (fileCheck instanceof Error) {
                        if (allFiles.length === i) {
                            loaders[formIndex] = false;
                            setTimeout(() => {
                                setImageLoaders([...loaders]);
                            }, 500);
                        }
                        toast.error(fileCheck.message);
                        i++;
                    } else {
                        const formData = new FormData();
                        formData.append("file_obj", file);
                        dispatch(Actions.uploadImage(formData)).then(response => {
                            tempArr.push(response.data);
                            if (allFiles.length === i) {
                                imagesData.files[formIndex] = tempArr;
                                const pData = props.formData;
                                projectData[formIndex]?.images
                                    ? projectData[formIndex].images = tempArr
                                    : projectData[formIndex] = { ...pData, images: tempArr };
                                loaders[formIndex] = false;
                                setImageLoaders(loaders);
                                dispatch({ type: "GET_REMOTE_DATA", payload: imagesData });
                                dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
                            }
                            i++;
                        }).catch(error => {
                            i++;
                            toast.error(error?.message);
                        });
                    }
                });
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    };
    const removeImage = (formIndex, imageIndex) => {
        const formData = { ...remoteData };
        const projectData = [...allProjects];
        delete formData.files?.[formIndex]?.[imageIndex];
        projectData[formIndex].images.splice(imageIndex, 1);
        dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: formData });
        dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
    };
    const handleProjectOperation = async () => {
        toggalLoader(true);
        await dispatch(submit('DeveloperProjectForm'));
        if (remoteData.update_data.length > 0) {
            const filtered = remoteData.update_data.filter(value => value);
            let projects = [];
            filtered.map((values) => {
                if (values) {
                    let data = {
                        title: values.title,
                        duration: parseInt(values.duration),
                        is_on_going: (values.is_on_going) ? values.is_on_going : false,
                        technologies: values?.technologies?.map(val => val.id),
                        skills: values.skills?.map(val => val.id),
                        description: values.description,
                        link: values.link,
                        images: values.images,
                        id: values?.id,
                    }
                    if (!data.id) {
                        delete data.id;
                    }
                    if (!data.project_title) {
                        delete data.project_title;
                    }
                    projects.push(data);
                }
            });

            dispatch(DeveloperActions.addProjectForAgencyDeveloper(devId, projects)).then(response => {
                if (response.status === 201) {
                    setOpenDialog(false);
                    toast.success('Project details updated successfully');
                    toggalLoader(false);
                    history.push(`/developer-profile/${devId}`);
                } else {
                    toggalLoader(false);
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
            }).catch(() => {
                toggalLoader(false);
                toast.error('Something went wrong');
            });
        }
    };
    const initialData = (project, index) => {
        const fData = { ...remoteData }
        if (remoteData && remoteData.update_data.length === 0) {
            fData.update_data[index] = project;
            dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: fData });
        }
    };
    const getAgencyDeveloperById = (developerId) => {
        dispatch(Actions.getAgencyDeveloperById(developerId));
    };
    const backToHome = () => {
        history.push('/home');
    };

    useEffect(() => {
        if (remoteData?.currentForm > 0) {
            const formData = { ...remoteData, currentForm: 0 };
            dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: formData });
        }
        getProjectList();
        getAgencyDeveloperById(devId);
        return () => {
            const initialRemoteData = { currentForm: 0, previousForm: 0, update_data: [], delete_data: [], files: [] }
            dispatch({ type: "GET_DEVELOPER_REMOTE_DATA", payload: initialRemoteData });
            dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: [] });
        }
    }, []);

    useEffect(() => {
        if (allProjects && allProjects?.length === 0) {
            const initial = {};
            dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: [initial] });
        }
    }, [allProjects]);

    useEffect(() => {
        if (title !== undefined) {
            const projectData = [...allProjects];
            projectData[remoteData?.currentForm].project_title = title || <i style={{ opacity: "0.5" }}>(Project Title)</i>;
            projectData[remoteData?.currentForm].title = title;
            dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: projectData });
        }
    }, [title])

    return (
        <>
            <Grid className="profile-top-section add_profile-top-section custom-bread-crumb">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-project" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className='clientId'>
                                Developer Name :
                                <span>{`${agencyDeveloper?.first_name} ${agencyDeveloper?.last_name}`}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Edit Project</h4>
                <Grid className="profile-inner edit_project-inner">
                    <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={12} className="projects-sidebar">
                            <div className="sidebarTitlle-section">
                                <h5 className="title">Projects</h5>
                                <IconButton
                                    underline="none"
                                    className="addIconBtn cursor-pointer"
                                    onClick={() => handleFormChange()}
                                >
                                    <img src={plusIcon} alt="add" />
                                </IconButton>
                            </div>

                            <List>
                                {allProjects && allProjects.length > 0 && allProjects.map((project, index) => {
                                    initialData(project, index);
                                    return (
                                        <ListItem
                                            className={(index === remoteData.currentForm) ? "projectsListItem active" : "projectsListItem"}
                                            key={index}
                                            onClick={() => handleFormChange(index)}
                                        >
                                            <Link href="#" underline="none">
                                                {project?.title || project?.project_title || <i style={{ opacity: "0.5" }}>(Project Title)</i>}
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={12} className="editProject-container">
                            <Grid className="edit-conatiner">
                                <div className="edit-container-header">
                                    <h5 className="title">{allProjects?.[remoteData?.currentForm]?.title || allProjects?.[remoteData?.currentForm]?.project_title || <i style={{ opacity: "0.5" }}>(Project Title)</i>}</h5>
                                    {!allProjects?.[remoteData?.currentForm]?.id && (allProjects?.length > 1) && <IconButton
                                        onClick={handleOpenDeleteDialog}
                                        aria-label="delete"
                                        size="medium"
                                        className="cursor-pointer"
                                    >
                                        <img
                                            src={deleteIcon}
                                            alt="img"
                                            style={{ filter: "brightness(10)" }}
                                        />
                                    </IconButton>}
                                </div>
                                <DeveloperProjectForm
                                    initialValues={allProjects?.[remoteData?.currentForm]}
                                    onSubmit={(data) => handleSubmit(data, remoteData?.currentForm)}
                                    formIndex={remoteData?.currentForm}
                                    technologies={technologies}
                                    skills={skills}
                                    uploadImage={handleMultipleImageUpload}
                                    filesData={allProjects?.[remoteData?.currentForm]?.images}
                                    removeImage={removeImage}
                                    imageLoaders={imageLoaders}
                                ></DeveloperProjectForm>
                            </Grid>
                            <Grid className="action-div">
                                <Link onClick={() => history.push(`/developer-profile/${devId}`)} underline="none" className="previousBtn cursor-pointer">
                                    Cancel
                                </Link>
                                <Button variant="contained" className="primaryButton nextBtn"
                                    onClick={handleOpenDialog}
                                    disabled={props.invalid}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <UpdateProjectDialog
                open={openDialog}
                close={handleCloseDialog}
                handleUpdate={handleProjectOperation}
                message="Are you Sure you want to Update Projects?"
                description="If you want to Edit other Projects then Click Continue Button and if you want to Save All changes then Click Update Button." />
            <DeleteProjectDialog
                open={openDeleteDialog}
                close={handleCloseDeleteDialog}
                confirm={handleConfirmRemove}
                message="Are you Sure you want to Remove this Project?"
                description="This Action Can’t be Undo, When you delete all data of this Project it will be removed."
            />
            <UnderApprovedDialog open={agencyDeveloper?.is_projects_in_review} backToHome={backToHome} />
        </>
    );
}
const selector = formValueSelector('DeveloperProjectForm');
const EditDeveloperProjectMap = connect(
    state => ({
        valid: isValid('DeveloperProjectForm')(state),
        invalid: isInvalid('DeveloperProjectForm')(state),
        formData: getFormValues('DeveloperProjectForm')(state),
        title: selector(state, 'title')
    })
)(EditDeveloperProject)
export default EditDeveloperProjectMap;
