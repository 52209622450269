import React, { useState, useEffect, useContext } from "react";
import PersonalDetailForm from "./component/PersonalDetailForm";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions/index";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import history from "utils/history";

function PersonalDetail() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [countryList, setCountryList] = useState([]);
    const [initValues, setInitValues] = useState(null);
    const [time, setTime] = useState('');
    const userDetails = useSelector(state => state.authReducer.userDetails);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: '/my-profile' },
    ]

    useEffect(() => {
        getUserDetail()
    }, []);

    const getCountryList = (search) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            if (search.length > 2) {
                dispatch(Actions.getCountryList(search)).then((response) => {
                    if (response.status === 200) {
                        let data = response.data?.results ?? response.data;
                        setCountryList(data);
                    } else {
                        setCountryList([]);
                    }
                }).catch(() => {
                    setCountryList([]);
                })
            }
        }, 1000));
    }
    const getUserDetail = () => {
        dispatch(Actions.getUserProfile()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_PROFILE', payload: response.data });
                const userData = { ...response.data }
                // userData.contact_number = userData.contact_number_without_code
                setInitValues(userData);
            } else {
                dispatch({ type: 'GET_USER_PROFILE', payload: null });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_PROFILE', payload: null });
        });
    }
    const updateUserProfile = (values) => {
        const addUserProfileData = {
            first_name: values.first_name,
            last_name: values.last_name,
            // contact_number: `+${values.country.phone}${values.contact_number}`,
            contact_number: values.contact_number,
            city: values.city.id,
        };
        toggalLoader(true);
        dispatch(Actions.updateUserProfile(userDetails.id, addUserProfileData))
            .then((response) => {
                if (response.status === 200) {
                    toast.success("User detail updated successfully");
                    history.push("/my-profile");
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    })
                }
                toggalLoader(false);
            })
            .catch(() => {
                toggalLoader(false);
            });

    };

    return (
        <div>
            <Grid className='profile-top-section freelancer_profile-top-section'>
                <Container>
                    <Grid container className='profile-top-inner'>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="personal-details" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className='clientId'>ID : <span>{userDetails.uid}</span></p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <PersonalDetailForm countryList={countryList}
                initialValues={initValues}
                onSubmit={(values) => {
                    updateUserProfile(values);
                }}
                getCountryList={getCountryList}
            />
        </div>
    );
}

export default PersonalDetail;
