import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import 'assets/css/jobpostdetail.css';


const CandidateJobpostCardSkeleton = () => {
    return (
        <Grid className='top-jobpost-main-content'>
            <div className="job-list-content">
                <div className="job-list-card">
                    <div className="job-card-header">
                        <div className="heading-text">
                            <div>

                                <Typography variant="h5"><Skeleton width={240} /></Typography>
                                <Typography variant="body1"><Skeleton width={200} /></Typography>
                            </div>
                            <div className='value-define'>
                                <Typography variant="h2">
                                    <Skeleton className='transform-scale-1' width={100} />
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="job-card-body">
                        <div className="position-content">
                            <List>
                                <ListItem>
                                    <Typography variant="h3"><Skeleton /></Typography>
                                    <span><Skeleton /></span>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="h3"><Skeleton /></Typography>
                                    <span><Skeleton /></span>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="h3"><Skeleton /></Typography>
                                    <span><Skeleton /></span>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default CandidateJobpostCardSkeleton;