import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Typography } from "@mui/material";
import docIcon from "assets/img/icons/doc.svg";
import zipIcon from "assets/img/icons/zip.png";
import rarIcon from "assets/img/icons/rar-file-format.png";
import xlsIcon from "assets/img/icons/xls.png";
import pdfIcon from "assets/img/icons/pdf.svg";
import { getFileExtention } from "utils/Common";
import downloadImg from "assets/img/icons/downloadAttachment.svg";
import AttachmentPreview from "./AttachmentPreview";
import ReadMoreLessWithSC from "components/ReadMoreReadLess/ReadMoreLessWithSC";

function ChatAttachments(props) {

    const { attachments, downlaodFile, cls, message } = props;
    const [open, setopen] = useState(false);
    const [previewData, setPreviewData] = useState({ attachments: [], message: '' });

    const fileIcons = {
        ".doc": docIcon,
        ".docx": docIcon,
        ".zip": zipIcon,
        ".rar": rarIcon,
        ".xls": xlsIcon,
        ".xlsx": xlsIcon,
        ".pdf": pdfIcon,
    };
    const getFileIcon = (extension) => {
        return fileIcons[extension] ? fileIcons[extension] : null;
    };
    const handleOpen = (attach, chatMessage) => {
        setopen(true);
        const data = { ...previewData };
        data.attachments = attach;
        data.message = chatMessage;
        setPreviewData(data);
    }
    const handleClose = () => {
        setopen(false);
    }

    return (
        <div className="chat-box-inner">
            {/* single img attachment */}
            {attachments?.length <= 2 && attachments.map((file, index) => {
                let extension = getFileExtention(file?.url);
                let icon = getFileIcon(extension);
                return (
                    <div key={index}>
                        {!icon ?
                            <div className={`${cls}-imageAttachmntChat-block attachmntChat-block marginBottom`} >
                                <div className={`${cls}-singleAttachment-block cursor-pointer`} onClick={() => handleOpen(attachments, message)}>
                                    <img className="single-imgAttachment" src={file?.url} alt="img" />
                                </div>
                                {index + 1 === attachments.length &&
                                    <div className={`${cls}-attachmntText`}>
                                        <Typography>
                                            <ReadMoreLessWithSC
                                                className="Test"
                                                charLimit={150}
                                                readMoreText={"Read More"}
                                                readLessText={"Read Less"}
                                                readMoreClassName="read-more-open-39A1FF"
                                                readLessClassName="read-more-close-39A1FF"
                                                children={message || ""} /* eslint-disable-line */
                                                textType={true}
                                            />
                                        </Typography>
                                    </div>
                                }
                            </div>
                            :
                            <div className={`${cls}-attachmntChat-block attachmntText-block marginBottom`}>
                                <div className={`${cls}-attachment-block`}>
                                    <img className="attachType-img" src={icon} alt="img" />
                                    <div className="attachmentDetails">
                                        <Typography className="attachmentName">{file?.original_filename}</Typography>
                                        <Typography className="attachmentSize">{file?.size ?? ''}</Typography>
                                    </div>
                                    <img className="attachDownload-img" src={downloadImg} alt="img" onClick={() => downlaodFile(file)} />
                                </div>
                                {index + 1 === attachments.length &&
                                    <div className={`${cls}-attachmntText`}>
                                        <Typography>
                                            <ReadMoreLessWithSC
                                                className="Test"
                                                charLimit={150}
                                                readMoreText={"Read More"}
                                                readLessText={"Read Less"}
                                                readMoreClassName="read-more-open-39A1FF"
                                                readLessClassName="read-more-close-39A1FF"
                                                children={message || ""} /* eslint-disable-line */
                                                textType={true}
                                            />
                                        </Typography>
                                    </div>
                                }
                            </div>
                        }

                    </div>
                )
            })
            }

            {/* single documents attachment */}


            {/* multi attachment */}
            {attachments?.length > 2 &&
                <>
                    <List className={`${cls}-multiAttachmntChat-block marginBottom`}>
                        {attachments.map((attached, index) => {
                            if (index > 3) return false;
                            let extension = getFileExtention(attached?.url);
                            let icon = getFileIcon(extension);
                            return (
                                <ListItem className={`${cls}-multiAttachment-block cursor-pointer`} key={index} onClick={() => handleOpen(attachments, message)}>
                                    {icon ?
                                        <div className="docAttachments">
                                            <div className="topDetails">
                                                <div className="file-icon">
                                                    <img src={icon} alt="img" />
                                                </div>
                                                <Typography className="fileSize">{attached?.size ?? ''}</Typography>
                                            </div>
                                            <Typography className="fileName">
                                                {attached?.original_filename}
                                            </Typography>
                                        </div>
                                        :
                                        <>
                                            <div className="multiImageAttachment">
                                                <img src={attached?.url} alt="img" />
                                            </div>
                                            {index == 3 && attachments?.length > 4 &&
                                                <div className='multiAttachOverlay'>
                                                    <Typography>{`+${attachments.length - 4}`}</Typography>
                                                </div>
                                            }
                                        </>

                                    }
                                </ListItem>
                            )

                        })
                        }
                        <div className={`${cls}-attachmntText`}>
                            <Typography>
                                <ReadMoreLessWithSC
                                    className="Test"
                                    charLimit={150}
                                    readMoreText={"Read More"}
                                    readLessText={"Read Less"}
                                    readMoreClassName="read-more-open-39A1FF"
                                    readLessClassName="read-more-close-39A1FF"
                                    children={message || ""} /* eslint-disable-line */
                                    textType={true}
                                />
                            </Typography>
                        </div>
                    </List>
                </>
            }
            {open && <AttachmentPreview open={open} close={handleClose} attachment={previewData?.attachments} message={previewData?.message} />}
        </div >
    );
}

export default ChatAttachments;
