import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ReduxBasicInfoForm from "./BasicInfo";
import Availability from "./Availability";
import ReduxExpertiseForm from "./Expertise";
import ReduxExperienceForm from "./Experience";
import ReduxBudgetForm from "./Budget";
import { useDispatch, useSelector } from "react-redux";

function JobPostContainer(props) {
    const { activeStep, steps, back, storeData, processing } = props;
    const dispatch = useDispatch();
    const data = useSelector(state => state.jobPostReducer?.jobPostData);

    return (
        <Grid className="createJob-wrapper">
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {activeStep === 0 && (
                        <ReduxBasicInfoForm
                            onSubmit={(value) => {
                                let basicInfoObj = {
                                    ...data,
                                    ...value
                                }
                                dispatch({ type: "JOBPOST_DATA", payload: basicInfoObj })
                                storeData(basicInfoObj);
                            }}
                            initialValues={data}
                        />
                    )}
                    {activeStep === 1 && (
                        <Availability
                            back={back}
                            onSubmit={(value) => {
                                let availabilityObj = {
                                    ...data,
                                    ...value
                                }
                                dispatch({ type: "JOBPOST_DATA", payload: availabilityObj })
                                storeData(availabilityObj);
                            }}
                            initialValues={data}
                        />
                    )}
                    {activeStep === 2 && (
                        <ReduxExpertiseForm
                            back={back}
                            onSubmit={(value) => {
                                let expertiseObj = {
                                    ...data,
                                    ...value
                                }
                                dispatch({ type: "JOBPOST_DATA", payload: expertiseObj });
                                storeData(expertiseObj);
                            }}
                            initialValues={data}
                        />
                    )}
                    {activeStep === 3 && (
                        <ReduxExperienceForm
                            back={back}
                            onSubmit={(value) => {
                                let experienceObj = {
                                    ...data,
                                    ...value
                                }
                                dispatch({ type: "JOBPOST_DATA", payload: experienceObj });
                                storeData(experienceObj);
                            }}
                            initialValues={data}
                        />
                    )}
                    {activeStep === 4 && (
                        <ReduxBudgetForm
                            back={back}
                            onSubmit={(value) => {
                                let budgetObj = {
                                    ...data,
                                    ...value
                                }
                                dispatch({ type: "JOBPOST_DATA", payload: budgetObj });
                                storeData(budgetObj);
                            }}
                            initialValues={data}
                            processing={processing}
                        />
                    )}
                </React.Fragment>
            )
            }
        </Grid >
    );
}

export default JobPostContainer;
