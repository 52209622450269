import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

function useChatScroll(dep, initial) {
    const ref = React.useRef();
    const scrollPosition = useSelector(state => state?.chatReducer?.scrollPosition);
    const scrollingAction = useSelector(state => state?.chatReducer?.scrollingAction);
    const contentLoading = useSelector(state => state.commonReducer?.contentLoading);
    const currentScrollPosition = useSelector(state => state.chatReducer?.currentScrollPosition);
    const dispatch = useDispatch();
    const setScrollPosition = () => {
        dispatch({ type: 'CHAT_SCROLL_POSITION', payload: ref.current.scrollHeight });
    }
    React.useEffect(() => {
        if (ref.current) {
            if (!scrollingAction || initial) {
                ref.current.scrollTop = ref.current.scrollHeight;
                setScrollPosition();
            } else {
                switch (scrollingAction) {
                    case 'send_message':
                        ref.current.scrollTop = ref.current.scrollHeight;
                        setScrollPosition();
                        break;

                    case 'read_message': {

                        if (!currentScrollPosition || (currentScrollPosition > 0 && currentScrollPosition < 120)) { ref.current.scrollTop = ref.current.scrollHeight; }
                        setScrollPosition();
                        break;
                    }

                    case 'pagination': {
                        const top = ref.current.scrollHeight - scrollPosition; ref.current.scrollTop = top;
                        setScrollPosition();
                        break;
                    }

                    default:
                        break;
                }
            }
        }
    }, [dep, initial, contentLoading]);
    return ref;
}
export default useChatScroll