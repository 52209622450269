import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ContactBottomImg from "assets/img/cantact-bottom-img.png";

const Footer = () => {
    return (
        <div className='contact-bottom-section'>
            <Container>
                <Grid container alignItems="center">
                    <Grid item lg={8} md={8} sm={8}>
                        <div className='contact-bottom-heading'>
                            <Typography variant="h4">We’d love to hear more from you</Typography>
                            <Typography variant='body1'>Whether you’re curious about Hiring Dedicated Software Development Teams or Getting Hired or even just to say ‘Hi’—we’re ready to get connected.</Typography>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className='contact-bottom-img'>
                            <img src={ContactBottomImg} alt="" />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;