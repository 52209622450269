import React, { useEffect } from 'react';
import history from 'utils/history';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../store/actions/index";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SearchBar from './component/SearchBar';
import ChatSideBar from './component/ChatSideBar';
import ChatHeader from './component/ChatHeader';
import ChatContainer from './component/ChatContainer';
import noDataImg from 'assets/img/icons/no-developer-found.svg';
import Typography from "@mui/material/Typography";
import 'assets/css/client-dashboard.css';

function Chat(props) {
	const { match } = props;
	const agency = history.location.state?.agency;
	const roomId = match.params?.roomId;
	const dispatch = useDispatch();
	const currentChat = useSelector(state => state.chatReducer?.currentChat);
	const currentChatCount = useSelector(state => state.chatReducer?.totalCount);
	const contactList = useSelector(state => state?.chatReducer?.contactList);
	const isInitial = useSelector(state => state?.chatReducer?.initial);
	const pageData = useSelector(state => state?.chatReducer?.pageData);
	const currentUser = useSelector(state => state.chatReducer?.currentUser);
	const isMobileView = useSelector(state => state.chatReducer?.isMobile);
	const errors = useSelector(state => state.socketReducer?.errors);
	const chatSearch = useSelector(state => state?.chatReducer?.search);
	const vertical = 'top';
	const horizontal = 'center';

	useEffect(() => {
		dispatch(Actions.getContactList(''));
		dispatch({ type: 'IS_MOBILE_VIEW', payload: { leftBar: "block", rightBar: "none", active: (window.innerWidth > 899) ? false : true } });
		return () => {
			dispatch({ type: 'CHAT_SCROLL_POSITION', payload: null });
			dispatch({ type: 'INITIAL', payload: true });
		}
	}, []);

	window.onbeforeunload = () => {
		history.replace({ ...history.location, state: undefined });
	}

	useEffect(() => {
		if (roomId && contactList && contactList.length > 0 && isInitial) {
			let temp_Chat = contactList.find(chat => chat.room == roomId);
			if (temp_Chat) {
				dispatch({ type: 'PAGE_DATA', payload: { page: 1, pageSize: 30 } });
				dispatch(Actions.getCurrentChatHistory(temp_Chat, 1, 30));
			} else {
				history.push('/page-not-found');
			}
		} else if (agency && contactList && contactList.length > 0 && isInitial) {
			let temp_Chat = contactList.find(chat => chat.room === agency.room);
			if (temp_Chat) {
				dispatch({ type: 'PAGE_DATA', payload: { page: 1, pageSize: 30 } });
				dispatch(Actions.getCurrentChatHistory(temp_Chat, 1, 30));
			}
		} else {
			if (contactList && contactList.length > 0 && isInitial) {
				let temp_Chat = contactList[0];
				dispatch({ type: 'PAGE_DATA', payload: { page: 1, pageSize: 30 } });
				dispatch(Actions.getCurrentChatHistory(temp_Chat, 1, 30));
			}
		}
	}, [contactList]);

	const handlePageData = (page) => {
		dispatch({ type: 'LOADING', payload: true });
		const pageLength = Math.ceil(currentChatCount / 10);
		if (pageLength && pageLength >= page) {
			setTimeout(() => {
				dispatch(Actions.getCurrentChatHistory(currentUser, page, pageData.pageSize, currentChat));
				const pData = { ...pageData, page: page };
				dispatch({ type: 'PAGE_DATA', payload: pData });
			}, 1000);
		} else {
			dispatch({ type: 'LOADING', payload: false });
		}
	}

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={errors?.type ? true : false}
				key={vertical + horizontal}
				className="chat_snackbar"
			>
				<Alert severity={errors?.type || "warning"} sx={{ width: '100%' }}>
					{errors?.message || "Connecting server..."}
				</Alert>
			</Snackbar>
			<Container>
				{!isInitial && contactList?.length === 0 && !chatSearch ?
					<Grid className='manage-developer-paper chat-blank-page'>
						<div className='no_data-content'>
							<div>
								<img src={noDataImg} alt="no data" />
								<Typography variant="h6">
									No Contact Found!!
								</Typography>
							</div>
						</div>
					</Grid>
					:
					<Grid container className='dashboard-container client-dashborad-content'>
						<Grid item lg={3} md={3} sm={12} xs={12} sx={{ display: { xs: isMobileView?.leftBar, sm: isMobileView?.leftBar, md: 'block' } }} >
							<Grid className='chat-aside'>
								<SearchBar search={chatSearch}/>
								<ChatSideBar handlePageData={handlePageData} />
							</Grid>
						</Grid>
						<Grid item lg={9} md={9} sm={12} xs={12} sx={{ display: { xs: isMobileView?.rightBar, sm: isMobileView?.rightBar, md: 'block' } }}>
							<Grid className='chat-main chat-main-block'>
								<ChatHeader />
								<ChatContainer handlePageData={handlePageData} />
							</Grid>
						</Grid>
					</Grid>
				}
			</Container>
		</>
	)
}
export default Chat