import React from "react";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import docIcon from "assets/img/icons/doc.svg";
import zipIcon from "assets/img/icons/zip.png";
import rarIcon from "assets/img/icons/rar-file-format.png";
import xlsIcon from "assets/img/icons/xls.png";
import pdfIcon from "assets/img/icons/pdf.svg";
import { getFileExtention } from "utils/Common";

function AttachmentView(props) {

  const { attachedFile, removeFile } = props;
  const isAttachmentLoading = useSelector((state) => state?.chatReducer?.isAttachmentLoading);
  const images = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
  const fileIcons = {
    ".doc": docIcon,
    ".docx": docIcon,
    ".zip": zipIcon,
    ".rar": rarIcon,
    ".xls": xlsIcon,
    ".xlsx": xlsIcon,
    ".pdf": pdfIcon,
  };
  const getFileIcon = (extension) => {
    return fileIcons[extension] ? fileIcons[extension] : docIcon;
  };

  return (
    <Grid className='file-viewer'>
      <Grid className="attachment-container">
        <List className="attachList">
          {attachedFile &&
            attachedFile.length > 0 &&
            attachedFile.map((file, index) => {
              let extension = getFileExtention(file?.url);
              let icon = getFileIcon(extension);
              return images.includes(extension) ? (
                <ListItem className="item" key={index}>
                  <img src={file?.url} alt="img" />
                  <div className="attachOverlay">
                    <img
                      src={deleteIcon}
                      alt={file?.original_filename}
                      onClick={() => removeFile(index)}
                    />
                  </div>
                </ListItem>
              ) : (
                <ListItem className="item fileItems" key={index}>
                  <img src={icon} className="file-icon" alt="img" />
                  <div className="fileItemDetail">
                    <Typography className="fileName">
                      {file?.original_filename}
                    </Typography>
                    <Typography className="fileSize">{file?.size ?? ''}</Typography>
                  </div>
                  <div className="attachOverlay">
                    <img
                      src={deleteIcon}
                      alt="img"
                      onClick={() => removeFile(index)}
                    />
                  </div>
                </ListItem>
              );
            })}
          {isAttachmentLoading && (
            <div style={{ marginTop: "16px" }}>
              <CircularProgress disableShrink />
            </div>
          )}
        </List>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default AttachmentView;
