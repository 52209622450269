import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import defaultImg from "assets/img/default-avatar.png";
import HeaderSkeleton from "./skeletons/HeaderSkeleton";
import IconButton from '@mui/material/IconButton';
import calendarIcon from 'assets/img/icons/calendar-icon.svg';
import dollarIcon from 'assets/img/icons/dolar-icon.svg';
import InterviewScheduleDialog from "./InterviewScheduleDialog";
import OfferDialog from "./OfferDialog";
import * as Actions from '../../../store/actions/index';
import moment from "moment";
import { toast } from "react-toastify";
import { USER_TYPES } from "utils/constant";
import BackIcon from 'assets/img/icons/arrow-right.svg';
import { errorToast } from "utils/Common";
import { reset } from "redux-form";
import { readMessage } from "services/WebSocketService";
import { hasActiveSubscription } from "utils/constant";

function ChatHeader() {

    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.chatReducer?.currentUser);
    const isInitial = useSelector(state => state?.chatReducer?.initial);
    const isMobileView = useSelector(state => state.chatReducer?.isMobile);
    const contentLoading = useSelector(state => state.commonReducer?.contentLoading);
    const connection = useSelector(state => state.socketReducer?.connection);

    const handleBackToView = () => {
        window.scrollTo(0, 0);
        dispatch({ type: 'IS_MOBILE_VIEW', payload: { ...isMobileView, leftBar: "block", rightBar: "none" } });
    }
    const [interviewDialog, setInterviewDialog] = useState(false);
    const [offerDialog, setOfferDialog] = useState(false);
    const [candidateLoading, setCandidateLoading] = useState(false);
    const [jobpostLoading, setJobpostLoading] = useState(false);
    const jobPosts = useSelector(state => state.jobPostReducer?.jobPostList);
    const candidates = useSelector(state => state.jobPostReducer?.processedCandidatesList);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    // const useState[submitProcessing, setSubmitProcessing] = useState(false);


    const handleInterviewDialog = () => {
        
            !interviewDialog && getJobPosts() || dispatch({ type: "GET_JOB_POST_LIST", payload: [] });
            setInterviewDialog(!interviewDialog);
      
    }

    const handleOfferDialog = () => {
        
            !offerDialog && getJobPosts() || dispatch({ type: "GET_JOB_POST_LIST", payload: [] });
            setOfferDialog(!offerDialog);
       
    }
    const handleInterviewSchedule = (values) => {
        // return new Promise(resolve => setTimeout(resolve, 5000));
        const postData = {
            job_post: values?.job_post?.id,
            candidate: currentUser?.opponent?.user_type === USER_TYPES.FREELANCER ? currentUser.opponent.id : values?.candidate?.id,
            date: values?.date,
            time: moment(values?.time).local().format("HH:mm:ss"),
            description: values?.description,
            meet_link: values?.link,
            timezone: moment.tz.guess(),
        }
        return dispatch(Actions.scheduleAnInterview(postData)).then(response => {
            if (response.status === 201) {
                handleInterviewDialog();
                setTimeout(() => {
                    dispatch(reset('InterviewScheduleForm'));
                }, 500);
                toast.success('Interview scheduled successfully');
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error.message);
        })
    }
    const handleOffer = (values) => {
        const postData = {
            job_post: values?.job_post?.id,
            candidate: currentUser?.opponent?.user_type === USER_TYPES.FREELANCER ? currentUser.opponent.id : values?.candidate?.id,
            salary_type: values?.salary_type,
            amount: values?.amount,
            chat_room: currentUser?.room,
        }

        return dispatch(Actions.submitOffer(postData)).then(response => {
            if (response.status === 201) {
                handleOfferDialog();
                setTimeout(() => {
                    dispatch(reset('OfferForm'));
                }, 500);
                toast.success('Offer has been sent successfully');
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error.message);
        })
    }

    const getJobPosts = async () => {
        setJobpostLoading(true);
        await dispatch(Actions.getJobPostForInterviewAndOffer(1, 1, 'all', currentUser?.opponent?.id));
        setJobpostLoading(false);
    }
    const getCandidates = async (jobPostId) => {
        await dispatch(Actions.getDeveloperForJobPostProcess(jobPostId, currentUser?.opponent?.id));
        setCandidateLoading(false);
    }

    const handleJobPostChange = async (jobPostId) => {
        setCandidateLoading(true);
        if (jobPostId) {
            getCandidates(jobPostId)
        } else {
            await dispatch({ type: "PROCESSED_CANDIDATES_LIST", payload: [] });
            setCandidateLoading(false);
        }
    }

    const handleReadMessage = () => {
        if (currentUser?.unread_count > 0 && (!isInitial && !contentLoading)) {
            let read = readMessage(connection, userDetails?.id, currentUser?.room);
            if (read) {
                dispatch(Actions.getContactList(''));
            }
        }
    }

    return (
        <Grid className='chat-header' onClick={() => handleReadMessage()}>
            {(isInitial || contentLoading) ?
                <HeaderSkeleton />
                :
                <>
                    <Grid className="chat-profile chat-sub-header">
                        <div className="chat-back-btn" onClick={() => handleBackToView()}>
                            <img src={BackIcon} alt="" />
                        </div>
                        <div className="profile-img-container">
                            <img src={currentUser?.opponent?.profile_image || defaultImg}
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} alt="img" />
                            {currentUser?.opponent?.is_online &&
                                <span className='statusDot'></span>
                            }
                        </div>
                        <div className="profile-title">
                            <Typography className="id">{currentUser?.opponent?.company_name}</Typography>
                        </div>
                    </Grid>
                    {userDetails?.user_type === USER_TYPES.CLIENT &&
                        <Grid className="calender-right-btn">
                            <IconButton aria-label="delete" className='attach-Btn' disabled={!currentUser?.is_room_active||!hasActiveSubscription(userDetails)} onClick={handleInterviewDialog}>
                                <img src={calendarIcon} alt='img' />
                            </IconButton>
                            <IconButton aria-label="delete" className='attach-Btn' disabled={!currentUser?.is_room_active||!hasActiveSubscription(userDetails)} onClick={handleOfferDialog}>
                                <img src={dollarIcon} alt='img' />
                            </IconButton>
                        </Grid>
                    }
                    <InterviewScheduleDialog
                        open={interviewDialog}
                        close={handleInterviewDialog}
                        data={currentUser?.opponent}
                        onSubmit={values => handleInterviewSchedule(values)}
                        jobposts={jobPosts}
                        jobPostChange={handleJobPostChange}
                        candidates={candidates}
                        candidateLoading={candidateLoading}
                        jobpostLoading={jobpostLoading}
                    />

                    <OfferDialog
                        open={offerDialog}
                        close={handleOfferDialog}
                        data={currentUser?.opponent}
                        onSubmit={values => handleOffer(values)}
                        jobposts={jobPosts}
                        jobPostChange={handleJobPostChange}
                        candidates={candidates}
                        candidateLoading={candidateLoading}
                        jobpostLoading={jobpostLoading}
                    />
                </>
            }
        </Grid>
    );
}

export default ChatHeader;
