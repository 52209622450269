import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import history from "utils/history";
import * as Actions from "../../store/actions/index";
import List from "./component/List";
import DeveloperListSkeleton from "./Skeletons/DeveloperListSkeleton";

function ManageDevelopers() {
    const dispatch = useDispatch();
    const [pageLoading, setPageLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [page, setPage] = useState(1);
    const developers = useSelector(state => state.manageDevelopersReducer?.getDevelopersList?.results);
    const pageSize = 25;

    const getDevelopersList = (page = 1, pageSize = 25) => {
        dispatch(Actions.getDevelopersList(page, pageSize))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: "GET_DEVELOPERS_LIST", payload: response.data });
                    handlePageLoading();
                } else {
                    dispatch({ type: "GET_DEVELOPERS_LIST", payload: [] });
                }
            }).catch(() => {
                dispatch({ type: "GET_DEVELOPERS_LIST", payload: [] });
            });
    };

    const handlePageLoading = () => {
        pageLoading && setTimeout(() => {
            setPageLoading(false);
        }, 1000);

    }
    const handleDataLoading = () => {
        dataLoading && setTimeout(() => {
            setDataLoading(false);
        }, 800);
    }

    const handlePages = (pageNumber) => {
        if (page !== pageNumber) {
            setDataLoading(true);
            getDevelopersList(pageNumber, pageSize);
            setPage(pageNumber);
        }
    }

    const confirmDeleteDeveloper = (userId) => {
        dispatch(Actions.deleteDevelopers(userId))
            .then((response) => {
                if (response.status === 204) {
                    toast.success("Developer deleted successfully");
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
                getDevelopersList();
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    const agencyDeveloperUpdate = (userId, parama) => {
        dispatch(Actions.agencyDeveloperUpdate(userId, parama))
            .then((response) => {
                if (response.status === 200) {
                    let msg = "Developer deactivated successfully";
                    if (parama.is_active) msg = "Developer activated successfully";
                    toast.success(msg);
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
                getDevelopersList(page, pageSize);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    useEffect(() => {
        history.push({ state: null });
        dispatch({ type: 'GET_AGENCY_DEVELOPER_DETAILS', payload: null });
        dispatch({ type: 'GET_DEVELOPER_CURRENT_TAB', payload: 0 });
        getDevelopersList(1, pageSize);
    }, []);

    useEffect(() => {
        handleDataLoading();
    }, [developers])

    return (
        <>
            {pageLoading ?
                <DeveloperListSkeleton />
                :
                <List
                    confirmDeleteDeveloper={confirmDeleteDeveloper}
                    developerStatusChange={(userId, parama) =>
                        agencyDeveloperUpdate(userId, parama)
                    }
                    pageSize={pageSize}
                    currentPage={page}
                    handlePages={handlePages}
                    loading={dataLoading}
                />
            }
        </>
    );
}
export default ManageDevelopers;
