import React, { useState } from 'react';

import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SpliceList } from 'components/SpliceList/SpliceList';
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess';
import ProjectDetailsDialog from 'components/Dialog/ProjectDetailsDialog';
import "assets/css/client-profile.css";
import linkIcon from "assets/img/icons/link-icon.svg";
import arrowIcon from "assets/img/icons/arrow-right.svg";
import noProjectImage from "assets/img/icons/no-projects-icon.svg";
import PaginationRounded from 'components/Pagination/PaginationRounded';
import CandidateProjectSkeleton from '../Skeletons/CandidateProjectSkeleton';

const CandidateProjectsSection = (props) => {
    const { projects, totalPages, currentPage, handlePages, loading } = props;
    const [openDetailsDialogue, setOpenDetailsDialogue] = useState(false);
    const [singleProjectData, setSingleProjectData] = useState(null);

    const handleOpenDetailsDialogue = (project = null) => {
        setOpenDetailsDialogue(!openDetailsDialogue)
        if (project?.id) setSingleProjectData(project);
    }

    return (
        <div className="projects-section">
            <Container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="title-section">
                        <Typography className="candidate-profile-title">Projects</Typography>
                    </div>
                    {loading ?
                        <CandidateProjectSkeleton /> :
                        <>
                            <Grid container className="projectsList candidateprofile-projects" >
                                {
                                    (projects && projects.length > 0)
                                        ?
                                        projects.map(project => (
                                            <Grid item className="projectListItem" key={project?.id}>
                                                <Card className="project-card">
                                                    <div className="project-card-inner">
                                                        <CardContent>
                                                            <Grid className="project-top">
                                                                <div className="icon-container">
                                                                    <Typography>{project?.title?.substring(0, 2)?.toUpperCase()}</Typography>
                                                                </div>
                                                                <div className="project-title">
                                                                    <p className="name">{project?.title}</p>
                                                                </div>
                                                                {project?.link &&
                                                                    <a href={project?.link} target="_blank">  {/*  eslint-disable-line */}
                                                                        <img src={linkIcon} alt="img" />
                                                                    </a>
                                                                }
                                                                <Grid className="duration-details">
                                                                    <Typography>
                                                                        {(project?.duration > 0) &&
                                                                            <>
                                                                                <span>{project?.duration} Months</span>
                                                                                {project?.is_on_going && <span> - Ongoing</span>}
                                                                            </>
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className="about_project">
                                                                <Typography>
                                                                    <ReadMoreReadLess
                                                                        charLimit={500}
                                                                        readMoreText={"Read More"}
                                                                        readLessText={"Read Less"}
                                                                        readMoreClassName="read-more-open-39A1FF"
                                                                        readLessClassName="read-more-close-39A1FF"
                                                                        data={project?.description || ""} /*  eslint-disable-line */
                                                                        textType={true}
                                                                    />
                                                                </Typography>
                                                            </Grid>
                                                            <p className="CategoryTitle portfolioTabSkill">Used Technologies</p>
                                                            <Grid className='skillset'>
                                                                <List className='skillList'>
                                                                    <SpliceList data={project?.technologies} mainClass="skillList-item" subClass="more-skill"
                                                                        limit={5}
                                                                    />
                                                                </List>
                                                            </Grid>
                                                            <p className="CategoryTitle portfolioTabSkill">Used Skills</p>
                                                            <Grid className='skillset'>
                                                                <List className='skillList'>
                                                                    <SpliceList data={project?.skills} mainClass="skillList-item" subClass="more-skill"
                                                                        limit={5}
                                                                    />
                                                                </List>
                                                            </Grid>
                                                            <a href={void (0)} className="arrow-link cursor-pointer" onClick={() => handleOpenDetailsDialogue(project)}>
                                                                <img src={arrowIcon} alt="img" />
                                                            </a>
                                                        </CardContent>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        ))

                                        :
                                        <Grid className="project-card-empty">
                                            <div>
                                                <img src={noProjectImage} alt="img" />
                                                <Typography>No Projects!</Typography>
                                            </div>
                                        </Grid>
                                }
                            </Grid>
                            {projects && projects.length > 0 &&
                                (<PaginationRounded
                                    totalPages={totalPages}
                                    handlePages={(page) => handlePages(page, 'projects')}
                                    page={currentPage}
                                />)

                            }
                        </>
                    }
                </Grid>
            </Container>
            <ProjectDetailsDialog open={openDetailsDialogue} close={handleOpenDetailsDialogue} data={singleProjectData} />
        </div>
    );
}

export default CandidateProjectsSection;