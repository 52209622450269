import React, { useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import searchIcon from "assets/img/icons/search.svg";
import "assets/css/chat.css";
import { useState } from "react";

const Search = styled("div")(() => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: alpha("#6a8caa", 0.08),
  "&:hover": {
    backgroundColor: alpha("#FFF", 0.25),
  },
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "32px",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SearchBar(props) {
  const { filterData, pageData } = props;
  const [search, setSearch] = useState(pageData.search);
  const [time, setTime] = useState(null);

  const handleSearch = (searched) => {
    clearTimeout(time); setTime(setTimeout(() => {
      filterData(pageData.page, pageData.pageSize, searched, pageData.order, pageData.orderBy);
    }, 1000));
  }

  useEffect(() => { handleSearch(search) }, [search]);
  useEffect(() => { if (pageData.search != search) setSearch(pageData.search) }, [pageData]);

  return (
    <>
      <Search className="searchBar">
        <SearchIconWrapper>
          <img src={searchIcon} alt="img" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </Search>
    </>
  );
}
export default SearchBar;
