import Header from "components/Navbars/AdminNavbar";
import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link, Stack } from "@mui/material";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import Footer from "components/Footer/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptonPlanById } from "store/actions";
import { Button } from "react-bootstrap";
import { subscribe } from "store/actions";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import { PLAN_PATH } from "utils/constant";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import { LoaderContext } from "utils/LoaderContext";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import { updateSubscription } from "store/actions";

function MakePayment() {
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const { toggalLoader } = useContext(LoaderContext);
  const breadCrumbs = [{ name: "home", path: "/home" }];
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const history = useHistory();
  const planId = history.location.state.planId;
  const Type = history.location.state.type;
  const [planDetail, setPlanDetail] = useState();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [actionDialogue, setActionDialogue] = React.useState({
    open: false,
    title: "Are you sure ?",
    message: "Once you upgrade to this plan, the data from your current plan will be removed, and you will only be able to use the data from the upgraded plan. This action cannot be undone.",
  });

  React.useEffect(() => {
    dispatch(getSubscriptonPlanById(planId))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data?.results ?? response.data;
          setPlanDetail(data);
        } else {
          setPlanDetail([]);
        }
      })
      .catch(() => {
        setPlanDetail([]);
      });
  }, [planId]);

  const handlePayment = async () => {
    toggalLoader(true)
    setPaymentLoading(true);
    const makeSubscribe = await dispatch(subscribe(planId)); //  Create order on your backend
    setPaymentLoading(false);
    if (makeSubscribe.status == 201) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        subscription_id: makeSubscribe?.data?.payment_subscription_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function () {
          toast.info("Your payment is under process. You will get notification on your mail for payment status.");
          dispatch(updateSubscription(makeSubscribe?.data?.id))
          history.push("/my-profile");
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        
      };
      
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response?.error?.description);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzp1.open();
      toggalLoader(false)
     
    } else {
      setPaymentLoading(false);
      toggalLoader(false)
      toast.error(makeSubscribe?.data?.non_field_errors[0]);
      history.push(
        PLAN_PATH[userDetails.user_type]
      );
    }
  };
  const handleClick = () => {
    if (Type == "upgrade") {
      setActionDialogue({ ...actionDialogue, open: true });
    } else {
      handlePayment();
    }
  };

  const closeActionDialogue = () => {
    setActionDialogue({ ...actionDialogue, open: false });
  };

  const UpgradeSelectedSubscription = () => {
    closeActionDialogue();
    handlePayment();
  };
  return (
    <>
      <div>
        <Header />
        <div className="makepayment-page updated-section">
          <section className="privacy-policy-section makepayment-banner">
            <div className="privacy-policy-inner">
              <Container>
                <Grid container>
                  <Grid item lg={6}>
                    <Stack spacing={2} className="breadcrumb-div">
                      <CustomBreadcrumbs
                        current="Plan"
                        previous={breadCrumbs}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid className="banner-header">
                  <Typography variant="h2">Make Payment</Typography>
                </Grid>
              </Container>
            </div>
          </section>
          {planDetail && (
            <section className="makepayment-selection-section mp-updated-section">
              <div className="makepayment-selection-inner">
                <Container className="mp-container">
                  <Grid container justifyContent={"space-between"}>
                    <Grid item lg={6} md={6} xs={12} className="equal-col">
                      <div className="select-plan-col">
                        <div className="select-plan-col-top">
                          <div className="heading">
                            <Typography variant="h3">Selected Plan</Typography>
                            <Typography variant="h2">
                              {planDetail?.name}
                            </Typography>
                            <p>
                            <ReadMoreReadLess
                                charLimit={30}
                                readMoreText={"Read More"}
                                readLessText={"Read Less"}
                                readMoreClassName="read-more-open-39A1FF"
                                readLessClassName="read-more-close-39A1FF"
                                data={planDetail?.description || ""}
                                textType={false}
                              /> </p>
                          </div>
                          <div
                            className="new-ul plan-list"
                            dangerouslySetInnerHTML={{
                              __html: planDetail?.plan_details,
                            }}
                          ></div>
                        </div>
                        {/* <div className="new-ul plan-list">
                          <ul>
                            <li>Real-time contact syncing</li>
                            <li>Automatic data enrichment</li>
                            <li>Up to 3 seats</li>
                            <li>Up to 3 seats</li>
                          </ul>
                        </div> */}

                        <div className="plan-price">
                          <Typography variant="h3">
                            {planDetail?.currency?.sign}{" "}
                            {parseFloat(planDetail?.amount)?.toFixed(2)?.replace(/\.?0+$/, "")}
                          </Typography>
                          <Typography variant="h4">
                            / {planDetail?.recurring?.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <div className="summary-col">
                        <div className="card-body">
                          <Card
                            sx={{ maxWidth: 368 }}
                            className="card-body-inner"
                          >
                            <CardContent className="card-cont">
                              <div className="card-cont-top-wrp">
                                <div className="card-cont-top">
                                  <Typography variant="h3">Summary</Typography>
                                  {/* <div
                                  className="new-ul"
                                  dangerouslySetInnerHTML={{
                                    __html: planDetail.plan_details,
                                  }}
                                ></div> */}
                                </div>
                                <div className="price-wrp">
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    className="price-item"
                                  >
                                    <Grid item lg={9}>
                                      <Typography variant="h6">
                                        Price
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                      <Typography variant="span">
                                        {planDetail?.currency?.sign}
                                        {parseFloat(planDetail?.amount)?.toFixed(2)?.replace(/\.?0+$/, "")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    className="price-item"
                                  >
                                    <Grid item lg={9}>
                                      <Typography variant="h6">Tax</Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                      <Typography variant="span">
                                        {planDetail?.currency?.sign}
                                         {parseFloat(planDetail?.tax_amount)?.toFixed(2)?.replace(/\.?0+$/, "")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className="total-amt">
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    className="price-item"
                                  >
                                    <Grid item lg={9}>
                                      <Typography variant="h6">
                                        Total Amount
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                      <Typography variant="span">
                                        {planDetail?.currency?.sign}
                                        {parseFloat(planDetail?.total_amount)?.toFixed(2)?.replace(/\.?0+$/, "")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className="pay-btn-wrp">
                                <Link
                                  className="cancel-btn"
                                  onClick={() => {
                                    history.push(
                                      PLAN_PATH[userDetails.user_type]
                                    );
                                  }}
                                >
                                  Cancel
                                </Link>
                                <Button
                                  className="pay-btn"
                                  disabled={paymentLoading}
                                  onClick={handleClick}
                                >
                                  {Type == "upgrade"
                                    ? "Upgrade Now"
                                    : "Pay Now"}
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </section>
          )}
        </div>
        <Footer />
      </div>
      <ConfirmationDialogue
        open={actionDialogue.open}
        close={closeActionDialogue}
        title={actionDialogue.title}
        message={actionDialogue.message}
        buttonText="Yes"
        buttonCallback={() => UpgradeSelectedSubscription()}
      />
    </>
  );
}

export default MakePayment;
