import React, { useState, useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import deleteIcon from "assets/img/icons/delete-icon.svg";

import "assets/css/profile.css";
import "assets/css/allModals.css";
import plusIcon from "assets/img/icons/plus-icon.svg";
import UpdateProjectDialog from "components/Dialog/UpdateProjectDialog";
import ProjectForm from "./ProjectForm";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/index';
import { submit, isValid, isInvalid, getFormValues } from "redux-form";
import { useHistory } from "react-router-dom";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { checkForImageValidations } from "utils/Common";


//// STARTS HERE ////
function EditProject(props) {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const user = useSelector(state => state.authReducer?.userProfile);
    const allProjects = useSelector(state => state.freelancerReducer?.projectList);
    const remoteData = useSelector(state => state.freelancerReducer?.remoteData);
    const technologies = useSelector(state => state.commonReducer?.technologies);
    const skills = useSelector(state => state.commonReducer?.skillsWithoutDepth);
    const history = useHistory();
    const projectId = history.location.state?.projectId ?? null;
    const { toggalLoader } = useContext(LoaderContext);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: '/my-profile' },
    ];
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleConfirmRemove = async () => {
        setOpenDeleteDialog(false);
        const index = remoteData.currentForm;
        const projectData = allProjects.filter((elem, ind) => ind !== index);
        const formData = await { ...remoteData, update_data: remoteData.update_data.filter((elem, ind) => ind !== index) };
        formData.currentForm = 0;

        dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        dispatch({ type: "GET_PROJECT", payload: projectData });
    }
    const getProjectList = () => {
        dispatch(Actions.getProjects(1, 'all'));
        dispatch(Actions.getTechnologies(''));
        dispatch(Actions.getSkillsWithoutDepth());
    }
    const handleFormChange = async (index = null) => {
        await dispatch(submit('ProjectForm'));
        if (props.valid) {
            if (index === null) {
                const keys = Object.keys(allProjects)
                index = (keys.length > 0) ? Math.max(...new Set(keys)) + 1 : 0;
                const projectData = [...allProjects];
                projectData[remoteData.currentForm] = props.formData;
                projectData[index] = {};
                await dispatch({ type: "GET_PROJECT", payload: projectData });
            }
            const formData = { ...remoteData };
            formData.currentForm = index;
            if (allProjects[index]) {
                formData.update_data[index] = allProjects[index];
            } else {
                formData.update_data[index] = {};
            }
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
    }
    const handleSubmit = (values, index) => {
        if (props.valid) {
            const formData = { ...remoteData };
            formData.update_data[index] = values;
            const projectData = [...allProjects];
            projectData[index] = values;
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
            dispatch({ type: "GET_PROJECT", payload: projectData });
        }
    }
    const handleMultipleImageUpload = (event, formIndex) => {
        try {
            const imagesData = { ...remoteData };
            const projectData = [...allProjects];
            const tempArr = allProjects[formIndex]?.images ? [...allProjects[formIndex]?.images] : [];
            if (event.target.files.length !== 0) {
                [...event.target.files].forEach(async (file, index) => {
                    const fileCheck = await checkForImageValidations(file, 'image');
                    if (fileCheck instanceof Error) {
                        toast.error(fileCheck.message)
                    } else {
                        const formData = new FormData();
                        formData.append("file_obj", file);
                        dispatch(Actions.uploadImage(formData)).then(response => {
                            tempArr.push(response.data);
                            if (event.target.files.length === index + 1) {
                                imagesData.files[formIndex] = tempArr;
                                const pData = props.formData;
                                projectData[formIndex]?.images
                                    ? projectData[formIndex].images = tempArr
                                    : projectData[formIndex] = { ...pData, images: tempArr };
                                dispatch({ type: "GET_REMOTE_DATA", payload: imagesData });
                                dispatch({ type: "GET_PROJECT", payload: projectData });
                            }
                        }).catch(error => {
                            toast.error(error?.message);
                        });
                    }
                });
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }
    const removeImage = (formIndex, imageIndex) => {
        const formData = { ...remoteData };
        const projectData = [...allProjects];
        delete formData.files?.[formIndex]?.[imageIndex];
        projectData[formIndex].images.splice(imageIndex, 1);
        dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        dispatch({ type: "GET_PROJECT", payload: projectData });
    };
    const handleProjectOperation = async () => {
        toggalLoader(true);
        await dispatch(submit('ProjectForm'));
        if (remoteData.update_data.length > 0) {
            const filtered = remoteData.update_data.filter(value => value);
            const projectsData = filtered.map((values) => {
                if (values) {
                    return {
                        ...values,
                        technologies: values?.technologies?.map(val => val.id),
                        skills: values?.skills?.map(val => val.id),
                    };
                }
            });

            dispatch(Actions.addDetails('projects/', projectsData)).then(response => {
                if (response.status === 201) {
                    setOpenDialog(false);
                    toast.success('Project details updated successfully');
                    toggalLoader(false);
                    history.push('/my-profile');
                } else {
                    toggalLoader(false);
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
            }).catch(() => {
                toggalLoader(false);
                toast.error('Something went wrong');
            });
        }
    }
    const initialData = (project, index) => {
        const fData = { ...remoteData }
        if (remoteData && remoteData.update_data.length === 0) {
            fData.update_data[index] = project;
            dispatch({ type: "GET_REMOTE_DATA", payload: fData });
        }
    }

    useEffect(() => {
        if (remoteData?.currentForm > 0) {
            const formData = { ...remoteData, currentForm: 0 };
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
        getProjectList();
    }, []);
    useEffect(() => {
        if (allProjects?.length && projectId) {
            let data = allProjects.find(project => project.id === projectId);
            let index = allProjects.indexOf(data);
            let tempData = { ...remoteData, currentForm: index };
            dispatch({ type: "GET_REMOTE_DATA", payload: tempData });
        }

    }, [projectId]);
    useEffect(() => {
        if (allProjects && allProjects?.length === 0) {
            const initial = {};
            dispatch({ type: "GET_PROJECT", payload: [initial] });
        }
    }, [allProjects]);

    return (
        <>
            <Grid className="profile-top-section add_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-project" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className="clientId">
                                ID : <span>{user?.uid}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Edit Project</h4>
                <Grid className="profile-inner edit_project-inner">
                    <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={12} className="projects-sidebar">
                            <div className="sidebarTitlle-section">
                                <h5 className="title">Projects</h5>
                                <IconButton
                                    underline="none"
                                    className="addIconBtn cursor-pointer"
                                    onClick={() => handleFormChange()}
                                >
                                    <img src={plusIcon} alt="add" />
                                </IconButton>
                            </div>

                            <List>
                                {allProjects && allProjects.map((project, index) => {
                                    initialData(project, index);
                                    return (
                                        <ListItem
                                            className={(index === remoteData.currentForm) ? "projectsListItem active" : "projectsListItem"}
                                            key={index}
                                            onClick={() => handleFormChange(index)}
                                        >
                                            <Link href="#" underline="none">
                                                {project.title}
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={12} className="editProject-container">
                            <Grid className="edit-conatiner">
                                <div className="edit-container-header">
                                    <h5 className="title">{allProjects?.[remoteData.currentForm]?.title}</h5>
                                    {!allProjects?.[remoteData.currentForm]?.id && (allProjects?.length > 1) && <IconButton
                                        onClick={handleOpenDeleteDialog}
                                        aria-label="delete"
                                        size="medium"
                                        className="cursor-pointer"
                                    >
                                        <img
                                            src={deleteIcon}
                                            alt="img"
                                            style={{ filter: "brightness(10)" }}
                                        />
                                    </IconButton>}
                                </div>
                                <ProjectForm
                                    initialValues={allProjects?.[remoteData.currentForm]}
                                    onSubmit={(data) => handleSubmit(data, remoteData.currentForm)}
                                    formIndex={remoteData?.currentForm}
                                    technologies={technologies}
                                    skills={skills}
                                    uploadImage={handleMultipleImageUpload}
                                    filesData={allProjects?.[remoteData.currentForm]?.images} removeImage={removeImage}
                                ></ProjectForm>
                            </Grid>
                            <Grid className="action-div">
                                <Link onClick={() => history.push("/my-profile")} underline="none" className="previousBtn cursor-pointer">
                                    Cancel
                                </Link>
                                <Button variant="contained" className="primaryButton nextBtn"
                                    onClick={handleOpenDialog}
                                    disabled={props.invalid}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <UpdateProjectDialog
                open={openDialog}
                close={handleCloseDialog}
                handleUpdate={handleProjectOperation}
                message="Are you Sure you want to Update Projects?"
                description="If you want to Edit other Projects then Click Continue Button and if you want to Save All changes then Click Update Button." />
            <DeleteProjectDialog
                open={openDeleteDialog}
                close={handleCloseDeleteDialog}
                confirm={handleConfirmRemove}
                message="Are you Sure you want to Remove this Project?"
                description="This Action Can’t be Undo, When you delete all data of this Project it will be removed."
            />
        </>
    );
}
const EditProjectMap = connect(
    state => ({
        valid: isValid('ProjectForm')(state),
        invalid: isInvalid('ProjectForm')(state),
        formData: getFormValues('ProjectForm')(state)
    })
)(EditProject)
export default EditProjectMap;
