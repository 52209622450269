import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "store/actions/index";
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import history from "utils/history";
import ClientForm from './component/ClientForm'
import 'assets/css/profile.css';
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { checkForImageValidations } from "utils/Common";
import ImageCropDialog from "components/Dialog/ImageCropDialog";
import { dispatchPageLoading } from "utils/Common";

function ManageClientProfile(props) {
    const { openDialog } = props;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [countryList, setCountryList] = useState([]);
    const [initValues, setInitValues] = useState(null);
    const userDetails = useSelector(state => state.authReducer.userDetails)
    const [files, setFiles] = useState({ profile: null, identity: null });
    const [time, setTime] = useState("");
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });


    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'my-profile', path: userDetails.is_new_registered ? '' : '/my-profile' },
    ]

    useEffect(() => {
        userDetail();
        dispatchPageLoading(dispatch);
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true })
        }
    }, [])

    const handleRemoveCountry = () => {
        setCountryList([]);
    }
    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file instanceof File ? file : null });
    }

    const getCountryList = (name, keys) => {
        clearTimeout(time);
        setTime(
            setTimeout(() => {
                if (name.length > 2) {
                    dispatch(Actions.getCountryList(name)).then((response) => {
                        if (response.status === 200) {
                            let data = response.data?.results ?? response.data;
                            if (keys && keys.length > 0) {
                                data = data.filter(elem => !keys.includes(elem.key));
                            }
                            setCountryList(data);
                        } else {
                            setCountryList([]);
                        }
                    }).catch(() => {
                        setCountryList([]);
                    })
                }
            }, 1000)
        );
    };
    const userDetail = () => {
        dispatch(Actions.getUserProfile()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_PROFILE', payload: response.data });
                const userData = { ...response.data };
                // userData.contact_number = userData.contact_number_without_code;
                const filesObject = {
                    ...files,
                    profile: userData?.profile_image,
                    identity: userData?.company?.identity,
                }

                setFiles(filesObject);
                setInitValues(userData);
            } else {
                dispatch({ type: 'GET_USER_PROFILE', payload: null });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_PROFILE', payload: null });
        });
    }

    const submitUserProfile = (values) => {
        delete values.company.user;
        const addUserProfileData = {
            first_name: values.first_name,
            last_name: values.last_name,
            // contact_number: `+${values.country.phone}${values.contact_number}`,
            contact_number: values.contact_number,
            city: values.city.id,
            profile_image: files.profile,
            company: {
                name: values.company.name,
                registration_number: (values.company.registration_number) ? values.company.registration_number : null,
                size: values.company.size,
                is_registered_company: (values.company.is_registered_company) ? values.company.is_registered_company : false,
                company_url: values.company.company_url,
                identity: files.identity,
            },
            branches: values.branches.map((val) => ({ city: val.city.id, is_head_quarter: val.is_head_quarter }))
        };
        toggalLoader(true);
        if (typeof addUserProfileData.profile_image === 'string' || addUserProfileData.profile_image === null) delete addUserProfileData.profile_image
        if (typeof addUserProfileData.company.identity === 'string' || addUserProfileData.company.identity === null) delete addUserProfileData.company.identity
        dispatch(Actions.addUserProfile(addUserProfileData))
            .then((response) => {
                if (response.status === 201) {
                    if (userDetails?.is_new_registered) {
                        toast.success("User detail added successfully and send for the approval");
                    } else {
                        toast.success("User detail updated successfully");
                    }
                    history.push("/my-profile");
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    })
                }
                toggalLoader(false);
            })
            .catch(() => {
                toggalLoader(false);
                toast.error('Please try again later!');
            });

    };

    const handleImageUpload = async (e) => {
        try {
            if (e instanceof File) {
                const fileCheck = await checkForImageValidations(e, 'file', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", e);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        if (response.status === 201) {
                            const filesObject = { ...files }
                            filesObject.identity = response.data;
                            setFiles(filesObject);
                        } else {
                            toast.error('Something went wrong... ');
                        }
                    }).catch(() => {
                        setFiles({ ...files, identity: null });
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong... ' + error.message);
        }
    };

    const hanldeProfileUplaod = async (event) => {
        try {
            if (event.target.files[0]) {
                const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message);
                    event.target.value = null;
                } else {
                    handleImgCropOpen(event.target.files[0]);
                    event.target.value = null;
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
            event.target.value = null;
        }
    }

    const uploadCroppedImage = (file) => {
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(Actions.uploadImage(formData)).then(response => {
            handleImgCropOpen();
            if (response.status === 201) {
                const filesObject = { ...files }
                filesObject.profile = response.data;
                setFiles(filesObject);
            } else {
                toast.error('Something went wrong... ');
            }
        }).catch(() => {
            setFiles({ ...files, profile: null });
        });
    }

    return (
        <>
            <Grid className='profile-top-section add_profile-top-section custom-bread-crumb'>
                <Container>
                    <Grid container className='profile-top-inner'>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current={(userDetails.is_new_registered) ? "add-profile-details" : "edit-profile-details"} previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className='clientId'>ID : <span>{userDetails.uid}</span></p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <ClientForm
                onSubmit={(values) => {
                    submitUserProfile(values);
                }}
                countryList={countryList || []}
                initialValues={initValues}
                uploadImage={handleImageUpload}
                uploadProfile={hanldeProfileUplaod}
                files={files}
                getCountryList={getCountryList}
                removeList={handleRemoveCountry}
                openDialog={openDialog}
            />
            <ImageCropDialog open={cropHandlers.openDialog} close={handleImgCropOpen} file={cropHandlers.file} uploadCroppedImage={uploadCroppedImage} />
        </>
    );
}

export default ManageClientProfile;

