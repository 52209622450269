import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "assets/css/profile.css";
import "assets/css/allModals.css";
import editIcon from "assets/img/icons/edit-icon.svg";
import envelopeIcon from "assets/img/icons/envelope-dark.svg";
import phoneIcon from "assets/img/icons/phone-dark.svg";
import infoIcon from 'assets/img/icons/info-icon.svg';
import history from "utils/history";
import { useDispatch, useSelector } from "react-redux";
import { getDeveloperTabDetails } from "utils/constant";
import * as DeveloperActions from '../../store/actions/manageDevelopers/manageDevelopers.actions';
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import IconButton from '@mui/material/IconButton';
import { getAvailability, checkForImageValidations, findAmountPerType } from "utils/Common";
import ProfessionalDetailsTab from "./component/ProfessionalDetailsTab";
import ProjectDetailsTab from "./component/ProjectDetailsTab";
import CertificationDetailsTab from "./component/CertificationDetailsTab";
import { styled } from "@mui/material/styles";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import 'assets/css/projectCard.css';
import ImageCropDialog from "components/Dialog/ImageCropDialog";
import RejectedRequestDialog from "components/Dialog/RejectedRequestDialog";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import ProjectTabSkeleton from "./Skeletons/ProjectTabSkeleton";
import CertificateTabSkeleton from "./Skeletons/CertificateTabSkeleton";
import ProfilePageSkeleton from "./Skeletons/ProfilePageSkeleton";
import ProfessionalTabSkeleton from "./Skeletons/ProfessionalTabSkeleton";
import DynamicDialog from "components/Dialog/DynamicDialog";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#548ca8",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#CECECE" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

function DeveloperProfilePage(props) {
    const { match } = props
    const dispatch = useDispatch();
    const agencyDeveloperDetails = useSelector((state) => state.manageDevelopersReducer?.agencyDeveloperDetails);
    const currentTabValue = useSelector(state => state.manageDevelopersReducer?.currentTab);
    const [value, setValue] = useState(currentTabValue || 0);
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });
    const [requestRejectedDialog, setRequestRejectedDialog] = useState({ open: false, message: null });
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [unblockLoading, setUnblockLoading] = useState(false);
    const [unblockDialog, setUnblockDialog] = useState(false);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-developers', path: '/manage-developers' },
    ];

    const handleRejectedRequestDialog = (message = null) => {
        setRequestRejectedDialog({ open: !requestRejectedDialog?.open, message: message });
    }

    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file instanceof File ? file : null });
    }

    const uploadProfileImage = async (event) => {
        if (event.target.files.length > 0) {
            const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message);
                event.target.value = null;
            } else {
                handleImgCropOpen(event.target.files[0]);
                event.target.value = null;
            }
        }
    }

    const handleChange = useCallback((_event, newValue) => {
        dispatch({ type: "GET_DEVELOPER_CURRENT_TAB", payload: newValue });
    }, []);
    const getTabDetails = (currentTab) => {
        try {
            dispatch(DeveloperActions.getDeveloperDetails(currentTab.getUrl)).then(response => {
                if (response.status === 200) {
                    dispatch({ type: currentTab.type, payload: response.data?.results ?? response.data });
                    if (currentTab?.countType) dispatch({ type: currentTab.countType, payload: response.data?.count || 0 });
                    setTimeout(() => {
                        setLoading(false);
                    }, 800);

                } else {
                    dispatch({ type: currentTab.type, payload: null });
                    if (currentTab?.countType) dispatch({ type: currentTab.countType, payload: 0 });
                }
            }).catch(() => {
                dispatch({ type: currentTab.type, payload: null });
                if (currentTab?.countType) dispatch({ type: currentTab.countType, payload: 0 });
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const getAgencyDeveloperById = useCallback(async (developerId) => {
        dispatch(DeveloperActions.getAgencyDeveloperById(developerId));
    }, []);

    const uploadCroppedImage = (file) => {
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(DeveloperActions.uploadDeveloperProfileImage(agencyDeveloperDetails?.id, formData)).then(response => {
            if (response.status === 200) {
                getAgencyDeveloperById(agencyDeveloperDetails?.id);
            } else {
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                })
            }
            handleImgCropOpen();
        }).catch(() => {
            toast.error("Something went wrong");
            handleImgCropOpen();
        });
    }

    const agencyDeveloperUpdate = useCallback((event) => {
        dispatch(DeveloperActions.agencyDeveloperUpdate(agencyDeveloperDetails?.id, { is_active: event.target.checked })).then((response) => {
            if (response.status === 200) {
                let msg = "Developer deactivated successfully";
                if (!event.target.checked) msg = "Developer activated successfully";
                toast.success(msg);
            } else {
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                });
            }
            getAgencyDeveloperById(agencyDeveloperDetails?.id);
        }).catch(() => {
            toast.error('Something went wrong');
        });
    }, [agencyDeveloperDetails?.id]);

    const getMessageOnStatus = () => {
        if (agencyDeveloperDetails?.is_profile_in_review || agencyDeveloperDetails?.is_profile_image_in_review) {
            return (
                <Typography className='approval-tag'>
                    Your profile is under approval
                </Typography>
            )
        } else if (agencyDeveloperDetails?.is_blocked) {
            return (
                <Button
                    className="primaryButton nextBtn"
                    variant="contained"
                    onClick={openUnblockDialog}
                >
                    Unblock</Button >
            )
        } else {
            return (
                <FormControlLabel
                    control={
                        <IOSSwitch
                            sx={{ m: 1 }}
                            checked={agencyDeveloperDetails?.is_active}
                            onChange={agencyDeveloperUpdate}
                        />
                    }
                />
            )
        }
    }

    const openUnblockDialog = () => {
        setUnblockDialog(true);
    }
    const closeUnblockDialog = () => {
        setUnblockDialog(false);
    }

    const handleUnblockDeveloper = async () => {
        setUnblockLoading(true);
        await dispatch(DeveloperActions.agencyDeveloperUpdate(agencyDeveloperDetails?.id, { is_blocked: false }));
        await getAgencyDeveloperById(agencyDeveloperDetails?.id);
        setUnblockLoading(false);
        closeUnblockDialog();
    }

    useEffect(() => {
        if (match.params?.agencyDeveloperId) {
            getAgencyDeveloperById(match.params.agencyDeveloperId);
            setTimeout(() => {
                setPageLoading(false);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (agencyDeveloperDetails?.on_boarding_steps) {
            history.push('/manage-developers/add', { developerId: agencyDeveloperDetails?.id });
        }
    }, [agencyDeveloperDetails]);

    useEffect(() => {
        const currentTab = getDeveloperTabDetails(currentTabValue, agencyDeveloperDetails?.id);
        if (currentTab && agencyDeveloperDetails) {
            setLoading(true);
            getTabDetails(currentTab);
            setValue(currentTabValue);
        }
    }, [currentTabValue]);

    return (
        <>
            {pageLoading ?
                <ProfilePageSkeleton />
                :
                <>
                    <Grid className="profile-top-section freelancer_profile-top-section custom-bread-crumb">
                        <Container>
                            <Grid container className="profile-top-inner">
                                <Grid item lg={12} md={12} sm={12}>
                                    <CustomBreadcrumbs current="developer-profile" previous={breadCrumbs} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Container className="profile-container my_profile-container">
                        <Grid className="userprofile-detail-section">
                            <Grid className="userDetails">
                                <Grid className="userImage">
                                    <ProfilePicture userDetails={agencyDeveloperDetails} uploadProfileImage={uploadProfileImage} />
                                </Grid>
                                <Grid className="userInfo">
                                    <div>
                                        <h4>{`${agencyDeveloperDetails?.first_name} ${agencyDeveloperDetails?.last_name}`}</h4>
                                        <p className="clientId">
                                            ID : <span>{agencyDeveloperDetails?.uid}</span>
                                        </p>
                                    </div>
                                    {/* <img className='more_action' src={moreActionIcon} alt='img' /> */}
                                    <div className='userInfo-right-section'>
                                        {agencyDeveloperDetails?.last_user_request_status === 3 &&
                                            <Typography className='rejected-tag'>
                                                Your request has been rejected
                                                <img className="cursor-pointer" src={infoIcon} onClick={() => handleRejectedRequestDialog(agencyDeveloperDetails?.last_user_request_rejection_message)}></img>
                                            </Typography>
                                        }
                                        {
                                            getMessageOnStatus()
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid className="companyDetails experienceDetails">
                                <Grid className="formGrid">
                                    <label>Profile Type</label>
                                    <p>{agencyDeveloperDetails?.portfolio?.profile_type?.title}</p>
                                </Grid>
                                <Grid className="formGrid">
                                    <label>Experience</label>
                                    <p>{agencyDeveloperDetails?.portfolio?.experience}  Years</p>
                                </Grid>
                                <Grid className="formGrid">
                                    <label>Projects</label>
                                    <p>{agencyDeveloperDetails?.portfolio?.projects_count || 0}</p>
                                </Grid>
                                <Grid className="formGrid">
                                    <label>Availability</label>
                                    <p>{getAvailability(agencyDeveloperDetails?.portfolio?.availability)}</p>
                                </Grid>
                                <Grid className="formGrid">
                                    <label>Education</label>
                                    <p>{agencyDeveloperDetails?.portfolio?.education_details}</p>
                                </Grid>
                                <Grid className="formGrid">
                                    <label>Price</label>
                                    <p>{findAmountPerType(agencyDeveloperDetails?.portfolio?.amount, agencyDeveloperDetails?.portfolio?.salary_type)}</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Freelancer Profile Details */}
                        <Grid container className="freelancer_profile-details">
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid className="profile-inner my_profile-inner personalDetail-section">
                                    <Grid className="title">
                                        <Typography variant="h5">Personal Details</Typography>
                                        <IconButton className="edit-icon editBtn" onClick={() => history.push(`/developer-profile/${agencyDeveloperDetails?.id}/personal-details`)}>
                                            <img src={editIcon} alt="img" />
                                        </IconButton>
                                    </Grid>
                                    <Grid container className="formGrid">
                                        <FormControl variant="standard" className="formControl">
                                            <Avatar className="profile_avatar">
                                                <img src={phoneIcon} alt="img" />
                                            </Avatar>
                                            <Grid>
                                                <label>Contact Number</label>
                                                <p className="value">{agencyDeveloperDetails?.contact_number}</p>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid container className="formGrid">
                                        <FormControl variant="standard" className="formControl">
                                            <Avatar className="profile_avatar">
                                                <img src={envelopeIcon} alt="img" />
                                            </Avatar>
                                            <Grid>
                                                <label>Email</label>
                                                <p className="value">{agencyDeveloperDetails?.email}</p>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid container className="formGrid">
                                <FormControl variant="standard" className="formControl">
                                    <Avatar className="profile_avatar">
                                        <img src={locationIcon} alt="img" />
                                    </Avatar>
                                    <Grid>
                                        <label>Location</label>
                                        <p className="value">{agencyDeveloperDetails?.city?.display_name || "-"}</p>
                                    </Grid>
                                </FormControl>
                            </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Grid className="profile-inner my_profile-inner otherDetail-section">
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab
                                                label="Professional Details"
                                                {...a11yProps(0)}
                                                className="tab-title"
                                            />
                                            <Tab
                                                label="Projects"
                                                {...a11yProps(1)}
                                                className="tab-title"
                                            />
                                            <Tab
                                                label="Certificates"
                                                {...a11yProps(2)}
                                                className="tab-title"
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        {loading ?
                                            <ProfessionalTabSkeleton />
                                            :
                                            <ProfessionalDetailsTab developerDetails={agencyDeveloperDetails} />
                                        }
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="projects">
                                        {loading ?
                                            <ProjectTabSkeleton />
                                            :
                                            <ProjectDetailsTab
                                                getTabDetails={getTabDetails}
                                                currentTab={value}
                                                developerDetails={agencyDeveloperDetails}
                                            />
                                        }
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {loading ?
                                            <CertificateTabSkeleton />
                                            :
                                            <CertificationDetailsTab
                                                getTabDetails={getTabDetails}
                                                currentTab={value}
                                                developerDetails={agencyDeveloperDetails}
                                            />
                                        }
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <ImageCropDialog open={cropHandlers.openDialog} close={handleImgCropOpen} file={cropHandlers.file} uploadCroppedImage={uploadCroppedImage} />
                    {requestRejectedDialog?.open &&
                        <RejectedRequestDialog
                            open={requestRejectedDialog.open}
                            close={handleRejectedRequestDialog}
                            message={requestRejectedDialog.message}
                            userId={agencyDeveloperDetails?.id}
                            developer={true}
                        />
                    }
                    <DynamicDialog
                        open={unblockDialog}
                        close={closeUnblockDialog}
                        loading={unblockLoading}
                        title="Are you sure you want to Unblock your Profile?"
                        message="After unblocking this developer will be displayed to the clients"
                        cancelButton={true}
                        cancelButtonText="Cancel"
                        buttonText="Yes, Unblock"
                        // icon={phoneIcon}
                        dialogClass=""
                        contentAlign="left"
                        buttonCallback={handleUnblockDeveloper}
                    />
                </>
            }
        </>
    );
}

export default DeveloperProfilePage;
