import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import AccordionIcon from 'assets/img/accordion-icon.png'

function AccordionComponent(props) {
    const { expanded, handleChange, header, body } = props;
    return (
        <Accordion className="acordian-content"
            expanded={expanded === header}
            onChange={handleChange(header)}>
            <AccordionSummary
                expandIcon={<img src={AccordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordion-panel">
                <Typography variant="h2">
                    {header}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="faq-accordion-body">
                <Typography variant="body1">
                    {body}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionComponent