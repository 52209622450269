import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import 'assets/css/profile.css';
import 'assets/css/allModals.css';
import CustomInput from "components/CustomInput/CustomInput.js";
import { required, checkPhoneNumber } from 'utils/Validation';
import { Field, getFormValues, reduxForm } from "redux-form";
import { SelectAutocomplete } from "components/CustomeAutoComplete/AsynAutocomplete";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../../../store/actions/index";
import history from "utils/history";
import { avoidPasting, getCountryCode, getCountryAndPhone } from "utils/Common";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";
import { charOnly,email} from "utils/Validation";

function PersonalDetailForm(props) {

    const dispatch = useDispatch();
    const { countryList, handleSubmit, change, initialValues, pristine, formStates, getCountryList } = props;

    const [country, setCountry] = useState({});
    const [city, setCity] = useState([]);
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);

    const handleChangeCountry = (value) => {
        const phoneDetails = getCountryAndPhone(value, countryCode);
        setCountryCode(phoneDetails.countryCode);
        setPhoneNumber(phoneDetails.phone);
        change('city', '')
        setCity([]);
        setCountry(value);
        change('contact_number', '');
    };
    const handleChangeCity = (name) => {
        if (country?.id) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                if (name.length > 2) {
                    setLoading(true)
                    dispatch(Actions.getCityList({ country: country?.id, name: name }))
                        .then((response) => {
                            setLoading(false)
                            if (response.status === 200) {
                                setCity(response.data);
                            } else {
                                setCity([]);
                            }
                        }).catch(() => {
                            setCity([]);
                        })
                }
            }, 1000));
        }
    };
    const handleChangePhone = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }
    useEffect(() => {
        if (initialValues?.contact_number) {
            setCountryCode(getCountryCode(initialValues.contact_number));
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number_without_code', `+91`);
        }
    }, [initialValues])
    useEffect(() => {
        if (initialValues && initialValues.user_city) {
            setCity(initialValues.user_city);
        }

        if (initialValues && initialValues.country) {
            setCountry(initialValues.country);
        }
    }, [initialValues])
    return (
        <Container className='profile-container add_profile-container'>
            <h4>Edit Personal Details</h4>
            <Grid className='profile-inner add_profile-inner'>
                <Grid className='add_profile-form'>
                    <form onSubmit={handleSubmit} noValidate> <Grid item lg={12} md={12} sm={12} className="text-center">
                    </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="first_name"
                                    component={CustomInput}
                                    id="first_name"
                                    labelText="First Name*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[required,charOnly]}
                                    inputProps={{
                                        placeholder: "Enter first name",
                                        type: "text",
                                    }}
                                />
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="last_name"
                                    component={CustomInput}
                                    id="last_name"
                                    labelText="Last Name*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[required,charOnly]}
                                    inputProps={{
                                        placeholder: "Enter last name",
                                        type: "text",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="country"
                                    component={SelectAutocomplete}
                                    id="country"
                                    labelText="Country*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    options={countryList}
                                    displayKey="name"
                                    validate={[required]}
                                    onChange={(value) => handleChangeCountry(value)}
                                    // onInputChange={(value) => value}
                                    inputProps={{
                                        placeholder: "Search Country",
                                    }}
                                    onSearch={(e) => getCountryList(e.target.value)}
                                // onFocusEvent={(event) => getCountryList(event.target.value)}
                                />
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="city"
                                    id="city"
                                    labelText="City*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    displayKey="display_name"
                                    options={city?.length > 0 ? city : []}
                                    component={SelectAutocomplete}
                                    loading={loading}
                                    required
                                    validate={[required]}
                                    inputProps={{
                                        placeholder: "Search City",
                                    }}
                                    onSearch={(e) => handleChangeCity(e.target.value)}
                                    onKeyDown={avoidPasting}
                                    disabled={!formStates?.country}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="email"
                                    component={CustomInput}
                                    id="email"
                                    labelText="Email*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[required,email]}
                                    inputProps={{
                                        placeholder: "Enter email",
                                        type: "email",
                                        disabled: true,
                                    }}
                                />
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <Field
                                    name="contact_number"
                                    component={PhoneNumberField}
                                    id="contact_number"
                                    labelText="Contact Number*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[checkPhoneNumber]}
                                    inputProps={{
                                        placeholder: "Enter contact number",

                                    }}
                                    disableDropdown={true}
                                    phone={phoneNumber}
                                    countryCode={countryCode}
                                    handleChangePhone={handleChangePhone}
                                />
                            </Grid>
                        </Grid>
                        <Grid className='action-div'>
                            <Link onClick={() => history.push("/my-profile")} underline="none" className='previousBtn cursor-pointer'>Cancel</Link>
                            <Button variant="contained" disabled={pristine} type="submit" className='primaryButton nextBtn'>Save</Button >
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

const ReduxClientFormMapped = reduxForm({
    form: "PersonalDetailForm", // a unique identifier for this form
    enableReinitialize: false,
})(PersonalDetailForm);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues('PersonalDetailForm')(state) // here 'form' is the name you have given your redux form 
    }
}

export default connect(mapStateToProps)(ReduxClientFormMapped);
