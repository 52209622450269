import React from 'react';
import Grid from "@mui/material/Grid";
import { Field, getFormValues, reduxForm } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import DatePickerField from 'components/CustomDateRangePicker/DatePickerField';
import { required, checkUrl, charLimitWithTrim } from 'utils/Validation';
import CustomCertiUpload from "components/customUpload/CustomCertiUpload";
import { FILETYPE } from 'utils/constant';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';

function DeveloperCertificationForm(props) {
    const { handleSubmit, uploadImage, formIndex, formStates } = props;

    const handleCapture = (e) => {
        uploadImage(e, formIndex);
    }
    const removeValue = (event) => {
        event.target.value = '';
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="edit-container-content">
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="title"
                                id="title"
                                labelText="Certificate Title*"
                                className='inputField'
                                component={CustomInput}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                validate={[required]}
                                inputProps={{
                                    type: "text",
                                    placeholder: "Enter Certificate Title",
                                }}
                                normalize={charLimitWithTrim(50)}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Grid container className="formGrid datepicker-formGrid">
                                <Field
                                    name="certification_date"
                                    id="certification_date"
                                    labelText="Certification Date*"
                                    placeholder="Select Date"
                                    component={DatePickerField}
                                    inputFormat="YYYY-MM-DD"
                                    validate={[required]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                type="file"
                                name="certificate_file"
                                component={CustomCertiUpload}
                                id=" certificate_file"
                                validate={!formStates?.certification_link ? required : null}
                                onChange={handleCapture}
                                mimeType={FILETYPE.certificate_file_type}
                                label="Upload Certificate"
                                onClick={removeValue}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: '24px', width: '100%', textAlign: 'center' }}>
                        <Typography>or</Typography>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="certification_link"
                                id="ccertification_link"
                                labelText="Certificate Link"
                                className='inputField'
                                component={CustomInput}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                validate={!formStates?.certificate_file ? [required, checkUrl] : checkUrl}
                                inputProps={{
                                    type: "text",
                                    placeholder: "Enter Link",
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </form>
        </>
    )
}

const ReduxFormMapped = reduxForm({
    form: 'DeveloperCertificationForm', // a unique identifier for this form
    enableReinitialize: true,
})(DeveloperCertificationForm);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues('DeveloperCertificationForm')(state) // here 'form' is the name you have given your redux form 
    }
}

export default connect(mapStateToProps)(ReduxFormMapped);