import React, { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Field, getFormValues, reduxForm } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import { required, checkUrl, charLimitWithTrim, characterLimit, checkZeroMonth } from 'utils/Validation';
import ChipAutocomplete from 'components/CustomeAutoComplete/ChipAutocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import { FILETYPE } from 'utils/constant';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import CustomCheckBox from 'components/CustomCheckBox/CustomCheckBox';
import InputAdornment from '@mui/material/InputAdornment';
import { avoidKeysForNumber } from 'utils/Common';

const DeveloperProjectForm = (props) => {
    const { handleSubmit, uploadImage, filesData, removeImage, technologies, skills, formIndex, imageLoaders } = props;

    const inputFile = useRef(null)

    const handleCapture = (e) => {
        uploadImage(e, formIndex);
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="edit-container-content">
                <Grid container className='formGrid'>
                    <Grid className='formGrid-inner'>
                        <span></span>
                        <Field
                            name="title"
                            id="title"
                            labelText="Project Name*"
                            className='inputField'
                            component={CustomInput}
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[required]}
                            inputProps={{
                                type: "text",
                                placeholder: "Project Name",
                            }}
                            normalize={charLimitWithTrim(50)}
                        />
                    </Grid>

                    <Grid className='formGrid-inner'>
                        <Grid container className='formGrid datepicker-formGrid'>
                            <Grid className='project-duretion-month'>
                                <Field
                                    name="duration"
                                    component={CustomInput}
                                    id="duration"
                                    labelText="Project Duration*"
                                    formControlProps={{
                                        fullWidth: true
                                    }}

                                    validate={[required, checkZeroMonth]}
                                    inputProps={{
                                        placeholder: "Enter number",
                                        type: 'number',
                                        endAdornment: (
                                            <InputAdornment position="end" className="month-duration" >
                                                Months
                                            </InputAdornment>
                                        )
                                    }}
                                    onKeyDown={avoidKeysForNumber}
                                    normalize={characterLimit(2)}
                                />
                                <Field
                                    id="is_on_going"
                                    name="is_on_going"
                                    component={CustomCheckBox}
                                    labelText="On going"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container className='formGrid'>
                    <Field
                        name="technologies"
                        component={ChipAutocomplete}
                        id="technologies"
                        labelText="Used Technology"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        options={technologies || []}
                        displayKey="title"
                        // validate={required}
                        onChange={(e) => e}
                        inputProps={{
                            placeholder: "Select Used Technologies",
                            type: "text",
                        }}
                    />
                </Grid>
                <Grid container className='formGrid'>
                    <Field
                        name="skills"
                        component={ChipAutocomplete}
                        id="skills"
                        labelText="Used Skills"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        options={skills || []}
                        displayKey="title"
                        // validate={required}
                        onChange={(e) => e}
                        inputProps={{
                            placeholder: "Select Used Skills",
                            type: "text",
                        }}
                    />
                </Grid>
                <Grid container className='formGrid'>
                    <Field
                        name="description"
                        component={CustomTextArea}
                        id="description"
                        labelText="About"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        multiline={true}
                        rows="2"
                        inputProps={{
                            placeholder: "Enter Text",
                        }}
                    />
                </Grid>
                <Grid className='add-image-container'>
                    <p className='label'>Images</p>
                    <List className='add-imageList'>
                        {filesData && Array.isArray(filesData) && filesData.map((file, index) => (
                            <ListItem className="item" key={index}>
                                <img src={file?.url} alt="img" />
                                <div className='imageOverlay'>
                                    <img onClick={() => { removeImage(formIndex, index); }} src={deleteIcon} alt='img' />
                                </div>
                            </ListItem>
                        ))}
                        {(imageLoaders && imageLoaders?.length > 0 && imageLoaders?.[formIndex]) &&
                            <ListItem className="item" >
                                <div style={{ justifyContent: 'center' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </ListItem>
                        }
                        <ListItem className="add-imageBox">
                            <Button onClick={() => {
                                inputFile.current.value = null;
                                inputFile.current.click();
                            }} >
                                <input ref={inputFile} accept={FILETYPE.image} type="file" multiple onChange={handleCapture} style={{ 'display': 'none' }} />
                                +<br />
                                Add Image
                            </Button>
                        </ListItem>
                    </List>
                </Grid>
                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Field
                                name="link"
                                id="link"
                                labelText="Project Link"
                                className='inputField'
                                component={CustomInput}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                validate={[checkUrl]}
                                inputProps={{
                                    type: "text",
                                    placeholder: "Enter Link",
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </form >
    );
}
const ReduxFormMapped = reduxForm({
    form: 'DeveloperProjectForm', // a unique identifier for this form
    enableReinitialize: true,
})(DeveloperProjectForm);

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues('DeveloperProjectForm')(state),
    }
};

export default connect(mapStateToProps)(ReduxFormMapped);