import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import CandidateProjectSkeleton from './CandidateProjectSkeleton';

const CandidateProfileSkeleton = () => {
    return (
        <>
            <Container>
                <Grid container className="profile-top-inner">
                    <Grid item lg={12} md={12} sm={12}>
                        <Skeleton className='transform-scale-1' width={180} height={20} />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container className="dashboard-container candidate-profile">
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid className="left-profile-container">
                            <div className="profile-details">
                                <Typography variant="h5" className="profile-id">
                                    <Skeleton width={170} height={57} />
                                </Typography>
                                <Typography className="profile-position">
                                    <Skeleton className='transform-scale-1' width={140} height={24} />
                                </Typography>
                            </div>
                            <div className="profile-subDetails">
                                <Typography>
                                    <span><Skeleton className='transform-scale-1' width={140} height={20} /></span>
                                    <br></br>
                                    <Skeleton className='transform-scale-1' width={140} height={20} />
                                </Typography>
                            </div>
                            <div className="profile-subDetails">
                                <Typography>
                                    <span><Skeleton className='transform-scale-1' width={140} height={20} /></span>
                                    <br></br>
                                    <Skeleton className='transform-scale-1' width={140} height={20} />
                                </Typography>
                            </div>
                            <div className="profile-subDetails">
                                <Typography>
                                    <span><Skeleton className='transform-scale-1' width={140} height={20} /></span>
                                    <br></br>
                                    <Skeleton className='transform-scale-1' width={140} height={20} />
                                </Typography>
                            </div>
                            <div className="profile-subDetails">
                                <Typography>
                                    <span><Skeleton className='transform-scale-1' width={140} height={20} /></span>
                                    <br></br>
                                    <Skeleton className='transform-scale-1' width={140} height={20} />
                                </Typography>
                            </div>
                            <Divider />
                            <Grid className="lang-section candidate-lang-section">
                                <Skeleton className='transform-scale-1' width={140} height={20} />
                                <div className="language-section">
                                    <div className="language-div">
                                        <Typography className="langEng-slider">
                                            <Skeleton width={50} height={20} />
                                        </Typography>
                                        <Typography className="lang-status">
                                            <Skeleton width={50} height={20} />
                                        </Typography>
                                        <Skeleton width='100%' height={20} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Grid className="right-profile-container">
                            <Grid className="profile-aboutContent">
                                <Typography className='m-b-12'>
                                    <Skeleton width={100} height={24} />
                                    <Skeleton width={56} height={10} />
                                </Typography>
                                <Typography className="candidate-profile-description">
                                    <Skeleton className='transform-scale-1' width='100%' height={120} />
                                </Typography>
                            </Grid>

                            <Grid className="specialties-section">
                                <Typography>
                                    <Skeleton width={100} height={24} />
                                </Typography>
                                <Skeleton width={56} height={10} />
                                <Typography className="candidate-profile-description">
                                    <Skeleton width={100} height={24} />
                                </Typography>
                            </Grid>

                            <Grid className="skills-section">
                                <Typography >
                                    <Skeleton width={100} height={24} />
                                    <Skeleton width={56} height={10} />
                                </Typography>
                                <Grid>
                                    <List className='categoryList'>
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                    </List>
                                </Grid>
                            </Grid>

                            <Grid className="skills-section">
                                <Typography >
                                    <Skeleton width={100} height={24} />
                                    <Skeleton width={56} height={10} />
                                </Typography>
                                <Grid>
                                    <List className='categoryList'>
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                    </List>
                                </Grid>
                            </Grid>

                            <Grid className="skills-section">
                                <Typography >
                                    <Skeleton width={100} height={24} />
                                    <Skeleton width={56} height={10} />
                                </Typography>
                                <Grid>
                                    <List className='categoryList'>
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                        <Skeleton className='categoryList-item bg-dark' width={100} height={45} />
                                    </List>
                                </Grid>
                            </Grid>

                            <Grid className="certificates-section">
                                <div className="title-section">
                                    <Typography className='m-b-12'>
                                        <Skeleton width={100} height={24} />
                                        <Skeleton width={56} height={10} />
                                    </Typography>
                                </div>
                                <Grid container>
                                    <Grid item lg={4} md={4}>
                                        <Skeleton className='transform-scale-1' width={230} height={235} />
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                        <Skeleton className='transform-scale-1' width={230} height={235} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div className="projects-section">
                                <Container>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div className="title-section">
                                            <Typography className='m-b-12'>
                                                <Skeleton width={100} height={45} />
                                                <Skeleton width={56} height={10} />
                                            </Typography>
                                        </div>
                                        <CandidateProjectSkeleton />
                                    </Grid>
                                </Container>
                            </div>
                        </Grid>
                    </Grid>
                </Grid >
            </Container >
        </>
    );
}

export default CandidateProfileSkeleton;