import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import CancleBtnRed from 'assets/img/icons/close-icon-red.svg';
import CancleBtn from 'assets/img/icons/close-icon-green.svg';
import ChatBtn from 'assets/img/icons/chat-icon-grey.svg';
import ChatBtnBlue from 'assets/img/icons/Vector.svg';
import { TablePagination } from '@mui/material';
import { JOBPOST_CANDIDATE_STATUS } from 'utils/constant';
import history from 'utils/history';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmationDialogue from 'components/Dialog/ConfirmationDialogue';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useSelector } from 'react-redux';
import { hasActiveSubscription } from 'utils/constant';

const headCells = [
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Job Post Title',
        ordering: true,
    },
    {
        id: 'client_uid',
        numeric: true,
        disablePadding: false,
        label: 'Client Id',
        ordering: true,
    },
    {
        id: 'client_country',
        numeric: false,
        disablePadding: false,
        label: 'Country',
        ordering: true,
    },
    {
        id: 'interview_date',
        numeric: true,
        disablePadding: false,
        label: 'Scheduled on',
        ordering: true,
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: '',
        ordering: false,
    },
];

export default function ActiveTableFreelancer(props) {
    const { data, action, filterData, pageData, loading, processing } = props;
    const pageOrderBy = pageData?.orderBy?.replace("-", "");
    const [dense,] = useState(false);
    const [hoveredId, setHoveredId] = useState(null)
    const userDetails = useSelector((state) => state?.authReducer?.userDetails);
    const candidateStatuses = JOBPOST_CANDIDATE_STATUS;
    const [actionDialogue, setActionDialogue] = useState({ open: false, title: 'Are you sure ?', message: "" })
    const [actionData, setActionData] = useState({ status: null, requestId: null, candidateUid: null });

    const openActionDialogue = (message = "", status = null, requestId = null, candidateUid) => {
        setActionData({ status, requestId, candidateUid });
        const dialogueData = { ...actionDialogue, open: !actionDialogue.open, message };
        setActionDialogue(dialogueData);
    }
    const closeActionDialogue = () => {
        setActionDialogue({ ...actionDialogue, open: false });
    }

    const handleRequestSort = (_, property) => {
        const isAsc = pageOrderBy === property ? (pageData.order === 'desc' ? true : false) : true;
        const order = isAsc ? 'asc' : 'desc'
        const orderBy = isAsc ? property : `-${property}`;
        filterData(pageData.page, pageData.pageSize, pageData.search, order, orderBy);
    };

    const handleChangePage = (_, newPage) => {
        filterData(newPage + 1, pageData.pageSize, pageData.search)
    };

    const handleChangeRowsPerPage = (event) => {
        filterData(1, parseInt(event.target.value, 10), pageData.search)
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleHover = (rowId = null) => {
        setHoveredId(rowId)
    }

    const handleRedirect = (id) => {
        history.push(`/my-proposals/${id}`)
    }

    useEffect(() => {
        if (!processing && actionDialogue?.open) closeActionDialogue();
    }, [processing]);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 0 }} id="freelancer-active-tab">
                <TableContainer>
                    <Table className='table-center-content' aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow >
                                {headCells.map((headCell) => (
                                    <TableCell key={headCell.id} align='left' padding='normal'
                                        sortDirection={pageOrderBy === headCell.id ? pageData.order : false}>
                                        {headCell.ordering ?
                                            <TableSortLabel
                                                active={pageOrderBy === headCell.id}
                                                direction={pageOrderBy === headCell.id ? pageData.order : 'asc'}
                                                onClick={createSortHandler(headCell.id)} >
                                                {headCell.label}
                                                {pageOrderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {pageData.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel> :
                                            headCell.label
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading ?
                            <div className='custom-search-loader'>
                                <CircularProgress className='custom-circle-loder' />
                            </div>
                            :
                            <TableBody>
                                {(data?.results && data?.results?.length > 0) ? data?.results?.map((row, index) => {
                                    return (
                                        <TableRow tabIndex={-1} key={index}
                                            onClick={() => handleRedirect(row.id)}
                                            onMouseEnter={() => handleHover(row.id)}
                                            onMouseLeave={() => handleHover()}
                                            className="cursor-pointer">
                                            <TableCell scope="row" padding="none">
                                                <Tooltip title={row?.title} TransitionComponent={Zoom}>
                                                    <div className='jobpost-first-title'>{row?.title}</div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{row?.client_uid}</TableCell>
                                            <TableCell align="left">{row?.client_country}</TableCell>
                                            <TableCell align="left">{row?.interview_date ? moment(new Date(row?.interview_date)).local().format('DD/MM/YYYY') : '-'}</TableCell>
                                            <TableCell align="left" onClick={(event) => event.stopPropagation()}>
                                                <div className='chat-cancle-btn'>
                                                    <div className='cancle-btn cursor-pointer' onClick={() => history.push('/chat', { agency: { room: row?.chat_room } })}><img src={row.id === hoveredId ? ChatBtnBlue : ChatBtn} /></div>
                                                  
                                                    <div className={row.id === hoveredId ? 'cancle-btn cancle-btn-red cursor-pointer' : 'cancle-btn cursor-pointer'}
                                                    style={!hasActiveSubscription(userDetails)?{pointerEvents:"none"}:null}
                                                     onClick={() => openActionDialogue("you want to reject the freelancer ", candidateStatuses.OFFER_REJECTED, row.id)}>
                                                        <img src={row.id === hoveredId ? CancleBtnRed : CancleBtn} /></div>
                                                   
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) :
                                    <TableRow>
                                        <TableCell className='single-td' align="center" colSpan={6}>Sorry, no matching records found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.count || 0}
                    rowsPerPage={pageData?.pageSize}
                    page={pageData?.page ? pageData?.page - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ConfirmationDialogue
                    open={actionDialogue.open}
                    close={closeActionDialogue}
                    title={actionDialogue.title}
                    message={actionDialogue.message}
                    buttonText="Yes"
                    processing={processing}
                    buttonCallback={() => { action(actionData.requestId, actionData.status, `Candidate rejected successfully`); }}
                />
            </Paper>
        </Box>
    );
}