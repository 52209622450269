import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Field, getFormValues, reduxForm } from "redux-form";
import { required, minAndMaxNumber } from "utils/Validation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { AVAILABILITY } from "utils/constant";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Link from "@mui/material/Link";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomInput from "components/CustomInput/CustomInput";
import { avoidKeysForPlainNumber } from "utils/Common";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";


export const renderRadioGroup = ({ dataTestId, input, ...rest }) => (
    <RadioGroup
        className="availability-options"
        valueselected={input.value}
        data-testid={dataTestId || ""}
        onChange={(__, value) => input.onChange(value)}
        {...input}
        {...rest}
    />
);

function Availability(props) {

    const { handleSubmit, back, formStates, valid, initialValues } = props;
    const data = useSelector(state => state.jobPostReducer?.jobPostData);
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);
    const dispatch = useDispatch();

    const handleSaveDraft = () => {
        if (valid) {
            dispatch({ type: "SAVE_DRAFT_CLICK", payload: true });
            setTimeout(() => {
                handleSubmit();
            }, 1000);
        }
    }

    const handleBack = () => {
        if (valid) {
            let budgetObj = {
                ...data,
                ...formStates
            }
            dispatch({ type: "JOBPOST_DATA", payload: budgetObj });
        }
        back();
    }

    useEffect(() => {
        if (!initialValues.availability) {
            initialValues.availability = '2';
        }
    }, [])

    return (
        <form onSubmit={handleSubmit} noValidate data-testid="availability-form" id="availability-form">
            <Grid className="createJob-wrapper-inner">
                <h3>Availability</h3>
                <Grid container className="formGrid">
                    <Field
                        name="positions"
                        component={CustomInput}
                        id="title"
                        labelText="No. of Positions*"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        min={1}
                        validate={[required]}
                        inputProps={{
                            placeholder: "Enter no. of positions",
                            type: 'number'
                        }}
                        onKeyDown={avoidKeysForPlainNumber}
                        normalize={minAndMaxNumber(1, 20)}
                        dataTestId="no-of-positions"
                    />

                </Grid>
                <Grid className="availability-section">
                    <Typography className="title">
                        Select Availability*
                    </Typography>
                    <Field
                        name="availability"
                        id="availability"
                        className="availability-options"
                        aria-labelledby="demo-form-control-label-placement"
                        component={renderRadioGroup}
                        validate={[required]}
                        dataTestId="availability-options">
                        {[...AVAILABILITY].reverse().map((radio, index) => (
                            <label className={formStates?.availability == radio.id ? "select-avaibility active" : "select-avaibility"} key={index}>
                                <FormControlLabel value={radio.id}
                                    control={<Radio
                                        icon={<CircleOutlinedIcon />}
                                        checkedIcon={<CheckCircleIcon />}
                                        className="select-radiobox cursor-pointer"
                                    />}
                                />
                                <div>
                                    <img src={radio?.icon} alt="img" />
                                    <Typography>{radio?.title}</Typography>
                                </div>
                            </label>
                        ))}
                    </Field>
                </Grid>
            </Grid>
            <div className="bottom-buttons">
                {(!initialValues?.id || (initialValues?.id && initialValues?.step)) &&
                    <LoadingButton
                        className={`saveDraftBtn cursor-pointer ${saveAsDraft && 'loading'}`}
                        loading={saveAsDraft} variant="string"
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        disabled={!valid || saveAsDraft}
                        onClick={handleSaveDraft}
                    >
                        Save as Draft
                    </LoadingButton>
                }
                <div className='action-div'>
                    <Link component="button" underline="none" className='cancelBtn' onClick={handleBack} disabled={saveAsDraft}>Back</Link>
                    <Button variant="contained" type="submit" className="primaryButton nextBtn" data-testid="availability-next" disabled={saveAsDraft}>
                        Next
                    </Button>
                </div>
            </div>
        </form>
    );
}
const ReduxAvailabilityForm = reduxForm({
    form: "AvailabilityForm",
    enableReinitialize: true,
})(Availability);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues("AvailabilityForm")(state)
    }
}

export default connect(mapStateToProps)(ReduxAvailabilityForm);
