import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SearchBar from '../SearchBar';
import 'assets/css/myrequest.css';
import { reduxForm } from 'redux-form';
import { JOBPOST_CANDIDATE_STATUS } from 'utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../../store/actions/index";
import { toast } from 'react-toastify';
import ActiveTableDeveloper from './components/ActiveTable';
import RejectedTableDeveloper from './components/RejectedTable';
import HiredTableDeveloper from './components/HiredTable';
import TableSkeleton from 'views/Jobpost/component/Skeletons/TableSkeleton';
import ProposalSkeleton from 'views/Jobpost/component/Skeletons/ProposalSkeleton';
import { errorToast } from 'utils/Common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme?.palette?.background?.paper,
    },
}));

function DeveloperProposals(props) {

    const developerId = props.match.params?.developerId;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [pageData, setPageData] = useState({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-title' });
    const developer = useSelector(state => state?.jobPostReducer?.requestedCandidatesJobs);
    const developerDetails = useSelector(state => state?.manageDevelopersReducer?.agencyDeveloperDetails);
    const [tableLoading, setTableLoading] = useState(false);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const contentLoading = useSelector(state => state?.commonReducer?.contentLoading);
    const [actionProcessing, setActionProcessing] = useState(false);
    window.scrollTo(0, 0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    const getCandidateProfile = () => {
        dispatch(Actions.getAgencyDeveloperById(developerId));
    }
    const getProposalsList = (page, pageSize, search, ordering = '-title') => {
        let status = '';
        switch (value) {
            case 0:
                status = JOBPOST_CANDIDATE_STATUS.REQUEST_ACCEPTED
                break;
            case 1:
                status = `${JOBPOST_CANDIDATE_STATUS.OFFER_REJECTED},${JOBPOST_CANDIDATE_STATUS.REQUEST_CANCELLED}`
                break;
            case 2:
                status = JOBPOST_CANDIDATE_STATUS.HIRED
                break;
            default:
                break;
        }
        dispatch(Actions.getRequestedCandidatesJobs(developerId, status, page, pageSize, search, ordering));
    }
    const filterData = (page = pageData.page, pageSize = pageData.pageSize, search = pageData.search, order = pageData.order, orderBy = pageData.orderBy) => {
        if (JSON.stringify(pageData) !== JSON.stringify({ page, pageSize, search, order, orderBy })) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
            getProposalsList(page, pageSize, search, orderBy);
            setPageData({ page, pageSize, search, order, orderBy });
        }
    }
    const candidateActions = (requestId, status, message) => {
        setActionProcessing(true);
        const data = { status }
        dispatch(Actions.jobpostCandidateActions(data, requestId)).then(response => {
            if (response.status === 200) {
                toast.success(message);
                getProposalsList();
                setActionProcessing(false);
            } else {
                errorToast(response);
                setActionProcessing(false);
            }
        });
    }

    useEffect(() => {
        setTableLoading(true);
        setPageData({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-title' });
        getProposalsList();
        setTimeout(() => {
            setTableLoading(false);
        }, 1000);
    }, [value]);
    useEffect(() => {
        getCandidateProfile();
        return () => { dispatch({ type: "PAGE_LOADING", payload: true }); }
    }, []);
    return (
        <>
            {pageLoading ?
                <ProposalSkeleton classes={classes} />
                :
                <Grid className="request-top-section">
                    <Container>
                        <Grid className="request-top-inner">
                            <div className="request-heading request-sub-heading">
                                <Typography variant='h3'>{`${developerDetails?.first_name} ${developerDetails?.last_name}'s Proposals`}</Typography>
                                <Typography variant='span'>{`Developer ID : ${developerDetails?.uid}`}</Typography>
                            </div>
                            <div className='request-table'>
                                <div className={classes.root}>
                                    <AppBar position="static">
                                        <Tabs value={value} onChange={handleChange} aria-label="proposals tab">
                                            <Tab label="Proposals" {...a11yProps(0)} />
                                            <Tab label="Rejected" {...a11yProps(1)} />
                                            <Tab label="Hired" {...a11yProps(2)} />
                                        </Tabs>
                                    </AppBar>
                                    <div className='main-table-filter'>
                                        <div className='searchbar-content'>
                                            <SearchBar filterData={filterData} pageData={pageData} />
                                            <div className='filter-btn'>
                                                {/* <Field
                                            name="profile_type"
                                            displayKey="title"
                                            id="profile_type"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={[]}
                                            component={SelectAutocomplete}
                                            loading={false}
                                            required
                                            validate={[required]}
                                            inputProps={{
                                                placeholder: "Select profile type",
                                            }}
                                            onChange={(value) => { value }}
                                        >
                                        </Field> */}
                                            </div>
                                        </div>
                                        <TabPanel value={value} index={0}>
                                            <div className='job-post-table'>
                                                {tableLoading ?
                                                    <TableSkeleton />
                                                    :
                                                    <ActiveTableDeveloper
                                                        data={developer}
                                                        action={candidateActions}
                                                        filterData={filterData}
                                                        pageData={pageData}
                                                        loading={contentLoading}
                                                        developerId={developerId}
                                                        processing={actionProcessing}
                                                    />
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <div className='job-post-table'>
                                                {tableLoading ?
                                                    <TableSkeleton />
                                                    :
                                                    <RejectedTableDeveloper
                                                        data={developer}
                                                        filterData={filterData}
                                                        pageData={pageData}
                                                        loading={contentLoading}
                                                        developerId={developerId}
                                                        processing={actionProcessing}
                                                    />
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <div className='job-post-table'> {tableLoading ?
                                                <TableSkeleton />
                                                :
                                                <HiredTableDeveloper
                                                    data={developer}
                                                    filterData={filterData}
                                                    pageData={pageData}
                                                    loading={contentLoading}
                                                    developerId={developerId}
                                                    processing={actionProcessing}
                                                />
                                            }
                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                </Grid>
            }
        </>
    );
}

const ProposalsList = reduxForm({
    form: "DeveloperProposalsList",
    enableReinitialize: true,
})(DeveloperProposals);
export default ProposalsList;
