import React, { useState, useEffect, useContext } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import "assets/css/profile.css";
import "assets/css/allModals.css";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions/index';
import plusIcon from "assets/img/icons/plus-icon.svg";
import WorkExperienceForm from './component/WorkExperienceForm';
import { submit, isValid, isInvalid, getFormValues } from "redux-form";
import history from 'utils/history';
import UpdateProjectDialog from 'components/Dialog/UpdateProjectDialog';
import CustomBreadcrumbs from 'components/CustomBreadcrumbs/CustomBreadcrumbs';
import { LoaderContext } from 'utils/LoaderContext';
import { toast } from "react-toastify";
import DeleteProjectDialog from 'components/Dialog/DeleteProjectDialog';
import deleteIcon from "assets/img/icons/delete-icon.svg";

function WorkExpeirence(props) {

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const user = useSelector(state => state.authReducer?.userProfile);
    const allWorkExperience = useSelector(state => state.freelancerReducer?.workExperience);
    const remoteData = useSelector(state => state.freelancerReducer?.remoteData);
    const { toggalLoader } = useContext(LoaderContext);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: '/my-profile' },
    ];
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const getWorkExperience = () => {
        dispatch(Actions.getWorkExperience());
    }
    const handleFormChange = async (index = null) => {
        await dispatch(submit('WorkExperienceForm'));
        if (props.valid) {
            if (index === null) {
                const keys = Object.keys(allWorkExperience)
                index = (keys.length > 0) ? Math.max(...new Set(keys)) + 1 : 0;
                const workExperienceData = [...allWorkExperience];
                workExperienceData[remoteData.currentForm] = props.formData;
                workExperienceData[index] = {};
                await dispatch({ type: "GET_WORK_EXPERIENCE", payload: workExperienceData });
            }
            const formData = { ...remoteData };
            formData.currentForm = index;
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
    }
    const handleSubmit = (values, index) => {
        if (props.valid) {
            const formData = { ...remoteData };
            values.images = formData.files?.[index];
            formData.update_data[index] = values;
            const workExperienceData = [...allWorkExperience];
            workExperienceData[index] = values;
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
            dispatch({ type: "GET_WORK_EXPERIENCE", payload: workExperienceData });
        }
    }
    const handleExperienceOperation = async () => {
        toggalLoader(true)
        await dispatch(submit('WorkExperienceForm'));
        if (remoteData.update_data.length > 0) {
            const filtered = remoteData.update_data.filter(value => value);
            dispatch(Actions.addDetails('work-experience/', filtered)).then(response => {
                if (response.status === 201) {
                    setOpenDialog(false);
                    toast.success('Work experience details updated successfully');
                    toggalLoader(false);
                    history.push('/my-profile');
                } else {
                    toggalLoader(false);
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
            }).catch(() => {
                toggalLoader(false);
                toast.error('Something went wrong');
            });
        }
    }
    const handleConfirmRemove = async () => {
        setOpenDeleteDialog(false);
        const index = remoteData.currentForm;
        const workExperienceData = allWorkExperience.filter((elem, ind) => ind !== index);
        const formData = await { ...remoteData, update_data: remoteData.update_data.filter((elem, ind) => ind !== index) };
        formData.currentForm = 0;

        dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        dispatch({ type: "GET_WORK_EXPERIENCE", payload: workExperienceData });
    }

    useEffect(() => {
        if (remoteData?.currentForm > 0) {
            const formData = { ...remoteData, currentForm: 0 };
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
        getWorkExperience();
    }, []);


    return (
        <>
            <Grid className="profile-top-section add_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-certification" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className="clientId">
                                ID : <span>{user?.uid}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Edit Work Expeirence</h4>
                <Grid className="profile-inner edit_project-inner">
                    <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={12} className="projects-sidebar">
                            <div className="sidebarTitlle-section">
                                <h5 className="title">Work Expeirence</h5>
                                <IconButton
                                    underline="none"
                                    className="addIconBtn cursor-pointer"
                                    onClick={() => handleFormChange()}
                                >
                                    <img src={plusIcon} alt="add" />
                                </IconButton>
                            </div>
                            <List>
                                {allWorkExperience && allWorkExperience.map((certificate, index) => {
                                    if (remoteData && remoteData.update_data.length === 0) {
                                        const fData = { ...remoteData }
                                        fData.update_data[index] = certificate;
                                        dispatch({ type: "GET_REMOTE_DATA", payload: fData });
                                    }
                                    return (
                                        <ListItem
                                            className="projectsListItem"
                                            key={index}
                                            onClick={() => handleFormChange(index)}
                                        >
                                            <Link href="#" underline="none">
                                                {certificate.title}
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={12} className="editProject-container">
                            <Grid className="edit-conatiner">
                                <div className="edit-container-header">
                                    <h5 className="title">{allWorkExperience?.[remoteData.currentForm]?.title}</h5>
                                    {!allWorkExperience?.[remoteData.currentForm]?.id && (allWorkExperience?.length > 0) && <IconButton
                                        onClick={handleOpenDeleteDialog}
                                        aria-label="delete"
                                        size="medium"
                                        className="cursor-pointer"
                                    >
                                        <img
                                            src={deleteIcon}
                                            alt="img"
                                            style={{ filter: "brightness(10)" }}
                                        />
                                    </IconButton>}
                                </div>

                                <WorkExperienceForm
                                    initialValues={allWorkExperience?.[remoteData.currentForm]}
                                    onSubmit={(data) => handleSubmit(data, remoteData.currentForm)}
                                    formIndex={remoteData?.currentForm} />
                            </Grid>
                            <Grid className="action-div">
                                <Link onClick={() => history.push("/my-profile")} underline="none" className="previousBtn cursor-pointer">
                                    Cancel
                                </Link>
                                <Button
                                    variant="contained"
                                    className="primaryButton nextBtn"
                                    onClick={handleOpenDialog}
                                    disabled={props.invalid}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <UpdateProjectDialog open={openDialog} close={handleCloseDialog}
                handleUpdate={handleExperienceOperation}
                message="Are you Sure you want to Update Work Experience?"
                description="If you want to Edit other Work Experience then Click Continue Button and if you want to Save All changes then Click Update Button." />
            <DeleteProjectDialog
                open={openDeleteDialog}
                close={handleCloseDeleteDialog}
                confirm={handleConfirmRemove}
                message="Are you Sure you want to Remove this Work Experience?"
                description="This Action Can’t be Undo, When you delete, all data of this work experience will be removed."
            />
        </>
    )
}
const WorkExpeirenceMapped = connect(
    state => ({
        valid: isValid('WorkExperienceForm')(state),
        invalid: isInvalid('WorkExperienceForm')(state),
        formData: getFormValues('WorkExperienceForm')(state)
    })
)(WorkExpeirence)
export default WorkExpeirenceMapped