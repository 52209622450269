import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { findSalaryType, errorToast, wrapperJobPostRedirection, wrapperDeveloperRedirection } from 'utils/Common';
import OfferActionsDialog from './OfferActionsDialog';
import { OFFER_STATUS, SALARY_TYPE } from 'utils/constant';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../store/actions/index";
import CounterOfferDialog from './CounterOfferDialog';
import Link from '@mui/material/Link';
import { hasActiveSubscription } from 'utils/constant';

const OfferWrapper = (props) => {
    const { details, messagePosition } = props;
    const [offerDialog, setOfferDialog] = useState({ open: false, texts: null, status: null });
    const [counterOfferDialog, setCounterOfferDialog] = useState({ open: false, details: null });
    const currentUser = useSelector(state => state.chatReducer?.currentUser);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const dispatch = useDispatch();
    const texts = {
        reject: {
            title: 'Are you Sure you want to Reject this offer?',
            message: 'If you reject the offer request then reject jobpost',
            buttonText: 'Yes, Reject',
        },
        accept: {
            title: 'Are you Sure you want to Accept this offer?',
            message: 'If you accept the offer request then you will be hired',
            buttonText: 'Yes, Accept',
        }
    }

    const openOfferDialog = (status) => {
        const data = { open: true, texts: texts?.[status] || null, status: status.toString().toUpperCase() };
        setOfferDialog(data);
    }
    const closeOfferDialog = () => {
        setOfferDialog({ ...offerDialog, open: false })
    }
    const handleCounterOfferDialog = (offerDetails = null) => {
        const data = { open: !counterOfferDialog.open, details: { ...offerDetails, salary_type: SALARY_TYPE.find(sal => sal.id === offerDetails?.salary_type), sal_type: SALARY_TYPE.find(sal => sal.id === offerDetails?.salary_type)?.title } };
        setCounterOfferDialog(data);
    }
    const handleAcceptOrReject = (status) => {
        const postData = {
            amount: details?.amount,
            salary_type: details?.salary_type,
            status: OFFER_STATUS[status],
            chat_room: currentUser?.room,
        }
        let messageStatus = OFFER_STATUS?.[status] == 2 ? 'Accepted' : 'Rejected';
        return dispatch(Actions.submitOffer(postData, details?.id)).then(response => {
            if (response.status === 200) {
                setOfferDialog({ open: false, texts: null, status: null });
                toast.success(`Offer ${messageStatus} successfully`);
            } else {
                errorToast(response);
            }
        }).catch(() => {
            setOfferDialog({ open: false, texts: null, status: null });
        })
    }
    const handleCounterOffer = (values) => {
        const postData = {
            salary_type: values?.salary_type?.id,
            amount: values?.amount,
            parent: values?.id,
            chat_room: currentUser?.room,
        }
        return dispatch(Actions.submitOffer(postData)).then(response => {
            if (response.status === 201) {
                setCounterOfferDialog({ ...counterOfferDialog, open: false });
                setTimeout(() => {
                    setCounterOfferDialog({ open: false, details: null });
                }, 500);
                toast.success("Counter offer has been sent successfully");
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error?.message || "Something went wrong");
        })
    }

    return (
        <div className={(messagePosition == 'left') ? 'interview-wrapper interview-left-wrapper' : "interview-wrapper"}>
            <div className='interview-main-box'>
                <div className='interview-text-heading'>
                    <Typography variant='h6'>{messagePosition == 'right' ? 'Your' : `${currentUser?.opponent?.company_name}'s`} {details?.parent ? 'Counter' : ''} Offer </Typography>
                    <Typography variant='h4'>${details?.amount} <span>/{findSalaryType(details?.salary_type)}</span></Typography>
                    <div className='interview-client-id'>
                        <Typography variant='body1'>Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(userDetails?.user_type, details?.job_post?.id, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.job_post?.uid}</Link></span></Typography>
                        <Typography variant='body1'>Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(userDetails?.user_type, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.candidate?.uid}</Link></span></Typography>
                    </div>
                </div>
            </div>
            {(messagePosition == 'left' && details?.show_detail) &&
                <div className='interview-btn-schedule'>
                    <div className='interview-view-btn' style={!hasActiveSubscription(userDetails)?{pointerEvents:"none"}:null} onClick={() => openOfferDialog('accept')}>
                        <Button className='btn btn-secondary'>Accept</Button>
                    </div>
                    <div className='interview-view-btn' style={!hasActiveSubscription(userDetails)?{pointerEvents:"none"}:null} onClick={() => handleCounterOfferDialog(details)}>
                        <Button className='btn btn-secondary'>Counter offer</Button>
                    </div>
                    <div className='interview-view-btn' style={!hasActiveSubscription(userDetails)?{pointerEvents:"none"}:null} onClick={() => openOfferDialog('reject')}>
                        <Button className='btn btn-primary'>Reject</Button>
                    </div>
                </div>
            }
            <OfferActionsDialog
                open={offerDialog?.open}
                close={closeOfferDialog}
                texts={offerDialog?.texts}
                status={offerDialog?.status}
                performActions={handleAcceptOrReject}
            />
            <CounterOfferDialog
                open={counterOfferDialog.open}
                close={handleCounterOfferDialog}
                opponent={currentUser?.opponent}
                initialValues={counterOfferDialog?.details}
                onSubmit={values => handleCounterOffer(values)}
                userDetails={userDetails}
            />
        </div>
    );
}

export default OfferWrapper;