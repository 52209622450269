import React, { useState, useEffect, useContext } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import "assets/css/profile.css";
import "assets/css/allModals.css";
import deleteIcon from "assets/img/icons/delete-icon.svg";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions/index';
import plusIcon from "assets/img/icons/plus-icon.svg";
import CertificationForm from './component/CertificationForm';
import { submit, isValid, isInvalid, getFormValues } from "redux-form";
import UpdateProjectDialog from 'components/Dialog/UpdateProjectDialog';
import CustomBreadcrumbs from 'components/CustomBreadcrumbs/CustomBreadcrumbs';
import { LoaderContext } from 'utils/LoaderContext';
import { toast } from "react-toastify";
import history from 'utils/history';
import DeleteProjectDialog from 'components/Dialog/DeleteProjectDialog';
import { checkForImageValidations } from 'utils/Common';


function Certification(props) {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const user = useSelector(state => state.authReducer?.userProfile);
    const allCertificate = useSelector(state => state.freelancerReducer?.certificateList);
    const remoteData = useSelector(state => state.freelancerReducer?.remoteData);
    const technologies = useSelector(state => state.commonReducer?.technologies);
    const { toggalLoader } = useContext(LoaderContext);

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: '/my-profile' },
    ]
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const getCertification = () => {
        dispatch(Actions.getCertificationList(1, 'all'));
    }
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleFormChange = async (index = null) => {
        await dispatch(submit('CertificationForm'));
        if (props.valid) {
            if (index === null) {
                const keys = Object.keys(allCertificate)
                index = (keys.length > 0) ? Math.max(...new Set(keys)) + 1 : 0;
                const certificateData = [...allCertificate];
                certificateData[remoteData.currentForm] = props.formData;
                certificateData[index] = {};
                await dispatch({ type: "GET_CERTIFICATE", payload: certificateData });
            }
            const formData = { ...remoteData };
            formData.currentForm = index;
            if (allCertificate[index]) {
                formData.update_data[index] = allCertificate[index];
            } else {
                formData.update_data[index] = {};
            }
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
    }
    const handleSubmit = (values, index) => {
        if (props.valid) {
            const formData = { ...remoteData };
            if (formData.files?.[index]) {
                values.certificate_file = formData.files?.[index];
            }
            formData.update_data[index] = values;
            const certificateData = [...allCertificate];
            certificateData[index] = values;
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
            dispatch({ type: "GET_CERTIFICATE", payload: certificateData });
        }
    }
    const handleMultipleImageUpload = async (file, formIndex) => {
        const imagesData = { ...remoteData };
        if (file instanceof File) {
            const fileCheck = await checkForImageValidations(file, 'certificate_file_type', false);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message)
            } else {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    imagesData.files[formIndex] = response.data;
                    dispatch({ type: "GET_REMOTE_DATA", payload: imagesData });
                }).catch(() => {
                    imagesData.files[formIndex] = null;
                    dispatch({ type: "GET_REMOTE_DATA", payload: imagesData });
                });
            }
        }
    }
    const handleProjectOperation = async () => {
        toggalLoader(true);
        await dispatch(submit('CertificationForm'));
        if (remoteData.update_data.length > 0) {
            const filtered = remoteData.update_data.filter(value => value);
            const certificatesData = filtered.map((values) => {
                if (values.certificate_file && typeof values.certificate_file === 'string') {
                    delete values.certificate_file;
                    delete values.url;
                }
                return values;
            });

            dispatch(Actions.addDetails('certification/', certificatesData)).then(response => {
                if (response.status === 201) {
                    setOpenDialog(false);
                    toast.success('Certification details updated successfully');
                    toggalLoader(false);
                    history.push('/my-profile');
                } else {
                    toggalLoader(false);
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    });
                }
            }).catch(() => {
                toggalLoader(false);
                toast.error('Something went wrong');
            });

        }
    }
    const handleConfirmRemove = async () => {
        setOpenDeleteDialog(false);
        const index = remoteData.currentForm;
        const certificationData = allCertificate.filter((_elem, ind) => ind !== index);
        const formData = await { ...remoteData, update_data: remoteData.update_data.filter((_elem, ind) => ind !== index) };
        formData.currentForm = 0;

        dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        dispatch({ type: "GET_CERTIFICATE", payload: certificationData });
    }

    useEffect(() => {
        if (remoteData?.currentForm > 0) {
            const formData = { ...remoteData, currentForm: 0 };
            dispatch({ type: "GET_REMOTE_DATA", payload: formData });
        }
        getCertification();
    }, []);

    useEffect(() => {
        if (allCertificate && allCertificate?.length === 0) {
            const initial = {};
            dispatch({ type: "GET_CERTIFICATE", payload: [initial] });
        }
    }, [allCertificate]);

    return (
        <>
            <Grid className="profile-top-section add_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-certification" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className="clientId">
                                ID : <span>{user?.uid}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Edit Certification</h4>
                <Grid className="profile-inner edit_project-inner">
                    <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={12} className="projects-sidebar">
                            <div className="sidebarTitlle-section">
                                <h5 className="title">Certification</h5>
                                <IconButton
                                    underline="none"
                                    className="addIconBtn cursor-pointer"
                                    onClick={() => handleFormChange()}
                                >
                                    <img src={plusIcon} alt="add" />
                                </IconButton>
                            </div>
                            <List>
                                {allCertificate && allCertificate.map((certificate, index) => {
                                    if (remoteData && remoteData.update_data.length === 0) {
                                        const fData = { ...remoteData }
                                        fData.update_data[index] = certificate;
                                        dispatch({ type: "GET_REMOTE_DATA", payload: fData });
                                    }
                                    return (
                                        <ListItem
                                            className={(index === remoteData.currentForm) ? "projectsListItem active" : "projectsListItem"}
                                            key={index}
                                            onClick={() => handleFormChange(index)}
                                        >
                                            <Link href="#" underline="none">
                                                {certificate.title}
                                            </Link>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={12} className="editProject-container">
                            <Grid className="edit-conatiner">
                                <div className="edit-container-header">
                                    <h5 className="title">{allCertificate?.[remoteData.currentForm]?.title}</h5>
                                    {!allCertificate?.[remoteData.currentForm]?.id && (allCertificate?.length > 1) && <IconButton
                                        onClick={handleOpenDeleteDialog}
                                        aria-label="delete"
                                        size="medium"
                                        className="cursor-pointer"
                                    >
                                        <img
                                            src={deleteIcon}
                                            alt="img"
                                            style={{ filter: "brightness(10)" }}
                                        />
                                    </IconButton>}
                                </div>
                                <CertificationForm
                                    initialValues={allCertificate?.[remoteData.currentForm]}
                                    onSubmit={(data) => handleSubmit(data, remoteData.currentForm)}
                                    formIndex={remoteData?.currentForm}
                                    technologies={technologies}
                                    uploadImage={handleMultipleImageUpload}
                                    filesData={allCertificate?.[remoteData.currentForm]?.images}
                                />
                            </Grid>
                            <Grid className="action-div">
                                <Link onClick={() => history.push("/my-profile")} underline="none" className="previousBtn cursor-pointer">
                                    Cancel
                                </Link>
                                <Button
                                    variant="contained"
                                    className="primaryButton nextBtn"
                                    onClick={handleOpenDialog}
                                    disabled={props.invalid}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <UpdateProjectDialog open={openDialog} close={handleCloseDialog}
                handleUpdate={handleProjectOperation}
                message="Are you Sure you want to Update Certification?"
                description="If you want to Edit other Certification then Click Continue Button and if you want to Save All changes then Click Update Button." />
            <DeleteProjectDialog
                open={openDeleteDialog}
                close={handleCloseDeleteDialog}
                confirm={handleConfirmRemove}
                message="Are you Sure you want to Remove this Certificate?"
                description="This Action Can’t be Undo, When you delete, all data of this Certificate will be removed."
            />
        </>
    )
}

const CertificationMapped = connect(
    state => ({
        valid: isValid('CertificationForm')(state),
        invalid: isInvalid('CertificationForm')(state),
        formData: getFormValues('CertificationForm')(state)
    })
)(Certification)
export default CertificationMapped