import React from 'react';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ContactRightImg from "assets/img/contact-bg1.png";
import ContactFacebook from "assets/img/icons/contact-facebook-icon.svg";
import ContactTwitter from "assets/img/icons/contact-twitter-icon.svg";
import ContactLinkedIn from "assets/img/icons/contact-linkedin-icon.svg";
import ContactInstagram from "assets/img/icons/contact-instagram-icon.svg";

const Right = () => {
    return (
        <div className='contact-right-content'>
            <div className='right-top-img'>
                <img src={ContactRightImg} alt="" />
            </div>

            <div className='contact-detail'>
                <div className='contact-text'>
                    <Typography variant='h3'>Source Firms</Typography>
                    <Typography>605, 6th Floor, B Square-I, BRTS Road, near Neptune House Iscon, Ambli, Ahmedabad, Gujarat. 380058</Typography>
                </div>
                <div className='contact-text'>
                    <Typography variant='h3'>Phone</Typography>
                    <Typography>+91 884-982-8275</Typography>
                </div>
                <div className='contact-text'>
                    <Typography variant='h3'>Email</Typography>
                    <Typography>hello@sourcefirms.com</Typography>
                </div>
                <div className='contact-icon'>
                    <List>
                        <ListItem><Link href="https://www.facebook.com/SourceFirms-104729722403599" target="_blank" rel='noopener noreferrer'><img src={ContactFacebook} alt="" /></Link></ListItem>
                        <ListItem><Link href="https://twitter.com/sourcefirms" target="_blank" rel='noopener noreferrer'><img src={ContactTwitter} alt="" /></Link></ListItem>
                        <ListItem><Link href="https://www.linkedin.com/company/sourcefirms" target="_blank" rel='noopener noreferrer'><img src={ContactLinkedIn} alt="" /></Link></ListItem>
                        <ListItem><Link href="https://www.instagram.com/sourcefirms/" target="_blank" rel='noopener noreferrer'><img src={ContactInstagram} alt="" /></Link></ListItem>
                    </List>
                </div>
            </div>
        </div>
    );
}

export default Right;