import React from 'react';
import Grid from '@mui/material/Grid';
import "assets/css/privacy-policy.css";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { ListItem } from '@mui/material';

const RightSection = () => {
    return (
        <Grid item md={9}>
            <div className="introduction-main-content">
                <div className="introduction" id="introduction">
                    <Typography variant='h4'>Introduction </Typography>
                    <Typography variant='body1'>At &quot;sourcefirms.com&quot;, we secure and respect the privacy of our users. We strictly follow global privacy guidelines/policies to protect your interests. We collect personal information about our users to best serve them for our services and support. </Typography>
                    <Typography variant='body1'>Please see our policy on the collection, use and disclosure of personal user data when you use our services. By using our services, you consent to the collection and use of information in accordance with this policy. </Typography>
                    <Typography variant='body1'>We do not use or share your information with anyone except as described in this Privacy Policy. </Typography>
                </div>

                <div className="introduction" id="information_we_collect">
                    <Typography variant='h4'>Information We Collect </Typography>
                    <Typography variant='body1'>Sourcefirms.com is an online marketplace that connects enterprises, agencies with freelancers. When you visit SourceFirms, you provide personal information that you knowingly choose to disclose collected by Sourcefirms.com and website usage information collected by Sourcefirms.com collected when you interact with the SourceFirms <a href='https://sourcefirms.com/' target="_blank" rel="noreferrer">website</a>. </Typography>
                    <Typography variant='body1'> When you register with SourceFirms as a freelancer or as an employer, you provide certain personal information, including your name, contact number, and email address. In addition, when you set up your profile as a freelancer or an employer, you will provide information regarding skills, experience, education, remuneration, availability work, geographic area and your self-assessment. This information does not identify individual member specificity. Freelancers are not required to do so, but have the option of making their contact information visible (i.e. name, address, phone number, email address, etc.) Search the Sourcefirms.com database. </Typography>
                    <Typography variant='body1'>When you visit the website, Sourcefirms.com gathers your IP address. This information is collected for all visitors to Sourcefirms.com. In addition, Sourcefirms.com stores certain information from your browser using &quot;cookies&quot;. A cookie is a piece of data stored on a user&quot;s computer that is associated with information about the user. Sourcefirms.com uses session ID cookies to confirm that the user is logged in. These cookies terminate when the user closes the browser. By default, Sourcefirms.com uses a persistent cookie that stores your login ID (but not your password) to help you log in when you return to the site. Sourcefirms.com members must enable cookies in their browsers. </Typography>
                    <Typography variant='body1'><span>Note:</span> Cookie preferences are set in the internet preferences/preferences of each browser. </Typography>
                    <Typography variant='body1'> If you post information on a bulletin board or participate in a public bulletin board, you should be aware that any personally identifiable information you choose to provide may be available to other users. This forum reads, collects or uses and may be used to send unsolicited messages. SourceFirms is not responsible for the personally identifiable information you choose to submit in these forums. </Typography>
                    <Typography variant='body1'>Sourcefirms.com is the sole owner of the information collected through the website. </Typography>
                </div>

                <div className="introduction" id="employee_data">
                    <Typography variant='h4'>Employee Data</Typography>
                    <Typography variant='body1'>We collect personal data about our prospective, current and former employees (including backup employees, suppliers and interns) as follows: basic forms, such as your name, title, work history, experience, and contract details. For current employees, we generally collect in addition to the above:</Typography>
                    <List>
                        <ListItem>Electronically identifiable data (e.g. email address, login information, badge number, online identifier/cookie, log file, connection time).</ListItem>
                        <ListItem>Financial information (e.g. bank details and tax information).</ListItem>
                        <ListItem>National registration number, social security number or local equivalent.</ListItem>
                        <ListItem>Detailed identifying information, including passport numbers, employment authorization documents, personal email and/or mailing addresses and country of residence.</ListItem>
                        <ListItem>Education and employment information (e.g. salary, bonus, insurance and other benefits information, date of employment, location information such as title, attendance information including, if applicable, illness or leave for medical or other reasons, language skills and education details, pension information including benefits and employment information including job application, resume calendars, background experts and references.</ListItem>
                        <ListItem>The types of personal data and the specific types of personal data we collect may vary depending on the nature of the services we provide to you. In rare cases, we may also collect other special categories of personal data about you because you voluntarily provide this data to us or we are forced to collect it because of legal requirements imposed on us.</ListItem>
                    </List>
                </div>

                <div className="introduction" id="how_we_use_your_information">
                    <Typography variant='h4'>How We Use Your Information</Typography>
                    <List>
                        <ListItem>To provide SourceFirms products/services and address your queries.</ListItem>
                        <ListItem>To comply with legal and regulatory requirements and our policies.</ListItem>
                        <ListItem>To share information, updates, offers about our products/services and user subscriptions.</ListItem>
                        <ListItem>To prepare reports, analyze data and extract meaningful information to better serve you.</ListItem>
                        <ListItem>To process financial transactions.</ListItem>
                    </List>
                    <Typography variant='body1'>For reasons other than those mentioned above, to market and promote the Services and to protect our interests and rights.</Typography>
                </div>

                <div className="introduction" id="security">
                    <Typography variant='h4'>Security</Typography>
                    <Typography variant='body1'>SourceFirms takes enterprise general protocols and technology to defend registered user data. Sourcefirms is a VeriSign Secure Site. All transactions executed via Guru.com are carried out in a Secure Socket Layer (SSL) session. The Secure Socket Layer encrypts all transaction records for transmittal in a layout that stops record theft. This process protects all personal facts from being disclosed to 1/3 parties. While on a steady web page, the lock icon at the lowest of a Web browser (i.e. Firefox or Chrome) will become locked. </Typography>
                    <Typography variant='body1'>Sourcefirms additionally protects registered user information offline. All registered consumer facts are restrained inside our offices. Servers used to save individually identifiable information are housed in a steady, supervised environment. In addition, best SourceFirms employees who want specific facts to carry out a challenge are granted admission to individually identifiable information. </Typography>
                </div>

                <div className="introduction" id="with_whom_do_we_share_your_personal_data">
                    <Typography variant='h4'>With whom do we share your personal data</Typography>
                    <Typography variant='body1'>We may share your personal and non-personal data with the third parties mentioned below to better serve you and for the development of your business. Development and growth of the company.</Typography>
                    <List>
                        <ListItem>With our affiliates/business partners/service providers/researchers bound by contractual obligations, to know the status of the service(s) used by the user to improve their experience and help manage the relationship.</ListItem>
                        <ListItem>With professionals i.e. accountants, auditors, lawyers, bankers and insurance companies to advise us on the services offered to our users.</ListItem>
                        <ListItem>With marketing and promotion companies to provide you with personalized advertisements/promotions related to our products and services.</ListItem>
                        <ListItem>With legal and government services related to compliance, audit, court order, security, fraud, asset sale, merger/consolidation, acquisition, reorganization, insolvency, etc. to comply with their regulations.</ListItem>
                    </List>
                </div>

                <div className="introduction" id="how_long_we_retain_personal_data_of_user">
                    <Typography variant='h4'>How Long We Retain Personal Data of User</Typography>
                    <Typography variant='body1'>We maintain your Personal Data so long as you hold your account with us and within reason needed. However, in a few circumstances, we can also additionally hold it even after you shut up your account i.e., to conform to regulatory and legal obligations and to enforce our contracts/agreements. </Typography>

                </div>

                <div className="introduction" id="security_of_personal_data">
                    <Typography variant='h4'>Security of Personal Data</Typography>
                    <Typography variant='body1'>SourceFirms makes use of excessive widespread safeguards to steady our user’s data to minimize the threat of loss/unauthorized access to statistics. We recognize your acceptance as true with Sourcefirms, consequently we use particularly encrypted systems/applications to protect our databases. </Typography>
                    <Typography variant='body1'>We preserve stringent regulations and techniques to cope with any Personal Data breach, but we cannot assure absolute safety and protection of Personal Data. We will let you know and the correct government in case of any breach of statistics protection. </Typography>
                </div>

                <div className="introduction" id="events">
                    <Typography variant='h4'>Events</Typography>
                    <Typography variant='body1'>We might also additionally gather Personal Data from you whilst you attend, certainly considered one among our events, for example at business meetings and change shows. The records we gather consist of statistics which you voluntarily deliver to us, along with your business card or contact details. We will use this information to expand an enterprise courting and in a manner consistent with the purpose you gave the information for. </Typography>
                    <Typography variant='body1'>We, at Sourcefirms.com, are able to ask your consent earlier than or whilst sending you advertising and marketing statistics through email. When you offer us together along with your consent to be contacted for advertising and marketing purposes, you&apos;ve got the proper to withdraw your consent at any time through following the commands to “opt-out” of receiving advertising and marketing communique in every advertising and marketing email we send you or through contacting us as indicated below. </Typography>
                </div>

                <div className="introduction" id="children_privacy_policy">
                    <Typography variant='h4'>Children&apos;s Privacy Policy</Typography>
                    <Typography variant='body1'>SourceFirms offerings aren&apos;t supposed for the children who&apos;ve now no longer attained the age of thirteen years. We no longer knowingly acquire records under the stated age. If you&apos;re under the age of thirteen years, please do not sign on or post any Personal Information on SourceFirms Website. </Typography>
                </div>

                <div className="introduction" id="contact_us">
                    <Typography variant='h4'>Contact Us</Typography>
                    <Typography variant='body1'>If you have any questions, comments, complaints or requests related to this Privacy Policy or the processing of your personal data, you can get in touch with us using Contact us form or email us at <Link href={void (0)}>legal@sourcefirms.com.</Link>  </Typography>
                    <Typography variant='body1' className='address-text'>Address: <span>605, 6th Floor, B Square-I, BRTS Road, near Neptune House Iscon, Ambli, Ahmedabad, Gujarat. 380058</span></Typography>
                </div>
            </div>
        </Grid>
    )
}

export default RightSection