import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Field, getFormValues, reduxForm } from "redux-form";
import { required } from "utils/Validation";
import ChipAutocomplete from "components/CustomeAutoComplete/ChipAutocomplete";
import Link from "@mui/material/Link";
import * as Actions from "../../../store/actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

function Expertise(props) {

    const { handleSubmit, back, valid, initialValues, formStates } = props;
    const dispatch = useDispatch();
    const technologies = useSelector((state) => state.commonReducer?.technologies);
    const skillsList = useSelector(state => state.commonReducer?.skillsWithoutDepth);
    const data = useSelector(state => state.jobPostReducer?.jobPostData);
    const saveAsDraft = useSelector(state => state.jobPostReducer?.saveDraftClick);

    const getSkill = () => {
        dispatch(Actions.getSkillsWithoutDepth());
    };

    const getTechnologies = () => {
        dispatch(Actions.getTechnologiesList());
    };

    const handleSaveDraft = () => {
        if (valid) {
            dispatch({ type: "SAVE_DRAFT_CLICK", payload: true });
            setTimeout(() => {
                handleSubmit();
            }, 1000);
        }
    }
    const handleBack = () => {
        if (valid) {
            let budgetObj = {
                ...data,
                ...formStates
            }
            dispatch({ type: "JOBPOST_DATA", payload: budgetObj });
        }
        back();
    }

    useEffect(() => {
        getSkill();
        getTechnologies();
    }, []);

    return (
        <form onSubmit={handleSubmit} noValidate data-testid="expertise-form" id="expertise-form">
            <Grid className="createJob-wrapper-inner">
                <h3>Expertise</h3>
                <Grid container className='formGrid'>
                    <Field
                        name="technologies"
                        component={ChipAutocomplete}
                        id="technologies"
                        labelText="Technologies*"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        options={technologies || []}
                        displayKey="title"
                        validate={[required]}
                        onChange={(value) => value}
                        inputProps={{
                            placeholder: "Select technologies",
                            type: "text",
                        }}
                    />
                </Grid>
                <Grid container className='formGrid'>
                    <Field
                        name="skills"
                        component={ChipAutocomplete}
                        id="skills"
                        labelText="Skills*"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        options={skillsList || []}
                        displayKey="title"
                        validate={[required]}
                        onInputChange={(value) => value}
                        inputProps={{
                            placeholder: "Select skills",
                            type: "text",
                        }}
                    />
                </Grid>
            </Grid>
            <div className="bottom-buttons">
                {(!initialValues?.id || (initialValues?.id && initialValues?.step)) &&
                    <LoadingButton
                        className={`saveDraftBtn cursor-pointer ${saveAsDraft && 'loading'}`}
                        loading={saveAsDraft} variant="string"
                        loadingIndicator={<CircularProgress color="inherit" size={16} />}
                        disabled={!valid || saveAsDraft}
                        onClick={handleSaveDraft}
                    >
                        Save as Draft
                    </LoadingButton>
                }
                <div className='action-div'>
                    <Link underline="none" className='cancelBtn' onClick={handleBack} component='button' disabled={saveAsDraft}>Back</Link>
                    <Button variant="contained" type="submit" className="primaryButton nextBtn" disabled={saveAsDraft}>
                        Next
                    </Button>
                </div>
            </div>
        </form>
    );
}
const ReduxExpertiseForm = reduxForm({
    form: "ExpertiseForm",
    enableReinitialize: true,
})(Expertise);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues('ExpertiseForm')(state)
    }
}

export default connect(mapStateToProps)(ReduxExpertiseForm);
