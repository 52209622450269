import { SALARY_TYPE } from "utils/constant";

const OfferValidate = values => {
    const errors = {};
    // let currentSalaryType = values.salary_type;
    const salaryType = values?.salary_type?.id ?? values?.job_post?.salary_type;
    let currentSalaryType = salaryType ? SALARY_TYPE.find(sal => sal.id === salaryType) : undefined;

    if (values.amount < currentSalaryType?.budget_from || values.amount > currentSalaryType?.budget_to) errors.amount = `Please enter amount between $${currentSalaryType?.budget_from} - $${currentSalaryType?.budget_to}`;

    return errors;
}

export default OfferValidate;