import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';


const CandidateCardSkeleton = () => {
    return (
        <>
            <Grid item lg={4} md={6} sm={6}>
                <div className='jobdetail-card'>
                    <Card className='candidate-card pointerClass'>
                        <div className='card-heading cursor-pointer'>
                            <Typography variant='h3'><Skeleton /></Typography>
                            <Typography variant='body1'><Skeleton /></Typography>
                        </div>
                        <Grid>
                            <List className="categoryList">
                                {/* <SpliceList data={profile?.portfolio?.skills} mainClass="subcategoryList-item" subClass="more-subcategory" limit={2} /> */}
                                <Skeleton className='subcategoryList-item' />
                                <Skeleton className='subcategoryList-item' />
                            </List>
                        </Grid>
                        <div className='candidate-text'>
                            <Typography variant='body1 elipse-text'>
                                <Skeleton />
                            </Typography>
                        </div>
                        <div className='jobpost-position-listing'>
                            <List>
                                <ListItem>
                                    <div>
                                        <h5><Skeleton height={40} width={40} /></h5>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div>
                                        <h5><Skeleton height={40} width={40} /></h5>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div>
                                        <h3><Skeleton height={40} width={40} /></h3>
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                        <div className='reject-btn'>
                            <Button className='btn-prymary btn' variant="contained" color="primary">
                                <Skeleton />
                            </Button>
                            <Button className='btn-secondary btn' variant="contained" color="secondary">
                                <Skeleton />
                            </Button>
                        </div>
                    </Card>
                </div>
            </Grid >
        </>
    );
}

export default CandidateCardSkeleton;