import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import 'assets/css/jobpostdetail.css';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { toast } from 'react-toastify';
import * as Actions from "../../store/actions/index";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import noDataImg from 'assets/img/icons/no-developer-found.svg';

import JobPostDetailsCard from "components/Cards/JobPostDetailsCard";
import CandidatesCard from "components/Cards/CandidatesCard";
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { Card, CircularProgress } from "@mui/material";
import history from "utils/history";
import JobpostDetailsSkeleton from "./component/Skeletons/JobpostDetailsSkeleton";
import CandidateCardSkeleton from "./component/Skeletons/CandidateCardSkeleton";
import { JOBPOST_CANDIDATE_STATUS } from "utils/constant";
import { errorToast } from "utils/Common";

const JobPostDetail = (props) => {

    const jobPostId = props.match.params.jobPostId;
    const dispatch = useDispatch();
    const jobPostDetail = useSelector(state => state?.jobPostReducer?.jobPostDetail);
    const candidates = useSelector(state => state?.jobPostReducer?.candidateList);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const pageSize = 5;
    const [prevPageSize, setPrevPageSize] = useState(pageSize);
    const [loadMore, setLoadMore] = useState(false);

    const getCandidateList = (jobPostId) => {
        dispatch(Actions.getJobPostAllCandidates(jobPostId, 1, pageSize));
    }

    const handleJobpostActionCallback = (status) => {
        let message = ""
        switch (status) {
            case JOBPOST_CANDIDATE_STATUS.SUGGESTED:
                message = "Request cancelled successfully."
                break;
            case JOBPOST_CANDIDATE_STATUS.NOT_INTERESTED:
                message = "Candidate rejected successfully."
                break;
            case JOBPOST_CANDIDATE_STATUS.REQUESTED:
                message = "Candidate requested successfully."
                break;
        }
        return message;
    }

    const candidateAction = async (actionStatus, proposalId) => {
        const callback = handleJobpostActionCallback(actionStatus);
        const data = { status: actionStatus }
        await dispatch(Actions.jobpostCandidateActions(data, proposalId)).then(response => {
            if (response.status === 200) {
                toast.success(callback);
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error.message);
        });
        if (candidates?.results && actionStatus === 2) {
            let processingCandidates = {};
            Object.keys(candidates?.results)?.forEach(value => {
                if (candidates?.results?.[value]?.length === 1) {
                    processingCandidates[value] = 'processing';
                } else {
                    processingCandidates[value] = candidates?.results?.[value];
                }
            })
            dispatch({ type: "CANDIDATE_LIST", payload: { ...candidates, results: processingCandidates } })
        }
        return dispatch(Actions.getJobPostAllCandidates(jobPostId, 1, prevPageSize));
    }

    const getJobDetail = (id) => {
        dispatch(Actions.getSingleJobPost(id)).then((res) => {
            if (res.status === 200) {
                let data = { ...res.data };
                dispatch({ type: "JOBPOST_DETAIL", payload: data });
            } else {
                toast.error("Job Post could not be found");
                history.push('/manage-job-posts');
            }
        }).catch(() => {
            toast.error("Something went wrong...");
            history.push('/manage-job-posts');
        });

    }

    const handleScroll = (event) => {
        let top = event.target.scrollTop + 5;
        let bottom = event.target.scrollHeight - event.target.clientHeight;

        if (top >= bottom && candidates?.next && !loadMore) {
            setLoadMore(true);
        }
    }

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-job-posts', path: '/manage-job-posts' }
    ];

    useEffect(() => {
        getJobDetail(jobPostId);
        getCandidateList(jobPostId, 1, prevPageSize);
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    }, []);

    useEffect(() => {
        if (loadMore) {
            const size = prevPageSize + pageSize;
            dispatch(Actions.getJobPostAllCandidates(jobPostId, 1, size)).then(() => {
                setLoadMore(false);
            }).catch(() => {
                setLoadMore(false);
            });
            setPrevPageSize(size);
        }
    }, [loadMore]);

    return (
        <>
            {pageLoading ?
                <JobpostDetailsSkeleton />
                :
                <Grid className="top-job-post">
                    <Container>
                        <Grid container className='job-post-inner'>
                            <Grid item lg={12}>
                                <Stack spacing={2} className='breadcrumb-div'>
                                    <Breadcrumbs
                                        className='breadcrumb-nav'
                                        separator={<NavigateNextIcon fontSize="small" />}
                                        aria-label="breadcrumb"
                                        color="#6A8CAA"
                                    >
                                        <CustomBreadcrumbs current="job-post-details" previous={breadCrumbs} />
                                    </Breadcrumbs>
                                </Stack>
                            </Grid>
                        </Grid>
                        <div className='job-post-main-content-detail'>
                            <JobPostDetailsCard />
                            <Grid container>
                                <Grid item lg={12} sm={12}>
                                    <div className='job-post-description'>
                                        <Typography variant="h4">Job Description</Typography>
                                        <Typography variant="body1">
                                            <ReadMoreReadLess
                                                charLimit={400}
                                                readMoreText={"Read More"}
                                                readLessText={"Read Less"}
                                                readMoreClassName="read-more-open-39A1FF"
                                                readLessClassName="read-more-close-39A1FF"
                                                data={jobPostDetail?.description || ''}
                                                textType={false}
                                            />
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container className='job-technology' spacing={3}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <div className='job-post-skill'>
                                        <Typography variant="h4">Technologies</Typography>
                                        <Grid>
                                            <List className="categoryList jobpost-listing-chip">
                                                {jobPostDetail?.technologies?.map((technologies, index) => (
                                                    <ListItem className="subcategoryList-item" key={index}>
                                                        <ListItemText primary={technologies?.title} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>
                                    <div className='job-post-skill'>
                                        <Typography variant="h4">Skills</Typography>
                                        <Grid>
                                            <List className="categoryList jobpost-listing-chip">
                                                {jobPostDetail?.skills?.map((skill, index) => (
                                                    <ListItem className="subcategoryList-item" key={index}>
                                                        <ListItemText primary={skill?.title} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item sm={12}>
                                    <div className='post-day'>
                                        <Typography variant="body1">
                                            Posted <span> {moment(jobPostDetail?.job_posted, "YYYYMMDD, h:mm:ss a").fromNow(true)} </span>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            {(jobPostDetail?.status === 1 || jobPostDetail?.status === 4) &&
                                <>
                                    <Grid>
                                        <div className='candidate-header'>
                                            <Typography variant='h3'>
                                                Candidates
                                            </Typography>
                                            {/* <Typography variant='body1'>
                                        <span>{candidates?.best_matches || 0}</span> Best Match
                                    </Typography> */}
                                        </div>
                                    </Grid>
                                    {candidates?.results && Object.keys(candidates?.results).length > 0 ?
                                        <div className="job-candidate-loader">
                                            <Grid className="job-candidates" onScroll={handleScroll}>
                                                {loadMore &&
                                                    <Grid className="candidate-position-loder">
                                                        <CircularProgress />
                                                    </Grid>
                                                }
                                                {candidates?.results &&
                                                    Object.keys(candidates?.results)?.map((candidate, key) => {
                                                        return (<div className='create-postion-card' key={key}>
                                                            <div className='position-content'>
                                                                <Typography variant='h4'>{`Position ${candidate}`}</Typography>
                                                            </div>
                                                            {(candidates?.results?.[candidate] === 'processing' || candidates?.results?.[candidate]?.searching_in_progress)
                                                                ?
                                                                <>
                                                                    <Grid container spacing={3}>
                                                                        <CandidateCardSkeleton />
                                                                        <CandidateCardSkeleton />
                                                                        <CandidateCardSkeleton />
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <Grid container spacing={3}>
                                                                    {Array.isArray(candidates?.results?.[candidate]) ?
                                                                        candidates?.results?.[candidate]?.map(each => (
                                                                            <CandidatesCard
                                                                                profile={each?.candidate}
                                                                                key={each?.candidate?.uid}
                                                                                action={candidateAction}
                                                                                jobPostId={jobPostId}
                                                                                proposalId={each?.id}
                                                                                proposalStatus={each?.status}
                                                                                jobPostDetail={jobPostDetail}
                                                                            />
                                                                        )) :
                                                                        <Grid item lg={12}>
                                                                            <Card className="candidate-card">
                                                                                <Typography>{candidates?.results?.[candidate]}</Typography>
                                                                            </Card>
                                                                        </Grid>
                                                                    }
                                                                </Grid>


                                                            }
                                                        </div>)
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                        :
                                        <>
                                            {candidates?.searching_in_progress
                                                ?
                                                <Grid className="job-candidates">
                                                    <div className='create-postion-card'>
                                                        <Grid container spacing={3}>
                                                            <CandidateCardSkeleton />
                                                            <CandidateCardSkeleton />
                                                            <CandidateCardSkeleton />
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                :
                                                <Grid className='manage-developer-paper'>
                                                    <div className='no_data-content'>
                                                        <div>
                                                            <img src={noDataImg} alt="no data" />
                                                            <Typography variant="h6">
                                                                No Developer Found!!
                                                            </Typography>

                                                        </div>
                                                    </div>
                                                </Grid>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Container>
                </Grid>
            }
        </>
    );
}
export default JobPostDetail;