import React, { useEffect, useContext } from "react";
import PersonalDetailForm from "./component/PersonalDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import * as DeveloperActions from '../../store/actions/manageDevelopers/manageDevelopers.actions';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useHistory } from "react-router";
import { dispatchPageLoading } from "utils/Common";

function DeveloperPersonalDetails(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const developerDetails = useSelector((state) => state?.manageDevelopersReducer?.agencyDeveloperDetails);
    const history = useHistory();
    const devId = match?.params?.agencyDeveloperId || null;

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-developers', path: '/manage-developers' },
        { name: 'developer-profile', path: `/developer-profile/${devId}` },
    ]
    const getAgencyDeveloperById = async (developerId) => {
        dispatch(DeveloperActions.getAgencyDeveloperById(developerId));
    };
    const updateDeveloperProfile = (values) => {
        const addUserProfileData = {
            first_name: values.first_name,
            last_name: values.last_name,
            contact_number: values.contact_number,
        };
        toggalLoader(true);
        dispatch(DeveloperActions.agencyDeveloperUpdate(developerDetails.id, addUserProfileData))
            .then((response) => {
                if (response.status === 200) {
                    toast.success("User detail updated successfully");
                    history.push(`/developer-profile/${devId}/`);
                } else {
                    Object.keys(response.data).forEach((error) => {
                        toast.error(response.data[error][0]);
                    })
                }
                toggalLoader(false);
            })
            .catch(() => {
                toggalLoader(false);
            });
    };

    useEffect(() => {
        if (devId) {
            getAgencyDeveloperById(devId);
            dispatchPageLoading(dispatch);
        }

    }, [devId]);

    useEffect(() => { return () => { dispatch({ type: "PAGE_LOADING", payload: true }) } }, []);

    return (
        <div>
            <Grid className='profile-top-section freelancer_profile-top-section custom-bread-crumb'>
                <Container>
                    <Grid container className='profile-top-inner'>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-personal-details" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className='clientId'>Developer Name : <span>{`${developerDetails?.first_name} ${developerDetails?.last_name}`}</span></p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            {developerDetails &&
                <PersonalDetailForm
                    initialValues={{ ...developerDetails }}
                    onSubmit={(values) => {
                        updateDeveloperProfile(values);
                    }}
                    devId={devId}
                />
            }
        </div>
    );
}

export default DeveloperPersonalDetails;
