import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SearchBar from '../SearchBar';
import 'assets/css/myrequest.css';
import { JOBPOST_CANDIDATE_STATUS } from "../../../../utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../../store/actions/index";
import { toast } from 'react-toastify';
import ClientRequestTable from './ClientRequestTable';
import TableSkeleton from 'views/Jobpost/component/Skeletons/TableSkeleton';
import RequestSkeleton from 'views/Jobpost/component/Skeletons/RequestSkeleton';
import { errorToast } from 'utils/Common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography variant='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme?.palette?.background?.paper,
    },
}));


function ClientRequests() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [pageData, setPageData] = useState({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-title' });
    const candidates = useSelector(state => state?.jobPostReducer?.requestedCandidates);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const contentLoading = useSelector(state => state?.commonReducer?.contentLoading);
    const candidateStatuses = JOBPOST_CANDIDATE_STATUS;
    const [tableLoading, setTableLoading] = useState(false);
    window.scrollTo(0, 0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
        setPageData({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-title' });
    };
    const getCandidates = (page = 1, pageSize = pageData.pageSize, search = pageData.search, ordering = "-title") => {
        let status = candidateStatuses.REQUESTED;
        if (value === 1) {
            status = candidateStatuses.REQUEST_REJECTED;
        }
        return dispatch(Actions.getCandidatesByStatus(status, page, pageSize, search, ordering));
    }
    const candidateActions = (requestId, status, message) => {
        const data = {
            status,
        }
        return dispatch(Actions.jobpostCandidateActions(data, requestId)).then(response => {
            if (response.status === 200) {
                toast.success(message);
                setPageData({ ...pageData, page: 1 })
                return getCandidates();
            } else {
                errorToast(response);
            }
        });
    }
    const filterData = (page = pageData.page, pageSize = pageData.pageSize, search = pageData.search, order = pageData.order, orderBy = pageData.orderBy) => {
        if (JSON.stringify(pageData) !== JSON.stringify({ page, pageSize, search, order, orderBy })) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
            setPageData({ page, pageSize, search, order, orderBy });
            getCandidates(page, pageSize, search, orderBy);
        }
    }
    useEffect(() => {
        setTableLoading(true);
        getCandidates();
        setTimeout(() => {
            setTableLoading(false);
        }, 1000);
    }, [value]);
    useEffect(() => {
        return () => { dispatch({ type: "PAGE_LOADING", payload: true }); }
    }, []);

    return (
        <>
            {pageLoading ?
                <RequestSkeleton classes={classes} />
                :
                <Grid className="request-top-section">
                    <Container>
                        <Grid className="request-top-inner">
                            <div className="request-heading top-heading-content">
                                <Typography variant='h3'>My Requests</Typography>
                            </div>
                            <div className='request-table'>
                                <div className={classes.root}>
                                    <AppBar position="static">
                                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                            <Tab label="Requested" {...a11yProps(0)} />
                                            <Tab label="Rejected" {...a11yProps(1)} />
                                        </Tabs>
                                    </AppBar>
                                    <div className='main-table-filter'>
                                        <div className='searchbar-content'>
                                            <SearchBar filter={filterData} pageData={pageData} />
                                        </div>
                                    </div>
                                    <TabPanel value={value} index={0}>
                                        <div className='main-table-filter'>
                                            <div className='job-post-table'>
                                                {tableLoading ?
                                                    <TableSkeleton />
                                                    :
                                                    <ClientRequestTable
                                                        data={candidates}
                                                        action={candidateActions}
                                                        filter={filterData}
                                                        tab={value}
                                                        page={pageData}
                                                        loading={contentLoading}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className='main-table-filter'>
                                            <div className='job-post-table'>
                                                {tableLoading ?
                                                    <TableSkeleton />
                                                    :
                                                    <ClientRequestTable
                                                        data={candidates}
                                                        action={candidateActions}
                                                        filter={filterData}
                                                        tab={value}
                                                        page={pageData}
                                                        loading={contentLoading}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                </Grid>
            }
        </>
    );
}
export default ClientRequests;