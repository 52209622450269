import React, { useEffect, useState } from "react";
import ProfilePage from "./component/ProfilePage";
import { useDispatch } from 'react-redux';
import * as Actions from '../../store/actions/index';
import { toast } from "react-toastify";
import { checkForImageValidations } from "utils/Common";
import ImageCropDialog from "components/Dialog/ImageCropDialog";

function FreelancerProfile() {
    const dispatch = useDispatch();
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });

    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file instanceof File ? file : null });
    }

    const getUserProfile = () => {
        dispatch(Actions.getUserProfile()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_PROFILE', payload: response.data });
            } else {
                dispatch({ type: 'GET_USER_PROFILE', payload: null });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_PROFILE', payload: null });
        });
    }

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(res => {
            if (res.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: res.data });
            }
            else {
                dispatch({ type: 'GET_USER_DETAILS', payload: {} });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_DETAILS', payload: {} });
        })
    }

    const uploadProfileImage = async (event) => {
        try {
            if (event.target.files.length > 0) {
                const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message);
                    event.target.value = null;
                } else {
                    handleImgCropOpen(event.target.files[0]);
                    event.target.value = null;
                }
            }
        } catch (error) {
            toast.error('Something went wrong... ' + error.message);
            event.target.value = null;
        }
    }

    const uploadCroppedImage = (file) => {
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(Actions.uploadProfileImage(formData)).then(response => {
            if (response.status == 201) {
                getUserProfile();
                getUserDetails();
                handleImgCropOpen();
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    useEffect(() => {
        getUserProfile();
        getUserDetails();
    }, []);

    return (
        <>
            <ProfilePage uploadProfileImage={uploadProfileImage} />
            <ImageCropDialog open={cropHandlers.openDialog} close={handleImgCropOpen} file={cropHandlers.file} uploadCroppedImage={uploadCroppedImage} />
        </>
    );
}

export default FreelancerProfile;
