import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "assets/css/allModals.css";
import "assets/css/profile.css";
import "assets/css/add-developer.css";
import DeveloperDetailsForm from "./component/DeveloperDetailsForm";
import * as Actions from "store/actions/index";
import history from "utils/history";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { checkForImageValidations } from "utils/Common";

function EditDeveloper(props) {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const { match } = props;
    const agencyDeveloperFormDetails = useSelector((state) => state.manageDevelopersReducer?.agencyDeveloperDetails);
    const [files, setFiles] = useState({ profile: null, identity: null });

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        { name: 'manage-developers', path: '/manage-developers' },
        { name: 'developer-profile', path: `/developer-profile/${match?.params?.agencyDeveloperId}` },
    ];
    const getAgencyDeveloperById = (developerId) => {
        dispatch(Actions.getAgencyDeveloperById(developerId));
    };

    useEffect(() => {
        if (match.params?.agencyDeveloperId) {
            getAgencyDeveloperById(match.params.agencyDeveloperId);
        }
    }, []);
    useEffect(() => {
        if (agencyDeveloperFormDetails?.on_boarding_steps) {
            history.push('/manage-developers/add', { developerId: agencyDeveloperFormDetails?.id });
        }
    }, [agencyDeveloperFormDetails])


    const handleImageUpload = async (e) => {
        try {
            let file = null;
            let whichFile = null;
            let dimensionCheck = true;
            let fileType = null;
            if (e instanceof File) {
                file = e;
                whichFile = 'identity';
                dimensionCheck = false;
                fileType = 'file';
            } else if (e.target.files.length !== 0) {
                file = e.target.files[0];
                whichFile = 'profile';
                fileType = 'image';
            }
            if (file) {
                const fileCheck = await checkForImageValidations(file, fileType, dimensionCheck);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", file);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        const filesObject = { ...files }
                        filesObject[whichFile] = response.data;
                        setFiles(filesObject);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    };
    const updateAgencyUserDeveloperDetails = (details) => {
        toggalLoader(true);
        const values = JSON.parse(JSON.stringify(details));
        const data = {
            profile_image: files.profile,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            contact_number_without_code: values.contact_number_without_code,
            portfolio: {
                skills: values.portfolio.skills.map(
                    (skill) => skill.id
                ),
                sub_skills: values.portfolio.sub_skills.map(
                    (subSkill) => subSkill.id
                ),
                technologies: values?.portfolio?.technologies?.map(
                    (tech) => tech.id
                ),
                profile_type: values.portfolio.profile_type.id,
                communication_language: values.portfolio.communication_language.map((val) => {
                    return {
                        id: val.id,
                        communication_language: val.communication_language.key,
                        proficiency: val.proficiency.id,
                    }
                }),
                experience: values.portfolio.experience,
                availability: values.portfolio.availability,
                education_details: values.portfolio.education_details,
                document_file: files.identity,
                specialties: (values.portfolio?.specialties?.replace(/<(.|\n)*?>/g, '').trim().length === 0) ? '' : values.portfolio.specialties,
                description: values.portfolio.description,
                salary_type: values.portfolio.salary_type,
                amount: values.portfolio.amount,
            }
        }
        if (!files.profile || typeof files.profile === 'string') {
            delete data.profile_image;
            delete data.portfolio.profile_image;
        }
        if (!files.identity || typeof files.identity === 'string')
            delete data.portfolio.document_file;
        dispatch(Actions.agencyDeveloperUpdate(match.params?.agencyDeveloperId, data)).then((response) => {
            if (response.status === 200) {
                toggalLoader(false);
                toast.success("Developer detail updated successfully");
                history.push(`/developer-profile/${match.params?.agencyDeveloperId}/`);
            } else {
                toggalLoader(false);
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                });
            }
        }).catch((err) => {
            toggalLoader(false);
            Object.keys(err.data).forEach((error) => {
                toast.error(err.data[error][0]);
            });
        });
    };

    return (
        <>
            <Grid className="profile-top-section add_profile-top-section custom-bread-crumb">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="edit-professional-details" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className='clientId'>Developer Name : <span>{`${agencyDeveloperFormDetails?.first_name} ${agencyDeveloperFormDetails?.last_name}`}</span></p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Edit Professional Details</h4>
                <Grid className="profile-inner add_profile-inner">
                    <DeveloperDetailsForm
                        onSubmit={(values) => {
                            updateAgencyUserDeveloperDetails(values);
                        }}
                        isAddType={false}
                        initialValues={agencyDeveloperFormDetails}
                        handleImageUpload={handleImageUpload}
                        files={files}
                    />
                </Grid>
            </Container>
        </>
    );
}

export default EditDeveloper;
