import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Link from "@mui/material/Link";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'assets/css/allModals.css';
import Typography from '@mui/material/Typography';
import defaultImg from "assets/img/default-avatar.png";
import { createFilterOptions } from '@mui/material/Autocomplete';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import DatePickerField from 'components/CustomDateRangePicker/DatePickerField';
import { required, checkUrl } from 'utils/Validation';
import TimePickerField from 'components/CustomTimePicker/TimePickerField';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import { connect, useDispatch, useSelector } from 'react-redux';
import { interviewMaxDate, handleMinTime, wrapperDeveloperRedirection, wrapperJobPostRedirection } from 'utils/Common';
import validate from './InterviewValidate';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



function RescheduleInterviewDialog(props) {
    const { open, close, handleSubmit, valid, initialValues, opponent, formStates, submitting } = props;
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.authReducer?.userDetails);

    const handleClose = (__, reason) => {
        if (reason === "backdropClick") return false;
        setTimeout(() => {
            dispatch(reset('RescheduleInterviewForm'));
        }, 500);
        close();
    }

    const handleDateChange = (date) => {
        if (formStates?.time) {
            const selectedTime = moment(formStates.time).local().format("HH:mm:ss");
            const convertedTime = moment(`${date} ${selectedTime}`).local().toDate();
            formStates.time = convertedTime;
        }
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='approval_modal offer-accept-model'
            >
                <BootstrapDialogTitle id="customized-dialog-title" className='model-top-heading' style={{ pointerEvents: submitting ? "none" : "inherit" }} onClose={handleClose}>
                    <Typography variant='h3'>Reschedule Interview</Typography>
                </BootstrapDialogTitle>
                <form onSubmit={handleSubmit} data-testid="reschedule-interview-form" id='reschedule-interview-form'>
                    <DialogContent className='interview-text'>
                        <div className='offer-content open-job_post'>
                            <div className='inner-content'>
                                <div className='offer-profile-img'>
                                    <img src={opponent?.profile_image || defaultImg} alt='' />
                                </div>
                                <div className='offer-profile-text'>
                                    <Typography variant='h5'>{opponent?.company_name}</Typography>
                                    <Typography variant='body1'>{opponent?.city?.name}, {opponent?.city?.country_name}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className='job-post-id'>
                            <Typography variant='body1'>
                                Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(userDetails?.user_type, initialValues?.job_post?.id, initialValues?.candidate?.id, initialValues?.job_post_proposal)} target='_blank'>{initialValues?.job_post?.uid}</Link></span>
                            </Typography>
                            <Typography variant='body1'>
                                Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(userDetails?.user_type, initialValues?.candidate?.id, initialValues?.job_post_proposal)} target='_blank'>{initialValues?.candidate?.uid}</Link></span>
                            </Typography>
                        </div>

                        <div className='center-content'>
                            <div className='interview-data'>

                                <div className='date-time-content'>
                                    <FormControl variant="standard" className=" form-date">
                                        <div className='select-option-data custom-select-data datetime-custom-control'>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Interview Date*
                                            </InputLabel>
                                            <div className='date-wrapper'>
                                                <Field
                                                    name="date"
                                                    id="date"
                                                    labelText=""
                                                    placeholder="Select Date"
                                                    component={DatePickerField}
                                                    inputFormat="YYYY-MM-DD"
                                                    validate={[required]}
                                                    disableFuture={false}
                                                    disablePast={true}
                                                    maxDate={interviewMaxDate()}
                                                    formatSelected='dd/MM/yyyy'
                                                    className="form-date chat-header-date"
                                                    onChange={date => handleDateChange(date)}
                                                />
                                            </div>
                                        </div>
                                    </FormControl>
                                    <FormControl variant="standard" className="form-time">
                                        <div className='select-option-data custom-select-data'>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Interview Time*
                                            </InputLabel>

                                            <div className='date-wrapper'>
                                                <Field
                                                    name="time"
                                                    id="time"
                                                    labelText=""
                                                    placeholder="Select Date"
                                                    component={TimePickerField}
                                                    inputFormat="YYYY-MM-DD"
                                                    validate={[required]}
                                                    minTime={handleMinTime(formStates?.date)}
                                                    className="form-date chat-header-date"
                                                />
                                            </div>
                                        </div>
                                    </FormControl>
                                </div>
                                <div className='select-option-data'>
                                    <FormControl variant="standard" className="">
                                        <Field
                                            name="description"
                                            component={CustomTextArea}
                                            id="description"
                                            labelText="Description"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            multiline={true}
                                            rows="2"
                                            inputProps={{
                                                placeholder: "Enter Text",
                                            }}
                                            className='multiSelect-Textfield text-area-content'
                                        />
                                    </FormControl>
                                </div>
                                <div className='select-option-data'>
                                    <FormControl variant="standard" className="">
                                        <Field
                                            name="meet_link"
                                            component={CustomInput}
                                            id="meet_link"
                                            labelText="Meeting Link*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[required, checkUrl]}
                                            inputProps={{
                                                placeholder: "Enter meeting link",
                                            }}
                                        />
                                    </FormControl>
                                </div>

                            </div>

                        </div>
                    </DialogContent>

                    <DialogActions className='bottom last-content-btn'>
                        <Link href={void (0)}
                            underline="none"
                            onClick={handleClose}
                            style={{ pointerEvents: submitting ? "none" : "inherit" }}
                            className='cancelBtn'
                        >
                            Cancel
                        </Link>
                        <LoadingButton
                            type="submit"
                            className={`modalBtn primaryButton ${submitting && 'loading'}`}
                            loading={submitting}
                            variant="contained"
                            loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size={16} />}
                            disabled={!valid}
                        >
                            Schedule
                        </LoadingButton>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </div>
    );
}

const RescheduleInterviewReduxForm = reduxForm({
    form: "RescheduleInterviewForm",
    validate,
    enableReinitialize: true,
})(RescheduleInterviewDialog);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues("RescheduleInterviewForm")(state),
    };
};

export default connect(mapStateToProps)(RescheduleInterviewReduxForm);
