import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomCheckBox from "components/CustomCheckBox/CustomCheckBox";
import { SelectAutocomplete } from "components/CustomeAutoComplete/AsynAutocomplete"
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import * as Actions from "../../../store/actions/index";
import { required, checkUrl, characterLimit, numberOnly, registrationNumber, checkPhoneNumber, company_size_required } from 'utils/Validation'
import BranchFields from 'components/BranchFields/BranchFields'
import UnderApprovedDialog from 'components/Dialog/UnderApprovedDialog'
import history from "utils/history";
import CustomFileUpload from "components/customUpload/CustomFileUpload";
import { FILETYPE } from "utils/constant";
import { avoidKeysForNumber, avoidPasting, getCountryCode, getCountryAndPhone } from "utils/Common";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import Loader from 'assets/img/SF_Loader.gif';
import { charOnly,email } from "utils/Validation";

function ClientForm(props) {
    const dispatch = useDispatch();
    const { countryList, handleSubmit, change, initialValues, formStates, uploadImage, files, getCountryList, removeList, uploadProfile, openDialog } = props;
    const userDetails = useSelector(state => state.authReducer.userDetails);
    const pageLoading = useSelector(state => state.commonReducer?.pageLoading);
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState({ userCountry: {} });
    const [cityList, setCityList] = useState({ userCityList: [], });
    const [branchCC, setBranchCC] = useState({ country: [], city: [] });
    const path = window.location.pathname;
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);

    useEffect(() => {
        if (initialValues && initialValues.branches.length) {
            setBranchCC({ country: initialValues.branches.map((val) => (val.country)) })
        }
        if (initialValues && initialValues.city) {
            setCityList({ userCityList: [initialValues.city] })
        }
        if (initialValues && initialValues.country) {
            setCountry({ userCountry: initialValues?.country })
        }
    }, [initialValues])
    const handleChangeCountry = (value) => {
        const phoneDetails = getCountryAndPhone(value, countryCode);
        setCountryCode(phoneDetails.countryCode);
        setPhoneNumber(phoneDetails.phone);
        change('city', '')
        setCityList({ userCityList: [] });
        setCountry({ userCountry: value });
        change('contact_number', '');
    };
    const handleChangeCity = (name) => {
        if (country.userCountry?.id) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                if (name.length > 2) {
                    // setLoading(true)
                    dispatch(Actions.getCityList({ country: country.userCountry?.id, name: name }))
                        .then((response) => {
                            // setLoading(false)
                            if (response.status === 200) {
                                setCityList({ userCityList: response.data });
                            } else {
                                setCityList({ userCityList: [] });
                            }
                        }).catch(() => {
                            setCityList({ userCityList: [] });
                        })
                }
            }, 1000));
        }
    };
    const handleChangeBranchCountry = (value, index) => {
        let tempArr = branchCC;
        tempArr.country[index] = value;
        setBranchCC({ ...branchCC, ...tempArr });

    };
    const handleChangeBranchCity = (name, index) => {
        if (branchCC.country[index]) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                if (name.length > 2) {
                    setLoading(true)
                    dispatch(Actions.getCityList({ country: branchCC.country[index].id, name: name }))
                        .then((response) => {
                            setLoading(false)
                            let tempArr = branchCC;
                            tempArr.city = []
                            if (response.status === 200 && response.data.length > 0) {
                                tempArr.city[index] = response.data
                                setBranchCC({ ...branchCC, ...tempArr })
                            } else {
                                tempArr.city[index] = []
                                setBranchCC({ ...branchCC, ...tempArr })
                            }
                        }).catch(() => {
                            let tempArr = branchCC;
                            tempArr.city[index] = []
                            setBranchCC({ ...branchCC, ...tempArr })
                        })
                }
            }, 1000));
        }
    };
    const backToHome = () => {
        history.push('/home');
    }

    const handleChangePhone = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }

    useEffect(() => {
        if (initialValues?.contact_number) {
            setCountryCode(getCountryCode(initialValues.contact_number));
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number', `+91`);
        }
    }, [initialValues]);

    return (
        <>
            <Container className='profile-container add_profile-container'>
                <h4>{userDetails.is_new_registered ? 'Add' : 'Edit'} Profile Details</h4>
                <Grid className='profile-inner add_profile-inner'>
                    {pageLoading ?
                        <div className="loder-main-rout">
                            <Grid className="form-loader-div">
                                <img src={Loader} alt='img' className="form-loader" />
                            </Grid>
                        </div>
                        :
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid className='add_profile-form'>
                                <h5 className='title'>
                                    Personal Details
                                </h5>
                                {(path === '/my-profile/add') &&
                                    <Grid item lg={12} md={12} sm={12} className="text-center">
                                        <ProfilePicture files={files} uploadProfileImage={uploadProfile} type='add' />
                                    </Grid>}
                                <Grid container className="formGrid">
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="first_name"
                                            component={CustomInput}
                                            id="first_name"
                                            labelText="First Name*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[required,charOnly]}
                                            inputProps={{
                                                placeholder: "Enter first name",
                                                type: "text",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="last_name"
                                            component={CustomInput}
                                            id="last_name"
                                            labelText="Last Name*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[required,charOnly]}
                                            inputProps={{
                                                placeholder: "Enter last name",
                                                type: "text",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className="formGrid">
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="country"
                                            component={SelectAutocomplete}
                                            id="country"
                                            labelText="Country*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={countryList}
                                            displayKey="name"
                                            validate={[required]}
                                            onChange={(value) => handleChangeCountry(value)}
                                            inputProps={{
                                                placeholder: "Choose country",
                                            }}
                                            onSearch={(e) => getCountryList(e.target.value)}
                                        // onFocusEvent={(event) => getCountryList(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="city"
                                            id="city"
                                            labelText="City*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            displayKey="display_name"
                                            options={cityList?.userCityList?.length > 0 ? cityList?.userCityList : []}
                                            component={SelectAutocomplete}
                                            loading={loading}
                                            required
                                            validate={[required]}
                                            inputProps={{
                                                placeholder: "Choose city",
                                            }}
                                            onSearch={(e) => handleChangeCity(e.target.value)}
                                            onKeyDown={avoidPasting}
                                            disabled={!formStates?.country}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className="formGrid">
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="email"
                                            component={CustomInput}
                                            id="email"
                                            labelText="Email*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[required,email]}
                                            inputProps={{
                                                placeholder: "Enter work email address",
                                                type: "email",
                                                disabled: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className="formGrid-inner">
                                        <Field
                                            name="contact_number"
                                            component={PhoneNumberField}
                                            id="contact_number"
                                            labelText="Contact Number*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[checkPhoneNumber]}
                                            inputProps={{
                                                placeholder: "Enter valid contact number",
                                            }}
                                            disableDropdown={true}
                                            phone={phoneNumber}
                                            countryCode={countryCode}
                                            handleChangePhone={handleChangePhone}
                                        />
                                    </Grid>
                                </Grid>

                                <h5 className='title'>
                                    Company Details
                                </h5>
                                <Grid container className='formGrid'>
                                    <Grid className='formGrid-inner'>
                                        <Field
                                            name="company.name"
                                            component={CustomInput}
                                            id="company.name"
                                            labelText="Company Name*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[required]}
                                            inputProps={{
                                                placeholder: "Enter company name",
                                                type: "text",
                                            }}
                                            normalize={characterLimit(50)}
                                        />
                                    </Grid>
                                    <Grid className='formGrid-inner registered-formGrid'>
                                        <Field
                                            name="company.is_registered_company"
                                            component={CustomCheckBox}
                                            labelText="Your Company is Registered?"
                                            id="company.is_registered_company"
                                            defaultChecked={(formStates?.company?.is_registered_company) ? true : false}
                                            onChange={(_e, checked) => {
                                                if (!checked) change('company.registration_number', '')
                                            }}
                                        />
                                        <Field
                                            name="company.registration_number"
                                            component={CustomInput}
                                            id="company.registration_number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={formStates?.company?.is_registered_company ? [required, registrationNumber] : null}
                                            inputProps={{
                                                placeholder: "Enter company registration number",
                                                disabled: (formStates?.company?.is_registered_company) ? false : true,
                                                type: "text",
                                            }}
                                        // normalize={characterLimit(50)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className='formGrid'>
                                    <Grid className='formGrid-inner'>
                                        <Field
                                            name="company.size"
                                            component={CustomInput}
                                            id="company.size"
                                            labelText="Company Size*"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            validate={[company_size_required, numberOnly]}
                                            inputProps={{
                                                placeholder: "Enter company size",
                                                type: "number",
                                            }}
                                            onKeyDown={avoidKeysForNumber}
                                        />
                                    </Grid>
                                    <Grid className='formGrid-inner'>
                                        <Field
                                            type="file"
                                            name='company.identity'
                                            id="company.identity"
                                            component={CustomFileUpload}
                                            onChange={(e) => uploadImage(e)}
                                            validate={[required]}
                                            mimeType={FILETYPE.file}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className='formGrid'>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Field
                                            name="company.company_url"
                                            component={CustomInput}
                                            id="company_url"
                                            labelText="Company Url"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            helperText='demo'
                                            validate={[checkUrl]}
                                            inputProps={{
                                                placeholder: "Enter Website",
                                                type: "text",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <h5 className='title'>
                                    Branches Details
                                </h5>
                                <FieldArray
                                    name="branches"
                                    countryList={countryList}
                                    branchCC={branchCC}
                                    component={BranchFields}
                                    handleChangeCity={handleChangeBranchCity}
                                    handleChangeCountry={handleChangeBranchCountry}
                                    loading={loading}
                                    change={change}
                                    required={required}
                                    removeList={removeList}
                                    getCountryList={getCountryList}
                                    formStates={formStates}
                                />

                                <Grid className='action-div'>
                                    {(path === '/my-profile/edit') &&
                                        <Link onClick={() => history.push("/my-profile")} underline="none" className='previousBtn cursor-pointer'>Cancel</Link>
                                    }
                                    <Button type="submit" variant="contained" className='primaryButton nextBtn' >Submit</Button >
                                </Grid>
                            </Grid>
                        </form>
                    }
                </Grid>
            </Container>
            {openDialog &&
                <UnderApprovedDialog open={openDialog} backToHome={backToHome} />
            }
        </>
    );
}

const ReduxClientFormMapped = reduxForm({
    form: "ClientForm", // a unique identifier for this form
    enableReinitialize: true,
})(ClientForm);

const mapStateToProps = (state) => {
    return {
        formStates: getFormValues('ClientForm')(state) // here 'form' is the name you have given your redux form 
    }
}

export default connect(mapStateToProps)(ReduxClientFormMapped);
