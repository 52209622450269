import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "assets/css/developer-profile.css";
import "assets/css/client-profile.css";
import "assets/css/profile.css";
import "assets/css/projectCard.css";
import suitcaseIcon from "assets/img/icons/suitcase-dark.svg";
import paperIcon from "assets/img/icons/paper-dark.svg";
import clockIcon from "assets/img/icons/clock-dark.svg";
import rightGreenArrow from "assets/img/icons/right-green-arrow.svg";
import graduateIcon from "assets/img/icons/graduate-cap-dark.svg";
import * as Actions from "../../../store/actions/index";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getAvailability, errorToast } from "utils/Common";
import { PROFICIENCY_TAG, PROFICIENCY_DIVIDER, JOBPOST_CANDIDATE_STATUS, USER_TYPES } from "utils/constant";
import CandidateCertificationSection from "./CandidateCertificationSection";
import CandidateProjectsSection from "./CandidateProjectsSection";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button, Link } from "@mui/material";
import CandidateJobpostCard from "./CandidateJobpostCard";
import CancleBtnRed from 'assets/img/icons/close-icon-red.svg';
import ChatBtnBlue from 'assets/img/icons/chat-icon.svg';
import JobpostCandidateProfileSkeleton from "./Skeletons/JobpostCandidateProfileSkeleton";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

function JobpostCandidateProfile(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const proposalId = match.params?.proposalId;
    const jobPostId = match.params?.jobPostId;
    const candidate = useSelector(state => state?.jobPostReducer?.candidateProfile);
    const candidateProjects = useSelector(state => state?.jobPostReducer?.candidateProjects);
    const candidateCertifications = useSelector(state => state?.jobPostReducer?.candidateCertificates);
    const proposalData = useSelector(state => state?.jobPostReducer?.proposalData);
    const jobPostData = useSelector(state => state?.jobPostReducer?.jobPostDetail);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const user = useSelector(state => state?.authReducer?.userDetails);
    const candidatePortfolio = candidate?.portfolio;
    const projectPageSize = 5;
    const certificationPageSize = 3;
    const history = useHistory();
    const path = window.location.pathname;
    const [page, setPage] = useState(1);
    const [actionDialogue, setActionDialogue] = useState({ open: false, title: 'Are you sure ?', message: "" })
    const [actionStatus, setActionStatus] = useState(null);
    const [processing, setProcessing] = useState(false);

    const openActionDialogue = (message = "", status = null) => {
        setActionStatus(status);
        const dialogueData = { ...actionDialogue, open: !actionDialogue.open, message };
        setActionDialogue(dialogueData);
    }
    const closeActionDialogue = () => {
        setActionDialogue({ ...actionDialogue, open: false });
    }

    const breadCrumbs = () => {
        if (path === `/job-post/${jobPostId}/candidate/${proposalId}`) {
            return [
                { name: 'home', path: '/home' },
                { name: 'manage-job-posts', path: '/manage-job-posts' },
                { name: 'job-post-details', path: `/job-post-detail/${jobPostId}` },
            ];
        } else if (path === `/my-requests/${proposalId}`) {
            return [
                { name: 'home', path: '/home' },
                { name: 'my-requests', path: '/my-requests' },
            ];
        } else if (path === `/my-proposals/${proposalId}`) {
            return [
                { name: 'home', path: '/home' },
                { name: 'my-proposals', path: '/my-proposals' },
            ];
        }
        return null;
    }

    const handleJobpostActionCallback = (status) => {
        let callback = {
            message: "",
            route: null,
        }
        switch (status) {
            case JOBPOST_CANDIDATE_STATUS.SUGGESTED:
                callback.message = "Request cancelled successfully."
                break;
            case JOBPOST_CANDIDATE_STATUS.NOT_INTERESTED:
                callback.message = "Candidate rejected successfully."
                callback.route = jobPostId ? `/job-post-detail/${jobPostId}` : '/my-requests';
                break;
            case JOBPOST_CANDIDATE_STATUS.REQUESTED:
                callback.message = "Candidate requested successfully."
                break;
            case JOBPOST_CANDIDATE_STATUS.OFFER_REJECTED:
                callback.message = "Offer has been rejected"
                callback.route = user?.user_type === USER_TYPES.AGENCY ? `/proposals` : '/my-proposals';
                break;
        }
        return callback;
    }

    const getProposal = () => {
        return dispatch(Actions.getJobPostProposalById(proposalId)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "CANDIDATE_PROFILE", payload: response?.data?.candidate });
                dispatch({ type: "JOBPOST_DETAIL", payload: response?.data?.job_post });
                dispatch({ type: "PROPOSAL_DATA", payload: response?.data });
            } else {
                dispatch({ type: "CANDIDATE_PROFILE", payload: null });
                dispatch({ type: "JOBPOST_DETAIL", payload: null });
                dispatch({ type: "PROPOSAL_DATA", payload: null });
                errorToast(response);
                history.goBack();
            }
        }).catch(() => {
            dispatch({ type: "CANDIDATE_PROFILE", payload: null });
            dispatch({ type: "JOBPOST_DETAIL", payload: null });
            dispatch({ type: "PROPOSAL_DATA", payload: null });
            toast.error("Something went wrong");
            history.goBack();
        })
    }

    const getCandidateProjects = (page = 1, pageSize = projectPageSize) => {
        dispatch(Actions.getJobPostCandidateProjects(candidate?.id, page, pageSize));
    }

    const getCandidateCertifications = (page = 1, pageSize = certificationPageSize) => {
        dispatch(Actions.getJobPostCandidateCertificates(candidate?.id, page, pageSize));
    }

    const handlePages = (pageNumber, which) => {
        setPage(pageNumber);
        switch (which) {
            case 'projects':
                getCandidateProjects(pageNumber, projectPageSize);
                break;

            case 'certificates':
                getCandidateCertifications(pageNumber, certificationPageSize);
                break;
        }
    }

    const candidateActions = async (actionedStatus) => {
        const callback = handleJobpostActionCallback(actionedStatus);
        const data = { status: actionedStatus }
        await dispatch(Actions.jobpostCandidateActions(data, proposalId)).then(response => {
            if (response.status === 200) {
                toast.success(callback.message);
                callback.route && history.push(callback.route);
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error.message);
        });
        if (!callback.route) return getProposal();
        else return new Promise();
    }

    const handleCandidateAction = (status) => {
        setProcessing(true);
        candidateActions(status).then(() => {
            closeActionDialogue();
            setProcessing(false);
        }).catch(() => {
            closeActionDialogue();
            setProcessing(false);
        })
    }

    useEffect(() => {
        if (proposalId) {
            getProposal();
        }
    }, [])
    useEffect(() => {
        if (candidate?.id) {
            getCandidateProjects();
            getCandidateCertifications(1, 'all');
        }
    }, [candidate?.id])
    return (
        <>
            {pageLoading ?
                <JobpostCandidateProfileSkeleton path={path} jobPostId={jobPostId} proposalId={proposalId} />
                :
                <>
                    <Container>
                        <Grid container className="profile-top-inner">
                            <Grid item lg={12} md={12} sm={12}>
                                <div className="topcandidate-heading frame-btn">
                                    <CustomBreadcrumbs current="profile" previous={breadCrumbs()} />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className="top-job-post">
                        <div className="my-request-main-section">
                            {path !== `/job-post/${jobPostId}/candidate/${proposalId}` &&
                                <CandidateJobpostCard jobPostId={jobPostData?.id} />}

                            <Grid container className="candidate-request-profile dashboard-container candidate-profile request-main-profile ">
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <Grid className="left-profile-container">
                                        <div className="profile-details">
                                            <Typography variant="h5" className="profile-id">
                                                {candidate?.uid || "N/A"}
                                            </Typography>
                                            <Typography className="profile-position">
                                                {candidatePortfolio?.profile_type?.title || "N/A"}
                                            </Typography>
                                        </div>
                                        <div className="profile-sub-data">
                                            <div className="profile-subDetails">
                                                <img src={suitcaseIcon} alt="img" />
                                                <Typography>
                                                    <span>{candidatePortfolio?.experience ? `${candidatePortfolio?.experience} Years` : "N/A"}</span>
                                                    <br></br>
                                                    Experience
                                                </Typography>
                                            </div>
                                            <div className="profile-subDetails">
                                                <img src={paperIcon} alt="img" />
                                                <Typography>
                                                    <span>{candidatePortfolio?.projects_count || 0}</span>
                                                    <br></br>
                                                    Projects Done
                                                </Typography>
                                            </div>
                                            <div className="profile-subDetails">
                                                <img src={clockIcon} alt="img" />
                                                <Typography>
                                                    <span>{getAvailability(candidatePortfolio?.availability)}</span>
                                                    <br></br>
                                                    Availability
                                                </Typography>
                                            </div>
                                            <div className="profile-subDetails">
                                                <img src={graduateIcon} alt="img" />
                                                <Typography>
                                                    <span>{candidatePortfolio?.education_details || "N/A"}</span>
                                                    <br></br>
                                                    Education
                                                </Typography>
                                            </div>
                                        </div>

                                        <Divider style={{ padding: "5px 0" }} />

                                        <Grid className="lang-section candidate-lang-section">
                                            <Typography className="candidate-profile-title">Languages</Typography>
                                            <div className="language-section">
                                                {candidatePortfolio?.communication_language?.map((lang) => (
                                                    <div className="language-div" key={lang?.communication_language?.key}>
                                                        <Typography className="side-bar-language-content" id="langEng-slider">{lang?.communication_language?.value}</Typography>
                                                        <Typography className="lang-status">{PROFICIENCY_TAG[lang?.proficiency?.id]}</Typography>
                                                        <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[lang?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                                                    </div>
                                                ))}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={9} md={9} sm={12} xs={12}>
                                    {proposalData?.status === JOBPOST_CANDIDATE_STATUS.SUGGESTED &&
                                        <div className="topcandidate-heading frame-btn reject-btn-right">
                                            <Button className="btn-primary" disabled={processing} onClick={() => openActionDialogue("you want to reject", JOBPOST_CANDIDATE_STATUS.NOT_INTERESTED)}>Reject</Button>

                                            <LoadingButton
                                                type="submit"
                                                className={`primaryButton ${processing && 'loading'}`}
                                                loading={processing}
                                                variant="contained"
                                                color="secondary"
                                                loadingIndicator={<CircularProgress sx={{ color: '#ffffff' }} size={16} />}
                                                onClick={() => handleCandidateAction(JOBPOST_CANDIDATE_STATUS.REQUESTED)}
                                                disabled={processing}
                                            >
                                                Send Request
                                            </LoadingButton>
                                        </div>}
                                    {proposalData?.status === JOBPOST_CANDIDATE_STATUS.REQUESTED &&
                                        <div className="rightcandidate-heading frame-btn request-btn-right">
                                            <Link onClick={() => openActionDialogue("you want to cancel the request", JOBPOST_CANDIDATE_STATUS.SUGGESTED)} underline="none" className="previousBtn cursor-pointer" style={{ pointerEvents: processing ? "none" : "inherit" }}>
                                                Cancel
                                            </Link>
                                            <span className="primaryButton"><img src={rightGreenArrow} alt="" />  Requested </span>
                                        </div>}
                                    {proposalData?.status === JOBPOST_CANDIDATE_STATUS.REQUEST_ACCEPTED &&
                                        <div className='jobpost-detail-btn chat-cancle-btn '>
                                            <div className='cancle-btn cursor-pointer' style={{ pointerEvents: processing ? "none" : "inherit" }} onClick={() => history.push('/chat', { agency: { room: proposalData?.chat_room } })}><img src={ChatBtnBlue} /></div>

                                            <div className='cancle-btn cancle-btn-red cursor-pointer' style={{ pointerEvents: processing ? "none" : "inherit" }} onClick={() => openActionDialogue("you want to reject the offer", JOBPOST_CANDIDATE_STATUS.OFFER_REJECTED)}>
                                                <img src={CancleBtnRed} /></div>
                                        </div>}
                                    <Grid className="right-profile-container">
                                        <Grid className="profile-aboutContent">
                                            <Typography className="candidate-profile-title">
                                                About
                                            </Typography>
                                            <Typography className="candidate-profile-description">
                                                <ReadMoreReadLess
                                                    charLimit={500}
                                                    readMoreText={"Read More"}
                                                    readLessText={"Read Less"}
                                                    readMoreClassName="read-more-open-39A1FF"
                                                    readLessClassName="read-more-close-39A1FF"
                                                    data={candidatePortfolio?.description || "N/A"} /* eslint-disable-line */
                                                    textType={true}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid className="specialties-section">
                                            <Typography className="candidate-profile-title">
                                                Specialties
                                            </Typography>
                                            <Typography className="candidate-profile-description">
                                                <ReadMoreReadLess
                                                    charLimit={500}
                                                    readMoreText={"Read More"}
                                                    readLessText={"Read Less"}
                                                    readMoreClassName="read-more-open-39A1FF"
                                                    readLessClassName="read-more-close-39A1FF"
                                                    data={candidatePortfolio?.specialties || "N/A"} /* eslint-disable-line */
                                                    textType={true}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid className="technologies-section">
                                            <Typography className="candidate-profile-title">
                                                Technologies
                                            </Typography>
                                            <Grid>
                                                <List className='categoryList'>
                                                    {
                                                        (candidatePortfolio?.technologies && candidatePortfolio?.technologies?.length > 0)
                                                            ?
                                                            candidatePortfolio.technologies.map(technology => (
                                                                <ListItem className='categoryList-item' key={technology?.id}>
                                                                    <ListItemText
                                                                        primary={technology?.title}
                                                                    />
                                                                </ListItem>
                                                            ))
                                                            :
                                                            <Typography className="empty-text">N/A</Typography>
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                        <Grid className="skills-section">
                                            <Typography className="candidate-profile-title">
                                                Skills
                                            </Typography>
                                            <Grid>
                                                <List className='categoryList'>
                                                    {
                                                        (candidatePortfolio?.skills && candidatePortfolio?.skills?.length > 0)
                                                            ?
                                                            candidatePortfolio.skills.map(skill => (
                                                                <ListItem className='categoryList-item' key={skill?.id}>
                                                                    <ListItemText
                                                                        primary={skill?.title}
                                                                    />
                                                                </ListItem>
                                                            ))
                                                            :
                                                            <Typography className="empty-text">N/A</Typography>
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                        <Grid className="sub-skills-section">
                                            <Typography className="candidate-profile-title">
                                                Sub-Skills
                                            </Typography>
                                            <Grid>
                                                <List className='categoryList'>
                                                    {
                                                        (candidatePortfolio?.sub_skills && candidatePortfolio?.sub_skills?.length > 0)
                                                            ?
                                                            candidatePortfolio.sub_skills.map(subSkill => (
                                                                <ListItem className='categoryList-item' key={subSkill?.id}>
                                                                    <ListItemText
                                                                        primary={subSkill?.title}
                                                                    />
                                                                </ListItem>
                                                            ))
                                                            :
                                                            <Typography className="empty-text">N/A</Typography>
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>

                                        <Grid className="certificates-section">
                                            <CandidateCertificationSection
                                                candidate={candidate}
                                                certificates={candidateCertifications}
                                                handlePages={handlePages}
                                                totalPages={Math.ceil(candidateCertifications?.count / certificationPageSize) || 0}
                                                pageSize={certificationPageSize}
                                                totalRecords={candidateCertifications?.count ?? 0}
                                            />
                                        </Grid>
                                        <CandidateProjectsSection
                                            candidate={candidate}
                                            projects={candidateProjects?.results}
                                            handlePages={handlePages}
                                            totalPages={Math.ceil(candidateProjects?.count / projectPageSize) || 0}
                                            pageSize={projectPageSize}
                                            page={page}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ConfirmationDialogue
                                open={actionDialogue.open}
                                close={closeActionDialogue}
                                title={actionDialogue.title}
                                message={actionDialogue.message}
                                buttonText="Yes"
                                processing={processing}
                                buttonCallback={() => { handleCandidateAction(actionStatus); }}

                            />
                        </div>
                    </Container>
                </>
            }
        </>
    );
}

export default JobpostCandidateProfile;
