import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CustomInput from "components/CustomInput/CustomInput.js";
import { required, requiredWithRegex, checkExperience, charLimitWithTrim,charWithDot, checkIsNumber, notZero, characterLimit } from 'utils/Validation';
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { SelectField } from "components/CustomeAutoComplete/AsynAutocomplete";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions/freelancer/freelancer.actions";
import * as CommonActions from "../../../store/actions/common/common.actions";
import MenuItem from '@mui/material/MenuItem';
import 'assets/css/add-developer.css';
import ChipAutocomplete from "components/CustomeAutoComplete/ChipAutocomplete";
import CustomFileUpload from "components/customUpload/CustomFileUpload";
import { CustomEditor } from "components/CustomEditor/CustomEditor";
import { FILETYPE, AVAILABILITY, SALARY_TYPE } from "utils/constant";
import history from "utils/history";
import UnderApprovedDialog from "components/Dialog/UnderApprovedDialog";
import LanguageField from "components/FieldArrayComponent/LanguageField";
import { avoidKeysForNumber } from "utils/Common";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import InputAdornment from '@mui/material/InputAdornment';
import dollar from 'assets/img/dollar.png';

function ProfessionalDetailsForm(props) {
    const { handleSubmit, uploadImage, customText, change, files, formValues, initialValues, uploadProfile } = props;
    const dispatch = useDispatch();
    const [time, setTime] = useState('');
    const allData = useSelector(state => state.freelancerReducer);
    const userDetails = useSelector(state => state.authReducer?.userDetails)
    const subSkillsList = useSelector(state => state.commonReducer?.subSkillsList);
    const skillsList = useSelector(state => state.commonReducer?.skillsList);
    const proficiency = useSelector(state => state.commonReducer?.proficieny);
    const technologies = useSelector(state => state.commonReducer?.technologies);
    const path = window.location.pathname;

    const getSkill = () => {
        dispatch(CommonActions.getSkills());
    };

    const getSubSkills = (skills, initial = false) => {
        const skillIds = populateIds(skills);
        if (skillIds.length > 0) {
            dispatch(CommonActions.getSubSkills(skillIds.toString())).then(response => {
                if (response.status === 200) {
                    dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: response.data });
                    if (!initial) {
                        let selected = populateIds(formValues?.sub_skills);
                        let temp = [];
                        response.data.forEach((element) => {
                            if (selected.includes(element.id)) temp.push(element);
                        });
                        change("sub_skills", temp);
                    }
                } else {
                    change("sub_skills", []);
                    dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
                }
            }).catch(() => {
                change("sub_skills", []);
                dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
            });
        } else {
            change("sub_skills", []);
        }
    }

    const getProficiency = () => {
        dispatch(CommonActions.getProficiency());
    }

    const getTechnologies = () => {
        dispatch(CommonActions.getTechnologiesList());
    };
    const handleGetProfileType = () => {
        dispatch(Actions.getProfileType());
    };
    const getLanguages = (search, keys) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            dispatch(Actions.getLanguagesList(search)).then((response) => {
                let data = response.data;
                if (keys && keys.length > 0) {
                    data = data.filter(elem => !keys.includes(elem.key));
                }
                dispatch({ type: 'GET_LANGUAGE_LIST', payload: data });
            }).catch(() => {
                dispatch({ type: 'GET_LANGUAGE_LIST', payload: [] });
            });
        }, 1000));
    };

    const handleRemoveLanguages = () => {
        dispatch({ type: 'GET_LANGUAGE_LIST', payload: [] });
    }

    const populateIds = (array) => {
        let ids = [];
        if (Array.isArray(array) && array.length > 0) {
            array.forEach(elem => {
                ids.push(elem.id);
            });
        }
        return ids;
    }

    const backToHome = () => {
        history.push('/home');
    }

    useEffect(() => {
        getSkill();
        getTechnologies();
        getProficiency();
        handleGetProfileType();
    }, []);
    useEffect(() => {
        if (initialValues && initialValues?.skills) {
            getSubSkills(initialValues?.skills, true);
        }
    }, [initialValues]);

    return (
        <>
            <Grid className='add_profile-form'>
                <h5 className="title">Professional Details</h5>
                <form onSubmit={handleSubmit} noValidate>
                    {(path === "/my-profile/add") &&
                        <Grid item lg={12} md={12} sm={12} className="text-center">
                            <ProfilePicture files={files} uploadProfileImage={uploadProfile} type='add' />
                        </Grid>
                    }
                    <Grid container className="formGrid">
                        <Field
                            name="profile_type.id"
                            component={SelectField}
                            id="profile_type"
                            labelText="Profile Type*"
                            validate={[required]}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            placeholder="Select profile type">
                            {allData?.profileType.map(ptype => (
                                <MenuItem value={ptype.id} key={ptype.id}>{ptype.title}</MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Field
                            name="technologies"
                            component={ChipAutocomplete}
                            id="technologies"
                            labelText="Technologies"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            options={technologies || []}
                            displayKey="title"
                            validate={[required]}
                            onChange={(value) => value}
                            inputProps={{
                                placeholder: "Select technologies",
                                type: "text",
                            }}
                        />
                    </Grid>
                    <Grid container className='formGrid'>
                        <Field
                            name="skills"
                            component={ChipAutocomplete}
                            id="skills"
                            labelText="Skills"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            options={skillsList || []}
                            displayKey="title"
                            validate={[required]}
                            onChange={(event, value) => {
                                change("sub_skills", "");
                                getSubSkills(value);
                            }}
                            onInputChange={(value) => value}
                            inputProps={{
                                placeholder: "Select skills",
                                type: "text",
                            }}
                        />
                        <Field
                            name="sub_skills"
                            component={ChipAutocomplete}
                            id="sub_skills"
                            labelText="Sub-skills"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            options={subSkillsList || []}
                            displayKey="title"
                            // validate={[required]}
                            onChange={(value) => value}
                            inputProps={{
                                placeholder: "Select sub-skills",
                                type: "text",
                            }}
                        />
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="experience"
                                component={CustomInput}
                                id="experience"
                                labelText="Experience*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[checkExperience]}
                                inputProps={{
                                    placeholder: "Enter experience",
                                    type: "number",
                                }}
                                onKeyDown={avoidKeysForNumber}
                                placeholder="Enter Experience"
                                normalize={charLimitWithTrim(2)}
                            >
                            </Field>
                        </Grid>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="availability"
                                component={SelectField}
                                id="availability"
                                labelText="Availability*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Enter hours",
                                }}
                                onKeyDown={avoidKeysForNumber}
                            >
                                {AVAILABILITY.map(avail => (
                                    <MenuItem value={avail.id} key={avail.id}>
                                        {avail.title}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>

                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="salary_type"
                                component={SelectField}
                                id="salary_type"
                                labelText="Salary Type"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Select salary type",
                                }}
                                onKeyDown={avoidKeysForNumber}
                                onChange={() => { change('amount', '') }}
                            >
                                {SALARY_TYPE.map(avail => (
                                    <MenuItem value={avail.id} key={avail.id}>
                                        {avail.title}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid className='formGrid-inner amount-box'>
                            <Field
                                name="amount"
                                component={CustomInput}
                                id="amount"
                                labelText="Amount"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                normalize={characterLimit(6)}
                                validate={[required, checkIsNumber, notZero]}
                                inputProps={{
                                    placeholder: "Enter amount",
                                    type: "number",
                                    endAdornment: (
                                        <InputAdornment position="end" className="amount-duration">
                                            <img src={dollar} />
                                        </InputAdornment>
                                    )
                                }}
                            >
                            </Field>
                        </Grid>
                    </Grid>

                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="education_details"
                                component={CustomInput}
                                id="education_details"
                                labelText="Education*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, charWithDot]}
                                inputProps={{
                                    placeholder: "Enter education",
                                    type: "text",
                                }}
                            />
                        </Grid>
                        <Grid className='formGrid-inner'>
                            <Field
                                type="file"
                                name='document_file'
                                id="document_file"
                                component={CustomFileUpload}
                                onChange={uploadImage}
                                validate={[required]}
                                mimeType={FILETYPE.file}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Field
                            name="specialties"
                            component={CustomEditor}
                            id="specialties"
                            labelText="Specialities"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            // validate={requiredWithRegex}
                            inputProps={{
                                placeholder: "Enter specialities"
                            }}
                        />
                    </Grid>
                    <Grid container className='formGrid'>
                        <Field
                            name="description"
                            component={CustomEditor}
                            id="description"
                            labelText="About*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={requiredWithRegex}
                            inputProps={{
                                placeholder: "Enter text",
                                type: "text",
                            }}
                        />
                    </Grid>
                    <Grid container className='formGrid'>
                        <FieldArray
                            name="communication_language"
                            change={change}
                            required={required}
                            component={LanguageField}
                            languages={allData?.language || []}
                            getLanguages={getLanguages}
                            proficiency={proficiency}
                            removeLanguages={handleRemoveLanguages}
                        />
                    </Grid>
                    <Grid className='action-div'>
                        {(path !== '/my-profile/add') &&
                            <Link onClick={() => history.push("/my-profile")} underline="none" className='previousBtn cursor-pointer'>Cancel</Link>
                        }
                        <Button variant="contained" type="submit" className='primaryButton nextBtn'>{customText?.buttonText}</Button >
                    </Grid>
                </form>
            </Grid >
            <UnderApprovedDialog open={userDetails?.is_profile_in_review} backToHome={backToHome} />
        </>
    );
}

const ReduxClientFormMapped = reduxForm({
    form: "ProfessionalDetailsForm", // a unique identifier for this form
    enableReinitialize: true,
})(ProfessionalDetailsForm);

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues('ProfessionalDetailsForm')(state),
    }
};

export default connect(mapStateToProps)(ReduxClientFormMapped);