import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import JobpostDetailsCardSkeleton from './JobpostDetailsCardSkeleton';
import CandidateCardSkeleton from './CandidateCardSkeleton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const JobpostDetailsSkeleton = () => {
    return (
        <Grid className="top-job-post">
            <Container>
                <Grid container className='job-post-inner'>
                    <Grid item lg={12}>
                        <Stack spacing={2} className='breadcrumb-div'>
                            <Breadcrumbs
                                className='breadcrumb-nav'
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                                color="#6A8CAA"
                            >
                                <Link underline="hover" color="inherit" className="cursor-pointer">
                                    <Skeleton width={100} />
                                </Link>
                                <Link underline="hover" color="inherit" className="cursor-pointer">
                                    <Skeleton width={100} />
                                </Link>
                                <Link underline="hover" color="inherit" className="cursor-pointer">
                                    <Skeleton width={100} />
                                </Link>
                            </Breadcrumbs>
                        </Stack>
                    </Grid>
                </Grid>
                <div className='job-post-main-content-detail'>
                    <JobpostDetailsCardSkeleton />
                    <Grid container>
                        <Grid item lg={12} sm={12}>
                            <div className='job-post-description'>
                                <Typography variant="h4"><Skeleton width="25%" /></Typography>
                                <Typography variant="body1">
                                    <Skeleton className='transform-scale-1' height={60} />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className='job-technology' spacing={3}>
                        <Grid item lg={6} md={6} sm={12}>
                            <div className='job-post-skill'>
                                <Typography variant="h4"><Skeleton width="25%" /></Typography>
                                <Grid>
                                    <List className="categoryList">
                                        <ListItem className="subcategoryList-item">
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item">
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item">
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item">
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item">
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <div className='job-post-skill'>
                                <Typography variant="h4"><Skeleton width="25%" /></Typography>
                                <Grid>
                                    <List className="categoryList">
                                        <ListItem className="subcategoryList-item" >
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item" >
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item" >
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item" >
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                        <ListItem className="subcategoryList-item" >
                                            <ListItemText primary={<Skeleton />} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item sm={12}>
                            <div className='post-day'>
                                <Typography variant="body1">
                                    <Skeleton width="20%" />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className='candidate-header'>
                            <Typography variant='h3'>
                                <Skeleton width={165} />
                            </Typography>
                            <Typography variant='body1'>
                                <Skeleton width={95} />
                            </Typography>
                        </div>
                    </Grid>
                    <Grid className="job-candidates">
                        <div className='create-postion-card' >
                            <div className='position-content'>
                                <Typography variant='h4'><Skeleton width="10%" /></Typography>
                            </div>
                            <Grid container spacing={3}>
                                <CandidateCardSkeleton />
                                <CandidateCardSkeleton />
                                <CandidateCardSkeleton />
                            </Grid>
                        </div>

                    </Grid>
                </div>
            </Container >
        </Grid >
    );
}

export default JobpostDetailsSkeleton;