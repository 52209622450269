import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import CertificateDetailsDialog from 'components/Dialog/CertificateDetailsDialog';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Actions from "../../../store/actions/index";
import noProjectImage from "assets/img/icons/no-projects-icon.svg";
import certificateIcon from "assets/img/certificate.svg";
import CertificateTableDialog from 'components/Dialog/CertificateTableDialog';
import { useDispatch } from 'react-redux';
import docIcon from "assets/img/icons/doc.svg";
import pdfIcon from "assets/img/icons/pdf.svg";
import { getFileExtention } from 'utils/Common';

const CandidateCertificationSection = (props) => {
    const { certificates, candidate, totalRecords } = props;
    const [certificateDetailsDialogue, setCertificateDetailsDialogue] = useState(false);
    const [allCertificateDialog, setAllCertificateDialog] = useState(false);
    const [singleCertificateData, setSingleCertificateData] = useState(null);
    const [allCertificate, setAllCertificate] = useState([]);
    const dispatch = useDispatch();

    const fileIcons = {
        ".doc": docIcon,
        ".docx": docIcon,
        ".pdf": pdfIcon,
    };

    const getFileIcon = (file) => {
        let extension = getFileExtention(file);
        return fileIcons[extension] ? fileIcons[extension] : file;
    };

    const checkForFileIcon = (file) => {
        let extension = getFileExtention(file);
        return fileIcons[extension] ? fileIcons[extension] : null;
    }

    const handleOpenDetailsDialogue = (certificate = null) => {
        setCertificateDetailsDialogue(!certificateDetailsDialogue);
        if (certificate?.id) setSingleCertificateData(certificate);
    }

    const handleOpenAllCertificate = (openFromThis = true) => {
        if (totalRecords < 3) return false;
        if (!openFromThis) setAllCertificateDialog(!allCertificateDialog);
        else getCandidateAllCertificate(1, 'all');
    }

    const getCandidateAllCertificate = (page = 1, pageSize = 'all') => {
        dispatch(Actions.getCandidateAllCertificate(candidate?.id, page, pageSize)).then(response => {
            if (response.status === 200) {
                setAllCertificate(response.data?.results ?? response.data);
                setAllCertificateDialog(!allCertificateDialog);
            }
        })
    }

    const settings = {
        dots: false,
        margin: 15,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    autoplay: true,
                },
            },
        ],
    };

    return (
        <>
            <div className="title-section">
                <Typography className="candidate-profile-title">
                    Certificates
                </Typography>
                {/* <a href={void (0)} className={totalRecords > 3 ? 'see-all-btn cursor-pointer' : 'see-all-btn'} onClick={() => handleOpenAllCertificate()}>See All</a> */}
            </div>
            <Grid>
                <Grid item lg={12}>
                    { certificates && certificates?.length > 0 ?
                    <Slider {...settings} className="certificate_slider">
                               {certificates.map(certificate => (
                                    <div className="item cursor-pointer" key={certificate?.id} onClick={() => handleOpenDetailsDialogue(certificate)}>
                                        <div className="candidate-certificateCard">
                                            <div className={!checkForFileIcon(certificate?.certificate_file) ? "card-img" : 'card-file'}>
                                                <img src={certificate?.certificate_file ? getFileIcon(certificate?.certificate_file) : certificateIcon} alt="img" />
                                            </div>
                                            <div>
                                                <Typography className="certi-title">
                                                    {certificate.title}
                                                </Typography>
                                                <Typography className="certi-date">
                                                    {moment(new Date(certificate?.certification_date)).format('DD MMMM YYYY')}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </Slider>
                    :
                    <Grid className="certificate-card-empty">
                        <div>
                            <img src={noProjectImage} alt="img" />
                            <Typography>No Certificates!</Typography>
                        </div>
                    </Grid> }

                    <CertificateDetailsDialog
                        open={certificateDetailsDialogue}
                        close={handleOpenDetailsDialogue}
                        data={singleCertificateData}
                    />
                    <CertificateTableDialog
                        open={allCertificateDialog}
                        close={handleOpenAllCertificate}
                        data={allCertificate}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default CandidateCertificationSection;