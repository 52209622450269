import React, { useContext, useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "assets/css/profile.css";
import ProfessionalDetailsForm from "./component/ProfessionalDetailsForm";
import ProjectDetailStepperContent from "./component/ProjectDetailStepperContent";
import CertificationStepperContent from "./component/CertificationStepperContent";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions/index";
import history from "utils/history";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import { LoaderContext } from "utils/LoaderContext";
import { getUrl } from "utils/constant";
import { checkForImageValidations } from "utils/Common";
import ImageCropDialog from "components/Dialog/ImageCropDialog";


const steps = ["Professional Details", "Projects Details", /*"Work Experience",*/ "Certification"];

function AddFreelancerProfile() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const [activeStep, setActiveStep] = useState(userDetails.on_boarding_steps - 1);
    const [completed, setCompleted] = useState(null); /* eslint-disable-line */
    const [proofOrProfileImage, setProofOrProfileImage] = useState({ profile: null, identity: null });
    const { toggalLoader } = useContext(LoaderContext);
    const freelancerDetails = useSelector(state => state.freelancerReducer);
    const [files, setFiles] = useState([]);
    const [cropHandlers, setCropHandlers] = useState({ openDialog: false, file: null });

    const breadCrumbs = [
        { name: 'home', path: '/home' },
        // { name: 'profile', path: '/profile' },
        { name: 'my-profile', path: userDetails.is_new_registered ? '' : '/my-profile' },
    ];
    const handleImgCropOpen = (file = null) => {
        setCropHandlers({ ...cropHandlers, openDialog: !cropHandlers.openDialog, file: file instanceof File ? file : null });
    }
    const totalSteps = () => {
        return steps.length;
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((_step, i) => !(i in completed)) : activeStep + 1;
        setActiveStep(newActiveStep);
        const currentTab = getUrl(newActiveStep);
        getTabDetails(currentTab);
    };
    const submitForm = (url, data, message) => {
        toggalLoader(true);
        dispatch(Actions.submitStepper(url, data)).then(response => {
            if (response.status === 201) {
                getUserDetails();
                if (isLastStep()) {
                    toast.success(message);
                    setTimeout(() => {
                        history.push('/my-profile');
                    }, 500);
                } else {
                    handleNext();
                    toast.success(message);
                }
                toggalLoader(false);
            } else {
                toggalLoader(false);
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                })
            }
        }).catch((error) => {
            toggalLoader(false);
            toast.error(error);
        });
    }

    const handleImageUpload = async (e) => {
        try {
            if (e instanceof File) {
                const fileCheck = await checkForImageValidations(e, 'file', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", e);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        if (response.status === 201) {
                            const filesObject = { ...proofOrProfileImage }
                            filesObject.identity = response.data;
                            setProofOrProfileImage(filesObject);
                        } else {
                            toast.error('Something went wrong... ');
                        }
                    }).catch(() => {
                        setProofOrProfileImage({ ...proofOrProfileImage, identity: null });
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong... ' + error.message);
        }
    };

    const hanldeProfileUplaod = async (event) => {
        try {
            if (event.target.files[0]) {
                const fileCheck = await checkForImageValidations(event.target.files[0], 'image', true);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message);
                    event.target.value = null;
                } else {
                    handleImgCropOpen(event.target.files[0]);
                    event.target.value = null;
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
            event.target.value = null;
        }
    }

    const uploadCroppedImage = (file) => {
        const formData = new FormData();
        formData.append("file_obj", file);
        return dispatch(Actions.uploadImage(formData)).then(response => {
            handleImgCropOpen();
            if (response.status === 201) {
                const filesObject = { ...proofOrProfileImage }
                filesObject.profile = response.data;
                setProofOrProfileImage(filesObject);
            } else {
                toast.error('Something went wrong... ');
            }
        }).catch(() => {
            setProofOrProfileImage({ ...proofOrProfileImage, profile: null });
        });
    }


    const handleCertificationImageUpload = async (file, formIndex) => {
        try {
            const imagesData = { ...files };
            if (file instanceof File) {
                const fileCheck = await checkForImageValidations(file, 'certificate_file_type', false);
                if (fileCheck instanceof Error) {
                    toast.error(fileCheck.message)
                } else {
                    const formData = new FormData();
                    formData.append("file_obj", file);
                    dispatch(Actions.uploadImage(formData)).then(response => {
                        imagesData[formIndex] = response.data;
                        setFiles(imagesData);
                    }).catch(() => {
                        imagesData[formIndex] = null;
                        setFiles(imagesData);
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }
    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
                const userData = { ...response.data }
                userData.contact_number = userData.contact_number_without_code;
                const filesObject = {
                    ...files,
                    profile: userData?.profile_image,
                    identity: userData?.company?.identity
                }
                setProofOrProfileImage(filesObject);
            }
            else {
                dispatch({ type: 'GET_USER_DETAILS', payload: {} });
            }
        }).catch(() => {
            dispatch({ type: 'GET_USER_DETAILS', payload: {} });
        })
    }
    const handleChangeStep = () => {
        dispatch(Actions.updateUserProfile(userDetails.id, { on_boarding_steps: userDetails.on_boarding_steps + 1 }))
            .then(() => {
                handleNext();
                getUserDetails();
            }).catch(error => {
                toast.error(error.message);
            });
    };

    const handleCreateProject = (projectsData) => {
        toggalLoader(true);
        let data = [];
        projectsData?.projects.forEach((ele, index) => {
            let projectData = {
                title: ele?.title,
                duration: parseInt(ele?.duration),
                is_on_going: (ele?.is_on_going) ? ele?.is_on_going : false,
                technologies: ele?.technologies?.map(val => (val.id)),
                skills: ele?.skills?.map(val => (val.id)),
                description: ele?.description,
                images: ele?.images,
                link: ele?.link
            }
            if (files && files[index]) {
                projectData.image = files[index]
            }
            data.push(projectData)
        })
        dispatch(Actions.addDetails('projects/', data)).then(response => {
            if (response.status === 201) {
                toast.success('Project added successfully');
                toggalLoader(false);
                handleChangeStep();
            } else {
                toggalLoader(false);
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                });
            }
        }).catch(() => {
            toggalLoader(false);
            toast.error('Something went wrong');
        });
    }

    const handleSkip = () => {
        toggalLoader(true);
        let param = { on_boarding_steps: activeStep + 2 };
        if (isLastStep()) {
            param.on_boarding_steps = null;
        }
        dispatch(Actions.updateUserProfile(userDetails?.id, param)).then(response => {
            if (response.status === 200) {
                getUserDetails();
                if (isLastStep()) {
                    history.push('/my-profile');
                } else {
                    handleNext();
                }
            } else {
                Object.keys(response.data).forEach((error) => {
                    toast.error(response.data[error][0]);
                });
            }
            toggalLoader(false);
        }).catch(() => {
            toggalLoader(false);
            toast.error('Something went wrong');
        });
    }

    useEffect(() => {
        if (userDetails?.on_boarding_steps === null) {
            history.push('/my-profile');
        } else {
            const currentTab = getUrl(userDetails.on_boarding_steps - 1);
            getTabDetails(currentTab);
        }
    }, []);

    const getTabDetails = (currentTab) => {
        dispatch(Actions.getDetails(currentTab?.url)).then(response => {
            if (response.status === 200) {
                dispatch({ type: currentTab.type, payload: response.data.results });
            } else {
                dispatch({ type: currentTab.type, payload: null });
            }
        }).catch(() => {
            dispatch({ type: currentTab.type, payload: null });
        });
    }
    useEffect(() => {
        if (freelancerDetails?.portFolioList[0]) {
            let fileObj = { ...proofOrProfileImage };
            if (fileObj.identity === null) {
                fileObj.identity = freelancerDetails?.portFolioList[0].document_file;
            }
            if (fileObj.profile === null) {
                fileObj.profile = freelancerDetails?.portFolioList[0].profile_image;
            }
            setProofOrProfileImage(fileObj);
        }
    }, [freelancerDetails]);
    return (
        <>
            <Grid className="profile-top-section freelancer_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomBreadcrumbs current="add-profile-details" previous={breadCrumbs} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className="clientId">
                                ID : <span>{userDetails?.uid}</span>
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container add_profile-container">
                <h4>Add Profile Details</h4>
                <Grid className="stepper-container">
                    <Box>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            className="stepperBox"
                        >
                            {steps.map((label) => {
                                return (
                                    <Step key={label} className="stepperStep">
                                        <StepLabel className="stepLabel">{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Grid>
                <Grid className="profile-inner add_profile-inner">
                    <Box className="add_profile-form">
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 && (
                                    <ProfessionalDetailsForm onSubmit={(details) => {
                                        const addUserProfileData = {
                                            skills: details.skills.map(val => (val.id)),
                                            sub_skills: details.sub_skills.map(val => (val.id)),
                                            technologies: details.technologies.map(val => (val.id)),
                                            communication_language: details.communication_language.map(val => {
                                                return {
                                                    id: val.id,
                                                    communication_language: val.communication_language.key,
                                                    proficiency: val.proficiency.id,
                                                }
                                            }),
                                            profile_image: proofOrProfileImage.profile,
                                            document_file: proofOrProfileImage.identity,
                                            profile_type: details.profile_type.id,
                                            experience: details.experience,
                                            salary_type : details.salary_type,
                                            amount: details.amount,
                                            education_details: details.education_details,
                                            specialties: details.specialties,
                                            description: details.description,
                                            availability: details.availability,
                                        }
                                        if (typeof addUserProfileData.profile_image === 'string' || addUserProfileData.profile_image === null) delete addUserProfileData.profile_image
                                        if (typeof addUserProfileData.document_file === 'string' || addUserProfileData.document_file === null) delete addUserProfileData.document_file
                                        submitForm('portfolio/', addUserProfileData, "Portfolio saved successfully");
                                    }}
                                        uploadImage={handleImageUpload}
                                        uploadProfile={hanldeProfileUplaod}
                                        buttonText="Submit"
                                        initialValues={freelancerDetails?.portFolioList[0]}
                                        files={proofOrProfileImage}
                                        customText={{
                                            buttonText: "Next",
                                            titleText: ''
                                        }} />
                                )}
                                {activeStep === 1 && (
                                    <ProjectDetailStepperContent
                                        initialValues={{ projects: freelancerDetails?.projectList }}
                                        onSubmit={(values) => {
                                            handleCreateProject(values);
                                        }}
                                        handleSkip={handleSkip}
                                    />
                                )}
                                {activeStep === 2 && (
                                    <CertificationStepperContent
                                        initialValues={{ certification: freelancerDetails?.certificateList }}
                                        imageUpload={handleCertificationImageUpload}
                                        onSubmit={(details) => {
                                            let certificateData = [];
                                            details?.certification.forEach((ele, index) => {
                                                let data = {
                                                    title: ele.title,
                                                    certification_date: ele.certification_date,
                                                    certification_link: ele.certification_link,
                                                    certificate_file: ele.certificate_file
                                                }
                                                if (typeof data.certificate_file === 'string' || data.certificate_file === null) {
                                                    delete data.certificate_file
                                                }
                                                if (files && files[index]) {
                                                    data.certificate_file = files[index]
                                                }
                                                certificateData.push(data)
                                                if (details?.certification.length === index + 1)
                                                    submitForm('certification/', certificateData, "Certification saved successfully")
                                            });
                                        }}
                                        customText={{
                                            buttonText: "Submit",
                                            titleText: ''
                                        }}
                                        handleSkip={handleSkip}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </Box>
                </Grid>
            </Container>
            <ImageCropDialog open={cropHandlers.openDialog} close={handleImgCropOpen} file={cropHandlers.file} uploadCroppedImage={uploadCroppedImage} />
        </>
    );
}

export default AddFreelancerProfile;
