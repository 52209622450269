import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import attachmentIcon from 'assets/img/icons/pin-icon.svg';
import sendIcon from 'assets/img/icons/send-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import useChatScroll from "./UseChatScroll";
import CircularProgress from "@mui/material/CircularProgress";
import AttachmentView from "./AttachmentView";
import { checkFileValidation, errorToast } from "utils/Common";
import * as Actions from "../../../store/actions/index";
import { FILETYPE } from 'utils/constant';
import { toast } from 'react-toastify';
import { saveAs } from "file-saver";
import ContainerSkeleton from "./skeletons/ContainerSkeleton";
import { sendMessage, readMessage } from "services/WebSocketService";
import Tooltip from "@mui/material/Tooltip";
import MessageWrapper from "./MessageWrapper";
import JobpostWrapper from "./JobpostWrapper";
import { hasActiveSubscription } from "utils/constant";

const ariaLabel = { 'aria-label': 'description' };

const CustomDivider = (props) => {
    const { title } = props;

    return (
        <div className="chat-bg-content">
            <Typography variant="body1">{title}</Typography>
        </div>
    );
};

function ChatContainer(props) {
    const { handlePageData } = props;
    const dispatch = useDispatch();
    const currentChat = useSelector(state => state.chatReducer?.currentChat);
    const loading = useSelector(state => state.chatReducer?.loading);
    const userDetails = useSelector(state => state.authReducer?.userDetails);
    const currentClient = useSelector(state => state?.chatReducer?.currentClient);
    const pageData = useSelector(state => state?.chatReducer?.pageData);
    const currentUser = useSelector(state => state?.chatReducer?.currentUser);
    const totalCount = useSelector(state => state?.chatReducer?.totalCount);
    const connection = useSelector(state => state.socketReducer?.connection);
    const isAttachmentLoading = useSelector((state) => state?.chatReducer?.isAttachmentLoading);
    const contentLoading = useSelector(state => state.commonReducer?.contentLoading);
    const [currentMsg, setCurrentMsg] = useState('');
    const [attachedFile, setAttachedFile] = useState([]);
    const isInitial = useSelector(state => state?.chatReducer?.initial);
    const ref = useChatScroll(currentChat, (isInitial === undefined ? true : isInitial));
    const [open, setOpen] = useState(false);
    const [tooltip, setTooltip] = useState('today');
    const [time, setTime] = useState("");
    let format = 'DD MMMM YYYY';
    let msgDate = null;

    const handleMessage = (msg) => {
        setCurrentMsg(msg);
    }
    const handleSendMessage = () => {
        dispatch({ type: "SCROLLING_ACTION", payload: "send_message" });
        let send = sendMessage(connection, userDetails?.id, currentUser?.opponent?.id, currentUser?.room, currentMsg, attachedFile);
        if (send) {
            setAttachedFile([]);
            setCurrentMsg('');
        }
    }
    const handleReadMessage = () => {
        if (currentUser?.unread_count > 0) {
            let read = readMessage(connection, userDetails?.id, currentUser?.room);
            if (read) {
                dispatch(Actions.getContactList(''));
            }
        }
    }
    const keyPress = (e) => {
        if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
            return false;
        }
        else if (e.keyCode == 13 && e.shiftKey) {
            return true;
        }
    }
    const handleScroll = (event) => {
        getTooltipDate();
        setOpen(true)
        let totalPages = Math.ceil(totalCount / 30);
        let nextPage = (pageData?.page || 0) + 1;
        let top = event.target.scrollTop;
        let bottomStart = ref.current.scrollHeight - ref.current.clientHeight;
        let checkPos = bottomStart - ref.current.scrollTop;
        clearTimeout(time);
        setTime(setTimeout(() => {
            dispatch({ type: "CURRENT_SCROLL_POSITION", payload: checkPos });
        }, 500));
        if (top === 0 && !loading && (nextPage <= totalPages)) {
            dispatch({ type: "SCROLLING_ACTION", payload: "pagination" });
            handlePageData(nextPage);
        }
    }
    const resetTooltip = () => setTimeout(() => setOpen(false), 2000);

    useEffect(() => {
        resetTooltip();
    }, [open]);

    const getTooltipDate = () => {
        const elem = document.getElementById("CHAT_AREA");
        const rect = elem.getBoundingClientRect();
        const index = document.elementFromPoint(rect.x, rect.y)?.className?.includes('chat-msg-container')
            ? document.elementFromPoint(rect.x, rect.y)?.id
            : -1;
        if (index != -1) {
            const date = moment(currentChat[index]?.created).format(format);
            if (date !== 'Invalid date' && tooltip !== date) {
                if (date === moment().format(format)) {
                    setTooltip('Today');
                } else if (date === moment().subtract(1, 'days').format(format)) {
                    setTooltip('Yesterday')
                } else {
                    setTooltip(date)
                }
            }
        }
    }
    const checkMessageDate = (date) => {
        let currentDate = moment(date).format(format);
        if (msgDate != currentDate) {
            if (currentDate === moment().format(format)) {
                msgDate = currentDate;
                return <CustomDivider title="Today" />
            } else if (currentDate === moment().subtract(1, 'days').format(format)) {
                msgDate = currentDate;
                return <CustomDivider title="Yesterday" />
            } else {
                msgDate = currentDate;
                return <CustomDivider title={moment(date).format(format)} />
            }
        }
    }
    useEffect(() => {
        setAttachedFile([]);
    }, [currentClient]);
    const handleUpload = (event) => {
        let temp = [...attachedFile];
        let allFiles = [...event.target.files];
        let previousCount = temp.length;
        const length = 10 - previousCount;
        if (temp?.length >= 10) {
            toast.error("Maximum files(10) upload have been reached for a message");
            return false;
        } else if (allFiles.length > length) {
            allFiles = allFiles.slice(0, length);
            toast.warn("You can only upload 10 files per message.");
        }
        let i = 1;
        dispatch({ type: 'ATTACHMENT_LOADING', payload: true });
        allFiles.forEach(async (file) => {
            const fileCheck = await checkFileValidation(file);
            if (fileCheck instanceof Error) {
                if (allFiles.length === i) {
                    dispatch({ type: 'ATTACHMENT_LOADING', payload: false });
                }
                toast.error(fileCheck.message)
                i++;
            } else {
                const formData = new FormData();
                formData.append('attached_file', file);
                formData.append('chat_room', currentUser?.room);
                dispatch(Actions.addAttachment(formData)).then((response) => {
                    if (response.status === 201) {
                        temp.push(response.data);

                    } else {
                        setAttachedFile([]);
                        errorToast(response);
                    }
                    if (allFiles.length === i) {
                        setAttachedFile(temp);
                        dispatch({ type: 'ATTACHMENT_LOADING', payload: false });
                    }
                    i++;
                }).catch(() => {
                    i++;
                    setAttachedFile([]);
                })
            }
        })
    }
    const handleRemoveFile = (index) => {
        let temp_attach = [...attachedFile];
        delete temp_attach[index];
        let filtered = temp_attach.filter(el => el);
        setAttachedFile(filtered);
    }
    const handleDownloadFile = (file) => {
        if (file?.url) {
            saveAs(file.url, (file?.original_filename || "attachments"));
        }
    }

    return (
        <>
            {(isInitial || contentLoading) ?
                <ContainerSkeleton />
                :
                <>
                    <Tooltip open={open} title={tooltip} leaveDelay={1000} id="date-label"
                        classes={{ popper: "zIndex: 0" }}>
                        <div></div>
                    </Tooltip>
                    <Grid className="chat-msg-wrapper" onClick={() => handleReadMessage()}>
                        <div className="chat-inner" ref={ref} id="CHAT_AREA" onScroll={handleScroll}>
                            {loading &&
                                <div style={{ marginLeft: "45%", marginTop: '25px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            }

                            {currentChat && currentChat.length > 0 && currentChat.map((chat, index) => (
                                <Grid className="chat-msg-container" key={index} id={index}>
                                    {checkMessageDate(chat?.created)}
                                    {chat?.message_type === 4 ?
                                        <>
                                            <JobpostWrapper chat={chat} details={chat?.message_detail} message={chat?.message} />
                                        </>
                                        :
                                        <MessageWrapper
                                            chat={chat}
                                            handleDownloadFile={handleDownloadFile}
                                            messagePosition={chat?.sender?.id === userDetails?.id ? "right" : "left"}
                                        />
                                    }
                                </Grid>
                            ))
                            }
                            {(!currentChat || currentChat?.length === 0) && currentUser?.is_room_active &&
                                <Grid className="conversation-content">
                                    <div>
                                        <Typography>Hmm... there&#39;s nothing to show.</Typography>
                                        <Typography>Start conversation…</Typography>
                                    </div>
                                </Grid>
                            }
                        </div>

                    </Grid>
                    {(currentUser?.is_room_active) && hasActiveSubscription(userDetails) &&
                        <Grid className="footer-container">
                            <Grid className='chat-footer'>
                                <div className="chat-typing-container">
                                    {(attachedFile && attachedFile.length > 0 || isAttachmentLoading) &&
                                        <AttachmentView attachedFile={attachedFile} removeFile={handleRemoveFile} />
                                    }
                                    <Grid className='chat-text-box'>
                                        <FormControl variant="standard" className='formControl'>
                                            <TextField onFocus={() => handleReadMessage()}
                                                onKeyDown={(e) => keyPress(e)} value={currentMsg}
                                                multiline={true}
                                                placeholder="Enter Your Message" inputProps={ariaLabel}
                                                onChange={(e) => handleMessage(e.target.value)}
                                            />
                                        </FormControl>
                                        <label htmlFor="icon-button-file">
                                            <input type="file" accept={FILETYPE?.chat_attachment_type} name="profile_image" multiple id="icon-button-file"
                                                onChange={(e) => handleUpload(e)} onClick={(event) => { event.target.value = null; }} style={{ display: "none" }} />
                                            <IconButton aria-label="upload picture" component="span" >
                                                <img className='attach-Btn' src={attachmentIcon} alt='img' />
                                            </IconButton>
                                        </label>

                                    </Grid>
                                </div>
                                <Grid className='chat-action'>
                                    <Button className='send-Btn' onClick={() => handleSendMessage()}>
                                        <img src={sendIcon} alt='img' />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </>
            }
        </>
    );
}

export default ChatContainer;