import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

const JobPostSidebarSkeleton = () => {
    return (
        <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="left-job-list">
                <List>
                    <ListItem className='cursor-pointer'>
                        <Skeleton className='list-img bg-dark' variant='circular' />
                        <div className="list-content">
                            <Typography variant="h5"><Skeleton /></Typography>
                        </div>
                    </ListItem>
                    <ListItem className='cursor-pointer'>
                        <Skeleton className='list-img bg-dark' variant='circular' />
                        <div className="list-content">
                            <Typography variant="h5"><Skeleton /></Typography>
                        </div>
                    </ListItem>
                    <ListItem className='cursor-pointer'>
                        <Skeleton className='list-img bg-dark' variant='circular' />
                        <div className="list-content">
                            <Typography variant="h5"><Skeleton /></Typography>
                        </div>
                    </ListItem>
                    <ListItem className='cursor-pointer'>
                        <Skeleton className='list-img bg-dark' variant='circular' />
                        <div className="list-content">
                            <Typography variant="h5"><Skeleton /></Typography>
                        </div>
                    </ListItem>
                    <ListItem className='cursor-pointer'>
                        <Skeleton className='list-img bg-dark' variant='circular' />
                        <div className="list-content">
                            <Typography variant="h5"><Skeleton /></Typography>
                        </div>
                    </ListItem>
                </List>
            </div>
        </Grid>
    );
}

export default JobPostSidebarSkeleton;