import { SALARY_TYPE } from "utils/constant";

const validate = values => {
    const errors = {};
    let currentSalaryType = SALARY_TYPE.find(x => x.id === +values.salary_type);
    if (parseInt(values.budget_from) < currentSalaryType?.budget_from) {
        errors.budget_from = `Minimum budget needs to be atleast ${currentSalaryType?.budget_from}`
    } else if (parseInt(values.budget_from) > parseInt(values.budget_to)) {
        errors.budget_from = `Minimum budget can't be greater than the maximum`;
    }

    if (parseInt(values.budget_to) > currentSalaryType?.budget_to) {
        errors.budget_to = `Maximum budget can only be ${currentSalaryType?.budget_to}`;
    } else if (parseInt(values.budget_to) < parseInt(values.budget_from)) {
        errors.budget_to = `Maximum budget can't be less than the minimum`;
    }

    return errors
}
export default validate;