import apiService from 'api/ApiService';

export function subscribe(id) {
    return () => apiService.post(`subscribe/`,{subscription:id})
}

export function getCurrentSubscription() {
    return () => apiService.get(`subscribe/`)
}

export function getCurrentPlan() {
    return () => apiService.get(`current-plan/`)
}

export function cancelSubscription(id) {
    return () => apiService.patch(`subscribe/${id}/cancel-subscription/`)
}

export function updateSubscription(id) {
    return () => apiService.patch(`subscribe/${id}/update-subscription/`)
}