import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import "assets/css/profile.css";
import * as CommonActions from "../../../store/actions/common/common.actions";
import { connect, useDispatch, useSelector } from "react-redux";
import ProjectField from "components/FieldArrayComponent/ProjectFields";
import { FieldArray, getFormValues, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import { checkForImageValidations } from "utils/Common";
import SkipDialog from "components/Dialog/SkipDialog";


function ProjectDetailStepperContent(props) {

    const { handleSubmit, change, invalid, initialValues, handleSkip } = props;
    const dispatch = useDispatch();
    const [filesData, setFilesData] = useState([]);
    const technologyList = useSelector((state) => state?.commonReducer.technologies);
    const skills = useSelector((state) => state?.commonReducer?.skillsWithoutDepth);
    const [openSkipDialog, setOpenSkipDialog] = useState(false);

    const handleSkipDialog = () => {
        setOpenSkipDialog(!openSkipDialog);
    }

    const handleRemove = (index) => {
        let tempFiles = [...filesData];
        tempFiles.splice(index, 1);
        setFilesData(tempFiles)
    }
    const uploadImage = async (e, filedIndex) => {
        try {
            const tempArr = [...filesData];
            let data = tempArr[filedIndex] || [];
            if (e.target.files.length !== 0) {
                [...e.target.files].forEach(async (file, index) => {
                    const fileCheck = await checkForImageValidations(file, 'image');
                    if (fileCheck instanceof Error) {
                        toast.error(fileCheck.message)
                    } else {
                        const formData = new FormData();
                        formData.append("file_obj", file);
                        dispatch(CommonActions.uploadImage(formData)).then(response => {
                            data.push(response.data);
                            if (e.target.files.length === index + 1) {
                                tempArr[filedIndex] = data;
                                setFilesData(tempArr);
                            }
                        }).catch(() => {
                            tempArr[filedIndex] = data;
                            setFilesData(tempArr);
                        });
                    }
                });
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    }
    const removeImage = (fileIndex, index) => {
        let temp = [...filesData];
        temp[index].splice(fileIndex, 1);
        setFilesData(temp);
    };
    useEffect(() => {
        dispatch(CommonActions.getTechnologies(""));
        dispatch(CommonActions.getSkillsWithoutDepth());
    }, []);

    useEffect(() => {
        if (initialValues?.projects && initialValues?.projects.length > 0) {
            let temp = [...filesData];
            initialValues.projects?.forEach((proj, index) => {
                temp[index] = proj?.images;
                if (initialValues.projects.length === index + 1) {
                    setFilesData(temp);
                }
            })
        }
    }, [initialValues])

    const Submit = (values) => {
        values?.projects?.forEach((val, index) => {
            val.images = filesData[index];
            if (values?.projects.length === index + 1) handleSubmit();
        });

    }

    return (
        <>
            <h5 className="title">Project</h5>
            <form onSubmit={handleSubmit(Submit.bind(this))} noValidate>
                <FieldArray name="projects"
                    component={ProjectField}
                    change={change}
                    technologies={technologyList}
                    skills={skills}
                    uploadImage={uploadImage}
                    allImages={filesData}
                    handleRemove={handleRemove}
                    removeImage={removeImage}
                />
                <Grid className="action-div">
                    <Button
                        type="button"
                        variant="contained"
                        className="primaryButton skipBtn"
                        onClick={handleSkipDialog}
                    >
                        Skip
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="primaryButton nextBtn"
                        disabled={invalid}>
                        Next
                    </Button>
                </Grid>
            </form>
            <SkipDialog
                open={openSkipDialog}
                close={handleSkipDialog}
                message="Are you sure ?"
                description="If you don't have the projects then you can skip this, You can add projects later from the profile"
                confirm={handleSkip}
                buttonText="Yes, Skip"
            />
        </>
    );
}
const ReduxWorkExperienceFormMapped = reduxForm({
    form: "ProjectFormForm",
    enableReinitialize: true,
})(ProjectDetailStepperContent);
const mapStateToProps = (state) => {
    return {
        formValue: getFormValues("ProjectFormForm")(state)
    };
};
export default connect(mapStateToProps)(ReduxWorkExperienceFormMapped);
