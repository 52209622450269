import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/privacy-policy.css";
import Footer from 'components/Footer/Footer';
import AdminNavbar from "components/Navbars/AdminNavbar";
import PageHeader from 'components/CustomPageSection/PageHeader';
import RightSection from './RightSection';
import LeftSidebar from 'components/CustomPageSection/LeftSidebar';
import { USER_TYPES } from 'utils/constant';
import { useSelector } from 'react-redux';

function PrivacyPolicy() {

    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const breadCrumbs = [{ name: 'home', path: '/home' }];

    const sideMenu = [{ id: "introduction", title: "Introduction" },
    { id: "information_we_collect", title: "Information We Collect" },
    { id: "employee_data", title: "Employee Data" },
    { id: "how_we_use_your_information", title: "How We Use Your Information" },
    { id: "security", title: "Security" },
    { id: "with_whom_do_we_share_your_personal_data", title: "With whom do we share your personal data" },
    { id: "how_long_we_retain_personal_data_of_user", title: "How Long We Retain Personal Data of User" },
    { id: "security_of_personal_data", title: "Security of Personal Data" },
    { id: "events", title: "Events" },
    { id: "children_privacy_policy", title: "Children’s Privacy Policy" },
    { id: "contact_us", title: "Contact Us" }];

    return (
        <div>
            <div className={`dashboard-main-content ${(userDetails?.user_type === USER_TYPES.CLIENT) ? "dashboard-main-client-content" : ""}`}>
                <div className="main-panel">
                    <AdminNavbar />
                    <div className='privacy-policy custom-bread-crumb'>
                        <PageHeader pageTitle="Privacy policy" current="privacy-policy" breadCrumbs={breadCrumbs} className="privacy-policy-section" />
                        <section className='privacy-introduction'>
                            <div className='introduction-inner'>
                                <Container>
                                    <Grid container>
                                        <LeftSidebar sideMenu={sideMenu} activeId="introduction" />
                                        <RightSection />
                                    </Grid>
                                </Container>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}


export default PrivacyPolicy