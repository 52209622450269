import React from "react";
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Grid from "@mui/material/Grid";

function HeaderSkeleton() {
    return (
        <Grid className="chat-profile">
            <div className="profile-img-container">
                <Skeleton variant="circular" width={40} height={40}>
                    <Avatar />
                </Skeleton>
            </div>
            <div className="profile-title">
                <Skeleton width="100%" height={35} />
            </div>
        </Grid>
    );
}

export default HeaderSkeleton;
