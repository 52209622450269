import React, { useContext, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import "assets/css/profile.css";
import "assets/css/projectDetail.css";
import "assets/css/allModals.css";
import List from "@mui/material/List";
import userIcon from "assets/img/icons/user-dark.svg";
import editIcon from "assets/img/icons/edit-icon.svg";
import infoIcon from "assets/img/icons/info-icon.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import history from "utils/history";
import copyIcon from "assets/img/icons/filecopy-icon.svg";
import checkIcon from "assets/img/icons/manage-list-blue4.svg";
import fileIcon from "assets/img/icons/file-icon.svg";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import mailIcon from "assets/img/icons/envelope-dark.svg";
import phoneIcon from "assets/img/icons/phone-dark.svg";
import locationIcon from "assets/img/icons/location-dark.svg";
import { SpliceList } from "components/SpliceList/SpliceList";
import RejectedRequestDialog from "components/Dialog/RejectedRequestDialog";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { Button } from "@mui/material";
import { getCurrentPlan } from "store/actions";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import { cancelSubscription } from "store/actions";
import { toast } from "react-toastify";
import useRazorpay from "react-razorpay";
import { subscribe } from "store/actions";
import { LoaderContext } from "utils/LoaderContext";
import moment from "moment";
import { errorToast } from "utils/Common";
import { updateSubscription } from "store/actions";

function AgencyDetailView(props) {
  const { toggalLoader } = useContext(LoaderContext);
  const { userProfile, uploadProfileImage } = props;
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({});
  const [nextPlan, setNextPlan] = useState({});
  const [Razorpay] = useRazorpay();
  const [requestRejectedDialog, setRequestRejectedDialog] = useState({
    open: false,
    message: null,
  });
  const [actionDialogue, setActionDialogue] = useState({
    open: false,
    title: "Are you sure ?",
    message: `You want to cancel your subscription? It will remain active until the end of the current billing cycle, which ends on ${moment(
      currentPlan?.is_current_plan?.end_date
    )?.format(
      "DD/MM/YYYY"
    )}. After that, you will no longer be charged for the plan.`,
  });
  const [copied, setCopied] = useState(copyIcon);

  const breadCrumbs = [
    { name: "home", path: "/home" },
    // { name: 'profile', path: '/profile' },
  ];
  const dispatch = useDispatch();

  const handleRejectedRequestDialog = (message = null) => {
    setRequestRejectedDialog({
      open: !requestRejectedDialog?.open,
      message: message,
    });
  };

  const handleCopy = (event) => {
    event.preventDefault();
    setCopied(checkIcon);
    navigator.clipboard.writeText(userProfile?.company?.company_url);
    setTimeout(() => {
      setCopied(copyIcon);
    }, 1000);
  };

  const fetchCurrentPlan = () => {
    dispatch(getCurrentPlan()).then((response) => {
      if (response.status == 200) {
        setCurrentPlan(response?.data?.current_plan);
        setNextPlan(response?.data?.next_plan);
      }
    });
  };

  const UpgradePlan = async () => {
    closeActionDialogue();
    toggalLoader(true);
    setPaymentLoading(true);
    const makeSubscribe = await dispatch(subscribe(nextPlan.id)); //  Create order on your backend
    setPaymentLoading(false);
    if (makeSubscribe.status == 201) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        subscription_id: makeSubscribe?.data?.payment_subscription_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function () {
          toast.info(
            "Your payment is under process. You will get notification on your mail for payment status."
          );
          await dispatch(updateSubscription(makeSubscribe?.data?.id));
          fetchCurrentPlan();
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response?.error?.description);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      rzp1.open();
      toggalLoader(false);
    } else {
      toggalLoader(false);
      errorToast(makeSubscribe);
    }
  };

  const closeActionDialogue = () => {
    setActionDialogue({ ...actionDialogue, open: false, message: "" });
  };

  const openCancelSubscriptionDialog = () => {
    setActionDialogue({
      ...actionDialogue,
      open: true,
      message: `You want to cancel your subscription? It will remain active until the end of the current billing cycle, which ends on ${moment(
        currentPlan?.is_current_plan?.end_date
      )?.format(
        "DD/MM/YYYY"
      )}. After that, you will no longer be charged for the plan.`,
    });
  };

  const cancelSelectedSubscription = () => {
    dispatch(cancelSubscription(currentPlan?.is_current_plan?.id)).then(
      (response) => {
        if (response.status == 200) {
          fetchCurrentPlan();
          toast.info("Your transaction cancellation request is sent.");
        }
      }
    );
    closeActionDialogue();
  };

  const openUpgradeDialog = () => {
    setDialogType("upgrade");
    setActionDialogue({
      ...actionDialogue,
      open: true,
      message:
        "Once you upgrade to this plan, the data from your current plan will be removed, and you will only be able to use the data from the upgraded plan. This action cannot be undone.",
    });
  };

  useEffect(() => {
    fetchCurrentPlan();
  }, []);

  return (
    <>
      <Grid className="profile-top-section agency_profile-top-section custom-bread-crumb">
        <Container>
          <Grid container className="profile-top-inner">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomBreadcrumbs current="my-profile" previous={breadCrumbs} />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Container className="profile-container my_profile-container">
        <Grid className="userprofile-detail-section">
          <Grid className="userDetails">
            <Grid className="userImage">
              <ProfilePicture
                userDetails={userDetails}
                uploadProfileImage={uploadProfileImage}
              />
            </Grid>
            <Grid className="userInfo">
              <div>
                <h4>{`${userProfile?.company_name}`}</h4>
                <p className="clientId">
                  ID : <span>{userProfile.uid}</span>
                </p>
              </div>
              <div className="userInfo-right-section">
                {userDetails?.is_profile_in_review ||
                userDetails.is_profile_image_in_review ? (
                  <Typography className="approval-tag">
                    Your profile is under approval
                  </Typography>
                ) : null}
                {userDetails?.has_subscription == false &&userDetails?.is_profile_in_review==false&&userDetails.is_profile_image_in_review==false ? (
                  <Typography className="approval-tag purchase-plan-notice">
                   You need to purchase a plan to access other services.
                  </Typography>
                ) : null}
                {userDetails?.last_user_request_status === 3 && (
                  <Typography className="rejected-tag">
                    Your request has been rejected
                    <img
                      className="cursor-pointer"
                      src={infoIcon}
                      onClick={() =>
                        handleRejectedRequestDialog(
                          userDetails?.last_user_request_rejection_message
                        )
                      }
                    ></img>
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid className="companyDetails">
            <Grid className="formGrid">
              <label>Agency Registration Number</label>
              <p>{userProfile.company?.registration_number || "-"}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Head Office Location</label>
              <p>{userProfile.company?.head_branch_location || "-"}</p>
            </Grid>
            <Grid className="formGrid agency-size">
              <label>Agency Size</label>
              <p>{userProfile.company?.size || 0}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Number of Branch</label>
              <p>{userProfile.company?.total_branches || "-"}</p>
            </Grid>
          </Grid>
        </Grid>
        {/* <h4>My Profile Details</h4> */}
        <Grid className="profile-inner my_profile-inner">
          <IconButton
            className="editBtn"
            onClick={() => {
              history.push("/my-profile/edit");
            }}
          >
            <img src={editIcon} alt="img" />
          </IconButton>
          <Grid className="add_profile-form">
            {userProfile?.company?.technologies?.length > 0 && (
              <>
                <p className="CategoryTitle category-technology">
                  Technologies
                </p>
                <Grid>
                  <List className="categoryList">
                    <SpliceList
                      data={userProfile?.company?.technologies}
                      mainClass="categoryList-item"
                      subClass="more-subcategory"
                      limit={5}
                    />
                  </List>
                </Grid>
              </>
            )}
            {userProfile?.company?.company_url && (
              <Grid container className="formGrid">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl variant="standard" className="formControl">
                    <Grid style={{ width: "100%" }}>
                      <label className="website-label">Website</label>
                      <a
                        className="project-link"
                        href={userProfile?.company?.company_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {userProfile?.company?.company_url}
                        <IconButton
                          component="span"
                          onClick={(e) => handleCopy(e)}
                        >
                          <img src={copied} className="copyIcon" alt="img" />
                        </IconButton>
                      </a>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {userProfile?.company?.identity && (
              <Grid container className="formGrid">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl variant="standard" className="formControl">
                    <Grid>
                      <label className="idProof-label">ID Proof</label>
                      {userProfile?.company?.identity ? (
                        <p className="idProof-name">
                          <span>
                            <img src={fileIcon} height="16px" alt="img" />
                          </span>
                          {userProfile?.company?.file_name}
                          <a
                            href={userProfile?.company?.identity}
                            className="downloadBtn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </p>
                      ) : (
                        <p className="value">-</p>
                      )}
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <h5 className="title">Personal Details</h5>
            <Grid container className="formGrid">
              <Grid className="formGrid-inner">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={userIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Full Name</label>
                    <p className="value">
                      {`${userProfile.first_name} ${userProfile.last_name}`}
                    </p>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid className="formGrid-inner">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={mailIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Email</label>
                    <p className="value">{userProfile.email}</p>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid className="formGrid-inner">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={phoneIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Contact Number</label>
                    <p className="value">{userProfile.contact_number}</p>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid className="formGrid-inner">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={locationIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Location</label>
                    <p className="value">{userProfile.city?.display_name}</p>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>

            <h5 className="title">Branches Details</h5>
            <Grid container className="formGrid">
              <Grid container className="formGrid">
                {userProfile &&
                  userProfile?.branches.map((branch, index) => (
                    <Grid className="formGrid-inner" key={index}>
                      <FormControl variant="standard" className="formControl">
                        <Grid>
                          <label>Branch {index + 1}</label>
                          <p className="value">
                            {`${branch?.city?.display_name}`}
                          </p>
                        </Grid>
                      </FormControl>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* 28-3-24 */}
      {currentPlan?.id && (
        <section className="my-plan-section agency-profile-plan">
          <Container>
            <div className="my-plan-inner">
              <div className="my-plan-wrp">
                <Grid container justifyContent={"space-between"}>
                  <Grid
                    container
                    columnSpacing={3}
                    justifyContent={"space-between"}
                  >
                    <Grid item md={12} xs={12}>
                      <div className="my-plan-title">
                        <Typography variant="h3">My Plan</Typography>
                        <a onClick={() => history.push("/plans/agency")}>
                          View all plans
                        </a>
                      </div>
                    </Grid>
                    {currentPlan.id && (
                      <Grid item md={6} xs={12} className="mp-col active">
                        <div className="my-plan-col">
                          <div className="my-plan-item">
                            <div className="mp-item-content">
                              <div className="mp-item-content-left">
                                <Typography variant="h4">
                                  {currentPlan?.name}
                                </Typography>
                                <Typography variant="h5">
                                  {currentPlan?.description}
                                </Typography>
                              </div>
                              <div className="mp-item-content-label">
                                <Typography variant="span">
                                  Current Plan
                                </Typography>
                              </div>
                            </div>
                            <div className="mp-item-price">
                              <div className="cancel-sub-btn">
                                {currentPlan?.is_current_plan?.is_cancelled ==
                                true ? (
                                  <p>
                                    Your subscription has been canceled and will
                                    end on{" "}
                                    {moment(
                                      currentPlan?.is_current_plan?.end_date
                                    ).format("DD/MM/YYYY")}
                                    .
                                  </p>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={openCancelSubscriptionDialog}
                                  >
                                    Cancel Subscription
                                  </Button>
                                )}
                              </div>
                              <div className="price">
                                <Typography variant="span">
                                  {currentPlan?.currency?.sign}{" "}
                                  {parseFloat(currentPlan?.amount)
                                    ?.toFixed(2)
                                    ?.replace(/\.?0+$/, "")}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {nextPlan?.id && (
                      <Grid item md={6} xs={12} className="mp-col">
                        <div className="my-plan-col">
                          <div className="my-plan-item">
                            <div className="mp-item-content">
                              <div className="mp-item-content-left">
                                <Typography variant="h4">
                                  {nextPlan?.name}
                                </Typography>
                                <Typography variant="h5">
                                  {nextPlan?.description}
                                </Typography>
                              </div>
                              <div className="mp-item-content-label">
                                {/* <Typography variant="span">
                                Best for You
                              </Typography> */}
                              </div>
                            </div>
                            <div className="mp-item-price">
                              <div className="cancel-sub-btn next-plan">
                                {nextPlan?.is_current_plan
                                  ?.subscription_status === "in progress" ? (
                                  <p>
                                    Your plan purchase is currently being
                                    processed.
                                  </p>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    disabled={paymentLoading}
                                    onClick={() => openUpgradeDialog()}
                                  >
                                    Upgrade Now
                                  </Button>
                                )}
                              </div>
                              <div className="price">
                                <Typography variant="span">
                                  {nextPlan?.currency?.sign}{" "}
                                  {parseFloat(nextPlan?.amount)
                                    ?.toFixed(2)
                                    ?.replace(/\.?0+$/, "")}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
        </section>
      )}
      <ConfirmationDialogue
        open={actionDialogue.open}
        close={closeActionDialogue}
        title={actionDialogue.title}
        message={actionDialogue.message}
        buttonText="Yes"
        buttonCallback={() =>
          dialogType == "upgrade" ? UpgradePlan() : cancelSelectedSubscription()
        }
      />
      <RejectedRequestDialog
        open={requestRejectedDialog.open}
        close={handleRejectedRequestDialog}
        message={requestRejectedDialog.message}
        userId={userDetails?.id}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userProfile: state.authReducer.userProfile,
  };
};
export default connect(mapStateToProps, "")(AgencyDetailView);
