import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import 'assets/css/profile.css';
import 'assets/css/allModals.css';
import CustomInput from "components/CustomInput/CustomInput.js";
import { required, checkPhoneNumber } from 'utils/Validation';
import { Field, reduxForm } from "redux-form";
import history from "utils/history";
import { getCountryCode } from "utils/Common";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";
import { useSelector } from "react-redux";
import Loader from 'assets/img/SF_Loader.gif';
import { charOnly,email } from "utils/Validation";

function PersonalDetailForm(props) {

    const { handleSubmit, devId, initialValues, change } = props;
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);
    const pageLoading = useSelector(state => state.commonReducer?.pageLoading);
    const [time, setTime] = useState('');

    const handleChangePhone = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }

    useEffect(() => {
        if (initialValues?.contact_number) {
            setCountryCode(getCountryCode(initialValues.contact_number));
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number', `+91`);
        }
    }, [initialValues])

    return (
        <Container className='profile-container add_profile-container'>
            <h4>Edit Personal Details</h4>
            <Grid className='profile-inner add_profile-inner'>
                {pageLoading ?
                    <div className="loder-main-rout">
                        <Grid className="form-loader-div">
                            <img src={Loader} alt='img' className="form-loader" />
                        </Grid>
                    </div>
                    :
                    <Grid className='add_profile-form'>
                        <form onSubmit={handleSubmit} noValidate> <Grid item lg={12} md={12} sm={12} className="text-center">
                        </Grid>
                            <Grid container className='formGrid'>
                                <Grid className='formGrid-inner'>
                                    <Field
                                        name="first_name"
                                        component={CustomInput}
                                        id="first_name"
                                        labelText="First Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, charOnly]}
                                        inputProps={{
                                            placeholder: "Enter first name",
                                            type: "text",
                                        }}
                                    />
                                </Grid>
                                <Grid className='formGrid-inner'>
                                    <Field
                                        name="last_name"
                                        component={CustomInput}
                                        id="last_name"
                                        labelText="Last Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, charOnly]}
                                        inputProps={{
                                            placeholder: "Enter last name",
                                            type: "text",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className='formGrid'>
                                <Grid className='formGrid-inner'>
                                    <Field
                                        name="email"
                                        component={CustomInput}
                                        id="email"
                                        labelText="Email*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required,email]}
                                        inputProps={{
                                            placeholder: "Enter email",
                                            type: "email",
                                            disabled: true,
                                        }}
                                    />
                                </Grid>
                                {/* <Grid className='formGrid-inner'>
                                <Field
                                    name="contact_number"
                                    component={CustomInput}
                                    id="contact_number"
                                    labelText="Contact Number*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[required, phoneNumber]}
                                    inputProps={{
                                        placeholder: "Enter contact number",
                                        type: "number",
                                    }}
                                    normalize={normalizePhone(10)}
                                    onKeyDown={avoidKeysForNumber}
                                />
                            </Grid> */}
                                <Grid item className="formGrid-inner">
                                    <Field
                                        name="contact_number"
                                        component={PhoneNumberField}
                                        id="contact_number"
                                        labelText="Contact Number*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[checkPhoneNumber]}
                                        inputProps={{
                                            placeholder: "Enter contact number",
                                        }}
                                        phone={phoneNumber}
                                        countryCode={countryCode}
                                        handleChangePhone={handleChangePhone}
                                    // onKeyDown={avoidKeysForNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className='action-div'>
                                <Link onClick={() => history.push(`/developer-profile/${devId}`)} underline="none" className='previousBtn cursor-pointer'>Cancel</Link>
                                <Button variant="contained" type="submit" className='primaryButton nextBtn'>Save</Button >
                            </Grid>
                        </form>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}

const ReduxDeveloperPersonalFormMapped = reduxForm({
    form: "DeveloperPersonalDetailForm", // a unique identifier for this form
    enableReinitialize: false,
})(PersonalDetailForm);

export default ReduxDeveloperPersonalFormMapped;
