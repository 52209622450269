import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';

const ClientProfileSkeleton = () => {
    return (
        <>
            <Grid className="profile-top-section freelancer_profile-top-section">
                <Container>
                    <Grid container className="profile-top-inner">
                        <Grid item lg={12} md={12} sm={12}>
                            <Skeleton className='bg-light' width={150} />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container className="profile-container my_profile-container">
                <Grid className="userprofile-detail-section">
                    <Grid className="userDetails">
                        <Grid className="userImage">
                            <Skeleton className='bg-light' variant='circular' width={130} height={130} />
                        </Grid>

                        <Grid className="userInfo">
                            <div>
                                <h4><Skeleton className='bg-light' width={150} /></h4>
                                <p className="clientId">
                                    <Skeleton className='bg-light' width={250} />
                                </p>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid className="companyDetails">
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={120} />
                            <Skeleton className='bg-light' width={170} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={120} />
                            <Skeleton className='bg-light' width={170} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={120} />
                            <Skeleton className='bg-light' width={170} />
                        </Grid>
                        <Grid className="formGrid">
                            <Skeleton className='bg-light' width={120} />
                            <Skeleton className='bg-light' width={170} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className='profile-inner my_profile-inner'>
                    {/* <IconButton className="editBtn" onClick={() => { history.push('/my-profile/edit') }}> */}
                    <Skeleton className='editBtn transform-scale-1  bg-dark' variant='rounded' width={32} height={32} />
                    {/* </IconButton> */}
                    <Grid className='add_profile-form'>
                        <Grid container className='formGrid'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <FormControl variant="standard" className='formControl'>
                                    <Grid>
                                        <label className="idProof-label">
                                            <Skeleton className='transform-scale-1' width={150} />
                                        </label>
                                        <p className='idProof-name'>
                                            <Skeleton className='bg-dark' variant='rounded' width={40} height={40} />
                                            <Skeleton className='transform-scale-1 bg-dark' width={150} />
                                        </p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider style={{ borderColor: "rgba(22, 75, 122, 0.16)" }} />
                        <h5 className='title'>
                            <Skeleton width={170} height={44} />
                        </h5>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner'>
                                <FormControl variant="standard" className='formControl'>
                                    <Skeleton className='bg-dark m-r-8' variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label>
                                            <Skeleton width={150} height={18} />
                                        </label>
                                        <p className='value'>
                                            <Skeleton width={150} height={27} />
                                        </p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <FormControl variant="standard" className='formControl'>
                                    <Skeleton className='bg-dark m-r-8' variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label>
                                            <Skeleton width={150} height={18} />
                                        </label>
                                        <p className='value'>
                                            <Skeleton width={150} height={27} />
                                        </p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <FormControl variant="standard" className='formControl'>
                                    <Skeleton className='bg-dark m-r-8' variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label>
                                            <Skeleton width={150} height={18} />
                                        </label>
                                        <p className='value'>
                                            <Skeleton width={150} height={27} />
                                        </p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <FormControl variant="standard" className='formControl'>
                                    <Skeleton className='bg-dark m-r-8' variant='circular' width={40} height={40} />
                                    <Grid>
                                        <label>
                                            <Skeleton width={150} height={18} />
                                        </label>
                                        <p className='value'>
                                            <Skeleton width={150} height={27} />
                                        </p>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <h5 className='title'>
                            <Skeleton width={170} height={44} />
                        </h5>
                        <Grid container className='formGrid'>
                            <Grid container className='formGrid'>
                                <Grid className='formGrid-inner' >
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label>
                                                <Skeleton width={60} />
                                            </label>
                                            <p className='value'>
                                                <Skeleton width={240} />
                                            </p>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid className='formGrid-inner' >
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label>
                                                <Skeleton width={60} />
                                            </label>
                                            <p className='value'>
                                                <Skeleton width={240} />
                                            </p>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ClientProfileSkeleton;