import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess'
import editIcon from 'assets/img/icons/edit-icon.svg';
import history from "utils/history";
import { FormControl, Slider, Typography } from "@mui/material";
import { SpliceList } from "components/SpliceList/SpliceList";
import { PROFICIENCY_DIVIDER, PROFICIENCY_TAG } from "utils/constant";
import fileIcon from 'assets/img/icons/file-icon.svg';

function PortFolioTab() {
    const userPortfolio = useSelector(state => state.freelancerReducer?.portFolioList[0]);
    const userDetails = useSelector(state => state.authReducer.userDetails);
    return (
        <>
            <Grid container justifyContent="flex-end">
                {
                    userDetails.is_portfolio_in_review &&
                    <Typography className='approval-tag profileInnerApproval-tag'>
                        Professional Detail is under approval
                    </Typography>
                }
                {userDetails.is_portfolio_in_review ||
                    <IconButton className="edit-icon editBtn cursor-pointer" disabled={userDetails?.is_portfolio_in_review} onClick={() => { history.push('/freelancer-profile/edit-professional-details') }}>
                        <img src={editIcon} alt='img' />
                    </IconButton>
                }
            </Grid>
            <p className="CategoryTitle portfolioTabSkill">Technologies</p>
            <Grid>
                <List className="categoryList portfolioTabSkill">
                    <SpliceList data={userPortfolio?.technologies} mainClass="categoryList-item" subClass="more-subcategory" limit={4} />
                </List>
            </Grid>
            <p className="CategoryTitle portfolioTabSkill">Skill</p>
            <Grid>
                <List className="categoryList portfolioTabSkill">
                    <SpliceList data={userPortfolio?.skills} mainClass="categoryList-item" subClass="more-subcategory" limit={5} />
                </List>
            </Grid>
            {userPortfolio?.sub_skills?.length > 0 &&
                <>
                    <p className="CategoryTitle">Sub-skills</p>
                    <Grid>
                        <List className="categoryList">
                            <SpliceList data={userPortfolio?.sub_skills} mainClass="categoryList-item" subClass="more-subcategory" limit={5} />
                        </List>
                    </Grid>
                </>
            }
            <Grid className="aboutContent">
                {userPortfolio?.description &&
                    <>
                        <p className="developer-profile-subtitle">About</p>
                        <p className="content">
                            <ReadMoreReadLess
                                charLimit={500}
                                readMoreText={"Read More"}
                                readLessText={"Read Less"}
                                readMoreClassName="read-more-open-39A1FF"
                                readLessClassName="read-more-close-39A1FF"
                                data={userPortfolio?.description || ""}
                                textType={true}
                            />
                        </p>
                    </>
                }
                {userPortfolio?.specialties &&
                    <>
                        <p className="developer-profile-subtitle">Specialities</p>
                        <p className="content">
                            <ReadMoreReadLess
                                charLimit={200}
                                readMoreText={"Read More"}
                                readLessText={"Read Less"}
                                readMoreClassName="read-more-open-39A1FF"
                                readLessClassName="read-more-close-39A1FF"
                                data={userPortfolio?.specialties || ""}
                                textType={true}
                            />
                        </p>
                    </>
                }
                {/* language slider */}
                <p className="developer-profile-subtitle">Language</p>
                <div className="language-section">
                    {userPortfolio?.communication_language?.map((lang) => (
                        <div className="language-div" key={lang?.communication_language?.key}>
                            <Typography className="language-content" id="langEng-slider">{lang?.communication_language?.value}</Typography>
                            <Typography className="lang-status">{PROFICIENCY_TAG[lang?.proficiency?.id]}</Typography>
                            <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[lang?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                        </div>
                    ))}
                </div>

                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label">ID Proof</label>
                                {userPortfolio?.document_file ?
                                    <Typography variant='p' component='p' className='idProof-name'>
                                        <span><img src={fileIcon} height="16px" alt="img" /></span>
                                        {userPortfolio?.file_name}
                                        <a href={userPortfolio?.document_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                        >View</a>
                                    </Typography>
                                    :
                                    ""
                                }
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default PortFolioTab;
