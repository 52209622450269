import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { List, Typography } from '@mui/material';
import CandidateJobpostCardSkeleton from './CandidateJobpostCardSkeleton';

const RequestDetailsSkeleton = () => {

    return (
        <>
            <Container>
                <Grid container className="profile-top-inner">
                    <Grid item lg={12} md={12} sm={12}>
                        <div className="topcandidate-heading">
                            <Stack spacing={2} className='breadcrumb-div'>
                                <Breadcrumbs className='breadcrumb-nav' separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                    <Link underline="hover" color="inherit" className="cursor-pointer">
                                        <Skeleton width={70} />
                                    </Link>
                                </Breadcrumbs>
                            </Stack >
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container className="top-job-post request-detail">
                <div className="m-t-24">
                    <CandidateJobpostCardSkeleton />
                </div>
                <Grid container>
                    <Grid item lg={12} sm={12}>
                        <div className='job-post-description'>
                            <Typography variant="h4"><Skeleton width={140} /></Typography>
                            <Typography variant="body1">
                                <Skeleton className='transform-scale-1' height={80} />
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='job-technology' spacing={3}>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className='job-post-skill'>
                            <Typography >
                                <Skeleton className='transform-scale-1' width={100} height={20} />
                            </Typography>
                            <Grid>
                                <List className='categoryList'>
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                </List>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12}>
                        <div className='job-post-skill'>
                            <Typography >
                                <Skeleton className='transform-scale-1' width={100} height={20} />
                            </Typography>
                            <Grid>
                                <List className='categoryList'>
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                    <Skeleton className='transform-scale-1 categoryList-item bg-dark' width={100} height={22} />
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid className='location-content'>
                    <div className='location-text'>
                        <Typography variant='h5'><Skeleton className='transform-scale-1' width={200} height={15} /></Typography>
                    </div>
                    <div className='location-time'>
                        <Typography variant='h5'><Skeleton className='transform-scale-1' width={200} height={15} /></Typography>
                    </div>
                </Grid>
            </Container>
        </>
    );
}

export default RequestDetailsSkeleton;