import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { reduxForm, FieldArray } from "redux-form";
import { required, checkUrl } from 'utils/Validation';
import CertificationFields from 'components/FieldArrayComponent/CertificationFields';
import SkipDialog from 'components/Dialog/SkipDialog';

function CertificationStepperContent(props) {
    const { handleSubmit, change, customText, imageUpload, handleSkip } = props;
    const [openSkipDialog, setOpenSkipDialog] = useState(false);

    const handleSkipDialog = () => {
        setOpenSkipDialog(!openSkipDialog);
    }

    return (
        <>
            <h5 className="title">Certification</h5>
            <form onSubmit={handleSubmit} noValidate>
                <FieldArray name="certification" validate={required} imageUpload={imageUpload} component={CertificationFields} change={change} required={required} checkUrl={checkUrl} />
                <Grid className="action-div">
                    <Button
                        type="button"
                        variant="contained"
                        className="primaryButton skipBtn"
                        onClick={handleSkipDialog}
                    >
                        Skip
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="primaryButton nextBtn">
                        {customText.buttonText}
                    </Button>
                </Grid>
            </form>
            <SkipDialog
                open={openSkipDialog}
                close={handleSkipDialog}
                message="Are you sure ?"
                description="If you don't have the certificates then you can skip this, You can add certificates later from the profile"
                confirm={handleSkip}
                buttonText="Yes, Skip"
            />
        </>
    );
}
const ReduxCertificationFormMapped = reduxForm({
    form: "CertificationForm", // a unique identifier for this form
    enableReinitialize: true,
})(CertificationStepperContent);

export default ReduxCertificationFormMapped;