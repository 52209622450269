import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import SearchBar from '../SearchBar';
import 'assets/css/myrequest.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../../store/actions/index";
import { toast } from 'react-toastify';
import AgencyRequestTable from './AgencyRequestTable';
import TableSkeleton from 'views/Jobpost/component/Skeletons/TableSkeleton';
import RequestSkeleton from 'views/Jobpost/component/Skeletons/RequestSkeleton';
import { errorToast } from 'utils/Common';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme?.palette?.background?.paper,
    },
}));


function AgencyRequests() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pageData, setPageData] = useState({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-uid' });
    const candidates = useSelector(state => state?.jobPostReducer?.requestedCandidatesForAgency);
    const pageLoading = useSelector(state => state?.commonReducer?.pageLoading);
    const contentLoading = useSelector(state => state?.commonReducer?.contentLoading);
    const [tableLoading, setTableLoading] = useState(false);
    window.scrollTo(0, 0);

    const getCandidates = (page = 1, pageSize = pageData.pageSize, search = pageData.search, ordering = '-uid') => {
        let status = 'requests'
        dispatch(Actions.getRequestedCandidatesForAgency(status, page, pageSize, search, ordering));
    }

    const candidateActions = (requestId, status, message) => {
        const data = { status }
        dispatch(Actions.jobpostCandidateActions(data, requestId)).then(response => {
            if (response.status === 200) {
                toast.success(message);
                setPageData({ ...pageData, page: 1 })
                getCandidates();
            } else {
                errorToast(response);
            }
        });
    }

    const filterData = (page = pageData.page, pageSize = pageData.pageSize, search = pageData.search, order = pageData.order, orderBy = pageData.orderBy) => {
        if (JSON.stringify(pageData) !== JSON.stringify({ page, pageSize, search, order, orderBy })) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
            getCandidates(page, pageSize, search, orderBy);
            setPageData({ page, pageSize, search, order, orderBy });
        }
    }

    useEffect(() => {
        setTableLoading(true);
        setPageData({ page: 1, pageSize: 10, search: '', order: 'desc', orderBy: '-uid' });
        getCandidates();
        setTimeout(() => {
            setTableLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => { dispatch({ type: "PAGE_LOADING", payload: true }); }
    }, []);
    return (
        <>
            {pageLoading ?
                <RequestSkeleton classes={classes} user='agency' />
                :
                <Grid className="request-top-section" id="agency-request">
                    <Container>
                        <Grid className="request-top-inner">
                            <div className="request-heading">
                                <Typography variant='h3'>Requests</Typography>
                            </div >
                            <div className='request-table'>
                                <div className={classes.root}>
                                    <div className='main-table-filter'>
                                        <div className='searchbar-content'>
                                            <SearchBar filter={filterData} pageData={pageData} />
                                        </div>
                                        <div className='job-post-table'>
                                            {tableLoading ?
                                                <TableSkeleton />
                                                :
                                                <AgencyRequestTable
                                                    data={candidates}
                                                    action={candidateActions}
                                                    filter={filterData}
                                                    page={pageData}
                                                    loading={contentLoading}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid >
                    </Container >
                </Grid >
            }
        </>
    );
}
export default AgencyRequests;