import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "assets/css/privacy-policy.css";
import "assets/css/term-condition.css";
import Footer from 'components/Footer/Footer';
import AdminNavbar from "components/Navbars/AdminNavbar";
import RightSection from './RightSection';
import PageHeader from 'components/CustomPageSection/PageHeader';
import LeftSidebar from 'components/CustomPageSection/LeftSidebar';
import { USER_TYPES } from 'utils/constant';
import { useSelector } from 'react-redux';

function TermsConditions() {

    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const breadCrumbs = [{ name: 'home', path: '/home' }];

    const sideMenu = [{ id: "terms_of_services", title: "Terms of Services" },
    { id: "key_terms", title: "Key Terms" },
    { id: "enrollment", title: "Enrollment" },
    { id: "user_registration", title: "User Registration" },
    { id: "age_restriction", title: "Age Restriction" },
    { id: "consent_and_collection_and_use_of_data", title: "Consent and Collection and Use of Data" },
    { id: "third_party_links", title: "Third- Party Links" },
    { id: "privacy_policy_and_service_agreement", title: "Privacy Policy and Service Agreement" },
    { id: "provision_of_the_service", title: "Provision of the Service" },
    { id: "communications", title: "Communications" },
    { id: "payments", title: "Payments" },
    { id: "rights", title: "Rights" },
    { id: "contacting", title: "Contacting" },
    { id: "termination", title: "Termination" }];

    return (
        <div>
            <div className={`dashboard-main-content ${(userDetails?.user_type === USER_TYPES.CLIENT) ? "dashboard-main-client-content" : ""}`}>
                <div className="main-panel">
                    <AdminNavbar />
                    <div className='privacy-policy custom-bread-crumb' >
                        <PageHeader pageTitle="Terms and Conditions" current="terms and conditions" breadCrumbs={breadCrumbs} className="privacy-policy-section terms-condition-section" />
                        <section className='privacy-introduction term-condition'>
                            <div className='introduction-inner'>
                                <Container>
                                    <Grid container>
                                        <LeftSidebar sideMenu={sideMenu} activeId="terms_of_services" />
                                        <RightSection />
                                    </Grid>
                                </Container>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}


export default TermsConditions