import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "assets/css/profile.css";
import "assets/css/allModals.css";
import editIcon from "assets/img/icons/edit-icon.svg";
import infoIcon from "assets/img/icons/info-icon.svg";
import envelopeIcon from "assets/img/icons/envelope-dark.svg";
import locationIcon from "assets/img/icons/location-dark.svg";
import phoneIcon from "assets/img/icons/phone-dark.svg";
import history from "utils/history";
import { useDispatch, useSelector } from "react-redux";
import PortFolioTab from "./PortFolioTab";
import ProjectTab from "./ProjectTab";
import { getUrl } from "utils/constant";
import * as Actions from "../../../store/actions/index";
import Certificates from "./Certificates";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import IconButton from "@mui/material/IconButton";
import { getAvailability, findAmountPerType, errorToast } from "utils/Common";
import "assets/css/projectCard.css";
import RejectedRequestDialog from "components/Dialog/RejectedRequestDialog";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import { Button } from "@mui/material";
import DynamicDialog from "components/Dialog/DynamicDialog";
import { toast } from "react-toastify";
import ConfirmationDialogue from "components/Dialog/ConfirmationDialogue";
import useRazorpay from "react-razorpay";
import { LoaderContext } from "utils/LoaderContext";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProfilePage(props) {
  const { toggalLoader } = useContext(LoaderContext);
  const { uploadProfileImage } = props;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [Razorpay] = useRazorpay();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const userProfile = useSelector((state) => state.authReducer.userProfile);
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const [currentPlan, setCurrentPlan] = useState({});
  const [nextPlan, setNextPlan] = useState({});
  const currentTabValue = useSelector(
    (state) => state.freelancerReducer?.currentTab
  );
  const [actionDialogue, setActionDialogue] = useState({
    open: false,
    title: "Are you sure ?",
    message: `You want to cancel your subscription? It will remain active until the end of the current billing cycle, which ends on ${moment(
      currentPlan?.is_current_plan?.end_date
    )?.format(
      "DD/MM/YYYY"
    )}. After that, you will no longer be charged for the plan.`,
  });
  const [requestRejectedDialog, setRequestRejectedDialog] = useState({
    open: false,
    message: null,
  });
  const [unblockLoading, setUnblockLoading] = useState(false);
  const [unblockDialog, setUnblockDialog] = useState(false);

  const handleRejectedRequestDialog = (message = null) => {
    setRequestRejectedDialog({
      open: !requestRejectedDialog?.open,
      message: message,
    });
  };

  const breadCrumbs = [
    { name: "home", path: "/home" },
    // { name: 'profile', path: '/profile' },
  ];
  const handleChange = (_event, newValue) => {
    dispatch(Actions.getCurrentTab(newValue));
    const currentTab = getUrl(newValue === undefined ? value : newValue);
    getTabDetails(currentTab);
    setValue(newValue === undefined ? value : newValue);
  };
  const getTabDetails = (currentTab) => {
    dispatch(Actions.getDetails(currentTab.url))
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: currentTab.type, payload: response.data.results });
          if (currentTab?.countType)
            dispatch({
              type: currentTab.countType,
              payload: response.data?.count || 0,
            });
        } else {
          dispatch({ type: currentTab.type, payload: null });
          if (currentTab?.countType)
            dispatch({ type: currentTab.countType, payload: 0 });
        }
      })
      .catch(() => {
        dispatch({ type: currentTab.type, payload: null });
        if (currentTab?.countType)
          dispatch({ type: currentTab.countType, payload: 0 });
      });
  };

  const getMessageOnStatus = () => {
    if (
      userDetails?.is_profile_in_review ||
      userDetails?.is_profile_image_in_review
    ) {
      return (
        <Typography className="approval-tag">
          Your profile is under approval
        </Typography>
      );
    }else if(userDetails?.has_subscription == false&&userDetails?.is_profile_in_review==false&&userDetails?.is_profile_image_in_review==false ){
      return (
        <Typography className="approval-tag purchase-plan-notice">
        You need to purchase a plan to access other services.
      </Typography>
      )
    } else if (userDetails?.is_blocked) {
      return (
        <Button
          className="primaryButton nextBtn"
          variant="contained"
          onClick={() => openUnblockDialog()}
        >
          Unblock
        </Button>
      );
    } else if (userDetails?.last_user_request_status === 3) {
      return (
        <Typography className="rejected-tag">
          Your request has been rejected
          <img
            className="cursor-pointer"
            src={infoIcon}
            onClick={() =>
              handleRejectedRequestDialog(
                userDetails?.last_user_request_rejection_message
              )
            }
          ></img>
        </Typography>
      );
    }
  };

  const openUnblockDialog = () => {
    setUnblockDialog(true);
  };
  const closeUnblockDialog = () => {
    setUnblockDialog(false);
  };
  const handleUnblockFreelancer = async () => {
    setUnblockLoading(true);
    await dispatch(
      Actions.updateProfessionalDetails(userDetails?.id, { is_blocked: false })
    );
    await getTabDetails(userDetails?.id);
    setUnblockLoading(false);
    closeUnblockDialog();
  };
  const fetchCurrentPlan = () => {
    dispatch(Actions.getCurrentPlan()).then((response) => {
      if (response.status == 200) {
        setCurrentPlan(response?.data?.current_plan);
        setNextPlan(response?.data?.next_plan);
      }
    });
  };
  const closeActionDialogue = () => {
    setActionDialogue({ ...actionDialogue, open: false, message: "" });
  };

  const openCancelSubscriptionDialog = () => {
    setActionDialogue({
      ...actionDialogue,
      open: true,
      message: `You want to cancel your subscription? It will remain active until the end of the current billing cycle, which ends on ${moment(
        currentPlan?.is_current_plan?.end_date
      )?.format(
        "DD/MM/YYYY"
      )}. After that, you will no longer be charged for the plan.`,
    });
  };

  const UpgradePlan = async () => {
    closeActionDialogue();
    toggalLoader(true);
    setPaymentLoading(true);
    const makeSubscribe = await dispatch(Actions.subscribe(nextPlan?.id)); //  Create order on your backend
    setPaymentLoading(false);
    if (makeSubscribe.status == 201) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        subscription_id: makeSubscribe?.data?.payment_subscription_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function () {
          toast.info(
            "Your payment is under process. You will get notification on your mail for payment status."
          );
         await dispatch(Actions.updateSubscription(makeSubscribe?.data?.id));
          fetchCurrentPlan();
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(response?.error?.description);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      rzp1.open();
      toggalLoader(false);
    } else {
      errorToast(makeSubscribe);
      toggalLoader(false);
    }
  };

  const cancelSelectedSubscription = () => {
    dispatch(Actions.cancelSubscription(currentPlan?.is_current_plan?.id)).then(
      (response) => {
        if (response.status == 200) {
          fetchCurrentPlan();
          toast.info("Your transaction cancellation request is sent.");
        }
      }
    );
    closeActionDialogue();
  };

  const openUpgradeDialog = () => {
    setDialogType("upgrade");
    setActionDialogue({
      ...actionDialogue,
      open: true,
      message:
        "Once you upgrade to this plan, the data from your current plan will be removed, and you will only be able to use the data from the upgraded plan. This action cannot be undone.",
    });
  };

  useEffect(() => {
    fetchCurrentPlan();
    handleChange("", currentTabValue);
  }, []);

  return (
    <>
      <Grid className="profile-top-section freelancer_profile-top-section">
        <Container>
          <Grid container className="profile-top-inner">
            <Grid item lg={12} md={12} sm={12}>
              <CustomBreadcrumbs current="my-profile" previous={breadCrumbs} />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Container className="profile-container my_profile-container">
        <Grid className="userprofile-detail-section">
          <Grid className="userDetails">
            <Grid className="userImage">
              <ProfilePicture
                userDetails={userDetails}
                uploadProfileImage={uploadProfileImage}
              />
            </Grid>
            <Grid className="userInfo">
              <div>
                <h4>{`${userProfile?.first_name} ${userProfile?.last_name}`}</h4>
                <p className="clientId">
                  ID : <span>{userProfile?.uid}</span>
                </p>
              </div>
              {/* <img className='more_action' src={moreActionIcon} alt='img' /> */}
              <div className="userInfo-right-section">
                {getMessageOnStatus()}
              </div>
            </Grid>
          </Grid>
          <Grid className="companyDetails experienceDetails">
            <Grid className="formGrid">
              <label>Profile Type</label>
              <p>{userProfile?.portfolio?.profile_type?.title}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Experience</label>
              <p>{userProfile?.portfolio?.experience} Years</p>
            </Grid>
            <Grid className="formGrid">
              <label>Projects</label>
              <p>{userProfile?.portfolio?.projects_count || 0}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Availability</label>
              <p>{getAvailability(userProfile?.portfolio?.availability)}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Education</label>
              <p>{userProfile?.portfolio?.education_details}</p>
            </Grid>
            <Grid className="formGrid">
              <label>Price</label>
              <p>
                {findAmountPerType(
                  userProfile?.portfolio?.amount,
                  userProfile?.portfolio?.salary_type
                )}
              </p>
            </Grid>
          </Grid>
        </Grid>

        {/* Freelancer Profile Details */}
        <Grid container className="freelancer_profile-details">
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid className="profile-inner my_profile-inner personalDetail-section">
              <Grid className="title">
                <Typography variant="h5">Personal Details</Typography>
                {!userDetails.is_profile_in_review && (
                  <IconButton
                    className="edit-icon editBtn"
                    onClick={() => history.push("/my-profile-personal-details")}
                  >
                    <img src={editIcon} alt="img" />
                  </IconButton>
                )}
              </Grid>
              <Grid container className="formGrid">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={phoneIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Contact Number</label>
                    <p className="value">{userProfile?.contact_number}</p>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid container className="formGrid">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={envelopeIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Email</label>
                    <p className="value">{userProfile?.email}</p>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid container className="formGrid">
                <FormControl variant="standard" className="formControl">
                  <Avatar className="profile_avatar">
                    <img src={locationIcon} alt="img" />
                  </Avatar>
                  <Grid>
                    <label>Location</label>
                    <p className="value">
                      {userProfile?.city?.display_name || "-"}
                    </p>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Grid className="profile-inner my_profile-inner otherDetail-section">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label="Professional Details"
                    {...a11yProps(0)}
                    className="tab-title"
                  />
                  <Tab
                    label="Projects"
                    {...a11yProps(1)}
                    className="tab-title"
                  />
                  {/* <Tab
                                        label="Work Experience"
                                        {...a11yProps(2)}
                                        className="tab-title"
                                    /> */}
                  <Tab
                    label="Certificates"
                    {...a11yProps(2)}
                    className="tab-title"
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <PortFolioTab />
              </TabPanel>
              <TabPanel value={value} index={1} className="projects">
                <ProjectTab getTabDetails={getTabDetails} currentTab={value} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Certificates
                  getTabDetails={getTabDetails}
                  currentTab={value}
                />
              </TabPanel>
            </Grid>
            {/* 28-3-24 */}
            {currentPlan?.id && (
              <div className="my-plan-section freelancer-profile-plans">
                <Container className="freelancer-profile-container">
                  <div className="my-plan-inner">
                    <div className="my-plan-wrp">
                      <Grid container justifyContent={"space-between"}>
                        <Grid
                          container
                          columnSpacing={3}
                          justifyContent={"space-between"}
                        >
                          <Grid item md={12} xs={12}>
                            <div className="my-plan-title">
                              <Typography variant="h3">My Plan</Typography>
                              <a
                                onClick={() =>
                                  history.push("/plans/freelancer")
                                }
                              >
                                View all plans
                              </a>
                            </div>
                          </Grid>
                          {currentPlan?.id && (
                            <Grid item md={6} xs={12} className="mp-col active">
                              <div className="my-plan-col">
                                <div className="my-plan-item">
                                  <div className="mp-item-content">
                                    <div className="mp-item-content-left">
                                      <Typography variant="h4">
                                        {currentPlan?.name}
                                      </Typography>
                                      <Typography variant="h5">
                                        {currentPlan?.description}
                                      </Typography>
                                    </div>
                                    <div className="mp-item-content-label">
                                      <Typography variant="span">
                                        Current Plan
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="mp-item-price">
                                    <div className="cancel-sub-btn">
                                      {currentPlan?.is_current_plan
                                        ?.is_cancelled == true ? (
                                        <p>
                                          Your subscription has been canceled
                                          and will end on{" "}
                                          {moment(
                                            currentPlan?.is_current_plan
                                              ?.end_date
                                          ).format("DD/MM/YYYY")}
                                          .
                                        </p>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          onClick={openCancelSubscriptionDialog}
                                        >
                                          Cancel Subscription
                                        </Button>
                                      )}
                                    </div>
                                    <div className="price">
                                      <Typography variant="span">
                                        {currentPlan?.currency?.sign}
                                        {parseFloat(currentPlan?.amount)
                                          ?.toFixed(2)
                                          ?.replace(/\.?0+$/, "")}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          )}
                          {nextPlan?.id && (
                            <Grid item md={6} xs={12} className="mp-col">
                              <div className="my-plan-col">
                                <div className="my-plan-item">
                                  <div className="mp-item-content">
                                    <div className="mp-item-content-left">
                                      <Typography variant="h4">
                                        {nextPlan?.name}
                                      </Typography>
                                      <Typography variant="h5">
                                        {nextPlan?.description}
                                      </Typography>
                                    </div>
                                    <div className="mp-item-content-label">
                                      {/* <Typography variant="span">
                                Best for You
                              </Typography> */}
                                    </div>
                                  </div>
                                  <div className="mp-item-price">
                                    <div className="cancel-sub-btn next-plan">
                                      {nextPlan?.is_current_plan
                                        ?.subscription_status ===
                                      "in progress" ? (
                                        <p>
                                          Your plan purchase is currently being processed.
                                        </p>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          disabled={paymentLoading}
                                          onClick={() => openUpgradeDialog()}
                                        >
                                          Upgrade Now
                                        </Button>
                                      )}
                                    </div>
                                    <div className="price">
                                      <Typography variant="span">
                                        {nextPlan?.currency?.sign}{" "}
                                        {parseFloat(nextPlan?.amount)
                                          ?.toFixed(2)
                                          ?.replace(/\.?0+$/, "")}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Container>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>

      <ConfirmationDialogue
        open={actionDialogue.open}
        close={closeActionDialogue}
        title={actionDialogue.title}
        message={actionDialogue.message}
        buttonText="Yes"
        buttonCallback={() =>
          dialogType == "upgrade" ? UpgradePlan() : cancelSelectedSubscription()
        }
      />
      <RejectedRequestDialog
        open={requestRejectedDialog.open}
        close={handleRejectedRequestDialog}
        message={requestRejectedDialog.message}
        userId={userDetails?.id}
      />
      <DynamicDialog
        open={unblockDialog}
        close={closeUnblockDialog}
        loading={unblockLoading}
        title="Are you sure you want to Unblock your Profile?"
        message="Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text."
        cancelButton={true}
        cancelButtonText="Cancel"
        buttonText="Yes, Unblock"
        // icon={phoneIcon}
        dialogClass=""
        contentAlign="left"
        buttonCallback={handleUnblockFreelancer}
      />
    </>
  );
}

export default ProfilePage;
