import React from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";
// core components
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import history from "utils/history";
import AdminLayout from "layouts/Admin.js";
import Login from "views/Authentication/Login/Login";
import ForgetPassword from "views/Authentication/ForgotPassword/ForgetPassword";
import SignUp from "views/Authentication/SignUp/SignUp";
import CheckMail from "views/Authentication/CheckMail";
import ResetPassword from "views/Authentication/ResetPassword/ResetPassword";
import EmailVerification from "views/Authentication/EmailVerification/index";
import VerifyEmail from "views/Authentication/VerifyEmail/VerifyEmail";
import { PersistGate } from 'redux-persist/integration/react';
import "./assets/css/custom.css";
import "./assets/css/make-payment.css"
import "./assets/css/my-profile-client.css"
import { isLogIn } from 'utils/Auth';
import Home from "views/Home";
import PageNotFound from "views/PageNotFound";
import Contact from "views/ContactUs/Contact";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "views/AboutUs/AboutUs";
import Faq from "views/Faq/Faq";
import TermsConditions from "views/Terms_Condition/TermsCondition";
import ScrollToTop from "ScrollToTop";
import UpdateInterview from "views/Jobpost/UpdateInterview/UpdateInterview";
import ClientWork from "views/HowItWorks/ClientWork";
import AgencyWork from "views/HowItWorks/AgencyWork";
import Subscription from "views/SubScriptionPlans/SubScriptionPlans";
import MakePayment from "views/Authentication/MakePayment/MakePayment";
import MyProfileClient from "views/Authentication/MyProfileClient/MyProfileClient";
import "./assets/css/make-payment.css"
import "./assets/css/make-payment-new.css"
import "./assets/css/my-profile-client.css"
import MakePaymentNew from "design/MakePaymentNew/MakePaymentNew";

function App() {
    const getRoute = (name) => {
        if (!isLogIn()) {
            return name;
        } else {
            return () => <Redirect to='/' />
        }
    };

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/login" component={getRoute(Login)} />
                        <Route exact path="/forgetpassword" component={getRoute(ForgetPassword)} />
                        <Route exact path="/resendmail" component={getRoute(CheckMail)} />
                        <Route exact path="/password-reset/:uid/:token" component={ResetPassword} />
                        <Route exact path="/verify-email/:uid/:token" component={VerifyEmail} />
                        <Route exact path="/extend-interview-days/:interview_id/:uid/:token" component={UpdateInterview} />
                        <Route exact path="/signup" component={getRoute(SignUp)} />
                        <Route exact path="/email-verify" component={EmailVerification} />
                        <Route exact path="/contact-us" component={getRoute(Contact)} />
                        <Route exact path="/privacy-policy" component={getRoute(PrivacyPolicy)} />
                        <Route exact path="/plans/client" component={getRoute(Subscription)}/>
                        <Route exact path="/plans/agency" component={getRoute(Subscription)}/>
                        <Route exact path="/plans/freelancer" component={getRoute(Subscription)}/>
                        <Route exact path="/terms-condition" component={getRoute(TermsConditions)} />
                        <Route exact path="/about-us" component={getRoute(AboutUs)} />
                        <Route exact path="/faq" component={getRoute(Faq)} />
                        <Route exact path="/how-it-works/client" component={getRoute(ClientWork)} />
                        <Route exact path="/how-it-works/agency" component={getRoute(AgencyWork)} />
                        <Route exact path="/" component={Home} />
                        <Route exact path='/' render={() => <Redirect to='/home' />} />
                        <Route exact path="/MyProfileClient" component={getRoute(MyProfileClient)} />
                        <Route exact path="/make-payment" component={getRoute(MakePayment)} />
                        <Route exact path="/make-payment-new" component={getRoute(MakePaymentNew)} /> 

                        <AdminLayout></AdminLayout>
                        <Route path='*' component={PageNotFound} />
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;