import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
import InterviewDetailsDialog from './InterviewDetailsDialog';
import RescheduleInterviewDialog from "./RescheduleInterviewDialog";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/index';
import { toast } from 'react-toastify';
import { INTERVIEW_STATUS } from 'utils/constant';
import { errorToast, wrapperDeveloperRedirection, wrapperJobPostRedirection } from 'utils/Common';
import Link from '@mui/material/Link';
import { hasActiveSubscription } from 'utils/constant';

const InterviewWrapper = (props) => {
    const { details, messagePosition } = props;
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.chatReducer?.currentUser);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const [interviewDialog, setInterviewDialog] = useState({ open: false, details: null });
    const [rescheduleInterviewDialog, setRescheduleInterviewDialog] = useState({ open: false, details: null });

    const openDetailPopup = (interviewDetails = null) => {
        const data = { open: true, details: interviewDetails }
        setInterviewDialog(data);
    }

    const closeDetailPopup = (details, reschedule = false) => {
        setInterviewDialog({ ...interviewDialog, open: false });
        reschedule && setTimeout(() => {
            openRescheduleInterviewDialog(details);
        }, 250);
    }

    const openRescheduleInterviewDialog = (interviewDetails = null) => {
        const data = { open: true, details: { ...interviewDetails, time: new Date(`${interviewDetails?.date} ${interviewDetails?.time} UTC`),
        date: new Date(`${interviewDetails?.date} ${interviewDetails?.time} UTC`)
    } }
        setRescheduleInterviewDialog(data);
    }
    const closeRescheduleInterviewDialog = () => {
        setRescheduleInterviewDialog({ ...rescheduleInterviewDialog, open: false });
    }

    const handleInterviewReschedule = (values) => {
        const postData = {
            date: moment(values?.time).local().format("YYYY-MM-DD"),
            time: moment(values?.time).local().format("HH:mm:ss"),
            timezone: moment.tz.guess(),
            description: values?.description,
            meet_link: values?.meet_link,
            chat_room: currentUser?.room,
        }
        return dispatch(Actions.scheduleAnInterview(postData, values?.id)).then(response => {
            if (response.status === 200) {
                closeRescheduleInterviewDialog();
                toast.success('Interview re-scheduled successfully');
            } else {
                errorToast(response);
            }
        }).catch(error => {
            toast.error(error.message);
        })
    }

    return (
        <div className={(messagePosition == 'left') ? 'interview-wrapper interview-left-wrapper' : "interview-wrapper"}>
            <div className='interview-main-box'>
                <div className='interview-text-heading'>
                    <Typography variant='h6'>{(details.status === INTERVIEW_STATUS.RESCHEDULED) ? 'Interview Rescheduled' : 'Interview Scheduled'} </Typography>
                    <Typography variant='h3'>{moment(details?.date + ' ' + details?.time).local().format('DD/MM/YYYY, hh:mm A')} </Typography>
                    <Typography variant='body1'>{details?.description}</Typography>
                    <div className='interview-client-id'>
                        <Typography variant='body1'>Job post : <span className='wrapper-custom-id'><Link href={wrapperJobPostRedirection(userDetails?.user_type, details?.job_post?.id, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.job_post?.uid}</Link></span></Typography>
                        <Typography variant='body1'>Developer : <span className='wrapper-custom-id'><Link href={wrapperDeveloperRedirection(userDetails?.user_type, details?.candidate?.id, details?.job_post_proposal)} target='_blank'>{details?.candidate?.uid}</Link></span></Typography>
                    </div>
                </div>
            </div>
            {details?.show_detail&&
                <div className='interview-btn-schedule'>
                    <div className='interview-view-btn' >
                        <Button className='btn btn-secondary' onClick={() => openDetailPopup(details)} disabled={!hasActiveSubscription(userDetails)}>View Detail</Button>
                    </div>
                    {/* {user.user_type === USER_TYPES.CLIENT && */}
                    <div className='interview-view-btn' >
                        <Button className='btn btn-primary' onClick={() => openRescheduleInterviewDialog(details)} disabled={!hasActiveSubscription(userDetails)}>Reschedule</Button>
                    </div>
                    {/* } */}
                </div>
            }
            {interviewDialog &&
                <InterviewDetailsDialog
                    open={interviewDialog.open}
                    close={closeDetailPopup}
                    details={interviewDialog?.details}
                    opponent={currentUser?.opponent}
                />
            }
            {rescheduleInterviewDialog?.open &&
                <RescheduleInterviewDialog
                    open={rescheduleInterviewDialog?.open}
                    close={closeRescheduleInterviewDialog}
                    initialValues={rescheduleInterviewDialog?.details}
                    opponent={currentUser?.opponent}
                    onSubmit={values => handleInterviewReschedule(values)}
                />
            }
        </div>
    );
}

export default InterviewWrapper;