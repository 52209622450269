import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions/index";
import Grid from "@mui/material/Grid";
import "assets/css/add-developer.css";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput";
import ChipAutocomplete from "components/CustomeAutoComplete/ChipAutocomplete";
import SingleAutocomplete from "components/CustomeAutoComplete/SingleAutocomplete";
import { required, checkIsNumber, notZero, checkExperience, checkPhoneNumber,charWithDot, charLimitWithTrim, requiredWithRegex, email, characterLimit } from "utils/Validation";
import CustomFileUpload from "components/customUpload/CustomFileUpload";
import history from "utils/history";
import { FILETYPE, AVAILABILITY, SALARY_TYPE } from "utils/constant";
import LanguageField from "components/FieldArrayComponent/LanguageField";
import { CustomEditor } from "components/CustomEditor/CustomEditor";
import { SelectField } from "components/CustomeAutoComplete/AsynAutocomplete";
import { MenuItem } from "@mui/material";
import { avoidKeysForNumber, getCountryCode, avoidKeysForPlainNumber } from "utils/Common";
import UnderApprovedDialog from "components/Dialog/UnderApprovedDialog";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import InputAdornment from '@mui/material/InputAdornment';
import dollar from 'assets/img/dollar.png';
import { charOnly } from "utils/Validation";

function DeveloperDetailForm(props) {
    const { formValues } = props;
    const dispatch = useDispatch();
    const { handleSubmit, change, initialValues, isAddType, handleImageUpload, files, uplaodProfile, submiting } = props;
    const [languages, setLanguages] = useState([]);
    const [profileTypes, setProfileTypes] = useState([]);
    const [time, setTime] = useState("");
    const technologies = useSelector((state) => state.commonReducer?.technologies);
    const path = window.location.pathname;
    const subSkillsList = useSelector(state => state.commonReducer?.subSkillsList);
    const skillsList = useSelector(state => state.commonReducer?.skillsList);
    const proficiency = useSelector(state => state.commonReducer?.proficieny);
    const developerDetails = useSelector((state) => state.manageDevelopersReducer?.agencyDeveloperDetails);
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);

    const handleChangePhone = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }

    const getProfileTypes = () => {
        dispatch(Actions.getProfileTypes()).then((response) => {
            if (response.status === 200) {
                setProfileTypes(response.data);
            } else {
                setProfileTypes([]);
            }
        }).catch(() => {
            setProfileTypes([]);
        });
    };

    const populateIds = (array) => {
        let ids = [];
        if (Array.isArray(array) && array.length > 0) {
            array.forEach(elem => {
                ids.push(elem.id);
            });
        }
        return ids;
    }

    const getSubSkills = (skills, initial = false) => {
        const skillIds = populateIds(skills);
        if (skillIds.length > 0) {
            dispatch(Actions.getSubSkills(skillIds.toString())).then(response => {
                if (response.status === 200) {
                    dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: response.data });
                    if (!initial) {
                        let selected = populateIds(formValues?.portfolio?.sub_skills);
                        let temp = [];
                        response.data.forEach((element) => {
                            if (selected.includes(element.id)) temp.push(element);
                        });
                        change("portfolio.sub_skills", temp);
                    }
                } else {
                    change("portfolio.sub_skills", []);
                    dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
                }
            }).catch(() => {
                change("portfolio.sub_skills", []);
                dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
            });
        } else {
            change("portfolio.sub_skills", []);
            dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
        }
    }

    const getSkills = () => {
        dispatch(Actions.getSkills());
    };

    const getLanguages = (name, keys) => {
        clearTimeout(time);
        setTime(
            setTimeout(() => {
                dispatch(Actions.getLanguages(name)).then((response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        if (keys && keys.length > 0) {
                            data = data.filter(elem => !keys.includes(elem.key));
                        }
                        setLanguages(data);
                    } else {
                        setLanguages([]);
                    }
                }).catch(() => {
                    setLanguages([]);
                });
            }, 1000)
        );
    };
    const getProficiency = () => {
        dispatch(Actions.getProficiency());
    }

    const handleRemoveLanguages = () => {
        setLanguages([]);
    }
    const getTechnologies = () => {
        dispatch(Actions.getTechnologiesList());
    }
    const backToHome = () => {
        history.push('/manage-developers');
    }
    const handleCancelRedirection = () => {
        if (path !== '/manage-developers/add') {
            history.push(`/developer-profile/${developerDetails?.id}`);
        } else {
            history.push('/manage-developers');
        }
    }

    useEffect(() => {
        getTechnologies();
        getProfileTypes();
        getLanguages("");
        getProficiency();
        getSkills();
        return () => {
            change("portfolio.sub_skills", []);
            dispatch({ type: 'GET_SUB_SKILLS_LIST', payload: [] });
        }
    }, []);

    useEffect(() => {
        if (initialValues && initialValues?.portfolio?.skills && !isAddType) {
            getSubSkills(initialValues.portfolio.skills, true);
        }
    }, [initialValues]);

    useEffect(() => {
        if (initialValues?.contact_number) {
            setCountryCode(getCountryCode(initialValues.contact_number));
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number_without_code', `+91`);
        }
    }, [initialValues])

    return (
        <>
            <Grid className="add_profile-form add_developer-form">
                {(path === '/manage-developers/add') &&
                    <h5 className="title">Developer Details</h5>
                }
                <form onSubmit={handleSubmit} noValidate>
                    {(path === '/manage-developers/add') &&
                        <Grid item lg={12} md={12} sm={12} className="text-center">
                            <ProfilePicture files={files} uploadProfileImage={uplaodProfile} type='add' />
                        </Grid>
                    }
                    {isAddType &&
                        <>
                            <Grid container className="formGrid">
                                <Grid className="formGrid-inner">
                                    <Field
                                        name="first_name"
                                        component={CustomInput}
                                        id="first_name"
                                        labelText="First Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required,charOnly]}
                                        inputProps={{
                                            placeholder: "Enter first name",
                                            type: "text",
                                        }}
                                        normalize={charLimitWithTrim(50)}
                                    />
                                </Grid>
                                <Grid className="formGrid-inner">
                                    <Field
                                        name="last_name"
                                        component={CustomInput}
                                        id="last_name"
                                        labelText="Last Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required,charOnly]}
                                        inputProps={{
                                            placeholder: "Enter last name",
                                            type: "text",
                                        }}
                                        normalize={charLimitWithTrim(50)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="formGrid">
                                <Grid className="formGrid-inner">
                                    <Field
                                        name="email"
                                        component={CustomInput}
                                        id="email"
                                        labelText="Email*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, email]}
                                        inputProps={{
                                            placeholder: "Enter email",
                                            type: "email",
                                        }}
                                    />
                                </Grid>
                                <Grid className="formGrid-inner">
                                    {/* <Field
                                        name="contact_number_without_code"
                                        component={CustomInput}
                                        id="contact_number_without_code"
                                        labelText="Contact Number*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, phoneNumber]}
                                        inputProps={{
                                            placeholder: "Enter contact number",
                                            type: "number",
                                        }}
                                        normalize={normalizePhone(10)}
                                        onKeyDown={avoidKeysForNumber}
                                    /> */}
                                    <Field
                                        name="contact_number"
                                        component={PhoneNumberField}
                                        id="contact_number"
                                        labelText="Contact Number*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[checkPhoneNumber]}
                                        inputProps={{
                                            placeholder: "Enter contact number",

                                        }}
                                        phone={phoneNumber}
                                        countryCode={countryCode}
                                        handleChangePhone={handleChangePhone}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                    <Grid className="formGrid-inner">
                        <Field
                            name="portfolio.profile_type"
                            component={SingleAutocomplete}
                            id="portfolio.profile_type"
                            labelText="Profile Type*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            options={profileTypes}
                            displayKey="title"
                            validate={[required]}
                            onChange={(value) => value}
                            onInputChange={(value) => value}
                            inputProps={{
                                placeholder: "Select profile type",
                            }}
                        />
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.technologies"
                                component={ChipAutocomplete}
                                id="portfolio.technologies"
                                labelText="Technologies*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={technologies}
                                displayKey="title"
                                validate={[required]}
                                inputProps={{ placeholder: "Select technologies" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Field
                            name="portfolio.skills"
                            component={ChipAutocomplete}
                            id="skills"
                            labelText="Skills*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            options={skillsList}
                            displayKey="title"
                            validate={[required]}
                            onChange={(_event, value) => getSubSkills(value)}
                            onInputChange={(value) => value}
                            inputProps={{ placeholder: "Select skills" }}
                        />
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.sub_skills"
                                component={ChipAutocomplete}
                                id="portfolio.sub_skills"
                                labelText="Sub-Skills"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={subSkillsList}
                                displayKey="title"
                                inputProps={{
                                    placeholder: "Select sub skills",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.experience"
                                component={CustomInput}
                                id="portfolio.experience"
                                labelText="Work Experience*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[checkExperience]}
                                inputProps={{
                                    placeholder: "Enter experience",
                                    type: "number",
                                }}
                                onKeyDown={avoidKeysForNumber}
                                normalize={charLimitWithTrim(2)}
                            >
                            </Field>
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.availability"
                                component={SelectField}
                                id="portfolio.availability"
                                labelText="Availability*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkIsNumber, notZero]}
                                placeholder="Enter Hours"
                                inputProps={{
                                    placeholder: "Enter Hours",
                                    min: 1,
                                }}>
                                {AVAILABILITY.map(avail => (
                                    <MenuItem value={avail.id} key={avail.id}>
                                        {avail.title}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>

                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.salary_type"
                                component={SelectField}
                                id="portfolio.salary_type"
                                labelText="Salary Type"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Select salary type",
                                    min: 1,
                                }}
                                onChange={() => { change('portfolio.amount', '') }}
                            >
                                {SALARY_TYPE.map(type => (
                                    <MenuItem value={type.id} key={type.id}>
                                        {type.title}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid className="formGrid-inner amount-box">
                            <Field
                                name="portfolio.amount"
                                component={CustomInput}
                                id="portfolio.amount"
                                labelText="Amount"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[checkIsNumber, notZero, required]}
                                normalize={characterLimit(6)}
                                onKeyDown={avoidKeysForPlainNumber}
                                placeholder="Enter amount"
                                inputProps={{
                                    placeholder: "Enter amount",
                                    type: "number",
                                    endAdornment: (
                                        <InputAdornment position="end" className="amount-duration">
                                            <img src={dollar} />
                                        </InputAdornment>
                                    )
                                }}>
                            </Field>
                        </Grid>
                    </Grid>


                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.education_details"
                                component={CustomInput}
                                id="portfolio.education_details"
                                labelText="Education*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, charWithDot]}
                                inputProps={{
                                    placeholder: "Enter education",
                                    type: "text",
                                }}
                                normalize={charLimitWithTrim(100)}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                type="file"
                                name="portfolio.document_file"
                                component={CustomFileUpload}
                                id="postfolio_document"
                                onChange={(e) => handleImageUpload(e)}
                                validate={[required]}
                                mimeType={FILETYPE.file}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.specialties"
                                component={CustomEditor}
                                id="portfolio.specialties"
                                labelText="Specialities"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                // validate={requiredWithRegex}
                                inputProps={{
                                    placeholder: "Enter specialities"
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.description"
                                component={CustomEditor}
                                id="portfolio.description"
                                labelText="About*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={requiredWithRegex}
                                inputProps={{
                                    placeholder: "Enter text"
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <FieldArray
                            name="portfolio.communication_language"
                            change={change}
                            required={required}
                            component={LanguageField}
                            languages={languages || []}
                            getLanguages={getLanguages}
                            proficiency={proficiency}
                            removeLanguages={handleRemoveLanguages}
                        />
                    </Grid>
                    <Grid className="action-div">
                        <Link onClick={handleCancelRedirection} underline="none" className="previousBtn cursor-pointer">
                            Cancel
                        </Link>
                        <Button
                            type="submit"
                            variant="contained"
                            className="primaryButton nextBtn"
                            disabled={submiting}
                        >
                            {isAddType ? "Next" : "Save"}
                        </Button>
                    </Grid>
                </form >
            </Grid >
            <UnderApprovedDialog open={developerDetails ? developerDetails?.is_portfolio_in_review : false} backToHome={backToHome} />
        </>

    );
}

const DeveloperDetailsReduxForm = reduxForm({
    form: "DeveloperDetailForm", // a unique identifier for this form
    enableReinitialize: true,
})(DeveloperDetailForm);

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues('DeveloperDetailForm')(state),
    }
};

export default connect(mapStateToProps)(DeveloperDetailsReduxForm);
